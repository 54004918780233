import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux';
import './NavigationBar.scss'
import Logo from '../../logo.svg';

const NavigationBar = ({
  isOpenProfileMenu, openProfileMenu, collapseMenu, logOut, name, leadsLeft, email, is_confirmed, location, userInfo
}) => (
  <nav
    className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-light navbar-hide-on-scroll navbar-border navbar-shadow navbar-brand-center">
    <div className="navbar-wrapper" style={{ height: '100%' }}>
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row" style={{height: '77px'}}>
          <li className={`nav-item ${location.pathname ==='/' ? 'active' : ''}`}>
            <Link className="navbar-brand" to="/">КВИЗЫ</Link>
          </li>
          <li className={`nav-item ${location.pathname.slice(0, 6) ==='/leads' ? 'active' : ''}`}>
            <div>
              <Link className="navbar-brand" to="/leads">ЗАЯВКИ</Link>
              <span className="badge badge badge-danger badge-pill float-right mr-2 margin-right-quizzes" style={{marginBottom: '2px', lineHeight: '24px', minWidth: '31px'}}>{userInfo && userInfo.new_leads ? '+ ' +  userInfo.new_leads : 0}</span>
            </div>
          </li>
          <li className={`nav-item ${location.pathname.slice(0, 10) ==='/templates' ? 'active' : ''}`}>
            <Link className="navbar-brand" to="/templates">ШАБЛОНЫ </Link>
          </li>
          <li className={`nav-item ${location.pathname.slice(0, 9) ==='/packages' ? 'active' : ''}`}>
            <Link className="navbar-brand" to="/packages">ОПЛАТА </Link>
          </li>
          <li className={`nav-item ${location.pathname.slice(0, 8) ==='/support' ? 'active' : ''}`}>
            <Link className="navbar-brand" to="/support">ПОДДЕРЖКА</Link>
          </li>
          <li className={`nav-item ${location.pathname.slice(0, 8) ==='/partner' ? 'active' : ''}`}>
            <Link className="navbar-brand" to="/partner">ПАРТНЕРАМ</Link>
          </li>
        </ul>
      </div>
      <div className="navbar-container content">
        <div className="collapse navbar-collapse" id="navbar-mobile">
          <ul className="nav navbar-nav mr-auto float-left">
            <Link className="navbar-brand" to="/">
              <img className="brand-logo" alt="modern admin logo" src={Logo} style={{ width: '3.5rem' }} />
            </Link>
          </ul>
          <ul className="nav navbar-nav float-right navbar-right">
            <li>
                <i class="far fa-bell"></i>
            </li>
            <li>
              <a href='https://enquiz.io/docs/' target="_blank">
                <i class="far fa-question-circle"></i>
              </a>
              
            </li>
            <li className="dropdown dropdown-user nav-item">
              <Dropdown isOpen={isOpenProfileMenu} toggle={openProfileMenu} className="dropdown-toggle nav-link dropdown-user-link">
                <DropdownToggle style={{ backgroundColor: 'transparent', border: 0, fontSize: '1.15rem' }} className="dropdown-toggle nav-link dropdown-user-link dropdown-fix" href="#" data-toggle="dropdown">
                <span className="mr-1">
                <i class={`far fa-user ${location.pathname ==='/profile' ? 'active' : ''}`}></i>

                </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-right">
                  <Link to="/profile" style={{ color: '#2A2E30' }}>
                    <DropdownItem className="drop-down-item-menu">
                        <i class="fas fa-cog"></i>
                        <FormattedMessage id="navigationBar.profileSetting" defaultMessage="Настройки профиля" />
                    </DropdownItem>
                  </Link>
                  <DropdownItem className="drop-down-item-menu" href="#" onClick={logOut}>
                    <i class="fas fa-sign-out-alt"></i>
                    <FormattedMessage id="navigationBar.logout" defaultMessage="Выйти из аккаунта" />
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>      
          </ul>
        </div>
      </div>
    </div>
  </nav>
)


const mapStateToProps = state => ({
  userInfo: state.userInfo,
})

export default connect(mapStateToProps)(NavigationBar)
