import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Intl } from '../../utils'
import { AuthService, RecoveryService } from '../../services'

class RecoveryPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      isSubmit: false,
      email: null,
      password: null,
      passwordConfirm: null,
      message: null,
      hash: null,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async componentDidMount() {
    const hash = new URLSearchParams(this.props.location.search).get('hash')
    this.setState({ hash })
    if (!hash) {
      document.getElementById('public_container').classList.remove('container')
    } else {
      this.setState({ step: 2 })
    }
  }

  componentWillUnmount() {
    document.getElementById('public_container').classList.add('container')
  }

  handleChange(event) {
    const { target: { value, name } } = event
    this.setState({
      [name]: value,
      message: null,
    })
  }

  async handleSubmit(event) {
    event.preventDefault()
    const isSubmit = true
    const {
      step, email, password, passwordConfirm, hash,
    } = this.state

    if (step === 1) {
      try {
        await RecoveryService.checkEmail({ email })
        this.setState({ isSubmit })
      } catch (error) {
        this.setState({ message: error.message })
      }
    }

    if (step === 2) {
      try {
        await RecoveryService.changePassword({ password, passwordConfirm, hash })
        this.setState({ isSubmit })
      } catch (error) {
        this.setState({ message: error.message })
      }
    }
  }

  render() {
    if (AuthService.isUserAuthenticated()) return <Redirect to="/" />

    const { message, step, isSubmit } = this.state

    return (
      <div>
        {step === 1 && (
          <section className="flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="box-shadow-2 p-0" style={{ width: '30rem' }}>
                {!isSubmit && (
                <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                  <div className="card-header border-0 pb-0">
                    <div className="card-title text-center">
                      <img src={require('../../enquiz_logo.svg')} alt="branding logo" style={{ width: '200px' }} />
                    </div>
                    <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                      <FormattedMessage id="recoveryPage.weWillSendLink" defaultMessage="Мы вышлем ссылку для смены пароля" />
                    </h6>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <div style={{ color: 'red', paddingBottom: '10px' }}>
                        { message && <FormattedMessage id={message} /> }
                      </div>
                      <form className="form-horizontal" action="login-simple.html" onSubmit={this.handleSubmit}>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="email"
                            className="form-control form-control-lg input-lg"
                            id="user-email"
                            placeholder={Intl.getMessage('recoveryPage.enterEmail')}
                            name="email"
                            required
                            onChange={this.handleChange}
                          />
                          <div className="form-control-position">
                            <i class="far fa-envelope"></i>
                          </div>
                        </fieldset>
                        <button type="submit" className="btn btn-outline-info btn-lg btn-block">
                          <i className="ft-unlock"></i>
                          <FormattedMessage id="recoveryPage.recoveryPassword" defaultMessage=" Восстановить пароль" />
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="card-footer border-0">
                    <p className="float-sm-left text-center">
                      <Link to="/auth" className="card-link">
                        <FormattedMessage id="authPage.login" defaultMessage=" Вход" />
                      </Link>
                    </p>
                    <p className="float-sm-right text-center">
                      <FormattedMessage id="authPage.notHaveAccount" defaultMessage="Еще нет аккаунта? " />
                      <Link to="/register" className="card-link">
                        <FormattedMessage id="authPage.createFree" defaultMessage="Создайте бесплатно" />
                      </Link>
                    </p>
                  </div>
                </div>
                )}
                {isSubmit && (
                  <div>
                    <div className="card-header bg-transparent border-0">
                      <h2 className="error-code text-center mb-2">
                        <FormattedMessage id="recoveryPage.thanks" defaultMessage="Спасибо" />
                      </h2>
                      <h3 className="text-uppercase text-center">
                        <FormattedMessage id="recoveryPage.linkSent" defaultMessage="На Ваш email выслана ссылка для смены пароля" />
                      </h3>
                    </div>
                    <div className="card-content">
                      <div className="">
                        <Link to="/" className="btn btn-primary btn-block">
                          <i className="ft-home"></i>
                          <FormattedMessage id="recoveryPage.returnToIndexPage" defaultMessage=" Возврат на главную страницу" />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
        {step === 2 && (
          <section className="flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-md-4 col-10 box-shadow-2 p-0">
                {!isSubmit && (
                <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                  <div className="card-header border-0">
                    <div className="card-title text-center">
                      <img src={require('./assets/images/logo/logo-dark.png')} alt="branding logo" />
                    </div>
                    <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                      <FormattedMessage id="recoveryPage.changePassword" defaultMessage="Смена пароля" />
                    </h6>
                  </div>
                  <div className="card-content">
                    <div className="card-body">
                      <div style={{ color: 'red', paddingBottom: '10px' }}>
                        { message && <FormattedMessage id={message} /> }
                      </div>
                      <form className="form-horizontal form-simple" action="index.html" onSubmit={this.handleSubmit}>
                        <fieldset className="form-group position-relative has-icon-left mb-1">
                          <input
                            type="password"
                            className="form-control form-control-lg input-lg"
                            id="user-password2"
                            placeholder={Intl.getMessage('recoveryPage.newPassword')}
                            name="password"
                            required
                            onChange={this.handleChange}
                          />
                          <div className="form-control-position">
                            <i class="fas fa-key"></i>
                          </div>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left">
                          <input
                            type="password"
                            className="form-control form-control-lg input-lg"
                            id="user-password"
                            placeholder={Intl.getMessage('recoveryPage.newPasswordRepeat')}
                            name="passwordConfirm"
                            required
                            onChange={this.handleChange}
                          />
                          <div className="form-control-position">
                            <i class="fas fa-key"></i>
                          </div>
                        </fieldset>
                        <button type="submit" className="btn btn-info btn-lg btn-block">
                          <i className="ft-unlock"></i>
                          <FormattedMessage id="recoveryPage.changePasswordBtn" defaultMessage=" Сменить пароль" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                )}
                {isSubmit && (
                  <div>
                    <div className="card-header bg-transparent border-0">
                      <h2 className="error-code text-center mb-2">
                        <FormattedMessage id="recoveryPage.passwordChanged" defaultMessage="Пароль изменен"/>
                      </h2>
                      <h3 className="text-uppercase text-center">
                        <FormattedMessage id="recoveryPage.nowYouCanAuth"
                                          defaultMessage="Теперь вы можете авторизоваться используя новый пароль"/>
                      </h3>
                    </div>
                    <div className="card-content">
                      <div className="">
                        <Link to="/" className="btn btn-primary btn-block">
                          <i className="ft-home"></i>
                          <FormattedMessage id="recoveryPage.enterSystem" defaultMessage=" Зайти в систему"/>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
      </div>
    )
  }
}

export default RecoveryPage
