import * as Cookies from 'universal-cookie'

const config = require('../config/config')

const cookies = new Cookies()

class AuthService {
  static async register(data) {
    try {
      const body = {
        service: 'go.micro.srv.userd',
        method: 'UsersDirectory.CreateUser',
        request: {
          user: data,
        },
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) {
        response = await response.json()

        switch (response.detail) {
          case 'Not valid email':
            throw new Error('registerPage.errorNotValidEmail')
          case 'Passwd is too short':
            throw new Error('registerPage.errorPasswdToShort')
          case 'This email is already used':
            throw new Error('registerPage.errorEmailAlreadyUsed')
          default:
            throw new Error(response.detail)
        }
      }

      return true
    } catch (error) {
      throw error
    }
  }

  static async auth(data) {
    try {
      const body = {
        service: 'go.micro.srv.userd',
        method: 'UsersAuthentication.AuthUser',
        request: data,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      response = await response

      if (response.status !== 200) {
        response = await response.json()

        switch (response.detail) {
          case 'Wrong email or password':
            throw new Error('authPage.errorWrongEmailOrPassword')
          case 'Wrong email address':
            throw new Error('registerPage.errorNotValidEmail')
          default:
            throw new Error(response.detail)
        }
      }

      response = await response.json()
      const cookieDate = new Date()
      cookieDate.setDate(cookieDate.getDate() + 170)
      
      cookies.set('token', response.token, { path: '/', domain: config.COOKIE_DOMAIN, expires: cookieDate })

      return true
    } catch (error) {
      throw error
    }
  }

  static async confirmEmail(data) {
    try {
      const body = {
        service: 'go.micro.srv.userd',
        method: 'UsersDirectory.ConfirmEmail',
        request: data,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status === 200) return true

      return false
    } catch (error) {
      throw error
    }
  }

  static async getUserInfo() {
    try {
      const body = {
        service: 'go.micro.srv.userd',
        method: 'UsersDirectory.GetUser',
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.getToken(),
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) return false
      response = await response.json()
      
      return response
    } catch (error) {
      throw error
    }
  }
  
  static async logOut() {
    try {
      const body = {
        service: 'go.micro.srv.userd',
        method: 'UsersAuthentication.LogoutUser',
        request: {
          token: this.getToken(),
        },
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status !== 200) return false
      
      return true
    } catch (error) {
      throw error
    }
  }
  
  static async checkEmail() {
    try {
      const body = {
        service: 'go.micro.srv.userd',
        method: 'UsersDirectory.SendConfirmation',
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status !== 200) return false
      
      return response
    } catch (error) {
      throw error
    }
  }

  static isUserAuthenticated() {
    return (!!cookies.get('token') && !!this.getUserInfo())
  }

  static deauthenticateUser() {
    clearInterval(+localStorage['intervalId'])
    this.logOut()
    cookies.remove('token', { path: '/', domain: config.COOKIE_DOMAIN })
  }

  static getToken() {
    return cookies.get('token')
  }

  static getRefID() {
    return cookies.get('refid')
  }
  
  static async changeUserDetail(request) {
    try {
      const body = {
        service: 'go.micro.srv.userd',
        method: 'UsersDirectory.UpdateUser',
        request,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      
      response = await response.json()
      return response
    } catch (error) {
      throw error
    }
  }
  
  static async changeUserPassword(request) {
    try {
      const body = {
        service: 'go.micro.srv.userd',
        method: 'UsersDirectory.ChangePassword',
        request,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status === 200) return true
      return false
    } catch (error) {
      throw error
    }
  }
}

export default AuthService
