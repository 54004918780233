const initialState = {
  name: '',
  email: '',
  allow_promos: '',
}

const userInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_INFO':
      return { ...action.payload }
    default:
      return state
  }
}

export default userInfo
