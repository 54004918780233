import React from 'react';
import { Collapse, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'
import { FormattedMessage } from 'react-intl'
import { Intl } from '../../utils'
import { Tooltip } from '../../components'
import { SketchPicker } from 'react-color'
import { Switch } from '../index'
import Answer from './Answer';
import doubleCheckIcon from './double-check.svg';
import asteriskIcon from './asterisk-symbol.svg';
const config = require('../../config/config');

const imageSize = '200,fit';

const SortableItem = SortableElement(({value}) => {
    return (
      <div>
        {value}
      </div>
    );
  });

const DragHandle = SortableHandle(() => (<i className="fas fa-bars" title={Intl.getMessage('step3.move')} style={{cursor: 'move'}}></i>));

const SortableListAnswers = SortableContainer(({
    kind,
    title,
    desc,
    image,
    items,
    id,
    isCollapsed,
    deleteQuestion,
    collapse,
    copyQuestion,
    toggleModalAnswer,
    toggleModalEditQuestion,
    getPreview,
    collapsePreview,
    handleClickNewAnswer,
    previewId,
    allow_multiple,
    required
  }) => {

  return (
    <div>
      <div className={`${(previewId!==undefined && previewId===id) ? 'active' : ''} card box-shadow-0 border-primary bg-blue-grey bg-lighten-4 border-primary border-accent-1 card-quest`}
          id={`${id}_question`}
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            !e.target.closest('.list-inline') && !e.target.closest('.btn-create') && !e.target.closest('.option-type') && getPreview()
          }}
      >
      <div className="card-header">
        <span className="badge badge-default badge-info" title="">{!isCollapsed ? 'Вопрос' : ''} {id + 1}</span>&nbsp;
        {isCollapsed && <span className="card-header-title prune">{title}</span>}
        {!isCollapsed && <span className="badge badge-default badge-danger" title="">
          {kind === 'option' && (<div><i class="fas fa-list"></i><span></span>Варианты</div>)}
          {kind === 'option_image' && (<div><i class="fas fa-images"></i><span>Варианты ответа + изображение</span></div>)}
          {kind === 'numeric' && (<div><i class="fas fa-tachometer-alt"></i><span> Числовое</span></div>)}
          {kind === 'text' && (<div><i class="fas fa-terminal"></i><span>Проивольный текст</span></div>)}
          {kind === 'file' && (<div><i class="fas fa-cloud-upload-alt"></i><span>Загрузка файла</span></div>)}
          {kind === 'motivator' && (<div><i class="fas fa-bullhorn"></i><span>Мотиватор</span></div>)}
          {kind === 'option_yesNo' && (<div><i class="fas fa-question-circle"></i><span></span>Да/Нет</div>)}
        </span>}&nbsp;
        {(required && !isCollapsed) ? <span className="badge" title={Intl.getMessage('step3.requiredTitle')}><img style={{width: '18px', marginLeft: '5px'}} src={asteriskIcon} alt="asterisk"/></span> : null}
        {(allow_multiple && !isCollapsed) && <span><img style={{width: '20px', marginLeft: '5px'}} src={doubleCheckIcon} alt="check"/></span>}
        {/* <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a> */}
        <div className="heading-elements">
          <ul className="list-inline mb-0">
            <li title={Intl.getMessage('step3.collapse')} onClick={collapse}>
              <a data-action="collapse" onClick={collapsePreview}>
                {!isCollapsed && (<i class="fas fa-chevron-up"></i>)}
                {isCollapsed && (<i class="fas fa-chevron-down"></i>)}
              </a>
            </li>
            <li title={Intl.getMessage('step3.copyQuestion')} onClick={copyQuestion}><a><i className="ft-copy"></i></a></li>
            <li><DragHandle/></li>
            <li title="Удалить" onClick={deleteQuestion}><a data-action="close"><i class="fas fa-times"></i></a></li>
          </ul>
        </div>
      </div>
      <Collapse isOpen={!isCollapsed} className="card-content">
        <div className="card-body cards-body">
          <h4 className="card-title question-title" style={{marginTop: '0'}}>{title}</h4>
          {desc && (<p>{desc}</p>)}
          {image && (<img src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${image.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net")}`} alt="" className="round-image" />)}
          <div className="row">
            <div className="col-md-12">
              {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value}/>
              ))}
              {(kind === 'option' || kind === 'option_image' ||  kind === 'motivator') &&
                (<button
                  type="button"
                  className="btn btn-blue-grey block btn-md btn-create"
                  data-toggle="modal"
                  data-target="#newanswer"
                  onClick={()=>handleClickNewAnswer(id)}
                  style={{ marginTop: '10px', marginBottom: '15px', color: "#E0186C",  }}
                >
                  <i class="fas fa-plus"></i>
                  <FormattedMessage id="step3.addNewAnswer" defaultMessage="Добавить новый ответ" />
                </button>)
              }
              {items.length === 0 && kind !== 'option' && kind !== 'motivator' && kind !== 'option_image' &&
              (<button
                  type="button"
                  className="btn btn-primary block btn-md btn-create"
                  data-toggle="modal"
                  data-target="#newanswer"
                  onClick={()=>handleClickNewAnswer(id)}
                  style={{ marginTop: '10px', marginBottom: '15px' }}
                >
                  <i class="fas fa-plus"></i>
                  <FormattedMessage id="step3.addNewAnswer" defaultMessage="Добавить новый ответ" />
                </button>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  )
})

class Question extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        isOpenModal: false,
        isChecked: false,
        isOpenModalEditQuestion: false,
        isLoad: false,
        answer: {},
        editQuestion: {},
        displayPickerTextColor: false,
        displayPickerBackgroundColor: false,
        displayPickerButtonColor: false,
        auto_time: true
      }
  
      this.deleteQuestion = this.deleteQuestion.bind(this)
      this.onSortEnd = this.onSortEnd.bind(this)
      this.copyQuestion = this.copyQuestion.bind(this)
      this.toggleModalAnswer = this.toggleModalAnswer.bind(this)
      this.handleChange = this.handleChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
      this.handleCheck = this.handleCheck.bind(this)
      this.editQuestion = this.editQuestion.bind(this)
      this.toggleModalEditQuestion = this.toggleModalEditQuestion.bind(this)
      this.handleChangeEditQuestion = this.handleChangeEditQuestion.bind(this)
      this.getBackgroundImage = this.getBackgroundImage.bind(this)
      this.deleteImage = this.deleteImage.bind(this)
      this.editImage = this.editImage.bind(this)
      this.deleteQuestionImage = this.deleteQuestionImage.bind(this)
      this.getPreview = this.getPreview.bind(this)
      this.collapsePreview = this.collapsePreview.bind(this)
      this.selectRightAnswer = this.selectRightAnswer.bind(this)
  
      this.openPickerBackgroundColor = this.openPickerBackgroundColor.bind(this)
      this.chooseBackgroundColor = this.chooseBackgroundColor.bind(this)
      this.changePickerBackgroundColor = this.changePickerBackgroundColor.bind(this)
  
      this.openPickerTextColor = this.openPickerTextColor.bind(this)
      this.chooseTextColor = this.chooseTextColor.bind(this)
      this.changePickerTextColor = this.changePickerTextColor.bind(this)
  
      this.openPickerButtonColor = this.openPickerButtonColor.bind(this)
      this.chooseButtonColor = this.chooseButtonColor.bind(this)
      this.changePickerButtonColor = this.changePickerButtonColor.bind(this)
  
      this.onChangeAutoTime = this.onChangeAutoTime.bind(this)
      this.handleSwitchAutoTime = this.handleSwitchAutoTime.bind(this)
  
      this.getImage = this.getImage.bind(this)
  
      this.onChangeType = this.onChangeType.bind(this)
    }
  
    openPickerBackgroundColor() {
      const { displayPickerBackgroundColor } = this.state
      this.setState({ displayPickerBackgroundColor: !displayPickerBackgroundColor })
    }
  
    chooseBackgroundColor() {
      this.setState({ displayPickerBackgroundColor: false })
    }
  
    changePickerBackgroundColor(color) {
      const editQuestion = { ...this.state.editQuestion }
      editQuestion['background_color'] = color.hex
      this.setState({ editQuestion })
    }
  
    openPickerTextColor() {
      const { displayPickerTextColor } = this.state
      this.setState({ displayPickerTextColor: !displayPickerTextColor })
    }
  
    chooseTextColor() {
      this.setState({ displayPickerTextColor: false })
      this.handleCheckAnswer = this.handleCheckAnswer.bind(this)
    }
  
    changePickerTextColor(color) {
      const editQuestion = { ...this.state.editQuestion }
      editQuestion['text_color'] = color.hex
      this.setState({editQuestion})
    }
  
    openPickerButtonColor() {
      const { displayPickerButtonColor } = this.state
      this.setState({ displayPickerButtonColor: !displayPickerButtonColor })
    }
  
    chooseButtonColor() {
      this.setState({ displayPickerButtonColor: false })
    }
  
    changePickerButtonColor(color) {
      const editQuestion = { ...this.state.editQuestion }
      editQuestion['button_color'] = color.hex
      this.setState({editQuestion})
    }
  
    onChangeAutoTime(event) {
      const { target: { value, name } } = event
      const editQuestion = { ...this.state.editQuestion }
      editQuestion['auto_time'] = value
      editQuestion['isAutoTime'] = value.length === 0 ? false : true
      this.setState({ editQuestion })
      this.handleChangeDiscountInput = this.handleChangeDiscountInput.bind(this)
    }
  
    handleSwitchAutoTime() {
      const {auto_time} = this.state
      const editQuestion = { ...this.state.editQuestion }
      editQuestion['auto_time'] = ''
      editQuestion['isAutoTime'] = !editQuestion.isAutoTime
      this.setState({auto_time: !auto_time})
      this.setState({ editQuestion })
    }
  
  
    deleteImage() {
      const { editQuestion } = this.state
      delete editQuestion.question_image
      delete editQuestion.question_background_image
      delete editQuestion.question_side_image
      this.setState({ editQuestion })
    }
  
    async getBackgroundImage(event) {
  
      const { editQuestion, isLoad } = this.state
      this.setState({ isLoad: true })
      const data = new FormData()
      const file = event.target.files[0]
      if (file) {
        data.append('file', file)
        editQuestion.question_image = data
        const name = event.target.name
        try {
          let response = await fetch(config.IMAGE_UPLOAD_URL, {
            method: 'POST',
            body: editQuestion.question_image,
          })
          response = await response.json()
          if(name) {
            if(name === 'side') {
              editQuestion.question_side_image = response.Url
              editQuestion.question_background_image = ''
            } else {
              editQuestion.question_side_image = ''
              editQuestion.question_background_image = response.Url
            }
            editQuestion.question_image = response.Url
          }
        } catch (error) {
          console.log(error)
        }
  
        this.setState({ editQuestion, isLoad: false })
      }
    }
  
  
    componentWillReceiveProps(prevProps) {
      const {
        title, kind, desc, image, allowMultiple, answers, imagePlacement, correct_text, incorrect_text, question_options_list, questions
      } = this.props
      let editQuestion
  
      if(kind !== 'motivator') {
        editQuestion = {
          question_title: title,
          correct_text,
          incorrect_text,
          question_description: desc,
          question_kind: kind,
          question_image: image,
          image_placement: imagePlacement,
          allow_multiple: allowMultiple,
          question_options_list: question_options_list,
          answers,
        }
      } else {
        const {motivatorData: {
          background_color, button_color, image_placement, text_color, auto_time, question_background_image, question_side_image
        }} = this.props
  
        editQuestion = {
          question_title: title,
          correct_text,
          incorrect_text,
          question_description: desc,
          question_kind: kind,
          question_image: image,
          allow_multiple: allowMultiple,
          question_options_list: question_options_list,
          answers,
          background_color,
          image_placement,
          text_color,
          button_color,
          auto_time,
          isAutoTime: auto_time === '' ? false : true,
          question_background_image,
          question_side_image
        }
  
      }
      if (kind === 'numeric') {
        editQuestion.answer_units = editQuestion.answers[0].answer_units
        editQuestion.required = editQuestion.answers[0].required
      }
      if (kind === 'text' || kind === 'file') {
        editQuestion.required = editQuestion.answers[0].required
      }
      if (kind === 'text') {
        editQuestion.textarea = editQuestion.answers[0].textarea
      }

      if(prevProps.questions.length!== questions.length) this.setState({ editQuestion })
    }
  
    async getImage(event) {
      const { answer } = this.state
      this.setState({ isLoad: true })
      const data = new FormData()
      const file = event.target.files[0]
      if (file) {
        data.append('file', file)
        answer.answer_image = data
        try {
          let response = await fetch(config.IMAGE_UPLOAD_URL, {
            method: 'POST',
            body: answer.answer_image,
          })
          response = await response.json()
          answer.answer_image = response.Url
        } catch (error) {
          console.log(error)
        }
        this.setState({ answer, isLoad: false })
      }
    }
  
    handleCheckAnswer() {
      const { answer } = this.state
      answer['custom'] = !answer.custom
      this.setState({ answer })
    }
  
    selectRightAnswer() {
      const { answer, editQuestion } = this.state
      const isAllFalse = editQuestion.answers.every((item) => item.is_correct === false)
      
      if (isAllFalse || editQuestion.allow_multiple) {
        answer.is_correct= !answer.is_correct
        this.setState({ answer })
      }
    }
  
    async editImage(event) {
      const { editQuestion, isLoad } = this.state
      this.setState({ isLoad: true })
      const data = new FormData()
      const file = event.target.files[0]
      if (file) {
        data.append('file', file)
        editQuestion.question_image = data
        try {
          let response = await fetch(config.IMAGE_UPLOAD_URL, {
            method: 'POST',
            body: editQuestion.question_image,
          })
          response = await response.json()
          editQuestion.question_image = response.Url
        } catch (error) {
          console.log(error)
        }
        this.setState({ editQuestion, isLoad: false })
      }
    }
  
    deleteImage() {
      const { answer } = this.state
      delete answer.answer_image
      this.setState({ answer })
    }
  
    deleteQuestionImage() {
      const { editQuestion } = this.state
      delete editQuestion.question_image
      delete editQuestion.question_background_image
      this.setState({ editQuestion })
    }
  
    copyQuestion(id) {
      this.props.copyQuestion(id)
    }
  
    deleteQuestion(id) {
      this.props.deleteQuestion(id)
    }
  
    getPreview(id) {
      this.props.getPreview(id)
    }
  
    editQuestion(id) {
      const { editQuestion } = this.state
      const { kind } = this.props
      if (kind === 'numeric') {
        editQuestion.answers[0].answer_units = editQuestion.answer_units
        editQuestion.answers[0].required = editQuestion.required
        delete editQuestion.answer_units
        delete editQuestion.required
      }
      if (kind === 'text' || kind === 'file') {
        editQuestion.answers[0].required = editQuestion.required
        delete editQuestion.required
      }
      if (kind === 'text') {
        editQuestion.answers[0].textarea = editQuestion.textarea
        delete editQuestion.textarea
      }
  
      if (kind === 'option' || kind === 'option_image' || kind === 'motivator') {
        if (!editQuestion.allow_multiple) {
          let countIsCorrect = 0
          editQuestion.answers.forEach((answer) => {
            if (answer.is_correct) {
              countIsCorrect += 1
            }
          })
          
          if (countIsCorrect > 1) {
            editQuestion.answers.forEach((answer, index) => {
              if (index === 0) {
                answer.is_correct = true
              } else {
                answer.is_correct = false
              }
            })
          }
        }
      }
  
      this.toggleModalEditQuestion()
      this.props.editQuestion(id, editQuestion)
    }
  
    toggleModalEditQuestion() {
      const { isOpenModalEditQuestion } = this.state
      if (!isOpenModalEditQuestion) this.collapse(this.props.previewId)
      this.setState({ isOpenModalEditQuestion: !isOpenModalEditQuestion })
    }
  
    handleChangeEditQuestion(event) {
      const { target: { value, name } } = event
      const editQuestion = { ...this.state.editQuestion }
      editQuestion[name] = value
      this.setState({ editQuestion })
    }
  
    collapse(id) {
      // this.props.collapse(id)
    }
  
    collapsePreview(id) {
      this.props.collapse(id)
    }
  
    onSortEnd(ids) {
      const { id } = this.props
      this.props.reorderAnswers(id, ids)
    }
  
    toggleModalAnswer() {
      const { isOpenModal } = this.state
      this.setState({ isOpenModal: !isOpenModal, answer: {} })
    }
  
    handleCheck() {
      const { isChecked } = this.state
      this.setState({ isChecked: !isChecked })
    }
  
    handleCheckEditQuestion(type) {
      const { editQuestion } = this.state
      if (type === 'required') {
        editQuestion.required = !editQuestion.required
      }
      if (type === 'allow_multiple') {
        editQuestion.allow_multiple = !editQuestion.allow_multiple
      }
      if (type === 'question_options_list') {
        editQuestion.question_options_list = !editQuestion.question_options_list
      }
      if (type === 'right-image') {
        editQuestion.image_placement = 'right-image'
      }
      if (type === 'option-image') {
        editQuestion.image_placement = 'option-image'
      }
      if (type === 'textarea') {
        editQuestion.textarea = !editQuestion.textarea
      }
      this.setState({ editQuestion })
    }
  
    handleChange(event) {
      const { target: { value, name } } = event
      const answer = { ...this.state.answer }
      answer[name] = value
      this.setState({ answer })
    }
  
    handleChangeDiscountInput(event, type) {
      const { target: { value, name } } = event
      const answer = { ...this.state.answer }
      answer[type] = value
      this.setState({ answer })
    }
  
  
    handleChangeDiscount(event) {
      const { target: { value, name } } = event
      const answer = { ...this.state.answer }
      answer[name] = value
      this.setState({ answer })
    }
  
    async handleSubmit(id) {
      const { answer, isChecked } = this.state
      if (isChecked) answer.required = true
      this.props.addAnswer(id, answer)
      this.setState({ answer: {} })
      this.toggleModalAnswer()
    }
  
    onChangeType(event) {
      const { target: { value, name } } = event
      const editQuestion = { ...this.state.editQuestion }
      editQuestion[name] = value
      this.setState({ editQuestion })
    }
  
    render() {
      const { isOpenModal, isChecked, isOpenModalEditQuestion, editQuestion, answer, isLoad, displayPickerBackgroundColor,
        displayPickerTextColor,
        displayPicketButtonColor,
        editQuestion: {button_color, text_color, background_color, isAutoTime},
        displayPickerButtonColor,
        auto_time
      } = this.state
      const {
        id, title, kind, desc, image, answers, deleteAnswer, isCollapsed,
        questions, setSkipTo, setWeight, setResultBinding, results, setMinMax, editAnswer,
        allowMultiple, discountUnit, newQuestion, handleChangeNewQuestion, handleEditAnswer, handleClickNewAnswer, previewId, selectedAnswerId, selectedQuestId, allow_multiple
      } = this.props

      return (
        <div className="questions-quest" id="question-side">
          <SortableListAnswers
            key={id}
            title={`${title}`}
            id={id}
            desc={desc}
            image={image}
            isCollapsed={isCollapsed}
            collapse={() => this.collapse(id)}
            collapsePreview={() => this.collapsePreview(id)}
            deleteQuestion={() => this.deleteQuestion(id)}
            editQuestion={() => this.editQuestion(id)}
            copyQuestion={() => this.copyQuestion(id)}
            getPreview={() => this.getPreview(id)}
            toggleModalAnswer={() => this.toggleModalAnswer()}
            toggleModalEditQuestion={() => this.toggleModalEditQuestion()}
            kind={kind}
            allow_multiple={allow_multiple}
            useWindowAsScrollContainer={true}
            handleClickNewAnswer={handleClickNewAnswer}
            previewId={previewId}
            required={answers && answers.length && answers[0].required}
            items={answers.map((answer, index) => (
              <Answer
                handlerEditQuestion={this.props.editQuestion}
                question={editQuestion}
                custom={answer.custom}
                is_correct={answer.is_correct}
                id={index}
                questions={questions}
                allowMultiple={allowMultiple}
                title={answer.answer_title}
                desc={answer.answer_description}
                questionId={id}
                kind={kind}
                image={answer.answer_image}
                skipTo={answer.skip_to}
                required={answer.required}
                weight={answer.weight}
                resultBinding={answer.result_binding}
                answerFactor={answer.answer_factor}
                answerFactorDefault={answer.answer_factor_default}
                units={answer.answer_units}
                minVal={answer.min_value}
                maxVal={answer.max_value}
                isCheckedUIInput={answer.ui_input}
                deleteAnswer={deleteAnswer}
                setSkipTo={setSkipTo}
                setWeight={setWeight}
                setMinMax={setMinMax}
                editAnswer={editAnswer}
                setResultBinding={setResultBinding}
                results={results}
                discount_action={answer.discount_action}
                discountUnit={discountUnit}
                discount_increase_by={answer.discount_increase_by}
                discount_set={answer.discount_set}
                handleEditAnswer={handleEditAnswer}
                selectedAnswerId={selectedAnswerId}
                selectedQuestId={selectedQuestId}
                className={(kind === 'option' || kind === 'option_image' ||  kind === 'motivator') && 'option-type'}
              />
          ))}
          onSortEnd={this.onSortEnd}
          useDragHandle={true}
        />
        </div>
      )
    }
  }

  export default Question;