import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NavigationBar } from '../../components'
import { AuthService } from '../../services'
import jdenticon from 'jdenticon'
import * as userInfoActions from '../../actions/userInfoActions'
import * as emailProfileActions from "../../actions/emailProfile";

class NavigationBarContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpenProfileMenu: false,
      // name: '',
      // leadsLeft: '',
      // email: this.props.userInfo.email,
      // is_confirmed: true,
    }

    this.openProfileMenu = this.openProfileMenu.bind(this)
    this.logOut = this.logOut.bind(this)
  }

  async componentDidMount() {
    // const { setUserInfo } = this.props
    // const { user } = await AuthService.getUserInfo()
    // if (user) {
    //   //let { name, leads_left: leadsLeft, email, is_confirmed, allow_promos } = user
    //   setUserInfo(user)
    //   console.log(user)
    //   // if (!leadsLeft) leadsLeft = 0
    //   // if (leadsLeft === -1) leadsLeft = 'Unlim'
    //   // if (!name) name = 'Аккаунт'
    //   // this.setState({ name, leadsLeft, email, is_confirmed }, jdenticon)
    //   // this.setState({ name, leadsLeft, email, is_confirmed }, jdenticon)
    // }
  }

  openProfileMenu() {
    this.setState({
      isOpenProfileMenu: !this.state.isOpenProfileMenu,
    })
  }

  collapseMenu() {
    document.body.classList.toggle('menu-expanded')
    document.body.classList.toggle('menu-collapsed')
  }

  logOut() {
    const { history } = this.props
    AuthService.deauthenticateUser()
    history.push('/auth')
  }

  render() {
    const { isOpenProfileMenu } = this.state
    const { name, email, leads_left, is_confirmed } = this.props.userInfo

    return (
      <NavigationBar
        isOpenProfileMenu={isOpenProfileMenu}
        openProfileMenu={() => this.openProfileMenu()}
        collapseMenu={() => this.collapseMenu()}
        logOut={() => this.logOut()}
        name={name || 'Аккаунт'}
        leadsLeft={(leads_left) ? ((leads_left === -1) ? 'Unlim' : leads_left) : '0'}
        email={email.slice(0, 19)}
        is_confirmed={is_confirmed}
        location={this.props.location}
      />
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
})

const mapDispatchToProps = {
  setUserInfo: userInfoActions.setUserInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationBarContainer))
