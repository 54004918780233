import * as Cookies from 'universal-cookie'

const config = require('../config/config')

const cookies = new Cookies()

class EmailProfileService {
  static async getList() {
    const body = {
      service: 'go.micro.srv.userd',
      method: 'UsersDirectory.GetEmailProfileList',
      request: {},
    }

    let response = null
    try {
      response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: cookies.get('token'),
        },
        body: JSON.stringify(body),
      })
    } catch (e) {
      console.log(e)
      return false
    }

    try {
      response = await response
    } catch (e) {
      console.log(e)
      return false
    }

    if (response.status === 200) {
      response = await response.json()
      if (response.email_profiles) {
        return response.email_profiles.reverse()
      }
      return response.email_profiles
    }

    return false
  }

  static async create({
    profile_name, username, passwd, server, port, sender_name, reply_to,
  }) {
    const body = {
      service: 'go.micro.srv.userd',
      method: 'UsersDirectory.CreateEmailProfile',
      request: {
        profile_name,
        username,
        passwd,
        server,
        port: parseInt(port),
        sender_name,
        reply_to,
      },
    }

    let response = null
    try {
      response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: cookies.get('token'),
        },
        body: JSON.stringify(body),
      })
    } catch (e) {
      console.log(e)
      return false
    }

    try {
      response = await response
    } catch (e) {
      console.log(e)
      return false
    }

    if (response.status === 200) {
      return {
        status: true,
        message: 'Профиль создан',
      }
    }

    return {
      status: false,
      message: 'Упс. Что-то пошло не так',
    }
  }

  static async test(id) {
    const body = {
      service: 'go.micro.srv.notif',
      method: 'Notification.TestEmailProfile',
      request: {
        id,
      },
    }

    let response = null
    try {
      response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: cookies.get('token'),
        },
        body: JSON.stringify(body),
      })
    } catch (e) {
      console.log(e)
      return false
    }

    let status = true
    let message = 'Ошибка соединения. Проверьте правильность данных и пересоздайте профиль.'

    if (response.status !== 200) {
      status = false
    } else {
      try {
        response = await response.json()
      } catch (e) {
        console.log(e)
        status = false
      }

      if (response.result) {
        status = true
        message = 'Соединениее прошло успешно'
      } else {
        status = false
      }
    }
    return {
      status,
      message,
    }
  }

  static async delete(id) {
    const body = {
      service: 'go.micro.srv.userd',
      method: 'UsersDirectory.DeleteEmailProfile',
      request: {
        id,
      },
    }

    let response = null
    try {
      response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: cookies.get('token'),
        },
        body: JSON.stringify(body),
      })
    } catch (e) {
      console.log(e)
      return false
    }

    try {
      response = await response
    } catch (e) {
      console.log(e)
      return false
    }

    if (response.status === 200) {
      return {
        status: true,
        message: 'Профиль удален',
      }
    }

    return {
      status: false,
      message: 'Ошибка удаления профиля. Возможно он уже был удален',
    }
  }
}

export default EmailProfileService
