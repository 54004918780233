import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Intl } from '../../utils'
import { AuthService } from '../../services'
import './assets/css/pages/login-register.css'

class RegisterPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: null,
      passwd: null,
      name: null,
      message: null,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.ymetrica = window.yaCounter49531330;
  }

  componentDidUpdate(){
    if(!this.ymetrica) this.ymetrica = window.yaCounter49531330;
  }

  handleChange(event) {
    const { target: { value, name } } = event
    this.setState({
      [name]: value,
      message: null,
    })
  }

  async handleSubmit(event) {
    event.preventDefault()
    const { email, passwd, name } = this.state
    const ref_id = AuthService.getRefID() || "0";
    try {
      await AuthService.register({ email, passwd, name, ref_id })
      await AuthService.auth({ email, passwd })
      this.setState({ message: null })
      
    } catch (error) {
      this.setState({ message: error.message })
    }
    if (this.ymetrica) {
      this.ymetrica.reachGoal('REGISTRATION');
    }
  }

  render() {
    if (AuthService.isUserAuthenticated()) return <Redirect to="/" />

    const { message } = this.state
    
    return (
      <section className="flexbox-container">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="box-shadow-2 p-0" style={{ width: '25rem' }}>
            <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
              <div className="card-header border-0">
                <div className="card-title text-center">
                  <img src={require('../../enquiz_logo.svg')} alt="branding logo" style={{ width: '200px' }} />
                </div>
                <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                  <FormattedMessage id="registerPage.regNewUser" defaultMessage="Регистрация нового пользователя" />
                </h6>
              </div>
              <div className="card-content">
                <div className="card-body">
                  <div style={{ color: 'red', paddingBottom: '10px' }}>
                    { message && <FormattedMessage id={message} /> }
                  </div>
                  <form className="form-horizontal form-simple" action="index.html" onSubmit={this.handleSubmit}>
                    <fieldset className="form-group position-relative has-icon-left mb-1">
                      <input
                        type="email"
                        className="form-control form-control-lg input-lg"
                        id="email"
                        name="email"
                        placeholder="Email"
                        required
                        onChange={this.handleChange}
                      />
                      <div className="form-control-position">
                        <i class="far fa-envelope"></i>
                      </div>
                    </fieldset>
                    <fieldset className="form-group position-relative has-icon-left">
                      <input
                        type="password"
                        className="form-control form-control-lg input-lg"
                        id="password"
                        name="passwd"
                        placeholder={Intl.getMessage('authPage.password')}
                        required
                        minLength="6"
                        onChange={this.handleChange}
                      />
                      <div className="form-control-position">
                        <i class="fas fa-key"></i>
                      </div>
                    </fieldset>
                    <fieldset className="form-group position-relative has-icon-left mb-1">
                      <input
                        type="text"
                        className="form-control form-control-lg input-lg"
                        id="name"
                        name="name"
                        placeholder={Intl.getMessage('registerPage.nameNotNecessary')}
                        onChange={this.handleChange}
                      />
                      <div className="form-control-position">
                        <i className="ft-user"></i>
                      </div>
                    </fieldset>
                    <button type="submit" className="btn btn-info btn-lg btn-block"><i className="ft-unlock"></i>
                      <FormattedMessage id="registerPage.register" defaultMessage="Зарегистрироваться" />
                    </button>
                  </form>
                </div>
                <p className="text-center">
                  <FormattedMessage id="registerPage.alreadyHaveAccount" defaultMessage="Уже есть аккаунт?" />
                  <Link to="/auth" className="card-link">
                    <FormattedMessage id="registerPage.logIn" defaultMessage="Авторизуйтесь" />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default RegisterPage
