const initialState = {
  isImport: false,
  createQuiz: false,
}

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IMPORT_QUIZ': {
      return { ...state, isImport: action.payload }
    }
    case 'CREATE_QUIZ': {
      return { ...state, createQuiz: !state.createQuiz }
    }
    default: {
      return state
    }
  }
}

export default quizReducer
