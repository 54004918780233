import React from 'react'
import { Quiz } from '../../components'

const config = require('../../config/config')

class QuizContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpenActions: false,
      isCollapseQuiz: true,
    }

    this.openActions = this.openActions.bind(this)
    this.collapseQuiz = this.collapseQuiz.bind(this)
    this.editQuiz = this.editQuiz.bind(this)
    this.saveAsTemplate = this.saveAsTemplate.bind(this)
    this.setQuizEndpoint = this.setQuizEndpoint.bind(this)
    this.deleteQuizEndpoint = this.deleteQuizEndpoint.bind(this)
  }

  openActions() {
    this.setState({
      isOpenActions: !this.state.isOpenActions,
    })
  }

  collapseQuiz() {
    this.setState({
      isCollapseQuiz: !this.state.isCollapseQuiz,
    })
  }

  editQuiz(url) {
    const { history } = this.props
    history.push(`/edit-quiz/${url}`)
  }

  saveAsTemplate(url) {
    this.props.saveAsTemplate(url)
  }

  getCode(url) {
    this.props.getCode(url)
  }

  setQuizEndpoint(url) {
    this.props.setQuizEndpoint(url)
  }

  deleteQuizEndpoint(url) {
    this.props.deleteQuizEndpoint(url)
  }

  render() {
    const {
      state, getImage, title, url, visits, leads, changeChatMode, sendToRequestDomain, chatSettings, creationDate, draft, deleteQuiz, exportQuiz, index, newLeads, unPublishQuiz, publishQuiz, copyQuiz, endpoint, endpoint_settings, deleteQuizEndpoint, sendToSpecialist
    } = this.props
    const { isCollapseQuiz, isOpenActions } = this.state;

    return (
      <Quiz
        state={state}
        chatSettings={chatSettings}
        title={title}
        url={`${config.QUIZZ_URL}q/${url}`}
        showUrl={config.QUIZZ_URL}
        hash={url}
        endpoint={endpoint}
        endpoint_settings={endpoint_settings}
        endpoint_url={`https://${endpoint}`}
        visits={visits ? visits : 0}
        leads={leads ? leads : 0}
        newLeads={newLeads ? newLeads : ''}
        conversion={leads ? (Math.round(leads / visits * 100)) : 0}
        creationDate={creationDate}
        isCollapseQuiz={isCollapseQuiz}
        isOpenActions={isOpenActions}
        changeChatMode={changeChatMode}
        collapseQuiz={() => this.collapseQuiz()}
        openActions={() => this.openActions()}
        editQuiz={() => this.editQuiz(url)}
        saveAsTemplate={() => this.saveAsTemplate(url)}
        getCode={() => this.getCode(url)}
        setQuizEndpoint={() => this.setQuizEndpoint(url)}
        deleteQuizEndpoint={() => deleteQuizEndpoint(index)}
        deleteQuiz={() => deleteQuiz(index)}
        exportQuiz={() => exportQuiz(url)}
        unpublishQuiz={() => unPublishQuiz(index)}
        publishQuiz={() => publishQuiz(index)}
        copyQuiz={() => copyQuiz(index)}
        sendToSpecialist={() => sendToSpecialist(index)}
        sendToRequestDomain={() => sendToRequestDomain(url)}
        draft={draft}
        getImage={getImage}
      />
    )
  }
}

export default QuizContainer
