/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Card from './Card'
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ReactPaginate from 'react-paginate'
import { Loader } from '../../components'
import swal from 'sweetalert'
import { QuizContainer } from '../../containers'
import { AuthService, QuizzService, LeadService } from '../../services'
import { Intl } from '../../utils'
import setCSVLinkAction from '../../actions/csvLinkAction'
import './LeadsPage.scss'
import {
  selectLeadAction,
  selectAllLeadsAction,
  resetLeadsAction,
  updateDataForRequest,
  getLeadsByQuizAction,
  getListLeadsAction,
  resetAllLeadsAction,
  resetLeadsState,
  resetLeadsStateAction,
} from '../../actions/leadActions'

const prodconfig = require('../../config/prodconfig')
const limit = 20

class LeadsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loader: true,
      leads: [],
      page: 1,
      countPage: 1,
      leadFilter: '',
      quizFilter: '',
      quizTitle: '',
      viewContacts: {},
      viewJsonData: {},
      viewUrl: '',
      viewModal: false,
      selected: null,
      searchText: '',
      loading: true,
      isSelectedAllLeads: false,
    }

    this.handlePage = this.handlePage.bind(this)
    this.openViewModal = this.openViewModal.bind(this)
    this.updateLeadState = this.updateLeadState.bind(this)
    this.updateCount = this.updateCount.bind(this)
    this.handleSelectLead = this.handleSelectLead.bind(this)
    this.handleSelectedAllLeads = this.handleSelectedAllLeads.bind(this)
  }


  async componentDidMount() {
    try {
      const { history, resetLeads, getListLeads, getLeadsByQuiz } = this.props
      const { page } = this.state
      resetLeads()
      const { filter: leadFilter } = this.props.match.params
      const quizFilter = new URLSearchParams(this.props.location.search).get('quiz')
      
      let quizTitle = ''
      if (quizFilter) {
        quizTitle = await QuizzService.getQuizz(quizFilter)
        quizTitle = quizTitle.title
      }
      if (quizFilter) {
        history.push(`/leads/${leadFilter}/?quiz=${quizFilter}`)
      }

      const auth = await AuthService.getUserInfo()
      if (!auth) {
        await AuthService.deauthenticateUser()
        history.push('/')
      }
      this.setState({ loader: true })
      if (quizFilter) {
        getLeadsByQuiz({ page: 1, limit, leadFilter: leadFilter === 'all' ? '' : leadFilter, quizFilter })
      } else {
        getListLeads({ page: 1, limit, leadFilter: leadFilter === 'all' ? '' : leadFilter })
      }

      this.setState({ quizFilter, quizTitle, loader: false })

    } catch(error) {
      console.log(error)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { resetLeads } = this.props
    const { page } = this.state
    const { updatedLeads, countPageRedux, pageRedux } = nextProps
    const { filter: leadFilter } = nextProps.match.params
    // После изменении статусов заявок, очищаю в Store выбранные заявки
    if (updatedLeads && updatedLeads.length !== 0) {
      this.setState({ leadFilter, leads: updatedLeads, countPage: countPageRedux })
    } else {
      if (!updatedLeads) {
        this.setState({ leadFilter, leads: [] })
      } else {
        this.setState({ leadFilter, leads: updatedLeads })
      }
    }
  }

  componentDidUpdate(nextProps) {
    const { filter: leadFilter } = nextProps.match.params
    const leadType = this.props.match.params.filter
    const { countPageRedux, getLeadsByQuiz, updatedLeads, getListLeads, quizFilterRedux, setCSVLink } = this.props
    const quizFilter = new URLSearchParams(this.props.location.search).get('quiz')

    const { page } = this.state
    setCSVLink({
      quizFilter: quizFilterRedux,
      leadFilter,
      defaultLink: prodconfig.LEAD_EXPORT,
    })
    if (leadFilter !== leadType) {
      this.setState({ loader: true })
      if (quizFilterRedux) {
        getLeadsByQuiz({ page, limit, leadFilter: leadType === 'all' ? '' : leadType, quizFilter: quizFilterRedux })
      } else {
        getListLeads({ page, limit, leadFilter: leadType === 'all' ? '' : leadType })
      }
      this.setState({ page, loader: false })
    }
  }

  componentWillUnmount() {
    const { resetAllLeads, resetLeadsState } = this.props
    resetAllLeads()
    resetLeadsState();
  }


  async updateCount() {
    const { user } = await AuthService.getUserInfo()
    if (user) {
      let { quizzes, new_leads: totalLeads } = user
      if (quizzes && document.getElementById('count-quizzes')) document.getElementById('count-quizzes').innerHTML = quizzes
      if (!totalLeads && document.getElementById('count-leads')) document.getElementById('count-leads').style.display = 'none'
      if (totalLeads && document.getElementById('count-leads')) document.getElementById('count-leads').innerHTML = totalLeads
    } else {
      clearInterval(+localStorage['intervalId'])
    }
  }

  async handlePage(data) {
    const { resetLeads, updateStateSelectedLeads, getLeadsByQuiz, getListLeads, quizFilterRedux } = this.props
    let { selected: page } = data
    const { filter: leadFilter } = this.props.match.params
    page += 1
    const quizFilter = new URLSearchParams(this.props.location.search).get('quiz')

    this.setState({ loader: true })
    if (quizFilterRedux) {
      getLeadsByQuiz({ page, limit, leadFilter: leadFilter === 'all' ? '' : leadFilter, quizFilter: quizFilterRedux })
    } else {
      getListLeads({ page, limit, leadFilter: leadFilter === 'all' ? '' : leadFilter })
    }

    updateStateSelectedLeads(page)
    this.setState({ page, loader: false })
    // При переходе страницы очищаю выбранные заявки
    resetLeads()
  }

  async openViewModal(id, is_paid) {
    const { viewModal, page, leadFilter } = this.state
    const { getListLeads } = this.props
    if (!viewModal) {
      try {
        let isUnfreeze
        if (!is_paid) {
          isUnfreeze = await LeadService.unfreezeLead(id)
        } else {
          isUnfreeze = true
        }
        if (!isUnfreeze) {
          swal({
            title: Intl.getMessage('leadsPage.noValidPackagesFound'),
            icon: 'info',
          })
        } else {
          const result = await LeadService.getLeadById(id)
          this.setState({ viewModal: !viewModal, viewContacts: JSON.parse(result.contacts), viewJsonData: JSON.parse(result.json_data), viewUrl: result.url })
        }

      } catch (error) {
        console.log(error)
      }
    } else {
      this.setState({ viewModal: !viewModal, viewContacts: {}, viewJsonData: {} })
    }
  }

  async updateLeadState(id, newState) {
    try {
      const result = await LeadService.updateLeadState({ id, newState })
      const { getListLeads, quizFilterRedux, getLeadsByQuiz } = this.props
      const { page } = this.state
      const { filter: leadFilter } = this.props.match.params

      this.setState({ loader: true })
      if (quizFilterRedux) {
        getLeadsByQuiz({ page, limit, leadFilter: leadFilter === 'all' ? '' : leadFilter, quizFilter: quizFilterRedux })
      } else {
        getListLeads({ page, limit, leadFilter: leadFilter === 'all' ? '' : leadFilter })
      }
      this.setState({ loader: false })
    } catch (error) {
      console.log(error)
    }
  }

  handleSelectLead(data) {
    const { selectLead } = this.props
    selectLead(data)
    this.setState({ isSelectedAllLeads: false })
  }

  handleSelectedAllLeads() {
    const { selectAllLeads, selectedLeads } = this.props
    const { leads, isSelectedAllLeads } = this.state
    let activeLeads = []

    if (!isSelectedAllLeads) {
      activeLeads = leads.reduce((acc, { is_paid, is_online, id }) => {
        if (is_paid || is_online) {
          acc.push(id)
        }

        return acc
      }, [])
    }

    selectAllLeads({ ids: activeLeads, selectedLeads })
    this.setState({ isSelectedAllLeads: !isSelectedAllLeads })
  }

  resetFilter() {
    const { getListLeads } = this.props
    getListLeads({ page: 1, leadFilter: '', limit: 20 })
  }

  render() {
    const {
      leads,
      viewContacts,
      viewJsonData,
      viewModal,
      quizFilter,
      leadFilter,
      quizTitle,
      loader,
      viewUrl,
      page,
      isSelectedAllLeads,
    } = this.state
    const {
      selectedLeads,
      resetLeads,
      updatedLeads,
      updateStateSelectedLeads,
      countPageRedux,
      quizFilterRedux,
      loading,
    } = this.props

    return (
      <div className="leads-page">
        {/* <div className="search-bar">
          <input className="form-control" value={searchText} onChange={event => this.setState({ searchText: event.target.value })} placeholder="Поиск..."/>
          <div className="search-icon">
            <i class="fas fa-search" aria-hidden="true"></i>
          </div>
        </div> */}
        {countPageRedux > 1 && !loader && (
          <ReactPaginate
            previousLabel={Intl.getMessage('leadsPage.prev')}
            nextLabel={Intl.getMessage('leadsPage.next')}
            breakLabel={<a className="page-link" href="">...</a>}
            breakClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            pageCount={+countPageRedux}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePage}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="pagination-active"
            forcePage={page - 1}
          />
        )}
        {loading && <Loader />}
        {quizFilterRedux && (
          <div className="quiz-filter">
            {Intl.getMessage('leadsPage.showLeadsOnlyFor')} {`"${quizTitle}"`}.
            <span onClick={() => this.resetFilter()}>
              {Intl.getMessage('leadsPage.showAll')}
            </span>
          </div>
        )}
        {leads && leads.length > 0 && (
          <div className="leads-table">
            <div className="table-header">
              <div className="checkbox-container">
                <label className="container">
                  <input type="checkbox" onChange={this.handleSelectedAllLeads} checked={isSelectedAllLeads} />
                  <span className="checkmark" />
                </label>
              </div>
              <p className="date">Дата</p>
              <p className="prog">Прогресс</p>
              <p className="status">Статус</p>
              <p className="contact">Контакт</p>
              <p className="action">Действия</p>
            </div>
            {leads.map(lead => (
              <Card
                key={lead.id}
                lead={lead}
                page={page}
                quizFilter={quizFilter}
                leadFilter={leadFilter}
                openViewModal={() => this.openViewModal(lead.id, lead.is_paid)}
                updateLeadState={this.updateLeadState}
                updatedLeads={updatedLeads}
                selectedLeads={selectedLeads}
                selectLead={this.handleSelectLead}
                resetLeads={resetLeads}
                updateDataForRequest={updateStateSelectedLeads}
              />
            ))}
          </div>
        )}
        {leads.length === 0 && !loading && !loader && (
          <div className="empty-leads">
            <h1>Пока заявок не поступало</h1>
          </div>
        )}

        {countPageRedux > 1 && !loading && (
          <ReactPaginate
            previousLabel="Назад"
            nextLabel="Вперед"
            breakLabel={<a className="page-link" href="">...</a>}
            breakClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            pageCount={+countPageRedux}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePage}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="pagination-active"
            forcePage={page - 1}
          />
        )}

        {viewModal && (
        <Modal isOpen={viewModal} toggle={this.openViewModal}>
          <ModalBody>
            <div className="card">
              <div className="card-content">
                <div className="card-body overflow-hidden row card modal-elems">
                  <div className="col-md-8 col-sm-12 border-right-grey border-right-lighten-2 modal-section">
                    <ModalHeader>{Intl.getMessage('leadsPage.viewLead')}</ModalHeader>
                    <div>
                      <h4 className="card-title modal-card-title"><strong>{viewJsonData.quiz_title}</strong></h4>
                    </div>
                    <div className="modal-card-info">
                      <h4>{Intl.getMessage('leadsPage.result')}</h4>
                      <div dangerouslySetInnerHTML={{ __html: viewJsonData.result.result_value }} />
                      <br />
                      <br />

                      <section id="timeline" className="timeline-left timeline-wrapper modal-timeline">
                        {viewJsonData.questions.map((question, index) => (
                          <ul className="timeline" key={index}>
                            <li className="timeline-line" />
                            <li className="timeline-item">
                              <div className="timeline-badge">
                                <span className="bg-primary bg-lighten-1 text-center font-medium-3 text-white text-bold-700" data-toggle="tooltip" data-placement="right" title="Вопрос 1">{index + 1}</span>
                              </div>
                              <div className="timeline-card card border-grey border-lighten-2" style={{ marginBottom: '-1rem' }}>
                                <div className="card-header" style={{ marginBottom: '-30px' }}>
                                  <h4 className="card-title">{question.question_title}</h4>
                                </div>
                                <div className="card-content">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-lg-12 col-12">
                                        {question.answer && (
                                          <div>
                                            {question.question_kind === 'option' && (<p className="card-text m-0"><span className="badge badge-default badge-info">{Intl.getMessage('leadsPage.answer')}</span>&nbsp;{question.answer.answer_value}</p>)}
                                            {question.question_kind === 'motivator' && (<p className="card-text m-0"><span className="badge badge-default badge-info">{Intl.getMessage('leadsPage.answer')}</span>&nbsp;{question.answer.answer_value}</p>)}
                                            {question.question_kind === 'text' && (<p className="card-text m-0"><span className="badge badge-default badge-info">{Intl.getMessage('leadsPage.answer')}</span>&nbsp;{question.answer.answer_value}</p>)}
                                            {question.question_kind === 'numeric' && (<p className="card-text m-0"><span className="badge badge-default badge-info">{Intl.getMessage('leadsPage.answer')}</span>&nbsp;{question.answer.answer_value} {question.answer.answer_units}</p>)}
                                            {question.question_kind === 'file' && question.answer && (<p className="card-text m-0"><span className="badge badge-default badge-info">{Intl.getMessage('leadsPage.answer')}</span>&nbsp;<a href={question.answer.answer_value}>{Intl.getMessage('leadsPage.download')}</a></p>)}
                                            {question.question_kind === 'option_image' && (
                                              <p className="card-text m-0">
                                                <img src={`${prodconfig.IMAGE_RESIZE_URL}/200,fit/${question.answer.answer_image.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net")}`} alt="answer_image" className="img-fluid" />
                                                <span className="badge badge-default badge-info">{Intl.getMessage('leadsPage.answer')}</span>&nbsp;
                                                {question.answer.answer_value}
                                              </p>
                                            )}

                                          </div>
                                        )}

                                        {question.answers && question.question_kind === 'file' && question.answers.map((answer, aIndex) => (
                                          <p className="card-text m-0" key={aIndex}>
                                            <span className="badge badge-default badge-info">
                                              {Intl.getMessage('leadsPage.answer')}
                                            </span>
                                            <a href={answer} target="_blank" rel="noreferrer">
                                              {Intl.getMessage('leadsPage.download')} - {aIndex + 1}
                                            </a>&nbsp;
                                          </p>
                                        ))}

                                        {question.answers && (question.question_kind === 'option' || question.question_kind === 'motivator') && question.answers.map((answer, aIndex) => (
                                          <p className="card-text m-0" key={aIndex}>
                                            <span className="badge badge-default badge-info">
                                              {Intl.getMessage('leadsPage.answer')}
                                            </span>
                                            {answer}
                                          </p>
                                        ))}

                                        {question.answers && question.question_kind === 'option_image' && question.answers.map((answer, aIndex) => (
                                          <p className="card-text m-0" key={aIndex}>
                                            <br />
                                            <span className="badge badge-default badge-info">{Intl.getMessage('leadsPage.answer')}</span>
                                            {` ${answer}`}
                                          </p>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        ))}
                      </section>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    {viewContacts && (
                    <div className="list-group">
                      {viewJsonData.quiz_title && (
                        <div className="list-group-item">
                          <h4>{viewJsonData.quiz_title}</h4>
                        </div>
                      )}
                      {viewContacts.name && (
                      <div className="list-group-item">
                        <h5 className="list-group-item-heading"> <strong>{Intl.getMessage('leadsPage.name')}:</strong></h5>
                        <p className="list-group-item-text">{viewContacts.name}</p>
                      </div>
                      )}
                      {viewContacts.email && (
                      <a href={`mailto:${viewContacts.email}`} className="list-group-item">
                        <h5 className="list-group-item-heading"> <strong>Email:</strong></h5>
                        <p className="list-group-item-text">{viewContacts.email}</p>
                      </a>
                      )}

                      {viewContacts.phone && (
                      <div className="list-group-item">
                        <h5 className="list-group-item-heading"> <strong>{Intl.getMessage('leadsPage.phone')}:</strong></h5>
                        <p className="list-group-item-text">{viewContacts.phone}</p>
                      </div>
                      )}

                      {viewContacts.skype && (
                      <div className="list-group-item">
                        <h5 className="list-group-item-heading"> <strong>Skype:</strong></h5>
                        <p className="list-group-item-text">{viewContacts.skype}</p>
                      </div>
                      )}

                      {viewContacts.whatsapp && (
                      <div className="list-group-item">
                        <h5 className="list-group-item-heading"> <strong>WhatsApp:</strong></h5>
                        <p className="list-group-item-text">{viewContacts.whatsapp}</p>
                      </div>
                      )}

                      {viewContacts.telegram && (
                        <div className="list-group-item">
                          <h5 className="list-group-item-heading"><strong>Telegram:</strong></h5>
                          <p className="list-group-item-text">{viewContacts.telegram}</p>
                        </div>
                      )}

                      {viewContacts.viber && (
                        <div className="list-group-item">
                          <h5 className="list-group-item-heading"><strong>Viber:</strong></h5>
                          <p className="list-group-item-text">{viewContacts.viber}</p>
                        </div>
                      )}
                    </div>
                    )}

                    {viewUrl && (
                      <div className="list-group">
                        <div className="list-group-item link">
                          <h5 className="list-group-item-heading"><strong>{Intl.getMessage('leadPage.viewUrl')}</strong></h5>
                          <span><a target="_blank" rel="noreferrer" href={viewUrl}>{viewUrl}</a></span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <input type="reset" className="btn btn-outline-secondary btn-lg" onClick={this.openViewModal} value={Intl.getMessage('step2.close')} />
          </ModalFooter>
          <div className="close-button" onClick={this.openViewModal}><i className="fas fa-times" /></div>
        </Modal>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  selectedLeads: state.leadReducer.selectedLeads,
  updatedLeads: state.leadReducer.leads,
  countPageRedux: state.leadReducer.countPage,
  pageRedux: state.leadReducer.page,
  quizFilterRedux: state.leadReducer.quizFilter,
  loading: state.leadReducer.loading,
})

const mapDispatchToProps = {
  setCSVLink: setCSVLinkAction,
  selectLead: selectLeadAction,
  selectAllLeads: selectAllLeadsAction,
  resetLeads: resetLeadsAction,
  resetAllLeads: resetAllLeadsAction,
  updateStateSelectedLeads: updateDataForRequest,
  getLeadsByQuiz: getLeadsByQuizAction,
  getListLeads: getListLeadsAction,
  resetLeadsState: resetLeadsStateAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsPage)
