import { AuthService } from './index'

const config = require('../config/config')

class PackagesService {
  static async getPackagesList() {
    try {
      const request = {
      }
      const body = {
        service: 'go.micro.srv.userd',
        method: 'Billing.GetPackageList',
        request,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) return false
      response = await response.json()
      
      return response
    } catch (error) {
      throw error
    }
  }
  
  static async addNewPackage(data) {
    try {
      const request = {
        leads: data.leads,
        days: data.days,
        promo_code: data.promo_code,
        is_pro: data.is_pro,
      }
      const body = {
        service: 'go.micro.srv.userd',
        method: 'Billing.AddPackage',
        request,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status !== 200) return false
      response = await response.json()
      
      return response
    } catch (error) {
      throw error
    }
  }

  static async getPromoCode(promoCode) {
    try {
      const request = {
      }
      const body = {
        service: 'go.micro.srv.userd',
        method: 'Billing.GetPromoCode',
        request: {
          id: promoCode,
        },
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) return false
      response = await response.json()
      
      return response
    } catch (error) {
      throw error
    }
  }
  
}

export default PackagesService
