/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React from 'react'
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import { withRouter } from "react-router-dom";
import { FormattedMessage, FormattedDate } from 'react-intl'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import classNames from 'classnames'
import { Intl } from '../../utils'
import { QuizzService } from "../../services";
import {
  EditIcon,
  PublishIcon,
  GetDomenIcon,
  SendExpertIcon,
  LandingIcon,
  LinkIcon,
  ViewsIcon,
  LeadsIcon,
  DataIcon,
  QuizLandingIcon,
  DraftIcon,
  CopyLinkIcon,
  OpenLinkIcon,
  TelegramIcon,
} from './icons'
import './style.scss'

import emptyImg from './empty.png';

class Quiz extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statusCopy: false,
      image: emptyImg,
      showChatConfig: false,
      chat_mode: this.props.chatSettings && JSON.parse(this.props.chatSettings).chat_mode,
      enable_pause: true,
      results_if_offline: false
    }

    this.getStatusQuiz = this.getStatusQuiz.bind(this)
    this.getHandler = this.getHandler.bind(this)
  }

  getHandler(status) {
    const { publishQuiz, unpublishQuiz } = this.props
    if (status === 'draft') {
      return publishQuiz
    } else if (status === 'published') {
      return unpublishQuiz
    } else return null
  }

  getStatusQuiz(status) {
    switch (status) {
      case 'draft': {
        return (
          <>
            <i class="fas fa-edit"></i>
            <FormattedMessage
              id="card.draft"
              defaultMessage="Черновик"
            />
          </>
        )
      }
      case 'published': {
        return (
          <>
            <i class="fas fa-check"></i>
            <FormattedMessage
              id="card.published"
              defaultMessage="Опубликовано"
            />
          </>
        )
      }
      case 'blocked': {
        return (
          <>
            <i class="fas fa-ban"></i>
            <FormattedMessage
              id="card.blocked"
              defaultMessage="Заблокирован"
            />
          </>
        )
      }
      case 'archived': {
        return (
          <FormattedMessage
            id="card.archived"
            defaultMessage="Архив"
          />
        )
      }
      default: {
        return null
      }
    }
  }

  changeChatStatus = status => {
    const {chat_mode, enable_pause, results_if_offline} = this.state;
    let settingsChat = {
      chat_mode: status,
      enable_pause: enable_pause,
      results_if_offline: results_if_offline
    }

    QuizzService.changeTelegramSettings({
      hash: this.props.hash,
      chatbot_settings: JSON.stringify(settingsChat),
    }).then(response => response && this.setState({...settingsChat}));
  }

  componentDidMount () {
    const {getImage, uniqueHash} = this.props;
    getImage && getImage().then(res=> {
      res && res.image && this.setState({image: res.image})
    })

    document.addEventListener('click',  (e) => {
      e.target.closest('.telegram-bar') === null && this.setState({showChatConfig: false})
    })

  }

  editQuizHandle = () => {
    this.props.draft ? this.props.history.push('/create-quiz-from-scratch/draft') : this.props.editQuiz();
  } 

  componentDidUpdate(prevProps){
    if(prevProps.chatSettings !== this.props.chatSettings){
      const chat = JSON.parse(this.props.chatSettings);
      this.setState({...chat})
    }
  }

  render() {
    const {
      state,
      title,
      url,
      visits,
      leads,
      endpoint,
      endpoint_url,
      sendToRequestDomain,
      endpoint_settings,
      creationDate,
      isOpenActions,
      openActions,
      editQuiz,
      draft,
      saveAsTemplate,
      deleteQuiz,
      exportQuiz,
      newLeads,
      unpublishQuiz,
      publishQuiz,
      copyQuiz,
      hash,
      getCode,
      setQuizEndpoint,
      deleteQuizEndpoint,
      sendToSpecialist,
      showUrl,
      chatSettings,
      changeChatMode,
      html
    } = this.props

    const {image, showChatConfig, chat_mode} = this.state;

    let creatDate = creationDate.toString();

    if(creatDate && creatDate.length < 14){
      for(let i=creatDate.length; i<13; i++){
        creatDate = creatDate+'0';
      }
    }
    
    const date = new Date().getDate() - new Date(+creatDate).getDate();

    const firstDate = new Date();
    const secondDate = new Date(+creatDate);

    const diffTime = Math.abs(secondDate - firstDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    
    const getClassButton = status => classNames({
      'quiz__button--blocked': status === 'blocked',
      'quiz__button--published': status === 'published',
      'quiz__button--archived': status === 'archived',
      'quiz__button--draft': status === 'draft',
    })

    return (
      <div className="quiz">
        <div className="quiz-body">
          <div className="quiz-content">
            <div className="quiz-img">
              <img src={image.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net")} onClick={this.editQuizHandle} alt='quiz' style={{maxHeight: '110px', width: '205px', cursor: 'pointer'}}/>
            </div>
            <div className="quiz-title">
              <div onClick={this.editQuizHandle} className="quiz-edit">
                <h4>{title}</h4>
                <i class="fas fa-pencil-alt"></i>
              </div>
              {!draft && <div className="quiz-domen">
                {url && <p className="link" onClick={()=>window.open(url, '_blank')}>{url}</p>}
                {!endpoint ? (
                  <span>
                    (
                    <DropdownItem className="dropdown-item" href="" onClick={setQuizEndpoint}>
                      <FormattedMessage
                        id="indexPage.setQuizEndpoint"
                        defaultMessage="Назначить  домен"
                        />
                    </DropdownItem>
                    )
                  </span>
                )
                :
                (<p className="link" onClick={()=>window.open(endpoint_url, '_blank')}>({endpoint_url})</p>)
              }
              </div>}
            </div>
          </div>
              <Dropdown
                isOpen={isOpenActions}
                toggle={openActions}
                className="btn-group float-md-right"
              >
                {draft ? (
                  <Link
                    to="/create-quiz-from-scratch/draft"
                    className="quiz__toolbar-action quiz__button quiz__button--actions continue"
                  >
                    <FormattedMessage
                      id="indexPage.continueCreating"
                      defaultMessage="Продолжить создание"
                    />
                    <i class="fas fa-arrow-right" style={{marginLeft: '10px'}}></i>
                  </Link>
                )
                  :
                  (<DropdownToggle
                    caret
                    className="quiz__toolbar-action quiz__button quiz__button--actions"
                  >
                    <i class="fas fa-cog" style={{fontSize: '18px', fontWeight: '700'}}></i>
                    <FormattedMessage id="card.actions" defaultMessage="Действия" />
                  </DropdownToggle>)
                }
                <DropdownMenu style={{ left: '-100%' }}>
                  <a href={url} target="_blank" style={{ color: 'black' }}>
                    <DropdownItem className="dropdown-item" href="">
                      <i className="ft-eye" />
                      <FormattedMessage id="card.view" defaultMessage="Просмотреть" />
                    </DropdownItem>
                  </a>
                  <DropdownItem className="dropdown-item" href="" onClick={editQuiz}>
                    <i
                      className="ft-feather"
                    />
                    <FormattedMessage id="card.edit" defaultMessage="Редактировать" />
                  </DropdownItem>
                  {state === 'published' && (
                    <DropdownItem className="dropdown-item" href="" onClick={unpublishQuiz}>
                      <i
                        className="ft-check"
                      />
                      <FormattedMessage
                        id="indexPage.unPublishQuiz"
                        defaultMessage="Снять с публикации"
                      />
                    </DropdownItem>
                  )}
                  {state === 'draft' && (
                    <DropdownItem className="dropdown-item" href="" onClick={publishQuiz}>
                      <i
                        className="ft-check"
                      />
                      <FormattedMessage
                        id="indexPage.publishQuiz"
                        defaultMessage="Опубликовать"
                      />
                    </DropdownItem>
                  )}
                  <DropdownItem className="dropdown-item" href="" onClick={copyQuiz}>
                    <i
                      className="ft-copy"
                    />
                    <FormattedMessage id="indexPage.copyQuiz" defaultMessage="Копировать" />
                  </DropdownItem>
                  <DropdownItem className="dropdown-item" href="" onClick={saveAsTemplate}>
                  <i class="far fa-images"></i>
                    <FormattedMessage
                      id="indexPage.saveAsTemplate"
                      defaultMessage="Сохранить как шаблон"
                    />
                  </DropdownItem>
                  <DropdownItem className="dropdown-item" href="" onClick={getCode}>
                    <i class="fas fa-code"></i>
                    <FormattedMessage id="card.getCode" defaultMessage="Получить код" />
                  </DropdownItem>
                  {!endpoint && (
                    <DropdownItem className="dropdown-item" href="" onClick={setQuizEndpoint}>
                      <i class="fas fa-link"></i>
                      <FormattedMessage
                        id="indexPage.setQuizEndpoint"
                        defaultMessage="Назначить свой домен"
                      />
                    </DropdownItem>
                  )}
                  {endpoint && (
                    <DropdownItem
                      className="dropdown-item red"
                      href=""
                      onClick={deleteQuizEndpoint}
                    >
                      <i class="fas fa-unlink"></i>
                      <FormattedMessage
                        id="indexPage.deleteQuizEndpoint"
                        defaultMessage="Удалить домен"
                      />
                    </DropdownItem>
                  )}
                  <DropdownItem className="dropdown-item" href="" onClick={exportQuiz}>
                    <i className="fa fa-download" aria-hidden="true" />
                    <FormattedMessage id="card.export" defaultMessage="Экспортировать" />
                  </DropdownItem>
                  <DropdownItem className="dropdown-item red" href="" onClick={deleteQuiz}>
                    <i
                      className="ft-trash"
                    />
                    <FormattedMessage id="card.delete" defaultMessage="Удалить" />
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
        </div>
        <div className="quiz-footer">
          <div className="quiz-status">
            <div onClick={draft ? () => {} : this.getHandler(state)} className={classNames('quiz__toolbar-status', 'quiz__button', getClassButton(state))}>
              {this.getStatusQuiz(state)}
            </div>
            {/* {!draft && <div className={`telegram-bar ${chat_mode ? 'active' : ''}`} onClick={()=>this.setState({showChatConfig: !showChatConfig})}>
              <i class="fas fa-paper-plane"></i> 
              <span>{chat_mode ? 'Чат активен' : 'Чат неактивен'}</span>
              <i class="fas fa-chevron-down"></i>
              {showChatConfig && 
                <div className="chat-config">
                  <p onClick={()=>this.changeChatStatus(!chat_mode)}>{chat_mode ? 'Выключить чат' : 'Включить чат'}</p>
                  <p  onClick={() => changeChatMode(hash, state)}>Показать настройки чата</p>
                </div>
              }
            </div>} */}
          </div>
          <div className="quiz-info">
              {!draft && <div>
                  <span>Просмотров: { visits}</span>
              </div>}
              {!draft && <div>
                <span>Заявок: </span>
                {!newLeads && (
                    <span>
                      <Link to={`/leads/all/?quiz=${hash}`} style={{ color: '#717171' }}>{` ${leads}`}</Link>
                    </span>
                  )}
                  {newLeads && (
                  <Link to={`/leads/all/?quiz=${hash}`} style={{ color: '#717171' }}>
                    {`${leads}`}
                    <span style={{ color: 'red', marginLeft: '5px' }}>
                      {`(${newLeads})`}
                    </span>
                    {' '}
                  </Link>
                  )}
              </div>}
              <div>
                  <span>Создано {diffDays} дня назад </span>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Quiz);
