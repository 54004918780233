import React from 'react'
import LoaderPrinter from 'react-loader-spinner';
import './index.scss'

const Loader = () => (
    <div className="loader">
      <LoaderPrinter
      type="TailSpin"
      color="#E0E0E0"
      height={100}
      width={100}
      timeout={3000}
    />
    </div>
)

export default Loader
