import React from 'react'
import { FormattedMessage } from 'react-intl'
import Card from '../Card'

const FixedResult = ({
  toggle, isVisible, results, questions, deleteResult, editResult, preview, setResultType, clearResultData, resultType, selectedId
}) => {

  const isNulls = results.every((item, resultId) => {
    let countBind = 0
    questions.map((question) => {
      question.answers.map((answer) => {
        if (answer.result_binding === resultId) {
          countBind += 1
        }
      })
    })
    return countBind === 0
  })
  return (
      <div className="fixed-result">
        <button
            onClick={() => {
              toggle()
              setResultType()
              clearResultData()
            }}
            type="button"
            className={`btn btn-create ${(isVisible) ? 'showPanel' : 'hidePanel'}`}
            data-toggle="modal"
            data-target="#bootstrap"
            id="controlPanel"
        >
          <i class="fas fa-plus"></i>
          <FormattedMessage id="step2.addNewResult" defaultMessage="Добавить новый результат" />
        </button>
        <br />
        <br />
        {results && results.map((card, resultId) =>
        {
          let countBind = 0
          const questionIds = []
          questions.map((question, questionId) => {
            question.answers.map((answer) => {
              if (answer.result_binding === resultId) {
                countBind += 1
                questionIds.push(questionId)
              }
            })
          })
          if (card) {
            return Card({
              id: resultId,
              selectedId,
              countBind,
              deleteResult: deleteResult,
              editResult: editResult,
              preview: preview,
              cardTitle: card.result_title,
              cardDesc: card.result_description,
              kind: card.result_kind,
              cardLink: card.result_url,
              image: card.result_image,
              questionIds,
              clearResultData,
              isShowEnd: resultType === 'fixed' && isNulls && resultId === results.length - 1,
            })
          }
        })}
      </div>
  )
}

export default FixedResult
