import React from 'react'
import { FormattedMessage } from 'react-intl'

const FixedCard = ({ show_results, previewTitle, imageSizeUrl, imageSize, previewImage, previewDesc, isVisible, toggle, clearResultData, setResultType }) => (
  <div style={{ position: 'sticky', top: '10%' }}>
    {/* {show_results && previewTitle && (
      <div className="card text-white bg-blue-grey text-center" >
        <div className="card-content">
          <div className="">
            <h4 className="card-title mt-3 text-white" />
            <div className="card-text">
              <img
                className="media-object round-image"
                src={`${imageSizeUrl}/${imageSize}/${previewImage}`}
                alt=""
              />
              <p>
                {previewTitle}
              </p>
              <div dangerouslySetInnerHTML={{ __html: previewDesc }} />
              {!previewImage && (<br />)}

              <br />
            </div>
          </div>
        </div>
      </div>
    )}
    <button onClick={() => { toggle(); setResultType(); clearResultData() }} type="button" className={`btn btn-success block btn-md ${(!isVisible) ? 'showPanel' : 'hidePanel'}`} data-toggle="modal" data-target="#bootstrap" id="controlPanel">
      <i className="ft-plus-circle" />
      <FormattedMessage id="step2.addNewResult" defaultMessage="Добавить новый результат" />
    </button> */}
  </div>
)

export default FixedCard
