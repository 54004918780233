/* eslint-disable global-require */
import React, { useRef, useState } from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

const Step7 = ({ uniqueHash = '' }) => {
  const iframeContainerRef = useRef()
  const [isSelectedAd, setIsSelectedAd] = useState(false)

  const getSiteId = () => {
    const symbolArray = uniqueHash.split('')
    return parseInt(symbolArray.reduce((acc, _, index) => acc + uniqueHash.charCodeAt(index), ''), 10) 
  }

  const handleScrollTo = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleClickBusinessBtn = () => {
    window.YaPartnerWidget.init({
      container: iframeContainerRef.current,
      partnerName: 'enquiz',
      partnerSiteId: getSiteId(),
      siteName: `https://show.enquiz.io/q/${uniqueHash}`,
      products: ['subscription', 'websubscription'],
    })
    setIsSelectedAd(true)
    handleScrollTo()
  }

  const handleClickDirectBtn = () => {
    window.YaMasterWidget.init({
      container: iframeContainerRef.current,
      site: `https://show.enquiz.io/q/${uniqueHash}`,
      partnerId: 2595318,
    })

    setIsSelectedAd(true)
    handleScrollTo()
  }

  const handleClickGoBack = () => {
    setIsSelectedAd(false)

    if (iframeContainerRef.current) {
      const element = iframeContainerRef.current.childNodes[0]
      const isIframe = element.tagName === 'IFRAME'

      if (isIframe) {
        element.remove()
      }
    }
  }

  return (
    <div id="contentWrapper" className={styles.contentWrapper}>
      {isSelectedAd && (
        <div
          role="button"
          tabIndex={0}
          className={styles.goBack}
          onClick={handleClickGoBack}
          onKeyDown={handleClickGoBack}
        >
          Вернуться к выбору рекламы
        </div>
      )}
      <div ref={iframeContainerRef} className={classNames(styles.iframeContainer, isSelectedAd && styles.visible)} />
      {!isSelectedAd && (
        <div className={styles.content}>
          <div className={styles.directWrapper}>
            <div className={styles.logo}>
              <img src={require('../../assets/images/direct-logo.svg')} alt="direct logo" />
            </div>
            <div className={styles.banner}>
              <img src={require('../../assets/images/direct-banner.png')} alt="direct banner" />
            </div>
            <div className={classNames(styles.text, styles.bmb)}>
              <div className={styles.title}>Мастер Кампаний от Яндекс Директа</div>
              <div className={styles.description}>
                <p>Мастер кампаний — это простой и быстрый способ самостоятельно запустить рекламу в Директе.</p>
                <p>
                  Достаточно указать ссылку на рекламируемую страницу сайта и следовать пошаговой инструкции —
                  Мастер подскажет настройки,тематические слова, тексты и картинки.
                  Пользователи увидят вашу рекламу в поиске, на площадках Рекламной сети и Главной странице Яндекса.
                </p>
              </div>
            </div>
            <div className={styles.settings}>
              <div>
                Подойдёт, если&nbsp;
                <b>хотите самостоятельно настраивать рекламу</b>
                &nbsp;и экспериментировать с инструментами.
              </div>
              <ul>
                <li>Гибкие настройки рекламы и самостоятельный контроль эффективности объявлений.</li>
                <li>Реклама в поиске Яндекса и на сайтах партнёров Рекламной сети.</li>
                <li>
                  Подробная статистика: вы можете смотреть статистику как по разным срезам,
                  так и по всей воронке: показы, клики, конверсии.
                </li>
              </ul>
              <div
                role="button"
                tabIndex={0}
                className={styles.btn}
                onClick={handleClickDirectBtn}
                onKeyDown={handleClickDirectBtn}
              >
                Запустить рекламу
              </div>
            </div>
          </div>
          <div className={styles.businessWrapper}>
            <div className={styles.logo}>
              <img src={require('../../assets/images/business-logo.svg')} alt="direct logo" />
            </div>
            <div className={styles.banner}>
              <img src={require('../../assets/images/business-banner.png')} alt="direct banner" />
            </div>
            <div className={styles.text}>
              <div className={styles.title}>Рекламная подписка от Яндекс Бизнеса</div>
              <div className={styles.description}>
                <p>
                  Рекламная подписка — простой инструмент для автоматического запуска рекламы сразу
                  на нескольких площадках Яндекса и Google*.
                </p>
                <p>
                  Вы рассказываете о своём бизнесе и выбираете, куда привести клиентов, а
                  Рекламная подписка делает всё остальное.Создаёт,
                  настраивает и запускает объявления на нескольких площадках по всему интернету.
                  И следит, чтобы реклама приводила клиентов.
                </p>
              </div>
            </div>
            <div className={styles.settings}>
              <div>
                Подойдёт, если нет&nbsp;
                <b> времени и возможности разбираться с рекламными настройками</b>
                &nbsp;и следить за показателями.
              </div>
              <ul>
                <li>Автоматическое создание и настройка рекламных объявлений.</li>
                <li>Реклама сразу на нескольких площадках Яндекса и Google*.</li>
                <li>Алгоритмы проследят за бюджетом и перенаправят его на эффективные объявления.</li>
                <li>Простая и понятная статистика: алгоритмы сами подготовят отчёт по ключевым показателям.</li>
              </ul>
              <div
                role="button"
                tabIndex={0}
                className={styles.btn}
                onClick={handleClickBusinessBtn}
                onKeyDown={handleClickBusinessBtn}
              >
                Запустить рекламу
              </div>
            </div>
            <div className={styles.correction}>
              *С 4 марта реклама в Google временно не размещается. Когда доступ к площадкам Google будет восстановлен,
              Рекламная подписка заработает на них автоматически
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Step7
