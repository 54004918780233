import React from 'react'
import { Intl } from '../../../utils'

const ComputedCard = ({ questions, addFormula }) => (
  <div className="card">
    <div className="card-content">
      <div>
        <div id="colourful-treeview" className="treeview">
          <p>Добавить ответы в формулу</p>
          <ul className="list-group">
            {questions.map((question, questionId) => (
              <div key={questionId} className="list-group-content">
                <li
                  className="list-group-item node-colourful-treeview list-title"
                  data-nodeid="0"
                  style={{ color: 'undefined', backgroundColor: 'undefined' }}
                >
                  {question.question_title}
                  {/* <span className="badge badge-glow badge-pill bg-cyan bg-accent-3 float-right ml-1">
                    {questionId + 1}
                  </span> */}
                </li>
                {question.answers.map((answer, answerId) => {
                  if (true) {
                    return (
                      <li
                        key={answerId}
                        onClick={() => addFormula(`Q${questionId + 1}A${answerId + 1}`)}
                        className="list-group-item node-colourful-treeview"
                        data-nodeid="1"
                        style={{ color: 'undefined', backgroundColor: 'undefined' }}
                      >
                        <i class="fas fa-plus"></i>
                        {answer.answer_title}
                        <span className="badge badge-default">
                          {`Q${questionId + 1}A${answerId + 1}`}
                        </span>
                        {answer.answer_factor && (<span className="badge badge-info float-right" title={Intl.getMessage('step3.answerFactor')}>{answer.answer_factor}</span>)}
                        {answer.answer_factor_default && (<span className="badge badge-default float-right" title={Intl.getMessage('step3.answerFactorDefault')} style={{ backgroundColor: '#00C87F' }}>{answer.answer_factor_default}</span>)}

                      </li>
                    )
                  }
                })}
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default ComputedCard
