function setUserInfo(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_USER_INFO',
      payload: data,
    })
  }
}

export {
  setUserInfo,
}
