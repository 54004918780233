import React from 'react'
import { Step1 } from '../../components'

class Step1Container extends React.Component {
  constructor(props) {
    super(props)

    const { data } = this.props

    this.state = {
      quizSysName: data.quiz_sys_name,
      quizTitle: data.quiz_title,
      quizDescription: data.quiz_description,
      buttonText: data.button_text,
      backgroundImage: data.background_image,
      sideImage: data.side_image,
      elementsColor: data.elements_color,
      majorColor: data.major_color,
      textColor: data.text_color,
      quizTypewriterForTitle: data.typewriter_for_title,
      showContactInfo: data.show_contact_info,
      companyName: data.company_name,
      descriptionActivity: data.description_activity,
      companyPhone: data.company_phone,
      openingHours: data.opening_hours,
      placeAtBottom: data.place_at_bottom,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    this.setState({
      quizSysName: data.quiz_sys_name,
      quizTitle: data.quiz_title,
      quizDescription: data.quiz_description,
      buttonText: data.button_text,
      backgroundImage: data.background_image,
      sideImage: data.side_image,
      elementsColor: data.elements_color,
      majorColor: data.major_color,
      textColor: data.text_color,
      quizTypewriterForTitle: data.typewriter_for_title,
      showContactInfo: data.show_contact_info,
      companyName: data.company_name,
      descriptionActivity: data.description_activity,
      companyPhone: data.company_phone,
      openingHours: data.opening_hours,
      placeAtBottom: data.place_at_bottom,
    })
  }

  sendData() {
    const { handler } = this.props
    handler(this.state)
  }

  handleChange(event) {
    const { target: { value, name } } = event
    this.setState({ [name]: value }, this.sendData)
  }

  toggleCheckBox = (name, value) => () => {
    this.setState({ [name]: value }, this.sendData)
  }

  render() {
    const { data } = this.props
    const {
      quizSysName,
      quizTitle,
      quizDescription,
      buttonText,
      backgroundImage,
      sideImage,
      elementsColor,
      majorColor,
      textColor,
      quizTypewriterForTitle,
      showContactInfo,
      companyName,
      descriptionActivity,
      companyPhone,
      openingHours,
      placeAtBottom,
    } = this.state
    const { phone_prefix: phonePrefix } = data
    
    return (
      <Step1
        quizSysName={quizSysName}
        quizTitle={quizTitle}
        quizDescription={quizDescription}
        buttonText={buttonText}
        backgroundImage={backgroundImage}
        sideImage={sideImage}
        elementsColor={elementsColor}
        majorColor={majorColor}
        textColor={textColor}
        handleChange={this.handleChange}
        toggleCheckBox={this.toggleCheckBox}
        quizTypewriterForTitle={quizTypewriterForTitle}
        showContactInfo={showContactInfo}
        companyName={companyName}
        descriptionActivity={descriptionActivity}
        companyPhone={companyPhone}
        openingHours={openingHours}
        phonePrefix={phonePrefix}
        placeAtBottom={placeAtBottom}
      />
    )
  }
}

export default Step1Container
