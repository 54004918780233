import messagesRu from '../locale/ru.json'
import messagesEn from '../locale/en.json'

class Intl {
  static getMessage(id) {
    const messages = {
      ru: messagesRu,
      en: messagesEn,
    }
    //const locale = navigator.language.split(/[-_]/)[0]
    const locale = 'ru'

    return messages[locale][id]
  }
  
  static getLocale() {
    //let locale = navigator.language.split(/[-_]/)[0]
    // if (!locale) locale = 'ru'
    const locale = 'ru'
    
    return locale
  }
}

export default Intl
