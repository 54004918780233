/* eslint-disable object-curly-newline */
import { LeadService } from '../services'

// Выделение заявки квиза и сохранение списка выделенных заявок в Store
const selectLeadAction = data => (dispatch) => {
  const { selectedLeads, id } = data
  const indexSelectedLead = selectedLeads.findIndex(item => item === id)
  let resultLeads
  if (indexSelectedLead !== -1) {
    selectedLeads.splice(indexSelectedLead, 1)
    resultLeads = selectedLeads.concat()
  } else {
    selectedLeads.push(id)
    resultLeads = selectedLeads.concat()
  }

  return dispatch({
    type: 'SELECT_LEAD',
    payload: resultLeads,
  })
}

const selectAllLeadsAction = data => (dispatch) => {
  const { ids, selectedLeads } = data
  let resultLeads = []

  if (ids.length) {
    resultLeads = ids.reduce((acc, lead) => {
      if (!acc.includes(lead)) {
        acc.push(lead)
      }
  
      return acc
    }, [...selectedLeads])
  }

  return dispatch({
    type: 'SELECT_LEAD',
    payload: resultLeads,
  })
}

// Сброс всех выделенных заявок
const resetLeadsAction = () => dispatch => dispatch({
  type: 'RESET_LEADS',
})

const resetAllLeadsAction = () => dispatch => dispatch({
  type: 'RESET_ALL_LEADS',
})

// Экшен срабатывает при выделении заявки квиза. Записывает page, для будущего запроса на бэк
const updateDataForRequest = page => dispatch => dispatch({
  type: 'REQUEST_LEADS_UPDATE',
  payload: page,
})

const setNewStateLeads = data => (dispatch) => {
  const { page, leadFilter, quizFilter, type, selectedLeads } = data
  let countPage = 0
  LeadService.updateSelectedLeads(selectedLeads, type)
    .then((response) => {
      if (response) {
        if (quizFilter) {
          LeadService.getListLeadsByQuiz({ quizFilter, page, limit: 20, leadFilter })
            .then((res) => {
              countPage = Math.ceil(res.total_leads / 20)
              if (!res.leads) {
                dispatch({
                  type: 'SET_NEW_STATE',
                  payload: { leads: [], countPage, quizFilter },
                })
              } else {
                dispatch({
                  type: 'SET_NEW_STATE',
                  payload: { leads: res.leads, countPage, quizFilter },
                })
              }
              dispatch({
                type: 'RESET_LEADS',
              })
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          LeadService.getListLeads({ page, limit: 20, leadFilter })
            .then((res) => {
              countPage = Math.ceil(res.total_leads / 20)
              if (!res.leads) {
                dispatch({
                  type: 'SET_NEW_STATE',
                  payload: { leads: [], countPage, quizFilter },
                })
              } else {
                dispatch({
                  type: 'SET_NEW_STATE',
                  payload: { leads: res.leads, countPage, quizFilter },
                })
              }
              dispatch({
                type: 'RESET_LEADS',
              })
            })
            .catch((error) => {
              console.log(error)
            })
        }
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

const getLeadsByQuizAction = data => (dispatch) => {
  const { page, leadFilter, quizFilter, limit } = data
  dispatch({type: 'LOADING_LEADS', payload: { loading: true}})
  LeadService.getListLeadsByQuiz({ quizFilter, page, limit, leadFilter })
    .then((res) => {
      const countPage = Math.ceil(res.total_leads / 20)
      if (!res.leads) {
        dispatch({
          type: 'SET_NEW_STATE',
          payload: { leads: [], countPage, quizFilter },
        })
      } else {
        dispatch({
          type: 'SET_NEW_STATE',
          payload: { leads: res.leads, countPage, quizFilter },
        })
      }
      dispatch({type: 'LOADING_LEADS', payload: { loading: false}})
    })
    .catch((error) => {
      console.log(error)
    })
}

const getListLeadsAction = data => (dispatch) => {
  const { page, leadFilter, limit } = data
  dispatch({type: 'LOADING_LEADS', payload: { loading: true}})
  LeadService.getListLeads({ page, limit, leadFilter })
    .then((res) => {
      const countPage = Math.ceil(res.total_leads / limit)
      if (!res.leads) {
        dispatch({
          type: 'SET_NEW_STATE',
          payload: { leads: [], countPage },
        })
      } else {
        dispatch({
          type: 'SET_NEW_STATE',
          payload: { leads: res.leads, countPage },
        })
      }
      dispatch({type: 'LOADING_LEADS', payload: { loading: false}})
    })
    .catch((error) => {
      console.log(error)
    })
}

const resetLeadsStateAction = data => (dispatch) => {
  dispatch({type: 'RESET_LEADS_STATE'})
}

export {
  selectLeadAction,
  selectAllLeadsAction,
  resetLeadsAction,
  updateDataForRequest,
  setNewStateLeads,
  getListLeadsAction,
  getLeadsByQuizAction,
  resetAllLeadsAction,
  resetLeadsStateAction,
}
