import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { AuthService } from '../../services'

class ConfirmPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      result: false,
    }
  }

  async componentDidMount() {
    document.getElementById('public_container').classList.remove('container')
    const hash = new URLSearchParams(this.props.location.search).get('hash')

    try {
      const result = await AuthService.confirmEmail({ hash })
      this.setState({ result })
    } catch (error) {
      throw new Error(error)
    }
  }

  render() {
    const { result } = this.state

    return (
      <section className="flexbox-container">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="col-md-4 col-10 p-0">
            <div className="card-header bg-transparent border-0">
              {result &&
              (
                <div>
                  <h2 className="error-code text-center mb-2">
                    <FormattedMessage id="confirmPage.trueFirstPart" defaultMessage="Спасибо!"/>
                  </h2>
                  <h3 className="text-uppercase text-center">
                    <FormattedMessage id="confirmPage.trueSecondPart" defaultMessage="Ваше email подтвержден"/>
                  </h3>
                </div>
              )}
              {!result &&
              (
                <div>
                  <h2 className="error-code text-center mb-2">
                    <FormattedMessage id="confirmPage.falseFirstPart" defaultMessage="Что то не так...!"/>
                  </h2>
                  <h3 className="text-uppercase text-center">
                    <FormattedMessage
                      id="confirmPage.falseSecondPart"
                      defaultMessage="Вы либо уже подтвердили email ранее, либо указан неверный код подтверждения"
                    />
                  </h3>
                </div>
              )}
            </div>
            <div className="card-content">
              <div className="">
                <Link to="/" className="btn btn-primary btn-block">
                  <i className="ft-home"></i>
                  <FormattedMessage id="confirmPage.returnIndex" defaultMessage="Возврат на главную страницу"/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ConfirmPage
