import React from 'react'
import { connect } from 'react-redux'
// тут import внешних скриптов

class SupportPage extends React.Component {
  constructor(props) {
    super(props)
	this.quizContaner = React.createRef()
  }
  componentDidMount() {
    // Тут вызвать внешние скрипты
    // document.getElementById('first-point').innerHTML = 'Second point'
    // const $ = window.$
    // $('#textJquery').html("123")
    const enquiz_ioLoader = window.enquiz_ioLoader
    
    const test = () => {
      new enquiz_ioLoader({
        quiz_hash: 'bzxzuzt',
        type: enquiz_ioLoader.EMBEDDED,
		contaner: this.quizContaner.current,
        contacts: {
          name: this.props.userInfo.name,
          email: this.props.userInfo.email,
        },
        //button: 'text_quiz',
      })
    }
    test()
  }
  render() {
    return (
      <div>
        <br />
        <div ref={this.quizContaner} style={{ height: '600px' }} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
})

export default connect(mapStateToProps)(SupportPage)
