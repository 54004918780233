import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Loader } from '../../components'
import { QuizContainer } from '../../containers'
import {AuthService, QuizzService, QuizzTemplatesService } from '../../services'
import { Intl } from '../../utils'
import './TemplatesPage.scss';

const config = require('../../config/config')


const CardSideImage = ({ sideImage, majorColor, elementsColor, quizTitle, quizDescription, deleteTemplate, id, isMine, makeQuiz }) => {
  return (
    <div className="col-4 template-responsive" key={id}>
      <div className="card text-white text-center" style={{backgroundColor: majorColor, height: '280px'}}>
        {isMine && (
          <div className="template-heading-elements">
            <a onClick={() => deleteTemplate(id)}><i className="ft-trash-2"></i></a>
          </div>
        )}
        <div className="card-content">
          <div className="card-body pt-3">
            <img src={sideImage.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net")} alt="element 01" width="190" className="float-left" />
            <div className="subdivtext">
              <h1 className="text-white">{quizTitle}</h1>
              <p className="card-text">{quizDescription}</p>
            
              <div className="card-btn">
                <a target="_blank" href={`${config.TEMPLATE_URL}${id}`}>
                  <button className="btn  btn-white btn-md template-actions-button">
                    <FormattedMessage id="templates.seeTemplate" defaultMessage="Просмотр" />
                  </button>
                </a>
                <button className="btn btn-blue btn-md template-actions-button" onClick={() => makeQuiz(id)}>
                  <FormattedMessage id="templates.chooseTemplate" defaultMessage="Выбрать" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CardBackgroundImage = ({ backgroundImage, majorColor, elementsColor, quizTitle, quizDescription, id, deleteTemplate, isMine, makeQuiz }) => {
  return (
    <div className="col-4 template-responsive" key={id}>
      <div className="card-btn">
        <a target="_blank" href={`${config.TEMPLATE_URL}${id}`}>
          <button className="btn btn-md btn-white template-actions-button">
            <FormattedMessage id="templates.seeTemplate" defaultMessage="Просмотр" />
          </button>
        </a>
        <button className="btn btn-md btn-blue template-actions-button" onClick={() => makeQuiz(id)}>
          <FormattedMessage id="templates.chooseTemplate" defaultMessage="Выбрать" />
        </button>
      </div>
      <div className="card text-white text-center" style={{ height: '280px', overflow: 'hidden' }}>
        {isMine && (
          <div className="template-heading-elements">
            <a onClick={() => deleteTemplate(id)}><i className="ft-trash-2"></i></a>
          </div>
        )}
        <div className="card-content">
          <img
            className="card-img img-fluid"
            src={`${(backgroundImage.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net"))}`}
            alt="background_image"
          />
          <div className="card-img-overlay">
          <div>
            <div className="subdivtext">
              <h4 className="text-white">{quizTitle}</h4>
              <p className="card-text">{quizDescription}</p>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CardNoImage = ({ sideImage, majorColor, elementsColor, quizTitle, quizDescription, deleteTemplate, id, isMine, makeQuiz }) => {
  return (
    <div className="col-4 template-responsive" key={id}>
      <div className="card text-white text-center" style={{ backgroundColor: majorColor, height: '280px'}}>
        {isMine && (
          <div className="template-heading-elements">
            <a onClick={() => deleteTemplate(id)}><i className="ft-trash-2"></i></a>
          </div>
        )}
        <div className="card-content" style={{ paddingTop: '15%' }}>
          <div className="card-body">
            <h4 className="card-title text-white">{quizTitle}</h4>
            <p className="card-text">{quizDescription}</p>
            <a target="_blank" href={`${config.TEMPLATE_URL}${id}`}>
              <button className="btn btn-primary bg-amber btn-md bg-darken-3 template-actions-button">
                <FormattedMessage id="templates.seeTemplate" defaultMessage="Просмотр" />
              </button>
            </a>
            <button className="btn btn-primary bg-amber btn-md bg-darken-3 template-actions-button" onClick={() => makeQuiz(id)}>
              <FormattedMessage id="templates.chooseTemplate" defaultMessage="Выбрать" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}


class TemplatesPage extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      templates: [],
      templateTheme: 'all',
      onlyMine: false,
      loader: true,
    }
    
    this.handleChangeTemplateTheme = this.handleChangeTemplateTheme.bind(this)
    this.getTemplates = this.getTemplates.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.deleteTemplate = this.deleteTemplate.bind(this)
    this.makeQuizFromTemplate = this.makeQuizFromTemplate.bind(this)
  }
  
  async getTemplates(data) {
    const { templateTheme, only_mine } = data
    let templates = await QuizzTemplatesService.getListQuizzTemplates({ templateTheme, only_mine })
    if (templates.quiz_templates) {
      templates = templates.quiz_templates.map((template, index) => {
        return {
          title: template.title,
          json_data: JSON.parse(template.json_data),
          is_mine: template.is_mine,
          id: template.id,
        }
      })
    } else {
      templates = []
    }
  
    this.setState({ templates, templateTheme, loader: false })
  }
  
  async componentDidMount() {
    const { history } = this.props
    const { templateTheme } = this.state
    try {
      const auth = await AuthService.getUserInfo()
      if (!auth) {
        await AuthService.deauthenticateUser()
        history.push('/')
      }
      this.getTemplates({ templateTheme })
      
    } catch (error) {
      console.log(error)
    }
  }
  
  async handleChangeTemplateTheme(name) {
    const { onlyMine } = this.state
    try {
      this.getTemplates({ templateTheme: name, only_mine: onlyMine })
    } catch (error) {
      console.log(error)
    }
  }
  
  handleSwitch(action) {
    const { templateTheme } = this.state
    this.getTemplates({ templateTheme, only_mine: action })
    
    this.setState({ onlyMine: action })
  }
  
  async deleteTemplate(id) {
    try {
      const { templateTheme, onlyMine } = this.state
      const result = await QuizzTemplatesService.deleteQuizzTemplate(id)
      this.getTemplates({ templateTheme, only_mine: onlyMine })
    } catch (error) {
      console.log(error)
    }
  }
  
  async makeQuizFromTemplate(id) {
    const { history } = this.props
    try {
      const quiz = await QuizzTemplatesService.makeQuizzFromTemplate(id)
      const { unique_hash } = quiz
      history.push(`/edit-quiz/${unique_hash}`)
    } catch (error) {
      console.log(error)
    }
  }
  
  render() {
    const { templates, templateTheme, onlyMine, loader } = this.state;
        
    return (
      <div className="templates">
        {loader ? <Loader /> : 
          (<React.Fragment>
            <div className="form-group row">
              <ul className="form-control template-themes">
                {['all', ...config.TEMPLATE_THEMES].map((name) => {
                    return (
                      <li 
                        key={name} 
                        value={name} 
                        className={`theme ${templateTheme===name ? 'active' : ''}`}
                        onClick={()=>this.handleChangeTemplateTheme(name)}
                      >
                          {Intl.getMessage(`templateThemes.${name}`) || 'Все'}
                      </li>
                    )
                })}
              </ul>
              <fieldset style={{ display: 'inlineBlock' }}>
                <div className="float-left">
                  <input type="checkbox" className="switch hidden"/>
                    <div className="btn-group">
                      <a className={`btn ${(!onlyMine) ? 'active btn-success' : 'btn-default'}`} onClick={() => this.handleSwitch(false)}>
                        <FormattedMessage id="templates.allTemplate" defaultMessage="Общие шаблоны" />
                      </a>
                      <a className={`btn ${(onlyMine) ? 'active btn-success' : 'btn-default'}`} onClick={() => this.handleSwitch(true)}>
                        <FormattedMessage id="templates.onlyMine" defaultMessage=" Мои шаблоны" />
                      </a>
                    </div>
                </div>
              </fieldset>
            </div>
            <div className="row">
            {templates.map((template, index) => {
              if (template.json_data.side_image) {
                return CardSideImage({
                  sideImage: template.json_data.side_image,
                  majorColor: template.json_data.major_color,
                  elementsColor: template.json_data.elements_color,
                  quizTitle: template.json_data.quiz_title,
                  quizDescription: template.json_data.quiz_description,
                  buttonText: template.json_data.button_text,
                  isMine: template.is_mine,
                  id: template.id,
                  deleteTemplate: this.deleteTemplate,
                  makeQuiz: this.makeQuizFromTemplate,
                })
              }
              if (template.json_data.background_image) {
                return CardBackgroundImage({
                  backgroundImage: config.IMAGE_RESIZE_URL + "/800x700/" + template.json_data.background_image,
                  majorColor: template.json_data.major_color,
                  elementsColor: template.json_data.elements_color,
                  quizTitle: template.json_data.quiz_title,
                  quizDescription: template.json_data.quiz_description,
                  buttonText: template.json_data.button_text,
                  isMine: template.is_mine,
                  id: template.id,
                  deleteTemplate: this.deleteTemplate,
                  makeQuiz: this.makeQuizFromTemplate,
                })
              }
              if (!template.json_data.background_image && !template.json_data.side_image) {
                return CardNoImage({
                  backgroundImage: template.json_data.background_image,
                  majorColor: template.json_data.major_color,
                  elementsColor: template.json_data.elements_color,
                  quizTitle: template.json_data.quiz_title,
                  quizDescription: template.json_data.quiz_description,
                  buttonText: template.json_data.button_text,
                  isMine: template.is_mine,
                  id: String(template.id),
                  deleteTemplate: this.deleteTemplate,
                  makeQuiz: this.makeQuizFromTemplate,
                })
              }
            }
            )}
          </div>
          </React.Fragment>)
        }
      </div>
    )
  }
}

export default TemplatesPage
