import { combineReducers } from 'redux'
import userInfo from './userInfo'
import emailProfile from './emailProfile'
import csvLinkReducer from './csvLinkReducer'
import leadReducer from './leadReducer'
import quizReducer from './quizReducer'

export default combineReducers({
  userInfo,
  emailProfile,
  csvLinkReducer,
  leadReducer,
  quizReducer,
})
