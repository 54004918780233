import { AuthService } from './index'

const config = require('../config/config')

class LeadService {
  static async getListLeads(data) {
    try {
      const request = {
        page: data.page,
        limit: data.limit,
      }
      if (data.leadFilter) {
        request.state = data.leadFilter
      }
      const body = {
        service: 'go.micro.srv.leads',
        method: 'Leads.GetLeadList',
        request,
      }

      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) return false
      response = await response.json()

      return response
    } catch (error) {
      throw error
    }
  }

  static async getLeadById(id) {
    try {
      const request = {
        id,
      }
      const body = {
        service: 'go.micro.srv.leads',
        method: 'Leads.GetLead',
        request,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status !== 200) return false
      response = await response.json()
      
      return response
    } catch (error) {
      throw error
    }
  }

  static async updateSelectedLeads(selectedLeads, type) {
    try {
      const request = {
        ids: selectedLeads,
        state: type,
      }
      const body = {
        service: 'go.micro.srv.leads',
        method: 'Leads.UpdateLeadStateBulk',
        request,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) return false

      return true
    } catch (error) {
      throw error
    }
  }

  static async updateLeadState(data) {
    try {
      const request = {
        id: data.id,
        state: data.newState,
      }
      const body = {
        service: 'go.micro.srv.leads',
        method: 'Leads.UpdateLeadState',
        request,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status !== 200) return false
      
      return true
    } catch (error) {
      throw error
    }
  }
  
  static async getListLeadsByQuiz(data) {
    try {
      const request = {
        quiz_hash: data.quizFilter,
        page: data.page,
        limit: data.limit,
        state: data.leadFilter,
      }
      const body = {
        service: 'go.micro.srv.leads',
        method: 'Leads.GetLeadList',
        request,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status !== 200) return false
  
      response = await response.json()
  
      return response
    } catch (error) {
      throw error
    }
  }
  
  static async unfreezeLead(id) {
    try {
      const request = {
        id,
      }
      const body = {
        service: 'go.micro.srv.leads',
        method: 'Leads.UnfreezeLead',
        request,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status !== 200) return false
      
      return true
    } catch (error) {
      throw error
    }
  }
}

export default LeadService
