import React from 'react'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'

class Tooltip extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      isOpen: false,
    }
  }
  
  openPopover() {
    this.setState({ isOpen: true })
  }
  
  closePopover() {
    this.setState({ isOpen: false })
  }
  
  render() {
    const { isOpen } = this.state
    const { header, body, target, placement } = this.props
    
    return (
      <div style={{ display: 'inline-block', marginLeft: '5px' }}>
        <i className="far fa-question-circle" style={{color: '#BDBDBD', fontSize: '16px'}} onMouseEnter={() => this.openPopover()} onMouseLeave={() => this.closePopover()} id={target} />
        <Popover placement={placement || 'right'} isOpen={isOpen} target={target} toggle={this.toggle}>
          <PopoverHeader><div dangerouslySetInnerHTML={{ __html: header }} /></PopoverHeader>
          <PopoverBody><div dangerouslySetInnerHTML={{ __html: body }} /></PopoverBody>
        </Popover>
      </div>
    )
  }
}

export default Tooltip
