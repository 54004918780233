import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Intl } from '../../utils'


const MainMenu = ({ quizzes, totalLeads, leadsLeft, is_confirmed }) => (
  <div className="main-menu menu-static menu-dark menu-accordion menu-shadow">
    <div className="main-menu-content" style={{ width: '106%' }}>
      <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
        <li className="nav-item fix-nav">
            <NavLink exact={true} to="/">
            <i className="la la-home"></i>
            <span className="menu-title"><FormattedMessage id="mainMenu.quizzes" defaultMessage="Квизы" /></span>
            <span className="badge badge badge-info badge-pill float-right mr-2 margin-right-quizzes" id="count-quizzes">{quizzes}</span>
            </NavLink>
        </li>
        <li className=" nav-item fix-nav">
          <NavLink exact={true} to="/leads">
            <i className="la la-rocket"></i>
            <span className="menu-title">{Intl.getMessage('leadsPage.leads')}</span>
            {totalLeads && (<span className="badge badge bg-purple badge-pill float-right mr-2 margin-right-quizzes" id="count-leads">{totalLeads}</span>)}
          </NavLink>
        </li>
        <li className="nav-item fix-nav">
          <NavLink to="/templates">
            <i class="far fa-images"></i>
            <span className="menu-title"><FormattedMessage id="mainMenu.templates" defaultMessage="Шаблоны" /></span>
          </NavLink>
        </li>
        <li className="nav-item fix-nav">
          <a href="http://enquiz.io/docs/" target="_blank">
            <i className="la la-folder"></i>
            <span className="menu-title" data-i18n=""><FormattedMessage id="mainMenu.guides" defaultMessage="Руководства" /></span>
          </a>
        </li>
        <li className=" nav-item">
          <NavLink to="/partner">
            <i className="la la-money"></i>
            <span className="menu-title" data-i18n=""><FormattedMessage id="mainMenu.partner" defaultMessage="Партнерам" /></span>
          </NavLink>
        </li>
        <li className=" navigation-header">
          <span data-i18n="nav.category.support"><FormattedMessage id="mainMenu.account" defaultMessage="Аккаунт" /></span>
          <i className="la la-ellipsis-h ft-minus" data-toggle="tooltip" data-placement="right" data-original-title="Support"></i>
        </li>
        <li className=" nav-item">
          <NavLink to="/packages">
            <i className="la la-suitcase"></i>
            <span className="menu-title" data-i18n="">{Intl.getMessage('packagesPage.leadsPackages')}</span>
            {leadsLeft && (<span className="badge badge badge-warning badge-pill float-right mr-2 margin-right-packages">{leadsLeft}</span>)}
          </NavLink>
        </li>
        <li className=" nav-item">
          <NavLink to="/profile">
            <i className="la la-user"></i>
            <span className="menu-title" data-i18n=""><FormattedMessage id="mainMenu.profile" defaultMessage="Профиль" /></span>
            {(!is_confirmed) && <span className="margin-right-confirm"><i className="la la-exclamation-circle" style={{ color: 'red', fontSize: '1.7rem' }}></i></span>}
          </NavLink>
        </li>
        <li className=" nav-item">
          <NavLink to="/support">
            <i className="la la-support"></i>
            <span className="menu-title" data-i18n=""><FormattedMessage id="mainMenu.support" defaultMessage="Поддержка" /></span>
          </NavLink>
        </li>
        <li className=" nav-item">
          <NavLink to="/feedback">
            <i className="la la-star-o"></i>
            <span className="menu-title" data-i18n=""><FormattedMessage id="mainMenu.feedback" defaultMessage="Нужно ваше мнение!" />
            </span><span className="badge badge badge-danger badge-pill float-right mr-2 margin-right-packages"> NEW</span>
          </NavLink>
        </li>
      </ul>
    </div>
  </div>
)

export default MainMenu
