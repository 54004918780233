import React, {useState, useEffect} from 'react';
import getIntegrationData from './data';
import { AuthService } from '../../services'
import './index.scss';

const IntegrationPage = props => {

    const {query} = props.location;
    const [activeIndex, setIndex] = useState(0);
    const [userData, setUserData] = useState(null);
    
    const unique_hash = query && query.unique_hash || '';
    const integrationData = getIntegrationData(userData && {...userData.user, unique_hash});
    const rightSide = integrationData[activeIndex];

    useEffect (()=> {
        async function getUserData(){
            try {
                const auth = await AuthService.getUserInfo()
                
                if (!auth) {
                    await AuthService.deauthenticateUser()
                }
                setUserData(auth);
            } catch (error) {
                console.log(error)
            }
        }
        getUserData();
    }, [])    

    return (
        <div className="integration-page">
            <h1>Интеграции</h1>
            <h2>{query && query.quiz_title || ''}</h2>
            <div>
                <div className="left-side">
                    {integrationData.map((elem, index)=> {
                        return (
                            <div className={`${activeIndex === index ? 'active' : ''} integration-elem`} onClick={()=>setIndex(index)} key={index}>
                                <p>{elem.title}</p>
                                {elem.titleAction && <span>{elem.titleAction}</span>}
                            </div>
                        )
                    })}
                </div>
                <section>
                    <h1>{rightSide.title}</h1>
                    <p>{rightSide.section.paragraph}</p>
                    <div>{rightSide.section.list && rightSide.section.list.map((item, index)=>(
                        <React.Fragment key={index}>
                            <span>{item.title}</span>
                            <ol>
                                {item.items.map((el, index)=>(
                                    <li key={index}>{el}</li>
                                ))}
                            </ol>
                        </React.Fragment>
                    ))}</div>
                    {rightSide.section.links && 
                        <div className="links">
                            {rightSide.section.links.map((elem, index)=>(
                                <a href={elem.path} key={index} target="_blank">{elem.link}</a>
                            ))}
                        </div>
                    }
                </section>
            </div>
        </div>
    )
}



export default IntegrationPage;