const rates = { // тарифы
  0: { // 30 дней
    10: 350,   // 20 заявок, цена
    20: 750,   // 50
    30: 1000,  // 100
    40: 2000,  // 250
    50: 3000,  // 500
    60: 5000,  // 1000
    70: 8000, // 2000
    80: 1500,  // Unlimited
  },
  30: { // 90 дней
    10: 350,   // 20 заявок, цена
    20: 750,   // 50
    30: 1000,  // 100
    40: 2000,  // 250
    50: 3000,  // 500
    60: 5000,  // 1000
    70: 8000, // 2000
    80: 3500,  // Unlimited
  },
  60: { // 182 дня
    10: 350,   // 10 заявок, цена
    20: 750,   // 50
    30: 1000,  // 100
    40: 2000,  // 250
    50: 3000,  // 500
    60: 5000,  // 1000
    70: 8000, // 2000
    80: 5000,  // Unlimited
  },
  100: { // 365 дней
    10: 350,   // 10 заявок, цена
    20: 750,   // 50
    30: 1000,  // 100
    40: 2000,  // 250
    50: 3000,  // 500
    60: 5000,  // 1000
    70: 8000, // 2000
    80: 8000,  // Unlimited
  },
}

export default rates
