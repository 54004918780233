import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ChromePicker } from 'react-color'
import { Switch } from '../index'
import { UploadService } from '../../services'
import './step5.css'
import { Tooltip } from '../../components'
import { Intl } from '../../utils'
import './style.scss';
const config = require('../../config/config')
const imageSize = '200,fit';

const colors = [
  {
    major_color: '#00C87F',
    elements_color: '#00ADFF',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#FFEF01',
    elements_color: '#000000',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#00ADFF',
    elements_color: '#FF0075',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#D2EF00',
    elements_color: '#656565',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#D5CFC5',
    elements_color: '#6D5D58',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#ECE9E1',
    elements_color: '#4181AC',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#4857A2',
    elements_color: '#9A6669',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#1D1E54',
    elements_color: '#D70059',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#BAD0EB',
    elements_color: '#75477C',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#88B7BD',
    elements_color: '#C4795E',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#FEF9D9',
    elements_color: '#00A082',
    text_color: '#FFFFFF'
  },
  {
    major_color: '#FFFFFF',
    elements_color: '#000000',
    text_color: '#FFFFFF'
  }
]

class Step5 extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      isLoadSide: false,
      isLoadExpert: false,
      isLoadBackgroundImgLoad: false,
      isLoadFavicon: false,
      json: this.props.data,
      displayPickerMajorColor: false,
      displayPickerElementsColor: false,
      displayPickerTextColor: false,
      customStyles: '',
      errorUploadFavicon: '',
    }
    this.toggleShowExpert = this.toggleShowExpert.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getImage = this.getImage.bind(this)
    this.openPickerMajorColor = this.openPickerMajorColor.bind(this)
    this.changeMajorColor = this.changeMajorColor.bind(this)
    this.chooseMajorColor = this.chooseMajorColor.bind(this)
    this.openPickerElementsColor = this.openPickerElementsColor.bind(this)
    this.chooseElementsColor = this.chooseElementsColor.bind(this)
    this.changeElementsColor = this.changeElementsColor.bind(this)
    this.changeLanguage = this.changeLanguage.bind(this)
    this.changeSlideAnimation = this.changeSlideAnimation.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.chooseTextColor = this.chooseTextColor.bind(this)
    this.openPickerTextColor = this.openPickerTextColor.bind(this)
    this.changeTextColor = this.changeTextColor.bind(this)
    this.changeCustomStyles = this.changeCustomStyles.bind(this)
  }
  
  componentDidMount() {
    const { json, displayPickerMajorColor, displayPickerElementsColor, displayPickerTextColor } = this.state
    if (!json.text_color) json.text_color = '#eef8ff';

    document.addEventListener('click', (e) => {
      const target = e.target;

      if((this.state.displayPickerMajorColor || this.state.displayPickerElementsColor || this.state.displayPickerTextColor) && !target.closest('.sketch-picker') && target.className !=='color' && target.className !=='chrome-picker' && !target.closest('.chrome-picker')){
        this.setState({displayPickerMajorColor: false, displayPickerElementsColor: false, displayPickerTextColor: false})
      }
    })

    this.setState({ json, customStyles: json.customStyles })
  }
  
  componentWillReceiveProps(nextProps) {
    const { data } = nextProps
    this.setState({ json: data })
  }
  
  toggleShowExpert() {
    const { json } = this.state
    json.show_expert = !json.show_expert
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  changeCustomStyles(event) {
    const { json } = this.state
    json.customStyles = event.target.value
    this.setState({ json, customStyles: event.target.value }, this.props.refreshState(this.state.json))
  }
  
  openPickerMajorColor() {
    const { displayPickerMajorColor } = this.state
    this.setState({ 
      displayPickerMajorColor: !displayPickerMajorColor,
      displayPickerElementsColor: false,
      displayPickerTextColor: false
    })
  }
  
  openPickerElementsColor() {
    const { displayPickerElementsColor } = this.state
    this.setState({ 
      displayPickerElementsColor: !displayPickerElementsColor ,
      displayPickerTextColor: false,
      displayPickerMajorColor: false,
    })
  }
  
  openPickerTextColor() {
    const { displayPickerTextColor } = this.state
    this.setState({ 
      displayPickerTextColor: !displayPickerTextColor ,
      displayPickerMajorColor: false,
      displayPickerElementsColor: false,
    })
  }

  changeDiscountWord(event) {
    const value = event.currentTarget.value
    const { json } = this.state
    json.discount_title = value
    this.setState({ json }, this.props.refreshState(this.state.json))
  }
  
  handleChange(event) {
    const { target: { value, name } } = event
    const { json } = this.state
    json[name] = value
    this.setState({ json }, this.props.refreshState(this.state.json))
  }
  
  setDiscountUnit = (event) => {
    const { target: { value, name } } = event
    const { json } = this.state
    json['discount_units'] = value
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  setDiscount = (type, action) => {
    const { json } = this.state
    if (type === 'volume') {
      if (action === 'minus') json.discount_volume -= 0.5
      if (action === 'plus') json.discount_volume += 0.5
    }
    if (type === 'increase_by') {
      json.discount_increase_by *= 10
      if (action === 'minus') json.discount_increase_by -= 1
      if (action === 'plus') json.discount_increase_by += 1
      json.discount_increase_by /= 10
    }

    this.setState({ json }, this.props.refreshState(this.state.json))
  }
  
  async getImage(event) {
    const { json } = this.state
    const name = event.target.name
    const data = new FormData()
    const file = event.target.files[0]

    if (name === 'favicon' && !this.checkingFaviconFileExtension(file)) {
      this.setState({ errorUploadFavicon: 'Неверный тип файла' })
      return
    }

    if (file) {
      if (name === 'background_image') this.setState({ isLoadBackgroundImgLoad: true });
      if (name === 'side_image') this.setState({ isLoadSide: true });
      if (name === 'expert_image') this.setState({ isLoadExpert: true });
      if (name === 'favicon_url') this.setState({ isLoadFavicon: true });
      data.append('file', file)
      try {
        json[name] = await UploadService.uploadImage(data)
      } catch (error) {
        console.log(error)
      }
      if (name === 'background_image') json.side_image = ''
      if (name === 'side_image') json.background_image = ''

      if (name === 'background_image') this.setState({ json, isLoadBackgroundImgLoad: false }, this.props.refreshState(this.state.json));
      if (name === 'side_image') this.setState({ json, isLoadSide: false }, this.props.refreshState(this.state.json));
      if (name === 'expert_image') this.setState({ json, isLoadExpert: false }, this.props.refreshState(this.state.json));
      if (name === 'favicon_url') this.setState({ json, isLoadFavicon: false }, this.props.refreshState(this.state.json));
    }
  }

  checkingFaviconFileExtension = (file) => {
    const acceptableExt = ['image/png', 'image/ico']
    return acceptableExt.includes(file.type)
  }
  
  chooseMajorColor() {
    this.setState({ displayPickerMajorColor: false })
  }
  
  chooseElementsColor() {
    this.setState({ displayPickerElementsColor: false })
  }
  
  chooseTextColor() {
    this.setState({ displayPickerTextColor: false })
  }
  
  changeMajorColor(color) {
    const { json } = this.state
    json.major_color = color.hex
    this.setState({ json }, this.props.refreshState(this.state.json))
  }
  
  changeElementsColor(color) {
    const { json } = this.state
    json.elements_color = color.hex
    this.setState({ json }, this.props.refreshState(this.state.json))
  }
  
  changeTextColor(color) {
    const { json } = this.state
    json.text_color = color.hex
    this.setState({ json }, this.props.refreshState(this.state.json))
  }
  
  changeLanguage(event) {
    const language = event.target.value
    const { json } = this.state
    json.language = language
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  changeButtonAnimation = (event) => {
    const animation = event.target.value
    const { json } = this.state
    json.button_animation = animation
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  changeSlideAnimation(event) {
    const animation = event.target.value
    const { json } = this.state
    json.slide_animation = animation
    this.setState({ json }, this.props.refreshState(this.state.json))
  }
  
  deleteExpertImage() {
    const { json } = this.state
    json.expert_image = ''
    this.setState({ json }, this.props.refreshState(this.state.json))
  }
  
  deleteImage() {
    const { json } = this.state
    json.background_image = ''
    json.side_image = ''
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  changeStandardColor = data => {
    const { json } = this.state;
    json.major_color = data.major_color;
    json.elements_color = data.elements_color;
    json.text_color = data.text_color;
    this.setState({json}, this.props.refreshState(this.state.json))
  }

  toggleCheckBox = (action) => {
    const { json } = this.state
    json[action] = !json[action]
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  handleInitialState = (value) => {
    const { json } = this.state
    const newValue = value === 'cover' ? 'question' : 'cover';
    json.initial_state = newValue;
    this.setState({ json }, this.props.refreshState(this.state.json))
  }
  
  render() {
    const {
      json: {
      show_expert, expert_name, expert_position, expert_image, background_image, side_image, major_color,
      elements_color, language, quiz_title, quiz_description, button_text, text_color, enable_quiz, discount_offer, discount_title: discountTitle, 
      discount_volume, discount_increment, discount_increase_by, discount_units, initial_state, button_animation, slide_animation, favicon_url,
      },
      displayPickerMajorColor,
      displayPickerElementsColor,
      displayPickerTextColor,
      isLoadBackgroundImgLoad,
      isLoadSide,
      isLoadExpert,
      customStyles,
      isLoadFavicon,
      errorUploadFavicon,
    } = this.state;
    
    return (
      <fieldset className="stage-5-content">
        <div className="row">
          <div className="col-md-6 left-side">
            <div className="card">
              <div className="card-content collapse show">
                <div className="card-body">
                  <p className="custom-text-title type-2">Цветовая схема</p>
                  <div className="picker-section">
                    <div className="form-group" id="spectrum">
                      <label>
                        <FormattedMessage id="step5.majorColor" defaultMessage="Основной цвет" />
                      </label>
                      <div className="swatch">
                        <div className="color" style={{ background: major_color }} onClick={this.openPickerMajorColor} />
                      </div>
                      {displayPickerMajorColor && (
                        <div className="popoverColorPicker">
                          <ChromePicker 
                            color={major_color} 
                            onChange={this.changeMajorColor} 
                            disableAlpha={true} 
                          />
                        </div>
                      )}
                    </div>
                    <div className="form-group" id="spectrum3">
                      <span className="custom-text-title" style={{marginRight: '12px'}}>Текст</span>
                      <br />
                      <div className="swatch">
                        <div className="color" style={{ background: text_color }} onClick={this.openPickerTextColor} />
                      </div>
                      {displayPickerTextColor && (
                        <div className="popoverColorPicker">
                          <ChromePicker color={text_color} onChange={this.changeTextColor} disableAlpha={true} />
                        </div>
                      )}
                    </div>
                    <div className="form-group" id="spectrum2">
                      <label>
                        <FormattedMessage id="step5.elementsColor" defaultMessage="Цвет элементов" />
                      </label>
                      <br />
                      <div className="swatch">
                        <div className="color" style={{ background: elements_color }} onClick={this.openPickerElementsColor} />
                      </div>
                      {displayPickerElementsColor && (
                        <div className="popoverColorPicker">
                          <ChromePicker color={elements_color} onChange={this.changeElementsColor} disableAlpha={true} />
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="custom-text-title standard">Стандартные темы оформления</p>
                  <div className="standard-colors">
                    {colors.map((elem, index)=> {
                      return (<span key={index} onClick={()=>this.changeStandardColor(elem)} className="color-element" style={{backgroundColor: elem.major_color}}></span>)
                    })}
                  </div>
                  <p className="custom-text-title type-2 image">Изображения</p>
                  <div className="media-content">
                    <div className="left">
                      <label>
                        <FormattedMessage id="step5.backgroundImage" defaultMessage="Фоновое изображение" />
                        <Tooltip
                          header={Intl.getMessage('step5.tooltipBackgroundImageTitle')}
                          body={Intl.getMessage('step5.tooltipBackgroundImage')}
                          target="id10232"
                        />
                      </label>
                      <div className="media-side">
                        {isLoadBackgroundImgLoad && (
                          <div>
                            <img src={require('./load.gif')} />
                          </div>
                        )}
                        {(background_image && !isLoadBackgroundImgLoad) && (
                          <div>
                            <img
                              className="img-fluid"
                              src={`${(background_image) ? background_image.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net") : require('./images/444.jpg')}`}
                              alt="background_image"
                              width="150px"
                            />
                            <span className="media-right">
                              <a className="btn-icon" onClick={() => this.deleteImage()}>
                                <i class="fas fa-times"></i>
                              </a>
                            </span>
                          </div>
                        )}
                        <label htmlFor="background_image" className="btn-create">
                          <FormattedMessage id="step5.clickToLoad" defaultMessage="Нажмите, чтобы загрузить" />
                        </label>
                        <input id="background_image" name="background_image" type="file" onChange={this.getImage} style={{ display: 'none'}} />
                      </div>
                    </div>
                    <div className="right">
                      <label>
                        <FormattedMessage id="step5.sideImage" defaultMessage="Боковое изображение" />
                        <Tooltip
                          header={Intl.getMessage('step5.tooltipSideImageTitle')}
                          body={Intl.getMessage('step5.tooltipSideImage')}
                          target="id10243"
                          />
                      </label>
                      <div className="media-side">
                        {isLoadSide && (
                          <div>
                            <img src={require('./load.gif')} />
                          </div>
                        )}
                        {(side_image && !isLoadSide) && (
                          <div>
                            <img
                              src={`${(side_image) ? side_image : require('./images/01.png')}`}
                              width="150"
                              className="float-left"
                              alt="side_image"
                            />
                            <span className="media-right">
                              <a className="btn-icon" onClick={() => this.deleteImage()}>
                                <i class="fas fa-times"></i>
                              </a>
                            </span>
                          </div>
                        )}
                        <label htmlFor="side_image" className="btn-create">
                          <FormattedMessage id="step5.clickToLoad" defaultMessage="Нажмите, чтобы загрузить" />
                        </label>
                        <input id="side_image" type="file" name="side_image" onChange={this.getImage} style={{ display: 'none'}} />
                      </div>
                    </div>
                  </div>
                  <div className="media-content favicon">
                    <div className="left">
                      <label>
                        <FormattedMessage id="step5.faviconUrl" defaultMessage="Favicon (только ico, png)" />
                      </label>
                      <div className="media-side">
                        {isLoadFavicon && (
                          <div>
                            <img src={require('./load.gif')} />
                          </div>
                        )}
                        {(favicon_url && !isLoadFavicon) && (
                          <div>
                            <img
                              className="img-fluid"
                              src={`${favicon_url.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net") || require('./images/444.jpg')}`}
                              alt="favicon_url"
                              width="48px"
                            />
                            <span className="media-right">
                              <a className="btn-icon" onClick={() => this.deleteImage()}>
                                <i class="fas fa-times"></i>
                              </a>
                            </span>
                          </div>
                        )}
                        <label htmlFor="favicon_url" className="btn-create">
                          <FormattedMessage id="step5.clickToLoad" defaultMessage="Нажмите, чтобы загрузить" />
                        </label>
                        <input id="favicon_url" name="favicon_url" type="file" onChange={this.getImage} style={{ display: 'none'}} />
                      </div>
                    </div>
                  </div>
                  {!!errorUploadFavicon && (
                    <div className="error">{errorUploadFavicon}</div>
                  )}
                  <div className="expert-side">
                    <p className="custom-text-title type-2">Дополнительно</p>
                    <div onClick={()=>this.handleInitialState(initial_state)}>
                      <Switch toggle={initial_state==='cover'} />
                      <label>
                        Показывать обложку квиза
                      </label>
                      <label className="ml-1">
                        <Tooltip
                          header={Intl.getMessage('step6.tooltipInitialStateTitle')}
                          body={Intl.getMessage('step6.tooltipInitialState')}
                          target="id1"
                        />
                      </label>
                    </div>
                    <div onClick={this.toggleShowExpert}>
                      <Switch toggle={show_expert} />
                      <label className="ml-1">
                        <FormattedMessage id="step5.showExpert" defaultMessage="Отображать эксперта" />
                        <Tooltip
                          header={Intl.getMessage('step5.tooltipShowExpertTitle')}
                          body={Intl.getMessage('step5.tooltipShowExpert')}
                          target="id102"
                        />
                      </label>
                    </div>
                    {show_expert && (
                      <div className="card-expert">
                        <div className="form-group">
                          <label htmlFor="expert_name">
                            <FormattedMessage id="step5.expertName" defaultMessage="Имя" />
                          </label>
                          <input name="expert_name" type="text" className="form-control" id="firstName2" value={expert_name} onChange={this.handleChange} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="expert_position">
                            <FormattedMessage id="step5.expertPosition" defaultMessage="Должность" />
                          </label>
                          <input name="expert_position" type="text" className="form-control" id="firstName2" value={expert_position} onChange={this.handleChange} />
                        </div>
                        <p className="custom-text-title">Фотография</p>
                        <div className="media-side">
                          {isLoadExpert && (
                            <div>
                              <img src={require('./load.gif')} />
                            </div>
                          )}
                          <input id="expert_image" name="expert_image" type="file" onChange={this.getImage} style={{ display: 'none'}} />
                          {expert_image && (
                            <div>
                              <img
                                src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${expert_image}`}
                                alt="background_image"
                              />
                                <span className="media-right">
                                  <a className="btn-icon" onClick={() => this.deleteExpertImage()}>
                                    <i class="fas fa-times"></i>
                                  </a>
                                </span>
                            </div>
                          )}
                          <label htmlFor="expert_image" className="btn-create">
                            <FormattedMessage id="step5.clickToLoadPhoto" defaultMessage="Нажмите, чтобы загрузить фото" />
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  
                  <div className="discount-side">
                    {false && (
                      <div onClick={() => this.toggleCheckBox('enable_quiz')}>
                        <Switch toggle={enable_quiz} />
                        <label className="ml-1">
                          <FormattedMessage id="step6.enableQuiz" defaultMessage="Включить вкиз" />
                        </label>
                        <Tooltip
                          header={Intl.getMessage('step6.enableQuizInfoTitle')}
                          body={Intl.getMessage('step6.enableQuizInfo')}
                          target="id1"
                        />
                      </div>
                    )}
                    <div style={{ display: "inline-block"}} onClick={() => this.toggleCheckBox('discount_offer')}>
                      <Switch toggle={discount_offer} />
                      <label className="ml-1">
                        <FormattedMessage id="step6.discountOffer" defaultMessage="Отображать скидку" />
                      </label>
                    </div>
                    {discount_offer && (
                      <div className="discount-container">
                        <div className="form-group discount-top">
                          <div>
                            <p className="custom-text-title">Текст</p>
                            <input type="text" placeholder="Введите слово" value={discountTitle} onChange={(event) => this.changeDiscountWord(event)} />
                          </div>
                          <fieldset>
                            <p className="custom-text-title">Размер скидки</p>
                            <div className="input-group bootstrap-touchspin" style={{flexWrap: 'unset'}}>
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text bootstrap-touchspin-prefix"
                                  style={{ display: 'none' }}
                                />
                                <input
                                  type="number"
                                  step="any"
                                  name="discount_volume"
                                  className="touchspin form-control"
                                  value={discount_volume}
                                  style={{ display: 'block', paddingRight: '0px', width: '70px' }}
                                  onChange={this.handleChange}
                                />
                              </div>

                            <div className="input-group-append">
                                <input className="touchspin form-control" style={{width: '50px', paddingRight: '0px', width: '70px'}} onChange={(e) => this.setDiscountUnit(e)} value={discount_units} />
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="form-group discount-bottom">
                          <label htmlFor="issueinput10">
                            <FormattedMessage id="step6.stepDiscountIncrement" defaultMessage="Шаг нарастания скидки" />
                          </label>
                          <fieldset>
                            <div className="input-group bootstrap-touchspin" style={{flexWrap: 'unset'}}>
                              <input
                                type="number"
                                step="any"
                                className="touchspin form-control"
                                name="discount_increase_by"
                                value={discount_increase_by}
                                onChange={this.handleChange}
                                data-bts-postfix="%"
                                style={{ display: 'block', paddingRight: '0px', width: '70px' }}
                              />
                              {discount_increase_by === '0' && <span>Шаг 0 означает, что скидка не увеличивается</span>}
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    )}
                  </div>

                  <fieldset className="form-group position-relative">
                    <label>
                      <FormattedMessage id="step5.language" defaultMessage="Язык квиза" />
                    </label>
                    <select className="form-control" id="DefaultSelect" onChange={this.changeLanguage} value={language}>
                      <option value="ru">Русский</option>
                      <option value="en">English</option>
                      <option value="ua">Український</option>
                      <option value="be">Беларускі</option>
                      <option value="kz">Қазақ</option>
                      <option value="lt">Latvietis</option>
                      <option value="pl">Polskie</option>
                      <option value="es">Español</option>
                    </select> 
                  </fieldset>
                  <fieldset className="form-group position-relative">
                    <label>
                      <FormattedMessage id="step5.button_animation" defaultMessage="Анимация кнопок" />
                    </label>
                    <select className="form-control" id="AnimationButtonSelect" onChange={this.changeButtonAnimation} value={button_animation}>
                      <option value="none">Без анимации</option>
                      <option value="flare">Блик</option>
                      <option value="pulse">Пульс</option>
                      <option value="flare_pulse">Блик + Пульс</option>
                    </select>
                  </fieldset>
                  <fieldset className="form-group position-relative">
                    <label>
                      <FormattedMessage id="step5.slideAnimation" defaultMessage="Стиль анимации слайдов" />
                    </label>
                    <select className="form-control" id="DefaultSlideAnimationSelect" onChange={this.changeSlideAnimation} value={slide_animation}>
                      <option value="none">Без анимации</option>
                      <option value="fadeInUp">Снизу вверх</option>
                      <option value="fadeInDown ">Сверху вниз</option>
                      <option value="fadeInLeft">Слева направо</option>
                      <option value="fadeInRight">Справа налево</option>
                    </select>
                  </fieldset>
                  <div className="form-group">
                    <label htmlFor="custom-styles">
                      <FormattedMessage id="step5.customStylesTyitle" defaultMessage="Переопределение стилей по умолчанию" />
                    </label>
                    <Tooltip
                      header={Intl.getMessage('step5.tooltipCustomStylesTitle')}
                      body={Intl.getMessage('step5.tooltipCustomStylesBody')}
                      target="id62"
                    />
                    <textarea
                      id="styles"
                      rows="5"
                      className="form-control"
                      name="quiz_result_text"
                      placeholder=".background-quiz { ..."
                      data-toggle="tooltip"
                      data-trigger="hover"
                      data-placement="top"
                      value={customStyles}
                      onChange={this.changeCustomStyles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 right-side">
            <p className="custom-text-title type-2">Предпросмотр дизайна</p>
              <div className="design-content" style={{backgroundColor: major_color}}>
                  <div className="design-container">
                      <div className="design-title">
                          <p style={{background: text_color}}></p>
                          <span style={{background: text_color}}></span>
                          <p style={{background: text_color}}></p>
                      </div>
                      <div className="design-body">
                        <div className="line">
                          <div style={{background: elements_color}}></div>
                          <span style={{background: text_color}}></span>
                        </div>
                        <div className="line">
                          <div style={{background: elements_color}}></div>
                          <span style={{background: text_color}}></span>
                        </div>
                        <div className="line">
                          <div style={{background: elements_color}}></div>
                          <span style={{background: text_color}}></span>
                        </div>
                        <div className="line">
                          <div style={{background: elements_color}}></div>
                          <span style={{background: text_color}}></span>
                        </div>
                      </div>
                      <div className="design-footer">
                          <div className="footer-text">
                            <p style={{background: elements_color}}></p>
                            <span style={{background: text_color}}></span>
                          </div>
                          <div className="footer-buttons">
                            <div style={{backgroundColor: elements_color}}>
                              <span></span>
                            </div>
                            <div style={{backgroundColor: elements_color}}>
                              <span></span>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </fieldset>
    )
  }
}

export default Step5
