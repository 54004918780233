/* eslint-disable global-require */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Intl } from '../../utils'
import { Switch } from '../index'
import './style.scss'

const Step1 = ({
  quizSysName,
  quizTitle,
  quizDescription,
  buttonText,
  handleChange,
  backgroundImage,
  sideImage,
  elementsColor,
  majorColor,
  textColor,
  toggleCheckBox,
  quizTypewriterForTitle,
  showContactInfo,
  companyName,
  descriptionActivity,
  companyPhone,
  openingHours,
  phonePrefix,
  placeAtBottom,
}) => (
  <fieldset>
    <div className="row quiz-step-1">
      <div className="col-md-6">
        <div className="card ">
          <div className="card-content collapse show">
            <div className="card-body" style={{ paddingLeft: '0' }}>
              <div className="form-group">
                <label htmlFor="firstName1">
                  <FormattedMessage id="step1.QuizSysName" defaultMessage="Название квиза" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName1"
                  value={quizSysName}
                  placeholder={Intl.getMessage('step1.QuizSysNamePlaceholder')}
                  name="quizSysName"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="firstName2">
                  <FormattedMessage id="step1.QuizTitle" defaultMessage="Заголовок квиза" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName2"
                  value={quizTitle}
                  name="quizTitle"
                  placeholder={Intl.getMessage('step1.QuizTitlePlaceholder')}
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <div onClick={toggleCheckBox('quizTypewriterForTitle', !quizTypewriterForTitle)}>
                  <Switch toggle={quizTypewriterForTitle} />
                  <label className="ml-1">
                    <FormattedMessage id="step1.quizTypewriterForTitle" defaultMessage="Эффект печатной машинки" />
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="issueinput8">
                  <FormattedMessage id="step1.QuizDesc" defaultMessage="Описание квиза" />
                </label>
                <textarea
                  id="issueinput8"
                  rows="5"
                  className="form-control"
                  name="quizDescription"
                  data-toggle="tooltip"
                  data-trigger="hover"
                  data-placement="top"
                  data-title="Comments"
                  data-original-title=""
                  title=""
                  placeholder={Intl.getMessage('step1.QuizDescPlaceholder')}
                  value={quizDescription}
                  onChange={handleChange}
                >
                  {quizDescription}
                </textarea>
              </div>
              <div className="form-group">
                <label htmlFor="firstName3">
                  <FormattedMessage id="step1.Button" defaultMessage="Текст кнопки" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  required
                  id="firstName3"
                  value={buttonText}
                  name="buttonText"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <div onClick={toggleCheckBox('showContactInfo', !showContactInfo)}>
                  <Switch toggle={showContactInfo} />
                  <label className="ml-1">
                    <FormattedMessage id="step1.addContactInfo" defaultMessage="Добавить контактную информацию" />
                  </label>
                </div>
              </div>
              {showContactInfo && (
                <>
                  <div className="form-group">
                    <label htmlFor="companyName">
                      <FormattedMessage id="step1.companyName" defaultMessage="Название компании" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="companyName"
                      value={companyName}
                      name="companyName"
                      onChange={handleChange}
                      placeholder="Кухонный двор"
                      maxLength="30"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="descriptionActivity">
                      <FormattedMessage id="step1.descriptionActivity" defaultMessage="Описание деятельности" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="descriptionActivity"
                      value={descriptionActivity}
                      name="descriptionActivity"
                      onChange={handleChange}
                      placeholder="Кухни от российского производителя с эксклюзивной отделкой в Москве"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="companyPhone">
                      <FormattedMessage id="step1.companyPhone" defaultMessage="Телефон" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="companyPhone"
                      value={companyPhone}
                      name="companyPhone"
                      onChange={handleChange}
                      placeholder="+7 (495) 500-04-04"
                      maxLength="30"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="openingHours">
                      <FormattedMessage id="step1.openingHours" defaultMessage="Время работы" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="openingHours"
                      value={openingHours}
                      name="openingHours"
                      onChange={handleChange}
                      placeholder="Время работы: 9.00 - 18.00"
                    />
                  </div>
                  <div className="form-group">
                    <div onClick={toggleCheckBox('placeAtBottom', !placeAtBottom)}>
                      <Switch toggle={placeAtBottom} />
                      <label className="ml-1">
                        <FormattedMessage id="step1.placeAtBottom" defaultMessage="Расположить внизу" />
                      </label>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        {(backgroundImage) && (
          <div className="card border-0 box-shadow-0 text-center">
            <div
              className="card-content"
              style={{ backgroundImage: `url("${backgroundImage ? backgroundImage.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net") : require('./images/444.jpg')}")` }}
            >
              <div className="card-img-overlay" />
              <div style={{ paddingTop: '15%' }} className="card-content-info">
                <div className="subdivtext">
                  <h1
                    className=""
                    style={{ color: textColor }}
                    dangerouslySetInnerHTML={{ __html: quizTitle || 'Введите заголовок квиза' }}
                  />
                  <p
                    className="card-text"
                    style={{ color: textColor }}
                    dangerouslySetInnerHTML={{ __html: quizDescription }}
                  />
                  <button
                    type="button"
                    className="btn btn-lg"
                    style={{ border: 'none', color: textColor, backgroundColor: elementsColor }}
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {(sideImage) && (
          <div className="card text-center">
            <div className="card-content" style={{ background: majorColor, margin: '10px' }}>
              <div className="card-body pt-3">
                <img
                  src={`${(sideImage) ? sideImage : require('./images/01.png')}`}
                  width="190"
                  className="float-left"
                  alt="side_image"
                />
                {/* <div className="card-img-overlay"></div> */}
                <h4 className="card-title mt-3" style={{ color: textColor }}>{quizTitle}</h4>
                <p className="card-text" style={{ color: textColor }}>{quizDescription}</p>
                <button
                  type="button"
                  className="btn btn-lg"
                  style={{ border: 'none', color: textColor, backgroundColor: elementsColor }}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </div>
        )}
        {((!sideImage && !backgroundImage) && (
          <div className="card text-center">
            <div className="card-content" style={{ background: majorColor, margin: '10px', minHeight: '300px' }}>
              <div className="card-body">
                <div className="card-content-info">
                  <div className="subdivtext">
                    <h1
                      className=""
                      style={{ color: textColor }}
                      dangerouslySetInnerHTML={{ __html: quizTitle || 'Введите заголовок квиза' }}
                    />
                    <p
                      className="card-text"
                      style={{ color: textColor }}
                      dangerouslySetInnerHTML={{ __html: quizDescription }}
                    />
                    <button
                      type="button"
                      className="btn btn-lg"
                      style={{ border: 'none', color: textColor, backgroundColor: elementsColor }}
                    >
                      {buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </fieldset>
)

export default Step1
