import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Switch } from '../index';
import { ChromePicker } from 'react-color'
import config from '../../config/config';
import { Tooltip } from '../../components'
import { Intl } from '../../utils'

class QuestAction extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isOpenSelectBody: false,
            displayPickerBackgroundColor: false,
            displayPickerTextColor: false,
            displayPickerButtonColor: false,
            dropdownOpen: false
        }
    }


    handleChangeSelect = (value, name) => {
        const {newQuestion, refreshQuestions} = this.props;
        newQuestion[name] = value;
        
        refreshQuestions(newQuestion);
        this.setState({isOpenSelectBody: false })
    }

    openPickerBackgroundColor = () => {
      const { displayPickerBackgroundColor } = this.state
      this.setState({
        displayPickerBackgroundColor: !displayPickerBackgroundColor ,
        displayPickerTextColor: false,
        displayPickerButtonColor: false,
      })
    }
  
    chooseBackgroundColor = () => {
      this.setState({ displayPickerBackgroundColor: false })
    }
  
    openPickerTextColor = () => {
      const { displayPickerTextColor } = this.state
      this.setState({
        displayPickerTextColor: !displayPickerTextColor ,
        displayPickerBackgroundColor: false ,
        displayPickerButtonColor: false,
      })
    }
  
    chooseTextColor = () => {
      this.setState({ displayPickerTextColor: false })
    }
  
    openPickerButtonColor = () => {
      const { displayPickerButtonColor } = this.state
      this.setState({
        displayPickerButtonColor: !displayPickerButtonColor ,
        displayPickerTextColor: false,
        displayPickerBackgroundColor: false,
      })
    }

    handleClickDropDown = () => {
      const {isOpenSelectBody} = this.state;
      const {newQuestion, activeType} = this.props;
      const type = newQuestion.question_kind;

      if(activeType!== 'questAction'){
        if(type==='motivator' || type==='option' || type==='option_image'){
          this.setState({isOpenSelectBody: !isOpenSelectBody})
        }
      }else{
        this.setState({isOpenSelectBody: !isOpenSelectBody})
      }
    }
  
    chooseButtonColor = () => {
      this.setState({ displayPickerButtonColor: false })
    }


    componentDidMount() {  
      document.addEventListener('click', (e) => {
        const target = e.target;
  
        if((this.state.displayPickerTextColor || this.state.displayPickerButtonColor || this.state.displayPickerBackgroundColor) && !target.closest('.sketch-picker') && target.className !=='color' && target.className !=='chrome-picker' && !target.closest('.chrome-picker')){
          this.setState({displayPickerTextColor: false, displayPickerButtonColor: false, displayPickerBackgroundColor: false})
        }
      })
    }
    
    render(){
        const {
            handleSubmit,
            newQuestion,
            isLoad,
            activeType,
            handleCheck,
            handleCancel,
            getImage,
            handleChangeNewQuestion,
            deleteImage,
            changePickerButtonColor,
            changePickerTextColor,
            changePickerBackgroundColor,
            handleSwitchAutoTime,
            onChangeAutoTime,
            handleChangeNewQuestionMinMax,
            uploadedImageType,
        } = this.props;

        const {
          isOpenSelectBody,
          displayPickerBackgroundColor,
          displayPickerTextColor,
          displayPickerButtonColor,
          dropdownOpen
        } = this.state;

        const selectedSelectIndex = selectData.findIndex(elem=>elem.key === newQuestion.question_kind);
        if(newQuestion.question_kind === 'numeric'){
          this.min_value = newQuestion.answers && newQuestion.answers[0].min_value || 0;
          this.max_value = newQuestion.answers && newQuestion.answers[0].max_value || 0;
        }
        const isMotivator = newQuestion.question_kind === 'motivator'

        return(
            <div className="side-body">
                <form action="" onSubmit={handleSubmit}>
                  <div className="modal-content">
                    <div className="modal-body">
                        <fieldset className="form-group position-relative">
                          <label>Тип вопроса</label>
                          <div className='custom-select'>
                            <div className="select-header" onClick={this.handleClickDropDown}>
                              <i class="fas fa-angle-down"></i>
                              <div>
                                  {selectData[selectedSelectIndex].icon}
                                  <div>
                                    <p>{selectData[selectedSelectIndex].title}</p>
                                    <span>{selectData[selectedSelectIndex].description}</span>
                                  </div>
                              </div>
                            </div>
                            {isOpenSelectBody && 
                              <div className="select-body">
                                {selectData.map(elem=>{
                                  if(activeType!== 'questAction'){
                                    return (elem.key==='motivator' || elem.key==='option' || elem.key==='option_image') ? 
                                    (
                                      <div className="select-item" onClick={()=>this.handleChangeSelect(elem.key, 'question_kind')}>
                                        {elem.icon}
                                        <div>
                                          <p>{elem.title}</p>
                                          <span>{elem.description}</span>
                                        </div>
                                      </div>
                                    )
                                    : null
                                  }else{
                                    return (
                                      <div className="select-item" onClick={()=>this.handleChangeSelect(elem.key, 'question_kind')}>
                                        {elem.icon}
                                        <div>
                                          <p>{elem.title}</p>
                                          <span>{elem.description}</span>
                                        </div>
                                      </div>
                                    )
                                  }
                                })}
                              </div>
                            }
                          </div>
                        </fieldset>
                        <fieldset className="form-group floating-label-form-group">
                          <label htmlFor="min">
                            <FormattedMessage id="step3.textQuestion" defaultMessage="Текст вопроса" />
                          </label>
                          <input required type="text" name="question_title" className="form-control" id="min" placeholder={Intl.getMessage('step3.newQuestionTitlePlaceholder')} value={newQuestion.question_title || ''} onChange={handleChangeNewQuestion}/>
                        </fieldset>



                        
                        {newQuestion.question_kind === 'numeric' && 
                         <div className="min-max-side" style={{marginBottom: '20px'}}>
                            <div>
                              <fieldset className="form-group floating-label-form-group">
                                <label htmlFor="min">
                                  <FormattedMessage id="step3.min_valueLabel" defaultMessage="Минимальное значение (0 - любое)" />
                                </label>
                                <input type="number" className="form-control" name="min_value" id="min" placeholder={(this.min_value) ? this.min_value : ''} value={this.min_value ? this.min_value : ''} onChange={handleChangeNewQuestionMinMax} />
                              </fieldset>
                              <fieldset className="form-group floating-label-form-group">
                                <label htmlFor="max">
                                  <FormattedMessage id="step3.max_valueLabel" defaultMessage="Максимальное значение (0 - любое)" />
                                </label>
                                <input type="number" className="form-control" name="max_value" id="max" placeholder={(this.max_value) ? this.max_value : ''} value={this.max_value ? this.max_value : '' } onChange={handleChangeNewQuestionMinMax} />
                              </fieldset>
                            </div>
                          <fieldset className="skin skin-square" onClick={() => handleCheck('ui_input')} style={{ cursor: 'pointer' }}>
                            <Switch toggle={newQuestion.answers[0].ui_input}/>
                            <FormattedMessage id="step3.allowUIinput" defaultMessage="Возможность графического ввода" />
                            <Tooltip
                              header={Intl.getMessage('step3.tooltipAllowUIInputTitle')}
                              body={Intl.getMessage('step3.tooltipAllowUIInput')}
                              target="id1321"
                            />
                          </fieldset>
                         </div>
                        }


                        <fieldset className="form-group floating-label-form-group">
                            <label htmlFor="qhelper">
                              <span>ТЕКСТ ПОДСКАЗКИ</span>
                            </label>
                            <textarea className="form-control" name="question_description" placeholder={Intl.getMessage('step3.newQuestionDescPlaceHolder')} value={!!newQuestion.question_description ? newQuestion.question_description : ''} onChange={handleChangeNewQuestion} id="qhelper" rows="3"></textarea>
                        </fieldset>

                        {(newQuestion.question_kind === 'numeric') && (
                          <fieldset className="form-group floating-label-form-group">
                            <FormattedMessage id="step3.unitsTitle" defaultMessage="Единицы измерения" />
                            <input type="text" name="answer_units" className="form-control" id="min" placeholder="" value={newQuestion.answers[0].answer_units || ''} onChange={handleChangeNewQuestionMinMax} />
                          </fieldset>
                        )}
                        {(newQuestion.question_kind === 'numeric' || newQuestion.question_kind === 'text' || newQuestion.question_kind === 'file') && (
                          <fieldset className="skin skin-square" onClick={() => handleCheck('required')} style={{ cursor: 'pointer' }} title={Intl.getMessage('step3.requiredTitle')}>
                          <br />
                          <Switch toggle={newQuestion.answers[0].required}/>
                          <FormattedMessage id="step3.required" defaultMessage="Обязательное" />
                        </fieldset>
                        )}
                        {(newQuestion.question_kind === 'option' || newQuestion.question_kind === 'option_image' || isMotivator) && (
                          <fieldset className="skin skin-square" onClick={() => handleCheck('allow_multiple')} style={{ cursor: 'pointer' }}>
                            <Switch toggle={newQuestion.allow_multiple}/>
                            <FormattedMessage id="step3.allowMultiply" defaultMessage="Возможность выбора нескольких вариантов" />
                          </fieldset>
                        )}
                        {newQuestion.question_kind === 'option' && (
                          <fieldset className="skin skin-square" onClick={() => handleCheck('question_options_list')} style={{ cursor: 'pointer' }}>
                            <Switch toggle={newQuestion.question_options_list}/>
                            <FormattedMessage id="step3.allowDropdown" defaultMessage="Выпадающий список" />
                          </fieldset>
                        )}
                        {(newQuestion.question_kind === 'text') && (
                          <fieldset className="skin skin-square" onClick={() => handleCheck('textarea')} style={{ cursor: 'pointer' }}>
                            <Switch toggle={newQuestion.textarea}/>
                            <span>{Intl.getMessage('step3.textArea')}</span>
                          </fieldset>
                        )}
                      <div>
                        <div className="image-media">
                          <div className="image-media-title">
                            <span>{isMotivator ? 'Фоновая картинка вопроса' : 'Картинка вопроса'}</span>
                            <Tooltip
                              header={Intl.getMessage(isMotivator ? 'step2.tooltipBackgroundImageTitle' : 'step2.tooltipImageTitle')}
                              body={Intl.getMessage(isMotivator ? 'step2.tooltipBackgroundImage' : 'step2.tooltipImage')}
                              target="id10232"
                            />
                          </div>
                          <div className="image-media-body">
                            {isLoad && uploadedImageType === 'background' && (
                              <img src={require('./load.gif')} />
                            )}
                            {newQuestion.question_background_image && (
                              <div className="media">
                                <span className="media-left">
                                  <img
                                    className="media-object"
                                    src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${newQuestion.question_background_image}`}
                                    alt="" 
                                  />
                                  <a className="btn-icon" onClick={deleteImage('background')}>
                                    <i class="fas fa-times"></i>
                                  </a>
                                </span>
                              </div>
                            )}
                            <label htmlFor="getImage" className="btn btn-create img-upload">
                              <FormattedMessage id="step3.clickToSetImage" defaultMessage="Нажмите, чтобы выбрать изображение" />
                            </label>
                            <input id="getImage" name="background" type="file" onChange={getImage} value={(!newQuestion.question_image) ? '' : ''} style={{ display: 'none' }} />
                          </div>
                        </div>
                        {(newQuestion.question_kind === 'option' || newQuestion.question_kind === 'option_image' || isMotivator) && (
                          <>
                            <fieldset className="form-group floating-label-form-group">
                              <label htmlFor="correct-text">
                                <FormattedMessage id="step3.correctTextQuestion" defaultMessage="Текст в случае правильного ответа" />
                              </label>
                              <input type="text" name="correct_text" className="form-control" id="correct-text" placeholder="Правильный ответ" value={newQuestion.correct_text || ''} onChange={handleChangeNewQuestion} />
                            </fieldset>
                            <fieldset className="form-group floating-label-form-group">
                              <label htmlFor="incorrect-text">
                                <FormattedMessage id="step3.inCorrectTextQuestion" defaultMessage="Текст в случае неправильного ответа" />
                              </label>
                              <input type="text" name="incorrect_text" className="form-control" id="incorrect-text" placeholder="Неправильный ответ" value={newQuestion.incorrect_text || ''} onChange={handleChangeNewQuestion} />
                            </fieldset>
                          </>
                        )}
                      </div>
                      {(newQuestion.question_kind === 'option_image' && (
                      <div>
                        <fieldset className="drop-down-side">
                          <label>
                            <FormattedMessage id="step3.choseImagePlacement" defaultMessage="Выберите расположение"/>
                          </label>
                          <select className="form-control" id="DefaultSelect" onChange={(e)=>handleCheck(e.target.value)} value={newQuestion.image_placement}>
                            <option value='option-image'>КАРТИНКА-ВАРИАНТ</option>
                            <option value='right-image'>КАРТИНКА СПРАВА</option>
                          </select>
                        </fieldset>
                      </div>
                    ))}
                      {(isMotivator) && (
                        <div>
                          <div className="image-media">
                            <div className="image-media-title">
                                <span>Картинка в вопросе</span>
                                <Tooltip
                                  header={Intl.getMessage('step2.tooltipSideImageTitle')}
                                  body={Intl.getMessage('step2.tooltipSideImage')}
                                  target="id10243"
                                />
                            </div>
                            <div className="image-media-body">
                              {isLoad && uploadedImageType === 'side' &&  (
                                <img src={require('./load.gif')} />
                              )}
                              {newQuestion.question_side_image && (
                                <div className="media">
                                  <span className="media-left">
                                    <img
                                      className="media-object"
                                      src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${newQuestion.question_side_image}`}
                                      alt="" 
                                    />
                                    <a className="btn-icon" onClick={deleteImage('side')}>
                                      <i class="fas fa-times"></i>
                                    </a>
                                  </span>
                                </div>
                              )}
                              <label htmlFor="side_image" className="btn btn-create img-upload">
                                <FormattedMessage id="step3.clickToSetImage" defaultMessage="Нажмите, чтобы загрузить" />
                              </label>
                              <input id="side_image" type="file" name="side" onChange={getImage} style={{ display: 'none'}} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="form-group" id="spectrum">
                                <label>
                                  {'Цвет кнопки'}
                                </label>
                                <br />
                                <div className="swatch">
                                    <div className="color" style={{ background: newQuestion.button_color}} onClick={this.openPickerButtonColor} />
                                </div>
                                {displayPickerButtonColor && (
                                  <div className="popoverColorPicker">
                                    <ChromePicker color={newQuestion.button_color} onChange={changePickerButtonColor} disableAlpha={true} />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group" id="spectrum">
                                <label>
                                  {'Цвет текста'}
                                </label>
                                <br />
                                <div className="swatch">
                                  <div className="color" style={{ background: newQuestion.text_color}} onClick={this.openPickerTextColor} />
                                </div>
                                {displayPickerTextColor && (
                                  <div className="popoverColorPicker">
                                    <ChromePicker color={newQuestion.text_color} onChange={changePickerTextColor} disableAlpha={true} />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-4">
                                {!newQuestion.question_background_image && (<div className="form-group" id="spectrum">
                                <label>
                                  {'Цвет фона'}
                                </label>
                                <br />
                                  <div className="swatch">
                                    <div className="color" style={{ background: newQuestion.background_color}} onClick={this.openPickerBackgroundColor} />
                                  </div>
                                  {displayPickerBackgroundColor && (
                                    <div className="popoverColorPicker">
                                      <ChromePicker color={newQuestion.background_color} onChange={changePickerBackgroundColor} disableAlpha={true} />
                                    </div>
                                  )}
                              </div>)}
                            </div>
                          </div>
                          <div className="form-group" id="spectrum">
                            <div onClick={handleSwitchAutoTime}>
                              <Switch toggle={newQuestion.auto_time} />
                              <label className="ml-1">
                                {'Автоматический переход'}
                              </label>
                            </div>
                          </div>
                          {newQuestion.auto_time && (<fieldset className="form-group floating-label-form-group">
                            <label htmlFor="atitle">
                              {'Время автоперехода'}
                            </label>
                            <input required name="auto_time" type="number" min="0" max="20" className="form-control" id="atitle" value={newQuestion.auto_time} onChange={onChangeAutoTime} />
                          </fieldset>)}
                        </div>
                      )}
                      </div>
                  </div>
                  {activeType === 'questAction' && <div className="modal-footer">
                    <input type="reset" onClick={handleCancel} className="btn btn-create reset" data-dismiss="modal" value={Intl.getMessage('step3.cancel')} />
                    <input type="submit" className="btn btn-create submit" value={Intl.getMessage('step3.add')} />
                  </div>}
                </form>
            </div>
        )
    }
}

export default QuestAction;


class ClearAnswer extends React.Component {
    render() {
      const {
        title, desc, image, kind,
      } = this.props
  
      return (
        <div>
        {(kind === 'option' || kind === 'option_image') && (
        <div className="card box-shadow-0 border-blue-grey border-accent-1 bg-blue-grey bg-accent-1">
            <div className="card-header">
              <h4 className="card-title"> {title} </h4>
              {desc && (<p style={{ color: 'black' }}>{desc}</p>)}
            </div>
          {image && (
            <div className="card-body">
              {image && (<img src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${image.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net")}`} className="media-left round-image" alt="" />)}
            </div>
          )}
        </div>
        )}
        </div>
      )
    }
  }

const imageSize = '200,fit'

const selectData = [
    {
      key: 'option',
      title: Intl.getMessage('step3.typeOption'),
      description: 'Выбор из нескольких текстовых вариантов',
      icon: <i class="fas fa-list"></i>
    },
    {
      key: 'option_image',
      title: Intl.getMessage('step3.typeOptionImage'),
      description: 'Выбор из нескольких вариантов с картинками',
      icon: <i class="fas fa-images"></i>
    },
    {
      key: 'motivator',
      title: 'Мотиватор',
      description: 'Варианты с картинками или без них. Свежее оформление квизов.',
      icon: <i class="fas fa-bullhorn"></i>
    },
    {
      key: 'option_yesNo',
      title: Intl.getMessage('step3.yesNo'),
      description: 'Когда нужно получить утвердительный или отрицательный ответ',
      icon: <i class="fas fa-question-circle"></i>
    },
    {
      key: 'numeric',
      title: Intl.getMessage('step3.typeNumeric'),
      description: 'Позволяет запросить числовое значение',
      icon: <i class="fas fa-tachometer-alt"></i>
    },
    {
      key: 'text',
      title: Intl.getMessage('step3.typeText'),
      description: 'Произвольный ввод текста (однострочный или многострочный)',
      icon: <i class="fas fa-terminal"></i>
    },
    {
      key: 'file',
      title: Intl.getMessage('step3.typeFile'),
      description: 'Позволяет посетителю загрузить свой файл',
      icon: <i class="fas fa-cloud-upload-alt"></i>
    }
  ]
  