import React from 'react'
import FixedCard from '../FixedCard'
import ComputedCard from '../ComputedCard'

const Cards = ({ show_results, previewTitle, imageSizeUrl, imageSize,
                 previewImage, previewDesc, isVisible, toggle, questions, addFormula, type, clearResultData, setResultType }) => {
  const fixedCard = (<FixedCard
    show_results={show_results}
    previewTitle={previewTitle}
    imageSizeUrl={imageSizeUrl}
    imageSize={imageSize}
    previewImage={previewImage}
    previewDesc={previewDesc}
    isVisible={isVisible}
    toggle={toggle}
    clearResultData={clearResultData}
    setResultType={setResultType}
    resultType={type}
  />)

  const computedCard = (<ComputedCard
    questions={questions}
    addFormula={addFormula}
  />)

  if (type === 'fixed') {
    return fixedCard
  } else if (type === 'computed' && show_results) {
    return computedCard
  } else if (type === 'mixed') {
    return (
      <React.Fragment>
        {/* {fixedCard} */}
        {computedCard}
      </React.Fragment>
    )
  }

  return null
}

export default Cards
