import { AuthService } from './index'

const config = require('../config/config')

class QuizzTemplatesService {
  static async createQuizzTemplate(data) {
    try {
      const {
        saveAsTemplateTheme: theme_sys_name, locale, saveAsTemplateIsPublic: is_public, json_data
      } = data

      const request = {
        theme_sys_name, locale, is_public, json_data,
      }
      const body = {
        service: 'go.micro.srv.quiz',
        method: 'Quiz.SaveQuizTemplate',
        request,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) return false

      return true
    } catch (error) {
      throw error
    }
  }
  
  static async getListQuizzTemplates(data) {
    try {
      const { only_mine } = data
      let theme_sys_name = ''
      if (data.templateTheme !== 'all') theme_sys_name = data.templateTheme
      
      const body = {
        service: 'go.micro.srv.quiz',
        method: 'Quiz.GetQuizTemplateList',
        request: {
          theme_sys_name,
          only_mine,
        },
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status !== 200) return false
      response = await response.json()
      
      return response
    } catch (error) {
      throw error
    }
  }
  
  static async deleteQuizzTemplate(id) {
    try {
      const body = {
        service: 'go.micro.srv.quiz',
        method: 'Quiz.DeleteQuizTemplate',
        request: {
          id,
        },
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status !== 200) return false
      
      return true
    } catch (error) {
      throw error
    }
  }
  
  static async makeQuizzFromTemplate(id) {
    try {
      const body = {
        service: 'go.micro.srv.quiz',
        method: 'Quiz.MakeQuizFromTemplate',
        request: {
          id,
        },
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })
      
      response = await response
      if (response.status !== 200) return false
      response = await response.json()
      
      return response
    } catch (error) {
      throw error
    }
  }
}

export default QuizzTemplatesService
