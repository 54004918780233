import React from 'react'
import { withRouter } from 'react-router-dom'

class PublicContainer extends React.Component {
  componentDidMount() {
    document.body.classList
      .add('horizontal-layout', 'horizontal-menu', '1-column', 'menu-expanded', 'horizontal-menu-padding', 'blank-page')
    document.body.classList
      .remove('vertical-layout', 'vertical-content-menu', '2-columns', 'menu-expanded', 'fixed-navbar')
  }

  render() {
    const { children } = this.props

    return (
      <div>
        <div className="app-content container center-layout mt-2" id="public_container">
          <div className="content-wrapper">
            <div className="content-body">{children}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(PublicContainer)
