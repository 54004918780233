/* eslint-disable class-methods-use-this */
import React from 'react'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import { ContentHeader } from '../../components'
import { Intl } from '../../utils'
import QuizzService from '../../services/QuizzService'
import { PackagesService } from '../../services'
import { importQuizAction } from '../../actions/quizPageActions'

class ContentHeaderContainer extends React.Component {
  constructor(props) {
    super(props)
    this.openAddQuiz = this.openAddQuiz.bind(this)
    this.openLeads = this.openLeads.bind(this)
    this.importQuizJSON = this.importQuizJSON.bind(this)
    this.state = {
      isOpenAddQuiz: false,
      isOpenLeads: false,
      leadState: '',
      quizess: [],
      packages: [],
    }
  }

  getLeadStateTitle(leadState) {
    switch (leadState) {
      case undefined:
        leadState = Intl.getMessage('contentHeader.allButArchived')
        break
      case 'new':
        leadState = Intl.getMessage('contentHeader.onlyNew')
        break
      case 'seen':
        leadState = Intl.getMessage('contentHeader.onlyViewed')
        break
      case 'done':
        leadState = Intl.getMessage('contentHeader.onlyProcessed')
        break
      case 'unfinished':
        leadState = Intl.getMessage('contentHeader.onlyIncomplete')
        break
      case 'archived':
        leadState = Intl.getMessage('contentHeader.onlyArchival')
        break
      default:
        leadState = Intl.getMessage('contentHeader.allButArchived')
        break
    }
    return leadState
  }

  async componentDidMount() {
    const leadState = this.getLeadStateTitle(this.props.params.filter)
    const quizzes = await QuizzService.getListQuizzes({page: 1, limit: 20})
    const { packages } = await PackagesService.getPackagesList()

    this.setState({ leadState, quizzes, packages })
  }

  componentWillReceiveProps(nextProps) {
    let leadState = nextProps.params.filter
    leadState = this.getLeadStateTitle(leadState)

    this.setState({ leadState })
  }

  openAddQuiz() {
    this.setState({
      isOpenAddQuiz: !this.state.isOpenAddQuiz,
    })
  }

  openLeads() {
    this.setState({
      isOpenLeads: !this.state.isOpenLeads,
    })
  }

  isImportNotAvailable() {
    const { packages } = this.state

    if (!packages || !packages.length) {
      return false
    }

    const purchasedPackages = packages.find(p => p.purchase_price > 0)

    return !purchasedPackages
  }

  importQuizJSON(event) {
    const { importQuiz } = this.props
    const reader = new FileReader()
    reader.readAsText(event.target.files[0], 'application/json')

    reader.onload = () => {
      if (reader.result.indexOf('quiz_title') !== -1 && reader.result.indexOf('quiz_sys_name') !== -1) {
        QuizzService.createQuizz({
          json: JSON.parse(reader.result).jsonData,
          notifications: JSON.parse(reader.result).notifications,
        }).then((response) => {
          importQuiz(true)
        })
      } else {
        // eslint-disable-next-line no-alert
        alert('Неверный формат файла')
      }
    }
  }

  async handleOpenAlertModal() {
    try {
      await swal({
        title: Intl.getMessage('contentHeaderContainer.alertAreUSure'),
        icon: 'warning',
        buttons: {
          confirm: {
            text: Intl.getMessage('contentHeaderContainer.alertYSure'),
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  render() {
    const {
      isOpenAddQuiz,
      isOpenLeads,
      leadState,
      quizzes,
    } = this.state
    const { pathname } = window.location

    return (
      <ContentHeader
        isOpenAddQuiz={isOpenAddQuiz}
        isOpenLeads={isOpenLeads}
        openLeads={() => this.openLeads()}
        openAddQuiz={() => this.openAddQuiz()}
        importQuizJSON={this.importQuizJSON}
        pathname={pathname}
        leadState={leadState}
        quizzesLength={quizzes && quizzes.length}
        importIsNotAvailable={this.isImportNotAvailable()}
        onOpenAlertModal={this.handleOpenAlertModal}
      />
    )
  }
}

const mapDispatchToProps = {
  importQuiz: importQuizAction,
}

export default connect(null, mapDispatchToProps)(ContentHeaderContainer)
