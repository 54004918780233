import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Tooltip } from '../../index'
import { Intl } from '../../../utils'

const ComputedResult = ({
  questions, results_formula, handleChangeFormula, setInputClick,
}) => {

  return (
  <div className="form-group result-formula" onClick={setInputClick}>
    <label htmlFor="issueinput12">
      <i class="fas fa-calculator"></i>
      <FormattedMessage id="step2.calcFormula" defaultMessage="Формула расчета результата"/>
      {/* <Tooltip
        header={Intl.getMessage('step2.tooltipFormulaTitle')}
        body={Intl.getMessage('step2.tooltipFormula')}
        target="id5"
      /> */}
    </label>
    {questions && questions.length > 0 && (
      <div className="textarea-field">
        {!results_formula && <span className="empty-data">Формула не указана <i class="fas fa-exclamation-triangle"></i></span>}
        <textarea
          id="results_formula"
          rows={results_formula ? 3 : 2}
          className="form-control"
          name="results_formula"
          data-toggle="tooltip"
          data-trigger="hover"
          data-placement="top"
          data-title={Intl.getMessage('step2.useVarsRight')}
          data-original-title=""
          title=""
          value={results_formula}
          onChange={handleChangeFormula}
        >
          {results_formula}
        </textarea>
      </div>
    )}
    {questions && questions.length === 0 && (
      <div>
        <br/>
        <FormattedMessage id="step2.caclFormulaError" defaultMessage="Формула расчета результата"/>
        <span>2</span>
      </div>
    )}
  </div>)
}

export default ComputedResult
