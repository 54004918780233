import React, {Component} from 'react'
import { QuizzService } from "../../services";
import Switch from "../Switch/Switch";
import './style.scss'
import {Intl} from "../../utils";
import {Loader} from "../index";

export class ChatModeModalContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quizData: null,
      chatMode: false,
      pauseWaiting: true,
      showResultOffline: false
    }
  }
  componentDidMount() {
    const { uniqueHash } = this.props
    QuizzService.getQuizz(uniqueHash).then(response => {
      const pauseWaitingSettings = JSON.parse(response.chatbot_settings).enable_pause
      this.setState({
        quizData: response,
        chatMode: JSON.parse(response.chatbot_settings).chat_mode,
        pauseWaiting: pauseWaitingSettings === undefined ? true : pauseWaitingSettings,
        showResultOffline: JSON.parse(response.chatbot_settings).results_if_offline,
      })
    })
  }

  render() {
    const { uniqueHash, userId } = this.props
    const { quizData, chatMode, pauseWaiting, showResultOffline } = this.state

    const changeSettings = (typeSettings) => {
      let settingsChat = {
        chat_mode: chatMode,
        enable_pause: pauseWaiting,
        results_if_offline: showResultOffline
      }

      switch (typeSettings) {
        case 'chatMode': {
          settingsChat.chat_mode = !chatMode
          this.setState({ chatMode: !chatMode })
          break
        }
        case 'pauseWaiting': {
          settingsChat.enable_pause = !pauseWaiting
          this.setState({ pauseWaiting: !pauseWaiting })
          break
        }
        case 'showResultOffline': {
          settingsChat.results_if_offline = !showResultOffline
          this.setState({ showResultOffline: !showResultOffline })
          break
        }
        default: {
          return
        }
      }

      QuizzService.changeTelegramSettings({
        hash: uniqueHash,
        chatbot_settings: JSON.stringify(settingsChat),
      }).then(response => response);
    }
    return (
      <div className="modal-content-container">
        {quizData ? (
          <>
            <div className="modal-content__enable-mode">
              <div className="modal-content__telegram-consultant" onClick={() => changeSettings('chatMode')}>
                <Switch toggle={this.state.chatMode}/>
                <h3 className="modal-content__telegram-consultant-title">{Intl.getMessage('indexPage.enableModeTitle')}</h3>
              </div>
              <p>{Intl.getMessage('indexPage.enableModeDescriptionFirst')}</p>
              <ul>
                <li><a href="https://t.me/enquizchatbot" target="_blank">enquizchatbot</a></li>
                <li><a href="https://t.me/enquiz2chatbot" target="_blank">enquiz2chatbot</a></li>
                <li><a href="https://t.me/enquiz3chatbot" target="_blank">enquiz3chatbot</a></li>
                <li><a href="https://t.me/enquiz4chatbot" target="_blank">enquiz4chatbot</a></li>
              </ul>
              <p>Наберите в поиске название и войдите в чат. Далее отправьте текст: <span>switch-{uniqueHash}-{Array.from(userId).splice(-7).join('')}</span> для подключения бота. Для отключения надо отправить этот же текст повторно.</p>
            </div>
            <div className="modal-content__pause-waiting-container">
              <div className="modal-content__pause-waiting" onClick={() => changeSettings('pauseWaiting')}>
                <Switch toggle={this.state.pauseWaiting}/>
                <h3 className="modal-content__pause-waiting-title">{Intl.getMessage('indexPage.enablePauseWaiting')}</h3>
              </div>
              <p>{Intl.getMessage('indexPage.pauseWaitingDescription')}</p>
            </div>
            <div className="modal-content__show-offline-container">
              <div className="modal-content__show-offline" onClick={() => changeSettings('showResultOffline')}>
                <Switch toggle={this.state.showResultOffline}/>
                <h3 className="modal-content__show-offline-title">{Intl.getMessage('indexPage.showResultsOfflineTitle')}</h3>
              </div>
              <p>{Intl.getMessage('indexPage.showResultsOfflineDescription')}</p>
            </div>
          </>
        ) : <Loader classes="loader-modal" />}
      </div>
    )
  }
}
