import React from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { FormattedDate } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { Button } from 'reactstrap'
import { PackagesService } from '../../services'
import { Intl } from '../../utils'
import rates from '../../config/rates'
import pro_rates from '../../config/pro_rates'
import { Switch } from '../../components/index'
import { Tooltip } from '../../components';
import {packagesData} from './packages';
import './PackagesPage.scss';

const config = require('../../config/config')

class Card extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      packet: this.props.packet,
      progress: 0,
      title: '',
      type: '',
      packageType: 'unlimit'
    }
  }
  
  componentDidMount() {
    let { packet: { leads_left, initial_leads, purchase_date, days_valid, purchase_price, packageType} } = this.state
    const locale = navigator.language.split(/[-_]/)[0]
    
    if (!leads_left) leads_left = 0
    const progress = leads_left === Infinity && initial_leads === Infinity ? Infinity : (leads_left / initial_leads) * 100;
    const date = new Date(purchase_date * 1000);
    const type = packageType || 'БАЗОВЫЙ';
    let title
    if (purchase_price > 0) {
      title = `
${Intl.getMessage('packagesPage.purchaseDate')}: ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}
${Intl.getMessage('packagesPage.daysValid')}: ${days_valid} ${Intl.getMessage('packagesPage.days')}
${Intl.getMessage('packagesPage.purchasePrice')}: ${purchase_price} ${(locale === 'ru') ? (Intl.getMessage('packagesPage.units')) : ''}`
    }
    if (purchase_price <= 0) {
      title = `
${Intl.getMessage('packagesPage.purchaseDate')}: ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}
${Intl.getMessage('packagesPage.daysValid')}: ${days_valid} ${Intl.getMessage('packagesPage.days')}`
    }

    this.setState({ progress, title,  type});
  }
  
  render() {
    const { packet, progress, title } = this.state;

    const isUnlimited = packet.leads_left === -1;
    const leadsLeft = progress === -1 ? 100 : packet.leads_left;
    const leadsInitial = progress === -1 ? 100 : packet.initial_leads;
    const packageType = packet.is_pro ? 'PRO' : isUnlimited ? 'БЕЗЛИМИТНЫЙ' : 'БАЗОВЫЙ';
    const progressClass = progress < 20 ? 'bd-danger' : isUnlimited ? 'infinity' : 'info';
    
    return (
      <div className="responsive-packages packages-cards" title={title} style={{ cursor: 'pointer'}}>
        <div className="card" style={{ marginBottom: '30px' }}>
          <div className="card-content">
            <div className="card-body">
              <div className="card-title">
                <div className="card-type">
                  <span>ТАРИФ: </span> <p>{packageType}</p>
                </div>
                {progress ? <div className="card-date">
                  <span>
                    {Intl.getMessage('packagesPage.activeUntil')} <FormattedDate value={new Date(packet.active_until * 1000)}/>
                  </span>
                </div> : null}
              </div>
              <div className="media d-flex">
                <div className="media-body text-left">
                  {
                  !isUnlimited ? 
                    <h3 className={progressClass}>
                      ОСТАЛОСЬ {(!leadsLeft) ? 0 : leadsLeft} {Intl.getMessage('packagesPage.from')} {leadsInitial} {Intl.getMessage('packagesPage.leads')}
                    </h3>
                  :
                  <h3 className={progressClass}>
                    Количество заявок не ограниченно
                  </h3>
                }
                </div>
              </div>
              <div className="progress mt-1 mb-0" style={{height: '7px'}}>
                <div
                  className={progressClass}
                  role="progressbar"
                  style={{width: `${progress === Infinity ? '100' : progress}%`}}
                  aria-valuenow="80"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {packet.initial_leads === -1 && (
          <div className="card bg-warning">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="media-body text-left text-white">
                  <h3 className="text-white">
                    {Intl.getMessage('packagesPage.unlimited')}
                  </h3>
                  <span>
                  {Intl.getMessage('packagesPage.activeUntil')} <FormattedDate value={new Date(packet.active_until * 1000)}/>
                </span>
                </div>
                <div className="align-self-center">
                  <i className={`icon-rocket text-white font-large-2 float-right`}></i>
                </div>
              </div>
              <div className="progress mt-1 mb-0" style={{height: '7px'}}>
              </div>
            </div>
          </div>
        </div>)} */}
      </div>
    )
  }
}

class PackagesPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      packages: [],
      table: rates,
      table_pro: pro_rates,
      sum: 1000,
      countLeads: 30,
      countDays: 0,
      countLeadsMarks: {
        10: '20',
        20: '50',
        30: '100',
        40: '250',
        50: '500',
        60: '1000',
        70: '2000',
        80: {
          label: 'Безлимитный',
        },
      },
      countLeadsMarksPro: {
        20: '50',
        30: '100',
        40: '250',
        50: '500',
        60: '1000',
        
      },
      countDaysMarks: {
        0: '30',
        30: '90',
        60: '182',
        100: '365',
      },
      leads: {
        10: 20,
        20: 50,
        30: 100,
        40: 250,
        50: 500,
        60: 1000,
        70: 2000,
        80: -1,
      },
      days: {
        0: 30,
        30: 90,
        60: 182,
        100: 365,
      },
      packageLeads: [20, 50, 100, 250, 500, 1000, 2000],
      packageDays: [
        {
          month: 1,
          price: 1700,
          discount: 0,
        },
        {
          month: 3,
          price: 3900,
          discount: 5,
        },
        {
          month: 6,
          price: 5900,
          discount: 45,
        },
        {
          month: 12,
          price: 9900,
          discount: 66,
        },
      ],
      billId: '',
      result: true,
      paymentUrl: ''
    }

    this.initialState = {...this.state};
    
    this.componentDidMount = this.componentDidMount.bind(this)
    this.handleChangeCountLeads = this.handleChangeCountLeads.bind(this)
    this.handleChangeCountLeadsPro = this.handleChangeCountLeadsPro.bind(this)
    this.handleChangeCountDays = this.handleChangeCountDays.bind(this)
    this.handleChangeCountDaysPro = this.handleChangeCountDaysPro.bind(this)
    this.showPromoCodeForm = this.showPromoCodeForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.loadPromoCode = this.loadPromoCode.bind(this)
    this.addPackage = this.addPackage.bind(this)
  }

  switchPackages() {
    const {
      switcher
    } = this.state
    
      this.setState({switcher: !switcher})
      if (switcher) {
        this.handleChangeCountLeads(30)
      }
      if (!switcher) {
        this.handleChangeCountLeads(20)
      }
  }
  
  async componentDidMount() {
    try {
      const { leads, countLeads, days, countDays} = this.state
      let { sum } = this.state
      let promoCode = new URLSearchParams(this.props.location.search).get('promo_code')
      const { packages } = await PackagesService.getPackagesList()
      var promoCodeObj;
      var discount;
      var promoActiveUntil;
      var promoIsValid;
      if (promoCode) {
        const promoCodeObj  = await PackagesService.getPromoCode(promoCode)
        promoIsValid = promoCodeObj.is_valid;
        if (promoIsValid) {
          discount = promoCodeObj.volume;
          promoActiveUntil = promoCodeObj.active_until;
          sum = sum - sum * discount / 100;
        }
        
      }
      // const { bill_id: billId } = await PackagesService.addNewPackage({ leads: leads[countLeads], days: days[countDays] })
      let { result } = this.props.match.params
      this.setState({ packages, result, promoCode, promoIsValid, discount, promoActiveUntil, sum });
      if (result === 'success'){
        const ymetrica = window.yaCounter49531330;
        ymetrica.reachGoal('PURCHASE');
      }
      
      
    } catch (error) {
      console.log(error)
    }
  }

  buyClickHandle = type => {
    // this.props.history.push(`packages/${type}`)
    this.setState({
      packageType: type,
    })
  }
  
  async addPackage() {
    try {
      
      const { countDays, countLeads, leads, days, promoCode, switcher } = this.state
      const { bill_id: billId, payment_url: paymentUrl } = await PackagesService.addNewPackage({ leads: leads[countLeads], days: days[countDays], promo_code: promoCode, is_pro: switcher })
      window.location.replace(paymentUrl)

      // this.setState({ billId, paymentUrl }, () => document.getElementById('addPackage').click())
      
    } catch(error) {
      console.log(error)
    }
  }
  
  async handleChangeCountLeads(data) {
    let { countLeads, countDays, table, sum, leads, days, discount} = this.state
    //const { bill_id: billId } = await PackagesService.addNewPackage({ leads: leads[countLeads], days: days[countDays] })
    countLeads = data
    
    sum = table[countDays][countLeads]
    if (discount) {
      sum = sum  - sum *  discount / 100;
    }
    if (countLeads == 80) {
      
      // this.setState()
    }

    console.log(data, leads[countLeads])

    this.setState({ sum, countLeads}, ()=>this.addPackage())
    
  }

  
  
  async handleChangeCountDays(data) {
    let { countLeads, countDays, table, sum, leads, days, discount } = this.state
    //const { bill_id: billId } = await PackagesService.addNewPackage({ leads: leads[countLeads], days: days[countDays] })
    countDays = data
    sum = table[countDays][countLeads]
    if (discount) {
      sum = sum  - sum *  discount / 100;
    }
    this.setState({ sum, countDays })
  }

  async handleChangeCountLeadsPro(data) {
    let { countLeads, countDays, table_pro, sum, leads, days, discount} = this.state
    //const { bill_id: billId } = await PackagesService.addNewPackage({ leads: leads[countLeads], days: days[countDays] })
    countLeads = data
    sum = table_pro[countDays][countLeads]
    if (discount) {
      sum = sum  - sum *  discount / 100;
    }
    this.setState({ sum, countLeads })
    
  }

  async handleChangeCountLeadsUnlimited(sum, month) {
    const countDays = month === 1 ? 0 : month === 3 ? 30 : month === 6 ? 60 : 100; 
    
    this.setState({ sum, countLeads: 80, countDays}, ()=>this.addPackage());
    
  }

  async handleChangeCountDaysPro(data) {
    let { countLeads, countDays, table_pro, sum, leads, days, discount } = this.state
    //const { bill_id: billId } = await PackagesService.addNewPackage({ leads: leads[countLeads], days: days[countDays] })
    countDays = data
    sum = table_pro[countDays][countLeads]
    if (discount) {
      sum = sum  - sum *  discount / 100;
    }
    this.setState({ sum, countDays })
  }

  async showPromoCodeForm() {
    let {promoCodeForm} = this.state
    promoCodeForm = true
    this.setState({ promoCodeForm })
  }

  async handleChange(event) {
    const { target: { value, name } } = event
    let { promoCode } = this.state
    promoCode = value
    this.setState({ promoCode })
  }

  async loadPromoCode() {
    let { promoIsValid, discount, promoActiveUntil, sum, promoCode, promoCodeForm } = this.state
    const promoCodeObj  = await PackagesService.getPromoCode(promoCode)
    promoIsValid = promoCodeObj.is_valid;
    if (promoIsValid) {
      discount = promoCodeObj.volume;
      promoActiveUntil = promoCodeObj.active_until;
      sum = sum - sum * discount / 100;
    }
    promoCodeForm = false
    this.setState({ promoCode, promoIsValid, discount, promoActiveUntil, sum, promoCodeForm })
  }
  
  render() {
    const { packages, packageDays, packageLeads, packageType, sum, countLeads, countDays, countLeadsMarks, countDaysMarks, leads, days, billId, result, promoCode, promoIsValid, discount, promoActiveUntil, promoCodeForm, switcher, countLeadsMarksPro } = this.state
    
    return (
       <div className="packages-page">
         {!packages && <div>
            <h3 style={{textAlign: 'center', marginBottom: '40px'}}>У вас нет активных пакетов</h3>
          </div>}
         {!packageType && <div>
          {result === 'success' && (
            <div className="bs-callout-success callout-border-left mt-1 p-1" style={{ width: '600px' }}>
              <strong>{Intl.getMessage('packagesPage.thanks')}</strong>
              <p>{Intl.getMessage('packagesPage.balanceUpdate')}</p>
            </div>
          )}
          {result === 'fail' && (
            <div className="bs-callout-danger callout-border-left mt-1 p-1" style={{ width: '600px' }}>
              <strong>{Intl.getMessage('packagesPage.error')}</strong>
              <p>{Intl.getMessage('packagesPage.balanceUpdateError')}</p>
            </div>
          )}
            <div className="row packages-info">
              {packages && packages.map((packet, index) => {
                return (
                    <Card
                      key={index}
                      packet={packet}
                    />
                  )
              })}
          </div>
         </div>}
         {!packageType && 
           <div className="packages-types">
           <div className="package-type">
             <div className="package-title">
               <h3 style={{color: '#00C87F'}}>Базовый</h3>
               <p>от 4 руб./заявка</p>
             </div>
             <div className="package-body">
               {packagesData.map((elem, index) => {
                 return (
                   elem.active ? 
                     <div className="package-item" key={index}>
                       <i class="fas fa-check"></i>
                       <p style={{color: 'black'}}>{elem.text}</p>
                     </div>
                   : 
                     <div className="package-item" key={index}>
                       <i class="fas fa-times"></i>
                       <p>{elem.text}</p>
                     </div> 
                 )
               })}
             </div>
             <button className="btn-create" onClick={()=>this.buyClickHandle('base')}>Купить</button>
           </div>
           <div className="package-type">
             <div className="package-title">
               <h3 style={{color: '#E0186C'}}>Безлимитный</h3>
               <p>от 825 руб./месяц</p>
             </div>
             <div className="package-body">
               {packagesData.map((elem, index) => {
                 return (
                   <div className="package-item" key={index}>
                     <i class="fas fa-check"></i>
                     <p style={{color: 'black'}}>{elem.text}</p>
                   </div>
                 )
               })}
             </div>
             <button className="btn-create" onClick={()=>this.buyClickHandle('unlimit')}>Купить</button>
           </div>
           {promoIsValid && (
             <span>
               <span> </span>{Intl.getMessage('packagesPage.promoSumWithoutDiscount')} <s>{sum / ((100 - discount) / 100) } {Intl.getMessage('packagesPage.units')}</s>
             </span>
          )}
          <br />
        </div>
         }
         {/* {' '}<Button color="success" onClick={() => this.addPackage()}>{Intl.getMessage('packagesPage.buy')} {switcher && (<span>PRO-пакет</span>)} {!switcher && (<span>Базовый пакет</span>)}</Button>
           {countLeads >= 50  && (countLeads != 80 || countDays >= 30)&& !switcher && (
             <div className="bs-callout-warning callout-border-left mt-1 p-1">
               <strong>{Intl.getMessage('packagesPage.bonus')}</strong>
               <p>{Intl.getMessage('packagesPage.bonusDesc')}</p>
             </div>
           )} */}

           <form method="post" action="https://wl.walletone.com/checkout/checkout/Index" style={{ display: 'none' }}>
             <input name="WMI_MERCHANT_ID"    readOnly value="103060391840" />
             <input name="WMI_PAYMENT_AMOUNT" readOnly value={sum} />
             <input name="WMI_PAYMENT_NO"     readOnly value={billId} />
             <input name="WMI_CURRENCY_ID"    readOnly value="643" />
             <input name="WMI_DESCRIPTION"    readOnly value={`${Intl.getMessage('packagesPage.purchasePacket')} ${((leads[countLeads]) == -1) ? 'Unlimited' : (leads[countLeads])} ${Intl.getMessage('packagesPage.leadsAtTime')} ${days[countDays]} ${Intl.getMessage('packagesPage.days')}`} />
             <input name="WMI_SUCCESS_URL"    readOnly value={`${config.MAIN_URL}packages/success/`} />
             <input name="WMI_FAIL_URL"       readOnly value={`${config.MAIN_URL}packages/fail/`} />
             <input type="submit"  id="addPackage" />
           </form>
           {/* {promoCodeForm && !promoIsValid && (
             <div className="form-group">
                <label htmlFor="firstName1">
                  {Intl.getMessage('packagesPage.promoCodeTypeIn')}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="promo_code"
                  value={promoCode}
                  name="promoCode"
                  onChange={this.handleChange}
                />
                <br/>
                <button className="btn btn-info" onClick={this.loadPromoCode}>{Intl.getMessage('packagesPage.promoCodeNextButton')}</button>
              </div>
           )}
           {promoCode  && promoIsValid && (
             <div>
             <div className="bs-callout-blue callout-border-left mt-1 p-1">
               <strong>{Intl.getMessage('packagesPage.promoCodeTitle')}</strong>
               <p><FormattedMessage id="packagesPage.promoCodeText" values={{ discount }} /> <FormattedDate value={new Date(promoActiveUntil * 1000)} /></p>
             </div>
             <br />
             <br />
            </div>
           )}
           {promoCode  && !promoIsValid && !promoCodeForm && (
             <div>
             <div className="bs-callout-pink callout-border-left mt-1 p-1">
               <strong>{Intl.getMessage('packagesPage.promoCodeTitleInvalid')}</strong>
               <p>{Intl.getMessage('packagesPage.promoCodeTextInvalid')}</p>
             </div>
             <br />
             <br />
            </div>
           )}
           {packageType!=='unlimit' && <p>{Intl.getMessage('packagesPage.countLeads')}</p>}
           {packageType && (<div>
            {packageType!=='unlimit' && <Slider min={10} max={80} marks={countLeadsMarks} step={null} onChange={this.handleChangeCountLeads} defaultValue={30} />}
           <br />
           <br />
           {countLeads == 80 && (
            <div>
            <p>{Intl.getMessage('packagesPage.countDays')}</p>
            <Slider min={0} max={100} marks={countDaysMarks} step={null} onChange={this.handleChangeCountDays} defaultValue={0} />
            <br />
            <br />
            </div>
           )}
           {countLeads !== 80 && (<span>{Intl.getMessage('packagesPage.oneLeadCost')} {sum / leads[countLeads]} {Intl.getMessage('packagesPage.units')} </span>)}
           </div>)}

           {packageType && (
             <div>
              <Slider min={20} max={60} marks={countLeadsMarksPro} step={null} onChange={this.handleChangeCountLeadsPro} defaultValue={20} />
              <br />
              <br />
              <p>{Intl.getMessage('packagesPage.countDays')}</p>
              <Slider min={0} max={100} marks={countDaysMarks} step={null} onChange={this.handleChangeCountDaysPro} defaultValue={0} />
              </div>
           )}
           <br />
           <br />
           {Intl.getMessage('packagesPage.totalCost')} {sum} {Intl.getMessage('packagesPage.units')}
           {promoIsValid && (
              <span>
              <span> </span>{Intl.getMessage('packagesPage.promoSumWithoutDiscount')} <s>{sum / ((100 - discount) / 100) } {Intl.getMessage('packagesPage.units')}</s>
              </span>
           )} */}
           <div className="package-rate">
            {packageType && (
                <div className="package-type">
                  <h3>Тариф "{packageType==='base' ? "Базовый" : 'Безлимитный'}"</h3>
                  { packageType === 'base' ? 
                    <div className="list unlimit"> 
                      {packageLeads.map((elem, index)=> (
                        <div key={elem} onClick={()=>this.handleChangeCountLeads((index+1)*10)}>
                           <div>
                              <h4>{`${elem} заявок`}</h4>
                              <span>{`Стоимость 1 заявки ${rates[0][(index+1)*10]/elem} р.`}</span>
                            </div>
                            <div>
                              <p>{`${rates[0][(index+1)*10]} р.`}</p>
                            </div>
                        </div>
                      ))}
                    </div>
                  :
                      <div className="list unlimit">
                        {packageDays.map(elem=> (
                          <div key={elem.price} onClick={()=>this.handleChangeCountLeadsUnlimited(Math.floor(elem.price/elem.month), elem.month)}>
                            <div>
                              <h4>{`${elem.month} месяц`}</h4>
                              <span className={elem.discount ? 'active' : ''}>{`Скидка ${elem.discount} %`}</span>
                            </div>
                            <div>
                              <p>{`${Math.floor(elem.price/elem.month)} руб. / месяц`}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                  }
                  <button className="btn-create" onClick={()=>this.setState({packageType: ''})}>Назад к тарифам</button>
                </div>
              )}
           </div>
        </div>
    )
  }
}

export default PackagesPage
