const integrationData = (data) => (
    [
        {
            title: 'Webhook',
            section: {
                paragraph: 'Включите "Отправлять webhook", и укажите адрес принимающего скрипта или сервиса.',
                links: [
                    {
                        link: 'Читать подробнее в документации',
                        path: 'https://enquiz.io/docs/integrations/webhooks/'
                    }
                ]
            }
        },
        {
            title: 'Яндекс.Метрика',
            section: {
                paragraph: 'Включите "Отправка целей в Метрику", и укажите номер счетчика. Не забудьте установить сам код счетчика, полученный в интерфейсе метрики, если хотите отслеживать не только цели, но и посещения.',
                links: [
                    {
                        link: 'Читать подробнее в документации',
                        path: 'https://enquiz.io/docs/integrations/metrica/'
                    }
                ]
            }
        },
        {
            title: 'Telegram',
            section: {
                paragraph: "Получайте заявки прямо в телеграм.",
                list: [
                    {
                        title: 'Подключение:',
                        items: ['Найдите в поиске EnquizBot и войдите в чат', 'Нажмите "Подключить квиз', `Введите ID квиза: ${data && data.unique_hash}`, `Введите секретный ключ: ${data && data.string_id.substr(data.string_id.length - 7)}`]
                    },
                    {
                        title: 'Отключение:',
                        items: ['Введите или нажмите "Отключить квиз', `Введите ID квиза: ${data && data.unique_hash}`, `Введите секретный ключ: ${data && data.string_id.substr(data.string_id.length - 7)}`]
                    }
                ]
            }
        },
        {
            title: 'AmoCRM, TimeDigital CRM, Unisender',
            titleAction: 'Через APIWAY',
            section: {
                paragraph: 'Подключите сервис Enquiz и куда отправлять, укажите адрес webhook',
                links: [
                    {
                        link: 'Видео',
                        path: 'https://www.youtube.com/watch?v=_4tB5arAKFM&t=6s'
                    },
                    {
                        link: 'ApiWay',
                        path: 'https://apiway.ai/'
                    }
                ]
            }
        },
        {
            title: 'AmoCRM',
            titleAction: 'Через apix-drive',
            section: {
                paragraph: 'Включите "Отправлять webhook", и укажите адрес webhook от Apix-Drive',
                links: [
                    {
                        link: 'Читать подробнее в документации',
                        path: 'https://enquiz.io/docs/integrations/amocrm_apixdrive/'
                    }
                ]
            }
        },
        {
            title: 'Битрикс-24',
            titleAction: 'Через apix-drive',
            section: {
                paragraph: 'Включите "Отправлять webhook", и укажите адрес webhook от Apix-Drive',
                links: [
                    {
                        link: 'Читать подробнее в документации',
                        path: 'https://enquiz.io/docs/integrations/bitrix24/'
                    }
                ]
            }
        },
        {
            title: 'Sendpulse, Unisender, MailChimp, GetResponse',
            titleAction: 'Через zapier',
            section: {
                paragraph: 'И сотни других сервисов. Включите "Отправлять webhook" и укажите адрес webhook от Zapier*. Воспользуйтесь документаций для AmoCRM, чтобы подключить любой другой сервис.',
                links: [
                    {
                        link: 'Читать подробнее в документации',
                        path: 'https://enquiz.io/docs/integrations/amocrm/'
                    }
                ]
            }
        },
    ]
)

export default integrationData;