import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { PrivateContainer, PublicContainer } from './containers'
import { IndexPage, RegisterPage, AuthPage, IntegrationPage,  ConfirmPage, RecoveryPage, QuizFromScratchPage, TemplatesPage, LeadsPage, PackagesPage, ProfilePage, SupportPage, FeedbackPage, Empty, PartnerPage } from './pages'
import { AuthService } from './services'
import './App.css'

document.title = 'Enquiz.io - улучшенный конструктор квизов'


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (AuthService.isUserAuthenticated() ? (
        <PrivateContainer>
          <Component {...props} />
        </PrivateContainer>
      ) : (
        <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
      ))
    }
  />
)

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <PublicContainer>
        <Component {...props} />
      </PublicContainer>
      )
    }
  />
)

const App = () => (
  <Switch>
    <PublicRoute path="/register" exact component={RegisterPage} />
    <PublicRoute path="/auth" exact component={AuthPage} />
    <PublicRoute path="/recover-password" exact component={RecoveryPage} />
    <PublicRoute path="/confirm-email" exact component={ConfirmPage} />
    <PrivateRoute path="/" exact component={IndexPage} />
    <PrivateRoute path="/integration" exact component={IntegrationPage} />
    <PrivateRoute path="/create-quiz-from-scratch/:draft?" exact component={QuizFromScratchPage} />
    <PrivateRoute path="/edit-quiz/:unique_hash" exact component={QuizFromScratchPage} />
    <PrivateRoute path="/templates/:type?" exact component={TemplatesPage} />
    <PrivateRoute path="/leads/:filter?" exact component={LeadsPage} />
    <PrivateRoute path="/packages/:result?" exact component={PackagesPage} />
    <PrivateRoute path="/profile" exact component={ProfilePage} />
    <PrivateRoute path="/support" exact component={SupportPage} />
    <PrivateRoute path="/feedback" exact component={FeedbackPage} />
    <PrivateRoute path="/partner" exact component={PartnerPage} />
    
    <PrivateRoute path="/empty" exact component={Empty} />
  </Switch>
)

export default App
