import { emailProfileConstants } from '../constants'
import { EmailProfileService } from '../services'

function getList() {
  function request() { return { type: emailProfileConstants.EMAIL_PROFILE_GETLIST_REQUEST } }
  function success(emailProfileList) { return { type: emailProfileConstants.EMAIL_PROFILE_GETLIST_SUCCESS, emailProfileList } }
  function failure(error) { return { type: emailProfileConstants.EMAIL_PROFILE_GETLIST_FAILURE, error } }

  return (dispatch) => {
    dispatch(request())

    EmailProfileService.getList()
      .then(
        (list) => {
          dispatch(success(list))
        },
        (err) => {
          dispatch(failure(err))
        },
      )
  }
}

function create(emailProfile) {
  function request() { return { type: emailProfileConstants.EMAIL_PROFILE_CREATE_REQUEST } }
  function success(emailProfileList) { return { type: emailProfileConstants.EMAIL_PROFILE_CREATE_SUCCESS, emailProfileList } }
  function failure(error) { return { type: emailProfileConstants.EMAIL_PROFILE_CREATE_FAILURE, error } }

  return (dispatch) => {
    dispatch(request())

    EmailProfileService.create(emailProfile)
      .then(
        () => {
          EmailProfileService.getList()
            .then(
              (list) => {
                dispatch(success(list))
              },
            )
        },
        (err) => {
          dispatch(failure(err))
        },
      )
  }
}

function remove(profileId) {
  function request() { return { type: emailProfileConstants.EMAIL_PROFILE_DELETE_REQUEST } }
  function success(emailProfileList) { return { type: emailProfileConstants.EMAIL_PROFILE_DELETE_SUCCESS, emailProfileList } }
  function failure(error) { return { type: emailProfileConstants.EMAIL_PROFILE_DELETE_FAILURE, error } }

  return (dispatch) => {
    dispatch(request())

    EmailProfileService.delete(profileId)
      .then(
        () => {
          EmailProfileService.getList()
            .then(
              (list) => {
                dispatch(success(list))
              },
            )
        },
        (err) => {
          dispatch(failure(err))
        },
      )
  }
}

export {
  getList,
  create,
  remove,
}
