import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import swal from 'sweetalert'
import { EmailProfileService } from '../../services';
import './style.scss'

const ProfileCard = ({
  id, profileName, userName, senderName, replyTo, deleteEmailProfile
}) => {
  const [collapse, setCollapse] = useState(false)

  const handleDeleteProfile = async (profileId) => {
    let willDelete = null
    try {
      willDelete = await swal({
        title: 'Вы уверены, что хотите удалить профиль?',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      })
    } catch (e) {
      console.log(e)
    }

    if (willDelete) {
      deleteEmailProfile(profileId)

      setCollapse(false)

      swal({
        text: 'Профиль удален',
        icon: 'success',
      })
    } else {
      swal({
        text: 'Ваш профиль в сохранности',
        icon: 'warning',
      })
    }
  }

  const handleTestProfile = async (profileId) => {
    let test = null
    try {
      test = await EmailProfileService.test(profileId)
    } catch (e) {
      console.log(e)
      return false
    }

    if (test) {
      let icon = 'success'

      if (!test.status) {
        icon = 'error'
      }

      swal('Результат теста', {
        icon,
        text: test.message,
      })
    } else {
      swal('Результат теста', {
        icon: 'error',
        text: 'Упс. Что-то пошло не так',
      })
    }

    return true
  }

  return (
    <div className="card profile-card">
      <div className="card-header">
        <h4>Профиль {profileName}</h4>
        <div>
          <button className="btn-create" onClick={() => handleTestProfile(id)}>ТЕСТ</button>
          <button className="btn-create" onClick={() => handleDeleteProfile(id)}>УДАЛИТЬ</button>
        </div>
      </div>
      <div className="card-body">
        <p><span>Имя отправителя: </span>{senderName}</p>
        <p><span>Имя пользователя: </span>{userName}</p>
        <p><span>Адрес для ответа: </span>{replyTo}</p>
      </div>
    </div>
  )
}

export default ProfileCard
