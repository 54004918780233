import React from 'react'
import { CustomInput, FormGroup, Label } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { Tooltip } from '../../components'
import { Switch } from '../index'
import { Intl } from '../../utils';
import { Link } from 'react-router-dom'
import './style.scss';

class Step6 extends React.Component {
  constructor(props) {
    super(props)

    let data = this.props.data
    if(!data.discount_units) data.discount_units = '%'

    this.state = {
      json: data,
      notifications: this.props.notifications,
      send_webhook: false,
      goalsState: data.goals,
      unique_hash: this.props.unique_hash,
      userData: this.props.userData,
      additionalEmailsToggle: false
    }

    this.toggleCheckBox = this.toggleCheckBox.bind(this)
    this.setDiscountUnit = this.setDiscountUnit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.setDiscount = this.setDiscount.bind(this)
    this.handleInitialState = this.handleInitialState.bind(this)
    this.handlePlacementType = this.handlePlacementType.bind(this)
    this.toggleCheckBoxNotifications = this.toggleCheckBoxNotifications.bind(this)
    this.handleChangeNotifications = this.handleChangeNotifications.bind(this)
    this.handleChangeAdditionalEmails = this.handleChangeAdditionalEmails.bind(this)
    this.changeDiscountWord = this.changeDiscountWord.bind(this)
  }

  toggleCheckBox(action) {
    const { json } = this.state
    json[action] = !json[action]
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  componentDidMount() {
    const { notifications, json } = this.state
    if (json.discount_title === '' || !json.discount_title) {
      json.discount_title = 'Скидка'
      this.setState({ json }, this.props.refreshState(this.state.json))
    }

    if(notifications.additional_emails) {
      const emails = notifications.additional_emails
      for(const email of emails) {
        if(email !== null) {
          this.setState({additionalEmailsToggle: true})
          break;
        }
      }
    } else {
      notifications.additional_emails = [null, null, null]
    }
  }

  toggleCheckBoxNotifications(action) {
    const {
      notifications, send_webhook, goalsState, json, additionalEmailsToggle
    } = this.state
    if (action === 'send_webhook') {
      if (send_webhook) notifications.webhook_url = ''
      notifications[action] = !notifications[action]
      this.setState({ send_webhook: !send_webhook, notifications }, this.props.refreshNotifications(this.state.notifications))
    }
    if (action === 'goals') {
      if (!goalsState) {
        json.metrica_id = ''
        json.google_metrika = ''
        json.fb_metrika = ''
      }
      json.goals = !goalsState
      notifications[action] = !notifications[action]
      this.setState({ goalsState: !goalsState, notifications, json }, this.props.refreshNotifications(this.state.notifications), this.props.refreshState(this.state.json))
    }
    if (action === 'send_owner_email') {
      notifications[action] = !notifications[action]
      this.setState({ notifications }, this.props.refreshNotifications(this.state.notifications))
    }
    if (action === 'additional_emails') {
      if(additionalEmailsToggle) notifications['additional_emails'] = [null, null, null]
      this.setState({additionalEmailsToggle: !additionalEmailsToggle})
      this.setState({ notifications }, this.props.refreshNotifications(this.state.notifications))
    }
  }

  handleChangeNotifications(event) {
    const { target: { value, name } } = event
    const { notifications } = this.state
    notifications[name] = value
    this.setState({ notifications }, this.props.refreshNotifications(this.state.notifications))
  }

  handleChangeAdditionalEmails(event) {
    const { target: { value, name } } = event
    const { notifications } = this.state

    let array = notifications.additional_emails

    if(name === 'email1') array[0] = value
    if(name === 'email2') array[1] = value
    if(name === 'email3') array[2] = value

    this.setState({ notifications }, this.props.refreshNotifications(this.state.notifications))
  }

  handlePlacementType(event) {
    const { json } = this.state
    json.placement_type = event.target.value
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  handleInitialState(event) {
    const { json } = this.state
    json.initial_state = event.target.value
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  handleChange(event) {
    const { json } = this.state
    const { refreshState } = this.props
    const { target: { value = '', name } } = event
    const trimValue = value.trim ? value.trim() : value

    json[name] = trimValue

    this.setState({ json }, refreshState(this.state.json))
  }

  setDiscount(type, action) {
    const { json } = this.state
    if (type === 'volume') {
      if (action === 'minus') json.discount_volume -= 0.5
      if (action === 'plus') json.discount_volume += 0.5
    }
    if (type === 'increase_by') {
      json.discount_increase_by *= 10
      if (action === 'minus') json.discount_increase_by -= 1
      if (action === 'plus') json.discount_increase_by += 1
      json.discount_increase_by /= 10
    }

    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  setDiscountUnit(event) {
    const { target: { value, name } } = event
    const { json } = this.state
    json['discount_units'] = value
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  changeDiscountWord(event) {
    const value = event.currentTarget.value
    const { json } = this.state
    json.discount_title = value
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  render() {
    const {
      json: {
        enable_quiz, discount_offer, goals, quiz_title, discount_volume, discount_title: discountTitle, discount_increment, discount_increase_by, initial_state, placement_type, metrica_id, fb_metrika, google_metrika, discount_units
      },
      notifications: {
        send_owner_email, webhook_url, additional_emails, notifications
      },
      send_webhook, goalsState, unique_hash, userData: { user: { email } }, userData: { user: { string_id } }, additionalEmailsToggle
    } = this.state


    return (
      <fieldset className="stage-6-content">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-content collapse show">
                <div className="card-body" >
                  <FormGroup>
                    <Label>
                      <FormattedMessage id="step6.initialState" defaultMessage="С чего начать квиз" />
                    </Label>
                    <FormGroup tag="fieldset">
                      <div>
                        <FormGroup check inline>
                          <Label>
                            <CustomInput
                              type="radio"
                              className="inline"
                              id="exampleCustomRadio"
                              name="customRadio"
                              label={Intl.getMessage('step6.initialStateCover')}
                              value="cover"
                              checked={initial_state === 'cover'}
                              onChange={this.handleInitialState}
                            />
                          </Label>
                          <span className="label-cover">Вы можете не добавлять квиз на ваш сайт, а направлять посетителей напрямую на квиз.</span>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label>
                            <CustomInput
                              type="radio"
                              className="inline"
                              id="exampleCustomRadio2"
                              name="customRadio"
                              label={Intl.getMessage('step6.initialStateQuestion')}
                              value="question"
                              checked={initial_state === 'question'}
                              onChange={this.handleInitialState}
                            />
                          </Label>
                          <span className="label-cover">Квиз будет появляться на вашем сайте. Для этого необходимо добавить наш код на ваш сайт.</span>
                        </FormGroup>
                      </div>
                    </FormGroup>
                  </FormGroup>
                  {initial_state !== 'cover' && <div className="display-type">
                    <fieldset className="form-group position-relative">
                      <label>
                        <FormattedMessage id="step6.placementType" defaultMessage="Вид отображения" />
                      </label>
                      <select className="form-control" id="DefaultSelect" onChange={this.handlePlacementType} value={placement_type}>
                        <option value="built-in">{Intl.getMessage('step6.placementTypeBuiltIn')}</option>
                        <option value="float">{Intl.getMessage('step6.placementTypeFloat')}</option>
                        <option value="float-button">{Intl.getMessage('step6.placementTypeFloatButton')}</option>
                        <option value="built-in-dialog">{Intl.getMessage('step6.placementTypeHybrid')}</option>
                      </select>
                    </fieldset>
                    {unique_hash && (
                    <div>
                      {(placement_type === 'float' || placement_type === 'built-in' || placement_type === 'built-in-dialog' || placement_type === 'float-button') && <div className="form-group">
                        <label htmlFor="issueinput10">
                          <FormattedMessage id="step6.initialCodeLabel" defaultMessage="Код иницилизации(Вставьте этот код в блок &lt;head&gt; в начале страницы)" />
                        </label>
                        <textarea
                          id="issueinput10"
                          rows="1"
                          className="form-control"
                          name="comments"
                          placeholder="comments"
                          data-toggle="tooltip"
                          data-trigger="hover"
                          data-placement="top"
                          data-title="Comments"
                          data-original-title=""
                          title=""
                          defaultValue={'<script type="text/javascript" src="https://show.enquiz.io/js/quizloader.js"></script>'}
                        />
                      </div>}
                      {placement_type === 'built-in' && <div className="form-group">
                        <label htmlFor="issueinput10">
                          <FormattedMessage id="step6.buttonCodeLabel" defaultMessage="Добавьте в нужное место, там где будет кнопка или сам квиз" />
                        </label>
                        <textarea
                          id="issueinput10"
                          rows="9"
                          className="form-control"
                          name="comments"
                          placeholder="comments"
                          data-toggle="tooltip"
                          data-trigger="hover"
                          data-placement="top"
                          data-title="Comments"
                          data-original-title=""
                          title=""
                          defaultValue={`<script>
    (function () {
        new enquiz_ioLoader({
            quiz_hash:"${unique_hash}",
            type: enquiz_ioLoader.EMBEDDED,
            hash: "quiz_${unique_hash}"
        })
    })()
</script>`}
                        />
                      </div>}

                      {placement_type === 'float-button' && <div className="form-group">
                        <label htmlFor="issueinput10">
                          <FormattedMessage id="step6.buttonCodeLabelFloatButton" defaultMessage="Добавьте в нужное место, там где будет кнопка или сам квиз" />
                        </label>
                        <textarea
                          id="issueinput10"
                          rows="10"
                          className="form-control"
                          name="comments"
                          placeholder="comments"
                          data-toggle="tooltip"
                          data-trigger="hover"
                          data-placement="top"
                          data-title="Comments"
                          data-original-title=""
                          title=""
                          defaultValue={`<script>
    (function () {
        new enquiz_ioLoader({
            quiz_hash:"${unique_hash}",
            type: enquiz_ioLoader.FLOAT_BUTTON,
            hash: "quiz_${unique_hash}",
            button_location: "right-bottom"
        })
    })()
</script>`}
                        />
                      </div>}

                      {placement_type === 'float' && <div className="form-group">
                        <label htmlFor="issueinput10">
                          <FormattedMessage id="step6.buttonCodeLabelDialog" />
                        </label>
                        <textarea
                          id="issueinput10"
                          rows="10"
                          className="form-control"
                          name="comments"
                          placeholder="comments"
                          data-toggle="tooltip"
                          data-trigger="hover"
                          data-placement="top"
                          data-title="Comments"
                          data-original-title=""
                          title=""
                          defaultValue={`<script>
    (function () {
        new enquiz_ioLoader({
            quiz_hash:"${unique_hash}",
            type: enquiz_ioLoader.FLOAT,
            button: "YourButtonId",
            hash: "quiz_${unique_hash}"
        })
    })()
</script>`
                          }
                        />
                        <br />
                        <FormattedMessage id="step6.buttonCodeLabelDialog2" /> <input type="text" value={`#quiz_${unique_hash}`} readOnly />
                      </div>}
                    </div>
                    )}


                    {placement_type === 'built-in-dialog' && <div className="form-group">
                        <label htmlFor="issueinput10">
                          <FormattedMessage id="step6.buttonCodeLabelMixed" defaultMessage="Добавьте в нужное место, там где будет кнопка или сам квиз" />
                        </label>
                        <textarea
                          id="issueinput10"
                          rows="9"
                          className="form-control"
                          name="comments"
                          placeholder="comments"
                          data-toggle="tooltip"
                          data-trigger="hover"
                          data-placement="top"
                          data-title="Comments"
                          data-original-title=""
                          title=""
                          defaultValue={`<script>
    (function () {
        new enquiz_ioLoader({
            quiz_hash:"${unique_hash}",
            type: "built-in-dialog",
            hash: "quiz_${unique_hash}"
        })
    })()
</script>`
                          }
                        />
                      </div>}

                    {!unique_hash && (
                      <div>
                        {Intl.getMessage('step6.htmlCodeWithoutHash')}
                      </div>
                    )}
                    <div className="display-type-footer">
                      <a href="https://enquiz.io/docs/creation/installation/on_site/" target="_blank">{Intl.getMessage('card.codeInstallationLink')}</a>
                    </div>
                  </div>}

                </div>
              </div>
            </div>

            <div className="card notes">
              <div className="card-content collapse show">
                <div className="card-body" >
                  <h4 className="custom-text-title type-2">
                    <FormattedMessage id="step6.settingsNotifications" defaultMessage="Настройки уведомлений" />
                  </h4>
                  <div onClick={() => this.toggleCheckBoxNotifications('send_owner_email')}>
                    <Switch toggle={send_owner_email} />
                    <label className="ml-1">
                      <FormattedMessage id="step6.emailNotification" values={{ email }} defaultMessage="Уведомлять по email" />
                      <span className="span-unset">({email})</span>
                    </label>
                  </div>
                  <div onClick={() => this.toggleCheckBoxNotifications('additional_emails')}>
                    <Switch toggle={additionalEmailsToggle} />
                    <label className="ml-1">
                      Дополнительные email адреса
                    </label>
                  </div>
                    {additionalEmailsToggle && (
                      <div className="form-side">
                        <div className="form-group">
                          <input type="email" name="email1" className="form-control" required id="email1" placeholder="email1" value={additional_emails[0] || ''} onChange={this.handleChangeAdditionalEmails} />
                          <br/>
                          <input type="email" name="email2" className="form-control" id="email2" placeholder="email2" value={additional_emails[1] || ''} onChange={this.handleChangeAdditionalEmails} />
                          <br/>
                          <input type="email" name="email3" className="form-control" id="email3" placeholder="email3" value={additional_emails[2] || ''} onChange={this.handleChangeAdditionalEmails} />
                        </div>
                      </div>
                    )}
                  <div onClick={() => this.toggleCheckBoxNotifications('send_webhook')}>
                    <Switch toggle={send_webhook || webhook_url} />
                    <label className="ml-1">
                      <FormattedMessage id="step6.sednWebhook" defaultMessage="Отправлять webhook" />
                    </label>
                  </div>
                  {(send_webhook || webhook_url) && (
                    <div className="form-side">
                      <div className="form-group">
                        <label htmlFor="issueinput12">
                          <FormattedMessage id="step6.webhookUrl" defaultMessage="URL для отправки webhook" />
                        </label>
                        <input type="url" required name="webhook_url" className="form-control" id="firstName2" placeholder="https://domain.ru/somescript" value={webhook_url} onChange={this.handleChangeNotifications} />
                      </div>
                    </div> 
                  )}
                  <div onClick={() => this.toggleCheckBoxNotifications('goals')}>
                    <Switch toggle={goalsState} />
                    <label className="ml-1">
                      <FormattedMessage id="step6.sendGoals" defaultMessage="Включить аналитику" />
                    </label>
                  </div>
                  {goalsState && (
                    <div className="form-side">
                      <div className="form-group">
                        <label htmlFor="issueinput12">
                          <FormattedMessage id="step6.metricaCounterNumber" defaultMessage="ID счетчика в Яндекс.Метрика" />
                        </label>
                        <Tooltip
                          header={Intl.getMessage('step6.tooltipMetricaTitle')}
                          body={Intl.getMessage('step6.tooltipMetrica')}
                          target="id650"
                        />
                        <input type="text" name="metrica_id" className="form-control" id="metrica_id" placeholder="12345678" value={metrica_id} onChange={this.handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="issueinput12">
                          <FormattedMessage id="step6.fbMetrika" defaultMessage="ID счетчика в Facebook Pixel" />
                        </label>
                        <input type="text" name="fb_metrika" className="form-control" id="fb_metrika" placeholder="12345678" value={fb_metrika} onChange={this.handleChange} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="issueinput12">
                          <FormattedMessage id="step6.googleMetrika" defaultMessage="ID счетчика в Google analytics" />
                        </label>
                        <input type="text" name="google_metrika" className="form-control" id="google_metrika" placeholder="UA-111111111-1" value={google_metrika} onChange={this.handleChange} />
                      </div>
                    </div>
                  )}
                </div>
                  <Link style={{fontWeight: '500', fontSize: '16px', color: '#00ADFF', marginTop: '25px'}} to={{pathname: "/Integration", query: {unique_hash, quiz_title}}}>Интеграции</Link>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    )
  }
}

export default Step6
