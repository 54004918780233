import React from 'react'
import { Intl } from '../../utils'
import './styles.scss'

class StepsTabList extends React.Component {
  constructor(props) {
    super(props)

    this.changeStep = this.changeStep.bind(this)
  }

  changeStep(step) {
    this.props.changeStep(step)
  }

  render() {
    const { step, setStep } = this.props
    return (
      <ul className="steps-list">
        <li onClick={() => setStep(1)}>
          <a className={`${step === 1 ? 'active' : '' }`}>
            <input id="settings" type="submit" className="inputStep" value={Intl.getMessage('steps.tabsSettings')} />
          </a>
        </li>
        <li onClick={() => setStep(2)}>
          <a className={`${step === 2 ? 'active' : '' }`}>
            <input id="questions" type="submit" className="inputStep" value="Вопросы" />
          </a>
        </li>
        <li onClick={() => setStep(3)}>
          <a className={`${step === 3 ? 'active' : '' }`}>
            <input id="results" type="submit" className="inputStep" value={Intl.getMessage('steps.tabsResults')} />
          </a>
        </li>
        <li onClick={() => setStep(4)}>
          <a className={`${step === 4 ? 'active' : '' }`}>
            <input id="requestContacts" type="submit" className="inputStep step4" value="Сбор контактов" />
          </a>
        </li>
        <li onClick={() => setStep(5)}>
          <a className={`${step === 5 ? 'active' : '' }`}>
            <input id="appearance" type="submit" className="inputStep step5" value="Дизайн" />
          </a>
        </li>
        <li onClick={() => setStep(6)}>
          <a className={`${step === 6 ? 'active' : '' }`}>
            <input id="publication" type="submit" className="inputStep step6" value="Доп. настройки"/>
          </a>
        </li>
        <li onClick={() => setStep(7)}>
          <a className={`${step === 7 ? 'active' : '' }`}>
            <input id="ad" type="submit" className="inputStep step7" value="Реклама в Яндекс"/>
          </a>
        </li>
      </ul>
    )
  }
}
export default StepsTabList
