import '../../assets/css/responsive/quiz-list.css'
import React from 'react'
import swal from 'sweetalert';
import Alert from 'react-s-alert';
import { FormattedMessage } from 'react-intl'
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'
import { Intl } from '../../utils'
import Question from './Question';
import QuestAction from './QuestAction';
import AnswerAction from './AnswerAction';
import './assets/css/Step3.css';
import './style.scss';
import { connect } from 'react-redux'

const config = require('../../config/config')
const imageSize = '200,fit'


const SortableItem = SortableElement(({value}) => {
  return (
    <div style={{ marginBottom: '-15px' }}>
      {value}
    </div>
  );
});

const SortableList = SortableContainer(({items}) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})

class Step3 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeType: undefined,
      selectedAnswerId: undefined,
      selectedQuestId: undefined,
      editableAnswer: null,
      isLoad: false,
      previewId: false,
      isVisible: true,
      isCollapsedAll: true,
      isOpenSelectBody: false,
      logicFilter: {
        isEnable: false,
        start: 0,
        end: 0,
      },
      filteredQuestions: [],
      copyId: null,
      isOpenModalNewQuestion: false,
      newQuestion: {
        question_kind: 'option',
        image_placement: "option-image",
        allow_multiple: false,
        question_options_list: false,
        auto_time: '',
        button_color: 'rgb(248, 145, 65)',
        text_color: 'rgb(255, 255, 255)',
        background_color: 'rgb(132, 172, 255)',
        textarea: false,
        answers: [{
          answer_units: '',
          required: false,
        }],
      },
      
      questions: this.props.data.questions,
      displayPickerTextColor: false,
      displayPickerBackgroundColor: false,
      displayPickerButtonColor: false,
      auto_time: false,
      uploadedImageType: null,
    }

    this.initialQuestion = {...this.state.newQuestion};

    this.collapseAll = this.collapseAll.bind(this)
    this.copyQuestion = this.copyQuestion.bind(this)
    this.deleteQuestion = this.deleteQuestion.bind(this)
    this.reorderAnswers = this.reorderAnswers.bind(this)
    this.reorderQuestions = this.reorderQuestions.bind(this)
    this.componentDidUpdate = this.componentDidUpdate.bind(this)
    this.setSkipTo = this.setSkipTo.bind(this)
    this.setWeight = this.setWeight.bind(this)
    this.setResultBinding = this.setResultBinding.bind(this)
    this.setMinMax = this.setMinMax.bind(this)
    this.toggleModalNewQuestion = this.toggleModalNewQuestion.bind(this)
    this.addQuestion = this.addQuestion.bind(this)
    this.editQuestion = this.editQuestion.bind(this)
    this.getImage = this.getImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    // this.getPreview = this.getPreview.bind(this)
    this.enableLogicFilter = this.enableLogicFilter.bind(this)
    this.changeStepQuestion = this.changeStepQuestion.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.checkVisibility = this.checkVisibility.bind(this)
    this.openPickerBackgroundColor = this.openPickerBackgroundColor.bind(this)
    this.chooseBackgroundColor = this.chooseBackgroundColor.bind(this)
    this.changePickerBackgroundColor = this.changePickerBackgroundColor.bind(this)

    this.openPickerTextColor = this.openPickerTextColor.bind(this)
    this.chooseTextColor = this.chooseTextColor.bind(this)
    this.changePickerTextColor = this.changePickerTextColor.bind(this)

    this.openPickerButtonColor = this.openPickerButtonColor.bind(this)
    this.chooseButtonColor = this.chooseButtonColor.bind(this)
    this.changePickerButtonColor = this.changePickerButtonColor.bind(this)

    this.onChangeAutoTime = this.onChangeAutoTime.bind(this)
    this.handleSwitchAutoTime = this.handleSwitchAutoTime.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    if(!this.props.questions.length){
      this.setState({activeType: 'questAction'});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate(prevProps, prevState) {
    const { copyId} = this.state
    const {questions, handleCreateQuiz} = this.props;

    if (copyId && prevProps.questions.length!==questions.length) {
      const scrollTo = document.getElementById(`${questions.length - 1}_question`)
      scrollTo && scrollTo.scrollIntoView({ behavior: 'smooth' })
      this.setState({ copyId: false })
    }
    if(handleCreateQuiz!==prevProps.handleCreateQuiz){
      this.toggleModalNewQuestion();
    }
  }

  onChangeAutoTime(event) {
    const { target: { value, name } } = event
    const {questions} = this.props;
    const {selectedQuestId, activeType} = this.state;
    const newQuestion = { ...this.state.newQuestion };
    
    if(activeType!== 'questAction'){
      questions[selectedQuestId][name] = value;
      this.props.refreshQuestions(questions);
    }else{
      newQuestion[name] = value
      this.setState({newQuestion})
    }
  }

  openPickerBackgroundColor() {
    const { displayPickerBackgroundColor } = this.state
    this.setState({ displayPickerBackgroundColor: !displayPickerBackgroundColor })
  }

  chooseBackgroundColor() {
    this.setState({ displayPickerBackgroundColor: false })
  }

  changePickerBackgroundColor(color) {
    const {questions} = this.props;
    const {selectedQuestId, activeType} = this.state;
    const newQuestion = { ...this.state.newQuestion };

    if(activeType!== 'questAction'){
      questions[selectedQuestId]['background_color'] = color.hex;
      this.props.refreshQuestions(questions);
    }else{
      newQuestion['background_color'] = color.hex
      this.setState({newQuestion})
    }
  }

  openPickerTextColor() {
    const { displayPickerTextColor } = this.state
    this.setState({ displayPickerTextColor: !displayPickerTextColor })
  }

  chooseTextColor() {
    this.setState({ displayPickerTextColor: false })
  }

  changePickerTextColor(color) {
    const {questions} = this.props;
    const {selectedQuestId, activeType} = this.state;
    const newQuestion = { ...this.state.newQuestion };

    if(activeType!== 'questAction'){
      questions[selectedQuestId]['text_color'] = color.hex;
      this.props.refreshQuestions(questions);
    }else{
      newQuestion['text_color'] = color.hex
      this.setState({newQuestion})
    }
  }

  openPickerButtonColor() {
    const { displayPickerButtonColor } = this.state
    this.setState({ displayPickerButtonColor: !displayPickerButtonColor })
  }

  chooseButtonColor() {
    this.setState({ displayPickerButtonColor: false })
  }

  changePickerButtonColor(color) {
    const {questions} = this.props;
    const {selectedQuestId, activeType} = this.state;
    const newQuestion = { ...this.state.newQuestion };

    if(activeType!== 'questAction'){
      questions[selectedQuestId]['button_color'] = color.hex;
      this.props.refreshQuestions(questions);
    }else{
      newQuestion['button_color'] = color.hex
      this.setState({newQuestion})
    }
  }

  handleSwitchAutoTime() {
    const {questions} = this.props;
    const {selectedQuestId, activeType} = this.state;
    const newQuestion = { ...this.state.newQuestion };

    if(activeType!== 'questAction'){
      questions[selectedQuestId]['auto_time'] = !questions[selectedQuestId]['auto_time'];
      this.props.refreshQuestions(questions);
    }else{
      newQuestion['auto_time'] = !newQuestion['auto_time']
      this.setState({newQuestion})
    }

  }

  handleScroll() {
    const panel = document.getElementById('controlPanel')
    this.setState({ isVisible: this.checkVisibility(panel) })
  }

  checkVisibility(el) {
    const rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  collapseAll(isCollapsedAll) {
    const { questions } = this.state
    questions.map((question, index) => {
      question.isCollapsed = isCollapsedAll
    })
    this.setState({ questions, showAll: isCollapsedAll})
  }

  copyQuestion(id) {

    const questions = [...this.props.questions];
    if(!questions || id === undefined){
      return
    }

    const copyId = true
    const question = { ...questions[id] }
    question.answers = []
    questions[id].answers.map((answer, index) => {
      question.answers[index] = { ...answer }
    })
    questions.splice(questions.length, 0, question);
    this.setState({ questions: [...questions], copyId }, this.props.refreshQuestions([...questions]))
  }

  deleteQuestion(id) {
    const { questions } = this.state;
    
    questions.map((question, questionId) => {
      question.answers.map((answer, answerId) => {
        if ((answer.skip_to - 1) === id) {
          questions[questionId].answers[answerId].skip_to = 'next'
        }
        if (id < (answer.skip_to - 1)) {
          questions[questionId].answers[answerId].skip_to -= 1
        }
      })
    })
    questions.splice(id, 1)
    this.setState({ questions, activeType: undefined, selectedQuestId: undefined, selectedAnswerId: undefined }, this.props.refreshQuestions(this.state.questions))
  }

  editQuestion(id, editQuestion) {
    const { questions } = this.state
    questions[id] = { ...editQuestion }
    this.setState({ questions }, this.props.refreshQuestions(this.state.questions))
  }

  addQuestion() {
    const newQuestion = {...this.state.newQuestion};
    const questions = [...this.props.questions]

    if (newQuestion.question_kind === 'numeric' || newQuestion.question_kind === 'text' || newQuestion.question_kind === 'file') {
      delete newQuestion.allow_multiple
      newQuestion.answers[0] = {
        answer_units: newQuestion.answer_units,
        required: newQuestion.answers[0].required,
        skip_to: 'next',
      }
      if (newQuestion.question_kind === 'text' || newQuestion.question_kind === 'file') {
        delete newQuestion.answers[0].answer_units
      }
    }
    if (newQuestion.question_kind === 'option' || newQuestion.question_kind === 'option_image' || newQuestion.question_kind === 'motivator') {
      newQuestion.answers = []
    }
    if (newQuestion.question_kind === 'option_yesNo') {
      newQuestion.question_kind = 'option'
      newQuestion.answers = [
        {
          answer_title: Intl.getMessage('step3.yes'),
          skip_to: 'next',
        },
        {
          answer_title: Intl.getMessage('step3.no'),
          skip_to: 'next',
        },
      ]
    }
    if (newQuestion.question_kind === 'text') {
      newQuestion.answers[0].textarea = newQuestion.textarea
      newQuestion.answers[0].skip_to = 'next'
    }
    delete newQuestion.textarea
    questions.splice(questions.length, 0, { ...newQuestion })

    this.resetQuestion();
    this.toggleModalNewQuestion();
    this.setState({copyId: true, activeType: undefined, selectedQuestId: undefined}, ()=>this.props.refreshQuestions(questions))
  }

  deleteImage = (name) => () => {
    const {questions} = this.props;
    const {selectedQuestId, activeType} = this.state;
    const newQuestion = { ...this.state.newQuestion };
    
    if(activeType !== 'questAction'){
      if (name === 'side') {
        delete questions[selectedQuestId].question_side_image;
        delete questions[selectedQuestId].question_image;
      }

      if (name === 'background') {
        delete questions[selectedQuestId].question_background_image;
        delete questions[selectedQuestId].question_image;
      }
      this.props.refreshQuestions(questions);
    } else {
      if (name === 'side') {
        delete newQuestion.question_side_image;
      }

      if (name === 'background') {
        delete newQuestion.question_background_image;
      }

      this.setState({newQuestion})
    }
  }

  async getImage(event) {
    const {questions} = this.props;
    const { newQuestion, selectedQuestId , activeType} = this.state
    const changedQuest = activeType!== 'questAction' ? questions[selectedQuestId] : newQuestion;
    const data = new FormData()
    const file = event.target.files[0]
    const name = event.target.name

    this.setState({ isLoad: true, uploadedImageType: name })

    if (file) {
      data.append('file', file)
      changedQuest.question_image = data

      try {
        let response = await fetch(config.IMAGE_UPLOAD_URL, {
          method: 'POST',
          body: changedQuest.question_image,
        })
        response = await response.json()
        if(name) {
          if(name === 'side') {
            changedQuest.question_side_image = response.Url
          } else {
            changedQuest.question_background_image = response.Url
          }
          changedQuest.question_image = response.Url
        }
      } catch (error) {
        console.log(error)
      }

      if(activeType !== 'questAction'){
        this.setState({isLoad: false, uploadedImageType: '' }, () => this.props.refreshQuestions(questions))
      }else{
        this.setState({isLoad: false, newQuestion: changedQuest, uploadedImageType: '' })
      }
    }
  }

  handleCheck = (type) => {
    const {questions} = this.props;
    const {selectedQuestId, newQuestion} = this.state;
    const result = selectedQuestId!==undefined ? questions[selectedQuestId] : newQuestion;
    
    if (type === 'required') {
      result.answers[0].required = !result.answers[0].required
      selectedQuestId!==undefined ? this.props.refreshQuestions(questions) : this.setState({newQuestion: result})
    }
    if (type === 'ui_input') {
      result.answers[0].ui_input = !result.answers[0].ui_input
      selectedQuestId!==undefined ? this.props.refreshQuestions(questions) : this.setState({newQuestion: result})
    }
    if (type === 'allow_multiple') {
      result.allow_multiple = !result.allow_multiple
      selectedQuestId!==undefined ? this.props.refreshQuestions(questions) : this.setState({newQuestion: result})
    }
    if (type === 'question_options_list') {
      result.question_options_list = !result.question_options_list
      selectedQuestId!==undefined ? this.props.refreshQuestions(questions) : this.setState({newQuestion: result})
    }
    if (type === 'right-image') {
      result.image_placement = 'right-image'
      selectedQuestId!==undefined ? this.props.refreshQuestions(questions) : this.setState({newQuestion: result})
    }
    if (type === 'option-image') {
      result.image_placement = 'option-image'
      selectedQuestId!==undefined ? this.props.refreshQuestions(questions) : this.setState({newQuestion: result})
    }
    if (type === 'textarea') {
      result.textarea = !result.textarea
      selectedQuestId!==undefined ? this.props.refreshQuestions(questions) : this.setState({newQuestion: result})
    }
  }

  reorderAnswers(questionId, ids) {
    const { oldIndex, newIndex } = ids
    const {questions} = this.props;
    questions[questionId].answers = arrayMove(questions[questionId].answers, oldIndex, newIndex)
    this.props.refreshQuestions(questions);
  }

  reorderQuestions(ids) {
    let { oldIndex, newIndex } = ids
    let {questions} = this.props;
    // questions = arrayMove(questions, oldIndex, newIndex)
    oldIndex += 1
    newIndex += 1

    questions.map((question, questionId) => {
        question.answers.map((answer, answerId) => {
          if (answer.skip_to) {
            if (((questionId < oldIndex) && (oldIndex < answer.skip_to)) && ((questionId < newIndex) && (newIndex < answer.skip_to))) {
            }
            if (answer.skip_to !== oldIndex) {
              // moving out of range, minus
              if (((questionId <= oldIndex) && (oldIndex < answer.skip_to)) && (answer.skip_to <= newIndex)) {
                questions[questionId].answers[answerId].skip_to -= 1
              }
              // moving to the range, plus
              if ((answer.skip_to < oldIndex) && (newIndex <= answer.skip_to)) {
                questions[questionId].answers[answerId].skip_to += 1
              }
            } else {
              // relocation
              if ((answer.skip_to === oldIndex) && ((questionId + 1) < newIndex)) {
                questions[questionId].answers[answerId].skip_to = newIndex
              }
              if ((answer.skip_to === oldIndex) && (newIndex <= (questionId + 1))) {
                questions[questionId].answers[answerId].skip_to = 'next'
              }
            }
          }
        })
      })

    oldIndex -= 1
    newIndex -= 1
    questions = arrayMove(questions, oldIndex, newIndex)

    this.props.refreshQuestions(questions);
  }

  setSkipTo(questionId, answerId, skipTo) {
    const {questions} = this.props;
    const { newQuestion, selectedQuestId , activeType} = this.state
    const changedQuest = activeType!== 'questAction' ? questions[selectedQuestId] : newQuestion;

    if (!isNaN(skipTo)) changedQuest.answers[answerId].skip_to = skipTo;

    changedQuest.answers[answerId].skip_to = skipTo;

    if(activeType!== 'questAction'){
      this.props.refreshQuestions(questions);
    }else{
      this.setState({newQuestion: changedQuest})
    }
  }

  setWeight(questionId, answerId, weight) {
    const {questions} = this.props;
    const { newQuestion, selectedQuestId , activeType} = this.state
    const changedQuest = activeType!== 'questAction' ? questions[selectedQuestId] : newQuestion;

    changedQuest.answers[answerId].weight = weight

    if(activeType!== 'questAction'){
      this.props.refreshQuestions(questions);
    }else{
      this.setState({newQuestion: changedQuest})
    }
  }

  setResultBinding(questionId, answerId, resultBinding) {
    const {questions} = this.props;
    const { newQuestion, selectedQuestId , activeType} = this.state
    const changedQuest = activeType!== 'questAction' ? questions[selectedQuestId] : newQuestion;

    if (resultBinding === 'not') delete changedQuest.answers[answerId].result_binding
    else changedQuest.answers[answerId].result_binding = resultBinding
    
    if(activeType!== 'questAction'){
      this.props.refreshQuestions(questions);
    }else{
      this.setState({newQuestion: changedQuest})
    }
  }

  setMinMax(questionId, answerId, newMinMax) {
    const {questions} = this.props;
    const { newQuestion, selectedQuestId , activeType} = this.state
    const changedQuest = activeType!== 'questAction' ? questions[selectedQuestId] : newQuestion;

    changedQuest.answers[answerId].max_value = newMinMax.maxVal
    changedQuest.answers[answerId].min_value = newMinMax.minVal
    changedQuest.answers[answerId].ui_input = newMinMax.isCheckedUIInput
    
    if(activeType!== 'questAction'){
      this.props.refreshQuestions(questions);
    }else{
      this.setState({newQuestion: changedQuest})
    }
  }


  enableLogicFilter() {
    let { logicFilter, questions, filteredQuestions } = this.state
    if (!logicFilter.isEnable) {
      logicFilter.isEnable = !logicFilter.isEnable
      let start = logicFilter.start
      let end = logicFilter.end
      // for (start; end < questions.length; end++) {
      //   if (questions[end].question_kind === 'option' || questions[end].question_kind === 'option_image') {
      //     end++
      //     break
      //   }
      // }
      end++
      filteredQuestions = questions.slice(start, end)
      logicFilter.end = end
    } else {
      logicFilter.isEnable = !logicFilter.isEnable
      logicFilter.start = 0
      logicFilter.end = 0
    }

    questions.map((question, index) => {
      if (questions[index].skip_to !== undefined) delete questions[index].skip_to
    })
    this.setState({ logicFilter, filteredQuestions, questions, previewId: false })
  }

  changeStepQuestion(action, resultId) {
    let { logicFilter, filteredQuestions } = this.state;
    let {questions} = this.props;
    if (action === 'next') {
      let start = logicFilter.end
      let end = logicFilter.end
      end++
      // for (start; end < questions.length; end++) {
      //   if (questions[end].question_kind === 'option' || questions[end].question_kind === 'option_image') {
      //     end++
      //     break
      //   }
      // }
      filteredQuestions = questions.slice(start, end)
      logicFilter.end = end
    }
    if (action === 'result') {
      let result
      const bindingResult = this.props.data.results[resultId]
      if (bindingResult === undefined) result = 'Нет связанного результата'
      else {
        const type = this.props.data.results[resultId].result_kind
        if (type === 'text') result = this.props.data.results[resultId].result_title
        if (type === 'link') result = this.props.data.results[resultId].result_url
      }
      swal(`Достигнут конец квиза с результатом - ${result}`, '', 'success')
    }
    if (action === 'lead_form') {
      swal(`Достигнута форма сбора контактов`, '', 'success')
    }
    if (!isNaN(+action)) {
      action = +action - 1
      let start = action
      let end = action
      for (start; end < questions.length; end++) {
        if (questions[end].question_kind === 'option' || questions[end].question_kind === 'option_image') {
          end++
          break
        }
      }
      filteredQuestions = questions.slice(start, end)
      logicFilter.end = end
    }
    if (filteredQuestions.length === 0) swal(`Достигнута форма сбора контактов`, '', 'success')
    else {
      this.setState({ filteredQuestions, logicFilter }, ()=> this.refreshQuestions(questions))
    }
  }

  handleRefreshAnswer = answer => {
    const {selectedQuestId, selectedAnswerId} = this.state;
    const {questions} = this.props;
    if(answer){
      questions[selectedQuestId].answers[selectedAnswerId] = {...answer};
    }

    this.props.refreshQuestions(questions);
  }

  toggleModalNewQuestion() {
    this.setState({ 
      newQuestion: {...this.initialQuestion},
      activeType: 'questAction',
      selectedQuestId: undefined,
      previewId: undefined
    })
  }

  handleResetQuestion = question => {
    const {questions, selectedQuestId, activeType} = this.state;
    questions[selectedQuestId] = question;

    activeType!== 'questAction' && this.props.refreshQuestions(questions);
  }

  resetQuestion = () => {
    this.setState({activeType: undefined, selectedQuestId: undefined, selectedAnswerId: undefined, previewId: undefined, newQuestion: {...this.initialQuestion}})
  }

  collapse = (id) => {
    const { questions } = this.state
    if (questions[id]) {
      const { isCollapsed } = questions[id]
      questions[id].isCollapsed = !isCollapsed
      this.setState({ questions })
    }
  }

  handelClickEditQuest = (id) => {
    const { questions } = this.state
    this.setState({ previewId: id, selectedQuestId: id, selectedAnswerId: undefined, activeType: 'questEdit', newQuestion: {...questions[id]} })
  }

  handleChangeNewQuestion = (event) => {
    const { target: { value, name } } = event;
    const {questions} = this.props;
    const {selectedQuestId, activeType, newQuestion} = this.state;

    if(activeType!== 'questAction'){
      questions[selectedQuestId][name] = value;
      this.props.refreshQuestions(questions);
    }else{
      newQuestion[name] = value;
      this.setState({newQuestion})
    }
  }

  handleChangeNewQuestionMinMax = (event) => {
    const { target: { value, name } } = event;
    const {questions} = this.props;
    const {selectedQuestId, activeType, newQuestion} = this.state;

    if(activeType!== 'questAction'){
      questions[selectedQuestId].answers[0][name] = value;
      this.props.refreshQuestions(questions);
    }else{
      newQuestion.answers[0][name] = value;
      this.setState({newQuestion})
    }
  }

  handleSaveEditQuest = (id) => {
    const { questions, newQuestion } = this.state
    
    if (questions[id]) {
      questions[id] = newQuestion;
      this.setState({ questions, selectedQuestId: undefined, selectedAnswerId: undefined, previewId: undefined }, () => this.props.refreshQuestions(this.state.questions))
    }
    this.resetQuestion();
  }

  handleClickEditAnswer = (questId, answerId) => {
    const questions = this.props.questions;

    this.setState({
      activeType: 'answerAction', 
      selectedQuestId: questId,
      selectedAnswerId: answerId,
      editableAnswer: questions[questId].answers[answerId],
      previewId: undefined
    })
  }

  handleChangeAnswer = editableAnswer => this.handleRefreshAnswer(editableAnswer);

  handleChangeImageAnswer = newAnswer => {
    const {activeType} = this.state;

    if(activeType === 'addAnswer'){
      this.setState({ editableAnswer: {...newAnswer} })
    }else{
      this.handleRefreshAnswer({...newAnswer});
    }
  }

  handleChangeEditAnswer = (event) => {
    const { target: { value, name } } = event
    const {editableAnswer, activeType} = this.state;
    const newAnswer = {...editableAnswer} || {};

    newAnswer[name] = value;

    if(activeType === 'addAnswer'){
      this.setState({ editableAnswer: newAnswer })
    }else{
      this.handleRefreshAnswer(newAnswer);
    }
  }

  handleChangeEditAnswerSwitch = (key, value) => {
    const {editableAnswer} = this.state;
    const newAnswer = editableAnswer || {};

    newAnswer[key] = value;
    this.handleRefreshAnswer(newAnswer);
  }

  handleClickNewAnswer = (questId) => {
    this.setState({
      activeType: 'addAnswer', 
      editableAnswer: null,
      selectedQuestId: questId,
      selectedAnswerId: undefined,
      previewId: undefined
    })
  }

  editAnswer = () => {
    const { questions, selectedQuestId, selectedAnswerId, editableAnswer} = this.state;
    questions[selectedQuestId].answers[selectedAnswerId] = editableAnswer;

    this.setState({activeType: undefined, questions, editableAnswer: null, selectedAnswerId: undefined}, () => this.props.handler(this.state.questions));
  }

  deleteAnswer = (questionId, answerId) => {
    const { questions } = this.state
    questions[questionId].answers.splice(answerId, 1);

    this.resetAnswer();
    this.setState({ questions }, this.props.refreshQuestions(this.state.questions))
  }

  addAnswer = () => {
    const {editableAnswer, selectedQuestId} = this.state;
    const {questions} = this.props;

    editableAnswer.skip_to = 'next'
    questions[selectedQuestId].answers.splice(questions[selectedQuestId].answers.length, 0, editableAnswer);

    this.resetAnswer();
    this.props.refreshQuestions(questions);
  }

  resetAnswer = () => {
    this.setState({activeType: undefined, editableAnswer: null, selectedAnswerId: undefined, selectedQuestId: undefined})
  }

  render() {
    const {
      isOpenModalNewQuestion,
      newQuestion,
      previewId,
      logicFilter,
      filteredQuestions,
      isVisible,
      isLoad,
      displayPickerBackgroundColor,
      displayPickerTextColor,
      displayPicketButtonColor,
      newQuestion: {button_color, text_color, background_color},
      displayPickerButtonColor,
      auto_time,
      showAll,
      activeType,
      selectedAnswerId,
      selectedQuestId,
      uploadedImageType,
    } = this.state;

    const {questions, handleCreateQuiz} = this.props;
    const { results } = this.props.data;

    const selectedQuest = activeType === 'questAction' ? newQuestion : selectedQuestId!==undefined && questions && questions[selectedQuestId];
    const editableAnswer = activeType === 'addAnswer' ? this.state.editableAnswer : selectedQuest && selectedQuest.answers[selectedAnswerId];

    return (
      <fieldset className="questions-step">
        <div className="row">
          <div className="col-md-6 left-side">
            <div>
              <div id="controlPanel">
                {!logicFilter.isEnable && (
                  <div style={{width: '130px' }}>
                    <button onClick={this.toggleModalNewQuestion} type="button" className="btn btn-create block btn-md" data-toggle="modal" data-target="#newquestion">
                      <i class="fas fa-plus"></i>
                      <FormattedMessage id="step3.addNewQuestion" defaultMessage="Добавить новый вопрос" />
                    </button>
                  </div>
                )}
                {questions.length ? <div className={`get-show-cards ${showAll ? 'show' : ''}`} onClick={() => this.collapseAll(!showAll)}>
                  <i class="fas fa-chevron-up"></i>
                  <span>{questions ? 'Свернуть все' : 'Показать все '}</span>
                </div> : null}
              </div>
              {!logicFilter.isEnable && (
              <div id="left-handles" className="align-self-start">
                    <SortableList
                      useWindowAsScrollContainer={true}
                      items={questions.map((question, index) => (
                        <Question
                          id={index}
                          previewId={previewId}
                          discountUnit={!this.props.data.discount_units ? '%' : this.props.data.discount_units}
                          title={question.question_title}
                          correct_text={question.correct_text}
                          incorrect_text={question.incorrect_text}
                          desc={question.question_description}
                          kind={question.question_kind}
                          allow_multiple={question.allow_multiple}
                          imagePlacement={question.image_placement}
                          allowMultiple={question.allow_multiple}
                          question_options_list={question.question_options_list}
                          image={question.question_side_image}
                          answers={question.answers}
                          isCollapsed={question.isCollapsed}
                          selectedQuestId={selectedQuestId}
                          selectedAnswerId={selectedAnswerId}
                          collapse={this.collapse}
                          copyQuestion={this.copyQuestion}
                          deleteQuestion={this.deleteQuestion}
                          deleteAnswer={this.deleteAnswer}
                          reorderAnswers={this.reorderAnswers}
                          getPreview={this.handelClickEditQuest}
                          questions={questions}
                          setSkipTo={this.setSkipTo}
                          setWeight={this.setWeight}
                          setMinMax={this.setMinMax}
                          setResultBinding={this.setResultBinding}
                          addAnswer={this.addAnswer}
                          editAnswer={this.editAnswer}
                          editQuestion={this.editQuestion}
                          results={results}
                          handleEditAnswer={this.handleClickEditAnswer}
                          handleClickNewAnswer={this.handleClickNewAnswer}
                          motivatorData={{
                            button_color: question.button_color,
                            background_color: question.background_color,
                            image_placement: question.image_placement,
                            text_color: question.text_color,
                            auto_time: question.auto_time,
                            question_side_image: question.question_side_image,
                            question_background_image: question.question_background_image
                          }}
                        />
                      ))}
                      onSortEnd={this.reorderQuestions}
                      useDragHandle={true}
                    />
                    <div id="scrollTo"></div>
                  </div>
              )}
              {logicFilter.isEnable && (
                <div>
                  {filteredQuestions.map((question, questionId) => {
                    if (question.skip_to === 'result') {
                      return (<div>РЕЗУЛЬТАТ</div>)
                    }
                    if (question.skip_to === 'lead_form') {
                      return (<div>ФОРМА СБОРА</div>)
                    }
                    if (question.question_kind === 'option' || question.question_kind === 'option_image') {
                      return (
                        <div
                          key={questionId}
                          className="card box-shadow-0 border-blue-grey bg-blue-grey bg-lighten-4 border-blue-grey border-accent-1"
                        >
                          <div className="card-content">
                            <div className="card-body">
                              <h4 className="card-title">{question.question_title}</h4>
                              <p>{question.question_description}</p>
                              {question.question_image &&
                              (<img
                                  className="media-object round-image"
                                  src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${question.question_image}`}
                                  alt="" />
                              )}
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                {question.answers.map((answer, answerId) => {
                                  return (
                                    <div key={answerId}>
                                      {answer.answer_title && (
                                        <div
                                          className="card box-shadow-0 border-blue-grey border-accent-1 bg-blue-grey bg-accent-1"
                                          onClick={() => this.changeStepQuestion(answer.skip_to, answer.result_binding)}
                                          style={{ marginLeft: '20px', marginRight: '20px', cursor: 'pointer' }}
                                        >
                                          <div className="card-header">
                                            <h4 className="card-title question-title">{answer.answer_title}</h4>
                                            <p>{answer.answer_description}</p>
                                            {answer.answer_image && (
                                              <img
                                                className="media-object round-image"
                                                src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${answer.answer_image}`}
                                                alt="" />
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div
                          key={questionId}
                          className="card box-shadow-0 border-blue-grey bg-blue-grey bg-lighten-4 border-blue-grey border-accent-1"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.changeStepQuestion(question.answers[0].skip_to)}
                        >
                          <div className="card-content">
                            <div className="card-body">
                              <h4 className="card-title question-title">{question.question_title}</h4>
                              <p>{question.question_description}</p>
                              {question.question_image &&
                              (<img
                                  className="media-object round-image"
                                  src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${question.question_image}`}
                                  alt="" />
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    }

                  })}
                </div>
              )}
              {!questions.length && activeType === 'questAction' && 
                <div className="notify">
                  <span>Новый вопрос</span>
                  <p>Укажите необходимые данные и нажмите добавить</p>
                </div>
              }
            </div>
          </div>

          <div id="toast-container" className="toast-container toast-top-right" style={{ display: 'none' }}>
            <div className="toast toast-error" aria-live="assertive">
              <div className="toast-title">Timeout!</div>
              <div className="toast-message">I do not think that word means what you think it means.</div>
            </div>
          </div>


          <div className="col-md-6 right-side">
            {
            activeType === 'questAction' || activeType==='questEdit' ? 
              <QuestAction 
                newQuestion={selectedQuest}
                isLoad={isLoad}
                text_color={text_color}
                background_color={background_color}
                button_color={button_color}
                displayPickerButtonColor={displayPickerButtonColor}
                displayPickerTextColor={displayPickerTextColor}
                displayPickerBackgroundColor={displayPickerBackgroundColor}
                auto_time={auto_time}
                activeType={activeType}
                selectedQuestId={selectedQuestId}
                handleSubmit={activeType === 'questAction' ? this.addQuestion : this.handleSaveEditQuest} 
                handleCheck={this.handleCheck}
                handleCancel={this.resetQuestion}
                handleChangeNewQuestion={this.handleChangeNewQuestion}
                handleSaveEditQuest={this.handleSaveEditQuest}
                getImage={this.getImage}
                deleteImage={this.deleteImage}
                changePickerButtonColor={this.changePickerButtonColor}
                changePickerTextColor={this.changePickerTextColor}
                changePickerBackgroundColor={this.changePickerBackgroundColor}
                handleSwitchAutoTime={this.handleSwitchAutoTime}
                onChangeAutoTime={this.onChangeAutoTime}
                refreshQuestions={this.handleResetQuestion}
                handleChangeNewQuestionMinMax={this.handleChangeNewQuestionMinMax}
                uploadedImageType={uploadedImageType}
              />

            : (activeType === 'answerAction' || activeType === 'addAnswer') ? 
              <AnswerAction
                selectedQuest={selectedQuest}
                selectedQuestId={selectedQuestId}
                selectedAnswerId={selectedAnswerId}
                editableAnswer={editableAnswer}
                imageSize={imageSize}
                activeType={activeType}
                cancelEditAnswer={this.resetAnswer}
                submitAnswer={activeType === 'addAnswer' ? this.addAnswer : this.editAnswer}
                handleChangeEditAnswer={this.handleChangeEditAnswer}
                addAnswer={this.addAnswer}
                getImage={this.getImage}
                handleChangeEditAnswerSwitch={this.handleChangeEditAnswerSwitch}
                handleChangeAnswer={this.handleChangeAnswer}
                handleChangeImageAnswer={this.handleChangeImageAnswer}
              />

            : null
            } 
          </div>
        </div>
      </fieldset>
    )
  }
}

const mapStateToProps = state => ({
  handleCreateQuiz: state.quizReducer.createQuiz,
})

export default connect(mapStateToProps)(Step3);
