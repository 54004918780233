const pro_rates = { // тарифы
  0: { // 30 дней
    20: 750,   // 50
    30: 1000,  // 100
    40: 2000,  // 250
    50: 3000,  // 500
    60: 5000,  // 1000
  },
  30: { // 90 дней
    20: 1250,   // 50
    30: 1500,  // 100
    40: 2500,  // 250
    50: 4000,  // 500
    60: 7000,  // 1000
  },
  60: { // 182 дня
    20: 1750,   // 50
    30: 2000,  // 100
    40: 3500,  // 250
    50: 6000,  // 500
    60: 8000,  // 1000
  },
  100: { // 365 дней
    20: 2000,   // 50
    30: 3000,  // 100
    40: 6000,  // 250
    50: 9000,  // 500
    60: 15000,  // 1000
  },
}

export default pro_rates
