import React from 'react'
import { Link, Redirect } from 'react-router-dom'

class Empty extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Redirect to="/create-quiz-from-scratch" />
    )
  }
}

export default Empty
