const initialState = {
  selectedLeads: [],
  page: 1,
  leads: null,
  countPage: 0,
  quizFilter: '',
  loading: true
}

const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_LEAD': {
      return { ...state, selectedLeads: action.payload }
    }
    case 'RESET_LEADS': {
      return { ...state, selectedLeads: [] }
    }
    case 'RESET_ALL_LEADS': {
      return { ...state, leads: [], selectedLeads: [] }
    }
    case 'REQUEST_LEADS_UPDATE': {
      return { ...state, page: action.payload }
    }
    case 'LOADING_LEADS': {
      return { ...state, loading: action.payload.loading }
    }
    case 'RESET_LEADS_STATE': {
      return { ...initialState }
    }
    case 'SET_NEW_STATE': {
      return {
        ...state,
        leads: action.payload.leads,
        countPage: action.payload.countPage,
        quizFilter: action.payload.quizFilter,
        page: action.payload.page,
        loading: true
      }
    }
    default: {
      return state
    }
  }
}

export default leadReducer
