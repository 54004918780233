const config = require('../config/config')

class RecoveryService {
  static async checkEmail(data) {
    console.log(data)
    try {
      const body = {
        service: 'go.micro.srv.userd',
        method: 'UsersDirectory.SendResetLink',
        request: data,
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) {
        throw new Error('recoveryPage.errorWrongEmail')
      }

      return true
    } catch (error) {
      throw error
    }
  }

  static async changePassword(data) {
    try {
      const body = {
        service: 'go.micro.srv.userd',
        method: 'UsersDirectory.ResetPassword',
        request: {
          new: data.password,
          new_again: data.passwordConfirm,
          hash: data.hash,
        },
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) {
        response = await response.json()
        switch (response.detail) {
          case 'Passwords don\'t match':
            throw new Error('recoveryPage.errorPasswordsDontMatch')
          case 'Password is too short':
            throw new Error('recoveryPage.errorPasswordToShort')
          case 'Your link is expired. Please try reset again.':
            throw new Error('recoveryPage.errorWrongHash')
          default:
            throw new Error(response.detail)
        }
      }

      return true
    } catch (error) {
      throw error
    }
  }
}

export default RecoveryService
