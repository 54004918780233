import React from 'react'
import { Button } from 'reactstrap'
import jdenticon from 'jdenticon'
import swal from 'sweetalert'
import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
import { AuthService } from '../../services'
import { Intl } from '../../utils'
import * as userInfoActions from '../../actions/userInfoActions'
import * as emailProfileActions from '../../actions/emailProfile'
import ProfileCard from '../../components/ProfileCard'
import { Tooltip } from '../../components'

import './ProfilePage.scss'

class ProfilePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      request: this.props.userInfo,
      message: '',
      messagePass: '',
      formChangeInfo: true,
      formChangePass: false,
      formEmailProfile: false,
      requestPass: {
        old: '',
        newPass: '',
        new_again: '',
      },
      is_confirmed: true,
      is_confirm_send: false,

      emailService: 'yandex',
      emailUsername: null,
      emailPass: null,
      emailSender: null,
      emailReplyTo: null,
      emailServer: null,
      emailPort: null,
      emailProfileList: [],
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChangePass = this.handleChangePass.bind(this)
    this.showFormEmailProfile = this.showFormEmailProfile.bind(this)
    this.updateUserDetail = this.updateUserDetail.bind(this)
    this.updateUserPass = this.updateUserPass.bind(this)
    this.handleChangeEmailService = this.handleChangeEmailService.bind(this)
    this.handleChangeUsername = this.handleChangeUsername.bind(this)
    this.handleChangeEmailPass = this.handleChangeEmailPass.bind(this)
    this.handleChangeSenderName = this.handleChangeSenderName.bind(this)
    this.handleChangeReplyTo = this.handleChangeReplyTo.bind(this)
    this.handleChangeEmailServer = this.handleChangeEmailServer.bind(this)
    this.handleChangeEmailPort = this.handleChangeEmailPort.bind(this)
    this.handleSubmitEmailProfile = this.handleSubmitEmailProfile.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ request: nextProps.userInfo })
  }

  componentDidMount() {
    const { getListEmailProfile } = this.props
    getListEmailProfile()
  }

  // async componentDidMount() {
  //   // try {
  //   //   const { user: { name, email, allow_promos, is_confirmed } } = await AuthService.getUserInfo()
  //   //   const request = {
  //   //     name,
  //   //     email,
  //   //     allow_promos,
  //   //   }
  //   //   this.setState({ request, is_confirmed })
  //   // } catch (error) {
  //   //   console.log(error)
  //   // }
  // }

  handleChange(event) {
    const { target: { value, name } } = event
    const { request } = this.state
    request[name] = value
    this.setState({ request, message: '' })
  }

  handleChangePass(event) {
    const { target: { value, name } } = event
    const { requestPass } = this.state
    requestPass[name] = value
    this.setState({ requestPass, messagePass: '' })
  }

  handleCheck() {
    const { request } = this.state
    request.allow_promos = !request.allow_promos
    this.setState({ request })
  }

  async updateUserDetail(e) {
    const { setUserInfo } = this.props
    e.preventDefault()
    try {
      const { request } = this.state
      const result = await AuthService.changeUserDetail(request)
      if (!result.detail) {
        // document.getElementById('user-name').innerHTML = request.name
        // document.getElementById('user-avatar').setAttribute('data-jdenticon-value', request.name)
        swal({
          title: Intl.getMessage('profilePage.saved'),
          icon: 'info',
        })
        setUserInfo(request)
        jdenticon()
      } else {
        const message = result.detail
        if (message === 'Not valid email') this.setState({ message: Intl.getMessage('profilePage.notValidEmail') })
        if (message === 'This email already exists') this.setState({ message: Intl.getMessage('profilePage.emailExists') })
      }
    } catch (error) {
      console.log(error)
    }
  }

  async updateUserPass(e) {
    e.preventDefault()
    try {
      const { requestPass } = this.state
      if (requestPass.newPass !== requestPass.new_again) {
        this.setState({ messagePass: Intl.getMessage('profilePage.passDontMatch') })
      } else {
        const request = {
          old: requestPass.old,
          new: requestPass.newPass,
          new_again: requestPass.new_again,
        }
        const result = await AuthService.changeUserPassword(request)
        if (result) {
          swal({
            title: Intl.getMessage('profilePage.passChanged'),
            icon: 'info',
          })
          this.setState({ formChangePass: false, requestPass: {} })
        } else {
          this.setState({ messagePass: Intl.getMessage('profilePage.wrongPass') })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async confirmEmail() {
    try {
      const confirm = await AuthService.checkEmail()
      if (confirm) this.setState({ is_confirm_send: true })
    } catch (error) {
      console.log(error)
    }
  }

  showFormChangeInfo() {
    this.setState({
      formChangePass: false,
      formEmailProfile: false,
      formChangeInfo: true,
    })
  }

  showFormChangePass() {
    this.setState({
      formChangePass: true,
      formEmailProfile: false,
      formChangeInfo: false,
    })
  }

  async showFormEmailProfile() {
    this.setState({
      formEmailProfile: true,
      formChangePass: false,
      formChangeInfo: false,
    })

    // let emailProfileList = null
    // try {
    //   emailProfileList = await EmailProfileService.getList()
    // } catch (e) {
    //   console.log(e)
    // }
    //
    // if (emailProfileList) {
    //   this.setState({
    //     emailProfileList,
    //   })
    // }
  }

  handleChangeEmailService(event) {
    this.setState({
      emailService: event.target.value,
    })
  }

  handleChangeUsername(event) {
    this.setState({
      emailUsername: event.target.value,
    })
  }

  handleChangeEmailPass(event) {
    this.setState({
      emailPass: event.target.value,
    })
  }

  handleChangeSenderName(event) {
    this.setState({
      emailSender: event.target.value,
    })
  }

  handleChangeReplyTo(event) {
    this.setState({
      emailReplyTo: event.target.value,
    })
  }

  handleChangeEmailServer(event) {
    this.setState({
      emailServer: event.target.value,
    })
  }

  handleChangeEmailPort(event) {
    this.setState({
      emailPort: event.target.value,
    })
  }

  async handleSubmitEmailProfile(event) {
    event.preventDefault()

    const {
      emailService, emailUsername, emailPass, emailSender, emailReplyTo, emailServer, emailPort,
    } = this.state

    const params = {
      profile_name: emailService,
      username: emailUsername,
      passwd: emailPass,
      server: emailServer,
      port: emailPort,
      sender_name: emailSender,
      reply_to: emailReplyTo,
    }

    const { createEmailProfile } = this.props
    createEmailProfile(params)

    swal({
      text: 'Профиль успешно создан',
      icon: 'success',
    })

    return true
  }

  render() {
    const {
      request: {
        name, email, allow_promos, is_confirmed,
      }, message, messagePass, is_confirm_send, formChangePass, formEmailProfile, requestPass: { old, newPass, new_again },
      emailService, emailUsername, emailPass, emailSender, emailReplyTo, emailServer, emailPort, formChangeInfo
    } = this.state

    const { emailProfileList, deleteEmailProfile } = this.props

    let showEmailProfileList = null

    if (emailProfileList) {
      showEmailProfileList = emailProfileList.length ? true : null
    }

    return (
      <div className="profile-page">
        {(!is_confirmed && !is_confirm_send) && (
        <div className="danger-content callout-border-left mt-1 p-1" style={{ width: '95%' }}>
          <i class="fas fa-exclamation-triangle"></i>
          <span style={{marginRight: '4px'}}>{Intl.getMessage('profilePage.notConfirmedTitle')}</span>
          <p>
            {Intl.getMessage('profilePage.notConfirmedText')}
            {' '}
            <b onClick={() => this.confirmEmail()} style={{ cursor: 'pointer' }}>{Intl.getMessage('profilePage.sendAgain')}</b>
          </p>
        </div>
        )}
        <div className="btn-group">
          <Button className={`commonBtn ${formChangeInfo ? 'active' : ''}`}onClick={() => this.showFormChangeInfo()}>{Intl.getMessage('profilePage.information')}</Button>
          <Button className={`commonBtn ${formChangePass ? 'active' : ''}`}onClick={() => this.showFormChangePass()}>{Intl.getMessage('profilePage.changePass')}</Button>
          <Button className={`commonBtn ${formEmailProfile ? 'active' : ''}`}onClick={() => this.showFormEmailProfile()}>{Intl.getMessage('profilePage.addEmailProfile')}</Button>
        </div>
        {(!is_confirmed && is_confirm_send) && (
        <div className="bs-callout-success callout-border-left mt-1 p-1" style={{ width: '600px' }}>
          <strong>{Intl.getMessage('profilePage.confirmedTitle')}</strong>
          <p>{Intl.getMessage('profilePage.confirmedText')}</p>
        </div>
        )}
        <br />
        <div className="profile-container">
          <div className="profile-form-container">
            <div className="profile-form">
              <div className="card-content show">
                {formChangeInfo && (
                  <form action="" method="POST" onSubmit={this.updateUserDetail}>
                    <div className="form-group">
                      <label htmlFor="firstName1">
                        {Intl.getMessage('profilePage.name')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={name}
                        placeholder={Intl.getMessage('profilePage.name')}
                        name="name"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="firstName2">
                      E-MAIL
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        name="email"
                        placeholder="Email"
                        required
                        onChange={this.handleChange}
                      />
                      <div style={{ color: 'red', paddingBottom: '10px' }}>
                        {message && (<div>{message}</div>)}
                      </div>
                    </div>
                    {/* <fieldset className="skin skin-square" onClick={() => this.handleCheck()} style={{ cursor: 'pointer' }}>
                      <br />
                      <div className={`icheckbox_square-red ${allow_promos ? 'checked' : ''}`} style={{ position: 'relative' }}>
                        <input type="checkbox" id="input-12" checked="" style={{ position: 'absolute', opacity: '0' }} />
                      </div>
                      {Intl.getMessage('profilePage.allowPromos')}
                    </fieldset> */}
                    <div className="form-group">
                      <div className="row">
                        <Button className="commonBtn" type="submit" className="btn-create">{Intl.getMessage('profilePage.save')}</Button>
                      </div>
                    </div>
                  </form>
                )}
                {formChangePass && (
                <form action="" method="POST" onSubmit={e => this.updateUserPass(e)}>
                  <div>
                    <div className="form-group">
                      <div style={{ color: 'red', paddingBottom: '10px' }}>
                        {messagePass && (<div>{messagePass}</div>)}
                      </div>
                      <label htmlFor="firstName1">
                        {Intl.getMessage('profilePage.oldPass')}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="old"
                        value={old}
                        placeholder={Intl.getMessage('profilePage.oldPass')}
                        name="old"
                        required
                        minLength="6"
                        onChange={this.handleChangePass}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="firstName1">
                        {Intl.getMessage('profilePage.newPass')}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="newPass"
                        value={newPass}
                        placeholder={Intl.getMessage('profilePage.newPass')}
                        name="newPass"
                        required
                        minLength="6"
                        onChange={this.handleChangePass}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="firstName1">
                        {Intl.getMessage('profilePage.newPassAgain')}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="new_again"
                        value={new_again}
                        placeholder={Intl.getMessage('profilePage.newPassAgain')}
                        name="new_again"
                        required
                        minLength="6"
                        onChange={this.handleChangePass}
                      />
                    </div>
                    <Button type="submit" className="btn-create">{Intl.getMessage('profilePage.updatePass')}</Button>
                  </div>
                </form>
                )}
                {
                  formEmailProfile && (
                    <form method="POST" onSubmit={this.handleSubmitEmailProfile}>
                      <div className="form-group">
                        <label>{Intl.getMessage('profilePage.emailService')}</label>
                        <select className="form-control" value={emailService} onChange={this.handleChangeEmailService}>
                          <option value="yandex">Yandex</option>
                          <option value="gmail">Gmail</option>
                          <option value="custom">Другой</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>
                          {Intl.getMessage('profilePage.userName')} <Tooltip header={Intl.getMessage('profilePage.tooltipUsernameTitle')} body={Intl.getMessage('profilePage.tooltipUsername')} target="id1" />
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={emailUsername}
                          placeholder={Intl.getMessage('profilePage.userName')}
                          required
                          onChange={this.handleChangeUsername}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          {Intl.getMessage('authPage.password')}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={emailPass}
                          placeholder={Intl.getMessage('authPage.password')}
                          onChange={this.handleChangeEmailPass}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          {Intl.getMessage('profilePage.emailSender')} <Tooltip header={Intl.getMessage('profilePage.tooltipSenderNameTitle')} body={Intl.getMessage('profilePage.tooltipSenderName')} target="id2" />
                        </label>
                        <input
                          type="name"
                          className="form-control"
                          value={emailSender}
                          placeholder={Intl.getMessage('profilePage.emailSender')}
                          onChange={this.handleChangeSenderName}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          {Intl.getMessage('profilePage.emailReplyTo')} <Tooltip header={Intl.getMessage('profilePage.tooltipSenderTitle')} body={Intl.getMessage('profilePage.tooltipSender')} target="id3" />
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={emailReplyTo}
                          placeholder={Intl.getMessage('profilePage.emailReplyTo')}
                          onChange={this.handleChangeReplyTo}
                          required
                        />
                      </div>
                      {
                        emailService === 'custom' && (
                          <React.Fragment>
                            <div className="form-group">
                              <label>
                                {Intl.getMessage('profilePage.emailServer')}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={emailServer}
                                placeholder={Intl.getMessage('profilePage.emailServer')}
                                name="server"
                                onChange={this.handleChangeEmailServer}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                {Intl.getMessage('profilePage.emailPort')}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                value={emailPort}
                                placeholder={Intl.getMessage('profilePage.emailPort')}
                                name="port"
                                onChange={this.handleChangeEmailPort}
                                min="0"
                                max="999999"
                                required
                              />
                            </div>
                          </React.Fragment>
                        )
                      }
                      <Button type="submit" className="btn-create">{Intl.getMessage('profilePage.create')}</Button>
                    </form>
                  )
                }
              </div>
            </div>
          </div>
          <div className="profils-info">
            {
              showEmailProfileList && formEmailProfile && emailProfileList.map(profile => (
                <ProfileCard
                  id={profile.id}
                  profileName={profile.profile_name}
                  userName={profile.username}
                  senderName={profile.sender_name}
                  replyTo={profile.reply_to}
                  deleteEmailProfile={deleteEmailProfile}
                />
              ))
            }
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  emailProfileList: state.emailProfile.emailProfileList,
})

const mapDispatchToProps = {
  getListEmailProfile: emailProfileActions.getList,
  createEmailProfile: emailProfileActions.create,
  deleteEmailProfile: emailProfileActions.remove,
  setUserInfo: userInfoActions.setUserInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
