import React from 'react';
import { Collapse, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'
import { FormattedMessage } from 'react-intl'
import { Intl } from '../../utils'
import { Tooltip } from '../../components'
const config = require('../../config/config')


const DragHandle = SortableHandle(() => (<i className="fas fa-bars" title={Intl.getMessage('step3.move')} style={{cursor: 'move'}}></i>));
const imageSize = '200,fit'


class Answer extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
        isLoad: false,
        dropDownOpen: false,
        dropDownOpenWeight: false,
        dropDownOpenWeightSub: false,
        isOpenModalMinMax: false,
        isOpenModalEditAnswer: false,
        isCheckedUIInput: false,
        minVal: '',
        maxVal: '',
        answer: {},
      }
  
      this.toggleDropDown = this.toggleDropDown.bind(this)
      this.toggleDropDownWeight = this.toggleDropDownWeight.bind(this)
      this.toggleDropDownWeightSub = this.toggleDropDownWeightSub.bind(this)
      this.deleteAnswer = this.deleteAnswer.bind(this)
      this.setSkipTo = this.setSkipTo.bind(this)
      this.setWeight = this.setWeight.bind(this)
      this.setResultBinding = this.setResultBinding.bind(this)
      this.toggleModalMinMax = this.toggleModalMinMax.bind(this)
      this.handleChangeMinMax = this.handleChangeMinMax.bind(this)
      this.editAnswer = this.editAnswer.bind(this)
      this.toggleModalEditAnswer = this.toggleModalEditAnswer.bind(this)
      this.editAnswer = this.editAnswer.bind(this)
      this.handleChangeDiscountInput = this.handleChangeDiscountInput.bind(this)
      this.handleCheckAnswer = this.handleCheckAnswer.bind(this)
      this.selectRightAnswer = this.selectRightAnswer.bind(this)
    }
  
    componentDidMount() {
      const {
        title, desc, answerFactor, minVal, maxVal, image, isCheckedUIInput, answerFactorDefault, discount_increase_by, discount_set, discount_action, custom, is_correct,
      } = this.props
      const answer = { ...this.state.answer }
      answer.answer_title = title
      answer.answer_description = desc
      answer.answer_factor = answerFactor
      answer.answer_factor_default = answerFactorDefault
      answer.answer_image = image
      answer.discount_increase_by = discount_increase_by
      answer.discount_set = discount_set
      answer.discount_action = discount_action
      answer.is_correct = is_correct
  
      if(discount_action === 'set_fixed') {
        answer.discount_set = discount_set
        delete answer.discount_increase_by
      }
  
      if(discount_action === 'increase') {
        answer.discount_increase_by = discount_increase_by
        delete answer.discount_set
      }
  
      if(discount_action === 'none') {
        delete answer.discount_set
        delete answer.discount_increase_by
      }
  
  
      answer.custom = custom
      this.setState({
        answer, minVal, maxVal, isCheckedUIInput,
      })
    }
  
    handleChangeDiscountInput(event, type) {
      const { target: { value, name } } = event
      const answer = { ...this.state.answer }
      answer[type] = value
      this.setState({ answer })
    }
  
    handleChangeDiscount(event) {
      const { target: { value, name } } = event
      const answer = { ...this.state.answer }
      answer[name] = value
      this.setState({ answer })
    }
  
    toggleDropDown() {
      const { dropDownOpen } = this.state
      this.setState({ dropDownOpen: !dropDownOpen })
    }
  
    componentWillReceiveProps(nextProps) {
      const {
        title, desc, answerFactor, image, answerFactorDefault, discount_increase_by, discount_set, discount_action, custom, is_correct,
      } = nextProps
      const answer = { ...this.state.answer }
      answer.answer_title = title
      answer.answer_description = desc
      answer.answer_factor = answerFactor
      answer.answer_factor_default = answerFactorDefault
      answer.answer_image = image
      answer.discount_action = discount_action
      answer.is_correct = is_correct
  
      if(discount_action === 'set_fixed') {
        answer.discount_set = discount_set
        delete answer.discount_increase_by
      }
  
      if(discount_action === 'increase') {
        answer.discount_increase_by = discount_increase_by
        delete answer.discount_set
      }
  
      if(discount_action === 'none') {
        delete answer.discount_set
        delete answer.discount_increase_by
      }
      answer.custom = custom
      this.setState({ answer })
    }
  
    toggleDropDownWeight() {
      const { dropDownOpenWeight } = this.state
      this.setState({ dropDownOpenWeight: !dropDownOpenWeight })
    }
  
    toggleDropDownWeightSub() {
      const { dropDownOpenWeightSub } = this.state
      this.setState({ dropDownOpenWeightSub: !dropDownOpenWeightSub })
    }
  
    deleteAnswer(questionId, answerId) {
      this.props.deleteAnswer(questionId, answerId)
    }
  
    editAnswer(questionId, answerId) {
      const { answer } = this.state
      const { question, handlerEditQuestion, kind } = this.props
      if ((kind === 'option' || kind === 'option_image' || kind === 'motivator') && !question.allow_multiple && answer.is_correct) {
        question.answers.forEach((item) => {
          if (item.answer_title !== answer.answer_title) {
            item.is_correct = false
          }
        })
  
        handlerEditQuestion(questionId, question)
      }
  
      this.props.editAnswer(questionId, answerId, answer)
      this.toggleModalEditAnswer()
    }
  
    handleCheckAnswer() {
      const { answer } = this.state
      answer['custom'] = !answer.custom
      this.setState({ answer })
    }
  
    selectRightAnswer() {
      const { answer } = this.state
      answer.is_correct = !answer.is_correct
      this.setState({ answer })
    }
  
    setSkipTo(questionId, answerId, skipTo) {
      this.props.setSkipTo(questionId, answerId, skipTo)
    }
  
    setWeight(questionId, answerId, weight) {
      this.props.setWeight(questionId, answerId, weight)
    }
  
    setResultBinding(questionId, answerId, resultBinding) {
      this.props.setResultBinding(questionId, answerId, resultBinding)
    }
  
    toggleModalMinMax() {
      const { isOpenModalMinMax } = this.state
      this.setState({ isOpenModalMinMax: !isOpenModalMinMax })
    }
  
    toggleModalEditAnswer() {
      const { isOpenModalEditAnswer } = this.state
      this.setState({ isOpenModalEditAnswer: !isOpenModalEditAnswer })
    }
  
    handleCheckUIInput() {
      const { isCheckedUIInput } = this.state
      this.setState({ isCheckedUIInput: !isCheckedUIInput })
    }
  
    handleChangeMinMax(event) {
      const { target: { value, name } } = event
      this.setState({
        [name]: value,
      })
    }
  
    setMinMax(questionId, answerId) {
      const { isCheckedUIInput, minVal, maxVal } = this.state
      const newMinMax = {
        isCheckedUIInput,
        minVal,
        maxVal,
      }
      this.props.setMinMax(questionId, answerId, newMinMax)
      this.setState({ isCheckedUIInput })
      this.toggleModalMinMax()
    }

    
  
    render() {
      const {
        dropDownOpen,
        dropDownOpenWeight,
        dropDownOpenWeightSub,
        isOpenModalMinMax,
        isCheckedUIInput,
        isOpenModalEditAnswer,
        answer,
        isLoad,
      } = this.state
      const {
        id,
        kind,
        title,
        desc,
        answerFactor,
        answerFactorDefault,
        resultBinding,
        weight,
        skipTo,
        questionId,
        required,
        questions,
        results,
        units,
        minVal,
        maxVal,
        image,
        allowMultiple,
        is_correct,
        handleEditAnswer,
        selectedAnswerId,
        selectedQuestId,
        className,
      } = this.props;

      const isHasImage = !!image && typeof image === 'string'
      
      return (
        <div 
          id="answer-side"
          className={`${(selectedQuestId===questionId && selectedAnswerId === id) ? 'active' : ''} ${className ? className : ''} card box-shadow-0  bg-blue-grey bg-lighten-3 quest-answer` }
          onClick={(e) => !e.target.closest('.heading-elements') && handleEditAnswer(questionId, id)
        }>
          {kind && (kind === 'option_image' || kind === 'motivator' ||  kind === 'option') && (
            <div className="card-header">
              <div className="answer-title-side">
                {(kind !== 'option_image') && (
                  <h4 className="card-title question-title">
                    {title}
                    {is_correct ? <i style={{ marginLeft: "10px", color: '#28d094' }} class="fas fa-check-circle"></i> : null}
                    {answer.custom ? <i style={{ marginLeft: "10px", color: "rgb(67, 3, 178)" }} class="fas fa-user-circle"></i> : null}
                  </h4>
                )}
                {kind === 'option_image' && !image && 'Нет изображения'}
              </div>
              {/* <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a> */}
              <div className="heading-elements">
                <ul className="list-inline mb-0">
                  <li><a data-action="close"><DragHandle /></a></li>
                  <li title={Intl.getMessage('step3.delete')} onClick={() => this.deleteAnswer(questionId, id)}><a data-action="close"><i class="fas fa-times"></i></a></li>
                </ul>
              </div>
            </div>
          )}
          <div className="card-content collapse show">
            <div className="card-body">
              {isHasImage && (<img src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${image.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net")}`} className="media-left round-image" alt="" />)}
              {(true) && (
              <div className="body-footer">
                {!allowMultiple && (
                <Dropdown
                  direction="up"
                  isOpen={dropDownOpen}
                  toggle={this.toggleDropDown}
                  className="btn-group dropup mr-1 mb-1  float-right"
                >
                  <DropdownToggle type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup={true} aria-expanded="false">
                      <span><b>Переход: </b> 
                        {skipTo === 'next' ? 'следующий вопрос' : skipTo==='result' ? 'результат' : skipTo==='lead_form' ? 'форма сбора контактов' : `Переход: Вопрос ${skipTo}`}
                      </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu open-left arrow" x-placement="top-start" style={{
                    position: 'absolute',
                    transform: 'translate3d(0px, -24px, 0px)',
                    top: '0px',
                    left: '0px',
                    willChange: 'transform',
                  }}>
                    <DropdownItem onClick={() => this.setSkipTo(questionId, id, 'next')} className={`dropdown-item ${(skipTo === 'next') ? 'active' : ''}`} type="button"> <i className="mr-1 ft-arrow-right"></i>
                      <FormattedMessage id="step3.next" defaultMessage="Переход на след. вопрос" />
                    </DropdownItem>
                    <DropdownItem divider/>
                    <DropdownItem onClick={() => this.setSkipTo(questionId, id, 'result')} className={`dropdown-item ${(skipTo === 'result') ? 'active' : ''}`} type="button"> <i className="mr-1 ft-star"></i>
                      <FormattedMessage id="step3.showBindResult" defaultMessage="Показать связанный результат" />
                    </DropdownItem>
                    <DropdownItem divider/>
                    <DropdownItem onClick={() => this.setSkipTo(questionId, id, 'lead_form')} className={`dropdown-item ${(skipTo === 'lead_form') ? 'active' : ''}`} type="button"><i className="mr-1 ft-at-sign"></i>
                      <FormattedMessage id="step3.leadForm" defaultMessage="Переход к форме сбора контактов" />
                    </DropdownItem>
                    <DropdownItem divider/>
                    <DropdownItem header className="dropdown-header">
                      <FormattedMessage id="step3.questionNumber" defaultMessage="Переход на вопрос:" />
                    </DropdownItem>
  
                    {questions.slice(questionId + 1).map((question, index) => {
                      return (
                        <DropdownItem key={index} className={`dropdown-item ${(skipTo === (questionId + index + 2)) ? 'active' : ''}`} type="button" onClick={() => this.setSkipTo(questionId, id, questionId + index + 2)}>
                        {skipTo === (questionId + index + 2) && (<i className="mr-1 ft-check-square"></i>)}
                        {skipTo !== (questionId + index + 2) && (<i className="mr-1 ft-square"></i>)}
                        <div className="pruneDropDownAnswer">{`Вопрос ${questionId + index + 2}: ${question.question_title}`}</div>
                      </DropdownItem>)
                    })}
                  </DropdownMenu>
                </Dropdown>
              )}
                {(kind === 'option' || kind === 'option_image' || kind === 'motivator') && (<Dropdown
                direction={'up'}
                isOpen={dropDownOpenWeight}
                toggle={this.toggleDropDownWeight}
                className="btn-group dropup mr-1 mb-1  float-right"
              >
                <DropdownToggle type="button" className="btn btn-blue dropdown-toggle" data-toggle="dropdown" aria-haspopup={true} aria-expanded="false">
                  <span>
                    <b>Результат: </b>
                    {resultBinding === undefined && weight}
                    {resultBinding !== undefined && weight && (resultBinding + 1 + 'вес' + weight)}
                    {resultBinding !== undefined && !weight && (resultBinding + 1)}
                    {resultBinding === undefined && !weight && ('не задан')}
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu open-left arrow"
                  x-placement="top-start"
                  style={{
                  position: 'absolute',
                  transform: 'translate3d(0px, -24px, 0px)',
                  top: '0px',
                  left: '0px',
                  willChange: 'transform',
                  }}
                >
                  <DropdownItem className="dropdown-item" type="button" onClick={() => this.setResultBinding(questionId, id, 'not')}>
                    {resultBinding === undefined &&
                    (<span><i className="mr-1 ft-check-square"></i>
                      <FormattedMessage id="step3.withoutResult" defaultMessage="Не задавать результат" />
                    </span>)}
                    {resultBinding !== undefined &&
                    (<span><i className="mr-1 ft-square"></i>
                       <FormattedMessage id="step3.withoutResult" defaultMessage="Не задавать результат" />
                    </span>)}
                  </DropdownItem>
                  <DropdownItem divider />
                  {results.map((result, index) => {
                    if (result) {
                      return (
                        <DropdownItem key={index} className="dropdown-item" type="button" onClick={() => this.setResultBinding(questionId, id, index)}>
                          {resultBinding === index && (<i className="mr-1 ft-check-square"></i>)}
                          {resultBinding !== index && (<i className="mr-1 ft-square"></i>)}
                          {(result.result_title) ? result.result_title : result.result_url}
                        </DropdownItem>
                      )
                    }
                  })}
                  {(results.length > 0) && (<DropdownItem divider />)}
                  <Dropdown
                    direction={'left'}
                    isOpen={dropDownOpenWeightSub}
                    toggle={this.toggleDropDownWeightSub}
                    className="dropdown-submenu"
                  >
                    <DropdownToggle className="dropdown-item" type="button">
                      <FormattedMessage id="step3.setWeightAnswer" defaultMessage="Задать вес ответа" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu open-left arrow-right" role="menu">
                      {[10, 20, 30, 40, 50, 60, 70].map((number) => {
                        return (
                          <DropdownItem key={number} className={`dropdown-item ${weight === number ? 'active' : ''}`} type="button" onClick={() => this.setWeight(questionId, id, number)}>
                            {weight === number && (<i className="mr-1 ft-check-square"></i>)}
                            {weight !== number && (<i className="mr-1 ft-square"></i>)}
                            {number}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </DropdownMenu>
              </Dropdown>)}
              </div>
              )}
            </div>
          </div>
        </div>
      )
    }
  }

  export default Answer;
  