import { AuthService } from './index'

const config = require('../config/config')

class QuizzService {
  static async getImages(hash, rev_id) {
    try {
      const response = await fetch(`https://upload.enquiz.io/full/${hash}/${rev_id}`)
        .then((response) => { return response.json()})
      return await response
    } catch (err) {
      console.log('err', err)
    }
  }

  static async getListQuizzes(data) {
    try {
      const request = {
        page: data.page,
        limit: data.limit,
        query: data.query,
      }

      const body = {
        service: 'go.micro.srv.quiz',
        method: 'Quiz.GetQuizList',
        request
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) return false
      response = await response.json()

      return response.quiz_list
    } catch (error) {
      throw error
    }
  }

  static async createQuizz(data) {
    try {
      const body = {
        service: 'go.micro.srv.quiz',
        method: 'Quiz.SaveQuiz',
        request: {
          state: 'published',
          notifications: JSON.stringify(data.notifications),
          json_data: JSON.stringify(data.json),
        },
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) return false

      return true
    } catch (error) {
      throw error
    }
  }

  static async getQuizz(unique_hash) {
    const body = {
      service: 'go.micro.srv.quiz',
      method: 'Quiz.GetQuiz',
      request: {
        hash: unique_hash,
      },
    }
    let response = await fetch(config.API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(body),
    })

    response = await response
    if (response.status !== 200) return false

    response = await response.json()

    return response
  }

  static async updateQuizz(data) {
    try {
      const body = {
        service: 'go.micro.srv.quiz',
        method: 'Quiz.SaveQuiz',
        request: {
          update_hash: data.unique_hash,
          state: data.state,
          notifications: JSON.stringify(data.notifications),
          json_data: JSON.stringify(data.json),
        },
      }
      let response = await fetch(config.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: AuthService.getToken(),
        },
        body: JSON.stringify(body),
      })

      response = await response
      if (response.status !== 200) return false
      return true
    } catch (error) {
      throw error
    }
  }

  static async deleteQuizz(unique_hash) {
    const body = {
      service: 'go.micro.srv.quiz',
      method: 'Quiz.ChangeQuizState',
      request: {
        hash: unique_hash,
        state: 'archived',
      },
    }
    let response = await fetch(config.API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(body),
    })

    response = await response
    if (response.status !== 200) return false

    return true
  }

  static async unPublishQuiz(unique_hash) {
    const body = {
      service: 'go.micro.srv.quiz',
      method: 'Quiz.ChangeQuizState',
      request: {
        hash: unique_hash,
        state: 'draft',
      },
    }
    let response = await fetch(config.API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(body),
    })

    response = await response
    if (response.status !== 200) return false

    return true
  }

  static async publishQuiz(unique_hash) {
    const body = {
      service: 'go.micro.srv.quiz',
      method: 'Quiz.ChangeQuizState',
      request: {
        hash: unique_hash,
        state: 'published',
      },
    }
    let response = await fetch(config.API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(body),
    })

    response = await response
    if (response.status !== 200) return false

    return true
  }

  static async setQuizEndpoint(unique_hash, path) {
    const body = {
      service: 'go.micro.srv.quiz',
      method: 'Quiz.SetQuizEndpoint',
      request: {
        hash: unique_hash,
        endpoint: path,
      },
    }
    let response = await fetch(config.API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(body),
    })

    response = await response

    const { detail } = await response.json()

    if (response.status !== 200) return { status: false, detail }

    return { status: true }
  }

  static async deleteQuizEndpoint(unique_hash) {
    const body = {
      service: 'go.micro.srv.quiz',
      method: 'Quiz.DeleteQuizEndpoint',
      request: {
        hash: unique_hash,
      },
    }
    let response = await fetch(config.API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(body),
    })

    response = await response
    if (response.status !== 200) return false

    return true
  }

  static async sendToSpecialist(unique_hash) {
    const body = {
      service: 'go.micro.srv.quiz',
      method: 'Quiz.SendToSpecialist',
      request: {
        hash: unique_hash,
      },
    }
    let response = await fetch(config.API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(body),
    })

    response = await response
    if (response.status !== 200) return false

    return true
  }

  static async sendToRequestDomain(data) {
    const { hash, domain } = data
    const body = {
      service: 'go.micro.srv.quiz',
      method: 'Quiz.RequestDomain',
      request: {
        hash,
        domain,
      },
    }
    let response = await fetch(config.API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(body),
    })

    response = await response
    if (response.status !== 200) return false

    return true
  }

  static async changeTelegramSettings(data) {
    const { hash, chatbot_settings } = data
    const body = {
      service: 'go.micro.srv.quiz',
      method: 'Quiz.UpdateChatbotSettings',
      request: {
        chatbot_settings: chatbot_settings,
        hash: hash,
      },
    }
    let response = await fetch(config.API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(body),
    })

    response = await response
    if (response.status !== 200) return false

    return true
  }
}



export default QuizzService
