import React from 'react'

const Switch = ({ toggle }) => (
  <span className="switch-content">
    {toggle && (
    <span
      className="switchery switchery-default"
      style={{
        backgroundColor: 'rgb(255, 255, 255)',
        borderColor: 'rgb(223, 223, 223)',
        transition: 'border 0.4s, box-shadow 0.4s, background-color 1.2s',
        height: '28px',
      }}
    >
    <small style={{boxShadow: 'none', left: '23px', top: '3px', backgroundColor: '#00C87F', transition: 'backgroundColor 0.4s, left 0.2s', width: '22px', height: '22px' }}></small>
    </span>
    )}
    {!toggle && (
    <span
      className="switchery switchery-default"
      style={{
        backgroundColor: 'rgb(255, 255, 255)',
        borderColor: 'rgb(223, 223, 223)',
        transition: 'border 0.4s, box-shadow 0.4s',
        height: '28px'
      }}
    >
      <small style={{boxShadow: 'none',left: '3px', top: '3px', transition: 'backgroundColor 0.4s, left 0.2s', backgroundColor: '#A5A5A5', width: '22px', height: '22px' }}></small>
      </span>
    )}
  </span>
)

export default Switch
