import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import jdenticon from 'jdenticon'
import { ContentHeaderContainer, MainMenuContainer, NavigationBarContainer } from '../index'
import { AuthService } from '../../services'
import * as userInfoActions from '../../actions/userInfoActions'
import * as emailProfileActions from "../../actions/emailProfile";
import FooterQuiz from '../../components/Footer/FooterQuiz'

class PrivateContainer extends React.Component {
  async componentDidMount() {
    document.body.classList
      .add('vertical-layout', 'vertical-content-menu', '2-columns', 'menu-expanded', 'fixed-navbar')
    document.body.classList
      .remove('horizontal-layout', 'horizontal-menu', '1-column', 'horizontal-menu-padding', 'blank-page')

    const { history } = this.props
    const { user } = await AuthService.getUserInfo()
    const { setUserInfo } = this.props
    setUserInfo(user)
    jdenticon()
    if (!user) {
      await AuthService.deauthenticateUser()
      history.push('/')
    }
  }

  render() {
    const { children } = this.props
    const { params, path } = this.props.match;
    const editQuiz = path.indexOf('edit-quiz/') !== -1;

    return (
      <div>
        <NavigationBarContainer />
        <div className="app-content content" style={{ overflow: 'visible' }}>
          <div className={`${editQuiz ? 'edit-quiz' : ''} content-wrapper`}>
            <ContentHeaderContainer params={params} />
            {/* <MainMenuContainer /> */}
            <div className="content-body">{children}</div>
          </div>
        </div>
        {path === '/' && <FooterQuiz/>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
})

const mapDispatchToProps = {
  setUserInfo: userInfoActions.setUserInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrivateContainer))
