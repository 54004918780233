/* eslint-disable global-require */
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Intl } from '../../utils'
import { AuthService } from '../../services'
import './AuthPage.scss'
// import './assets/css/pages/login-register.css'

class AuthPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: null,
      passwd: null,
      message: null,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const { target: { value, name } } = event
    this.setState({
      [name]: value,
      message: null,
    })
  }

  async handleSubmit(event) {
    event.preventDefault()
    const { email, passwd } = this.state
    try {
      await AuthService.auth({ email, passwd })
      this.setState({ message: null })
    } catch (error) {
      this.setState({ message: error.message })
    }
  }

  render() {
    if (AuthService.isUserAuthenticated()) return <Redirect to="/" />

    const { message } = this.state

    return (
      <section className="flexbox-container auth-page">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="box-shadow-2 p-0">
            <div className="card border-grey border-lighten-3 m-0">
              <div className="card-header border-0">
                <div className="card-title text-center">
                  <div className="p-1">
                    <img src={require('../../enquiz_logo.svg')} alt="branding logo" />
                  </div>
                </div>
                <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                  <FormattedMessage id="authPage.login" defaultMessage="Вход" />
                </h6>
              </div>
              <div className="card-content">
                <div className="card-body">
                  <div className="error">
                    { message && <FormattedMessage id={message} /> }
                  </div>
                  <form className="form-horizontal form-simple" action="index.html" onSubmit={this.handleSubmit}>
                    <fieldset className="form-group position-relative has-icon-left mb-1">
                      <input
                        type="email"
                        className="form-control form-control-lg input-lg"
                        id="user-email"
                        placeholder="Email"
                        name="email"
                        required
                        onChange={this.handleChange}
                      />
                      <div className="form-control-position">
                        <i className="far fa-envelope" />
                      </div>
                    </fieldset>
                    <fieldset className="form-group position-relative has-icon-left">
                      <input
                        type="password"
                        className="form-control form-control-lg input-lg"
                        id="user-password"
                        placeholder={Intl.getMessage('authPage.password')}
                        name="passwd"
                        required
                        onChange={this.handleChange}
                      />
                      <div className="form-control-position">
                        <i className="fas fa-key" />
                      </div>
                    </fieldset>
                    <div className="form-group row">
                      <div className="col-md-6 col-12 text-center text-md-left" />
                      <div className="col-md-6 col-12 text-center text-md-right">
                        <Link to="/recover-password" className="card-link">
                          <FormattedMessage id="authPage.forgotPassword" defaultMessage="Забыли пароль?" />
                        </Link>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-info btn-lg btn-block">
                      <i className="ft-unlock" />
                      <FormattedMessage id="authPage.login" defaultMessage="Вход" />
                    </button>
                  </form>
                </div>
              </div>
              <div className="card-footer">
                <div className="">
                  <p className="float-sm-right text-center m-0">
                    <FormattedMessage id="authPage.notHaveAccount" defaultMessage="Еще нет аккаунта?" />
                    <Link to="/register" className="card-link">
                      <FormattedMessage id="authPage.createFree" defaultMessage="Создайте бесплатно" />
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default AuthPage
