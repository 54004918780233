/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap'
import swal from 'sweetalert'
import { Loader } from '../../components'
import { QuizContainer } from '../../containers'
import { AuthService, QuizzService, QuizzTemplatesService } from '../../services'
import { importQuizAction } from '../../actions/quizPageActions'
import { Intl } from '../../utils'
import { StepForWarning } from './assets/settings/StepForWarning'
import {ChatModeModalContent} from "../../components/Modals/chatModeModalContent";
import 'react-tippy/dist/tippy.css'
import './assets/css/IndexPage.css'
import './styles.css'
import './style.scss'


const config = require('../../config/config')
const limit = 20

const CardBackgroundImage = ({
  backgroundImage, quizTitle, quizDescription, id, makeQuiz,
}) => (
  <div className="col-4 template-responsive" key={id}>
    <div className="card text-white bg-primary text-center" style={{ height: '356px', overflow: 'hidden' }}>

      <div className="card-content">
        <img
          className="card-img img-fluid"
          src={`${(backgroundImage.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net"))}`}
          alt="background_image"
        />
        <div className="card-img-overlay">
          <div style={{ paddingTop: '15%' }}>
            <div className="subdivtext">
              <h1 className="text-white">{quizTitle}</h1>
              <p className="card-text">{quizDescription}</p>
              <a target="_blank" href={`${config.TEMPLATE_URL}${id}`}>
                <button className="btn  bg-amber btn-md bg-darken-3 template-actions-button">
                  <FormattedMessage id="templates.seeTemplate" defaultMessage="Просмотр" />
                </button>
              </a>
              <button className="btn bg-amber btn-md bg-darken-3 template-actions-button" onClick={() => makeQuiz(id)}>
                <FormattedMessage id="templates.chooseTemplate" defaultMessage="Выбрать" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpenAddQuiz: false,
      loader: true,
      quizzes: [],
      draftQuiz: false,
      modalSaveAsTemplate: false,
      modalSetQuizEndpoint: false,
      modalGetCode: false,
      saveAsTemplateTheme: 'other',
      saveAsTemplateIsPublic: false,
      saveEndpointURL: '',
      uniqueHash: '',
      message: '',
      image: '',
      leads_left: 1,
      isWarning: false,
      countPage: 1,
      selected: null,
      page: 1,
      quizQueryValue: '',
      modalTelegramConsultant: false,
      hashSelectedQuiz: '',
      userId: '',
    }
    this.toggleModalSaveAsTemplate = this.toggleModalSaveAsTemplate.bind(this)
    this.toggleModalSetQuizEndpoint = this.toggleModalSetQuizEndpoint.bind(this)
    this.handleChangeSaveAsTemplateTheme = this.handleChangeSaveAsTemplateTheme.bind(this)
    this.saveAsTemplate = this.saveAsTemplate.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.updateCount = this.updateCount.bind(this)
    this.deleteQuiz = this.deleteQuiz.bind(this)
    this.exportQuiz = this.exportQuiz.bind(this)
    this.unPublishQuiz = this.unPublishQuiz.bind(this)
    this.deleteQuizEndpoint = this.deleteQuizEndpoint.bind(this)
    this.publishQuiz = this.publishQuiz.bind(this)
    this.sendToSpecialist = this.sendToSpecialist.bind(this)
    this.openModalChatMode = this.openModalChatMode.bind(this)
    this.sendToRequestDomain = this.sendToRequestDomain.bind(this)
    this.copyQuiz = this.copyQuiz.bind(this)
    this.handleInputSearch = this.handleInputSearch.bind(this)
    this.getQuizzesQuery = this.getQuizzesQuery.bind(this)
    this.toggleModalGetCode = this.toggleModalGetCode.bind(this)
    this.makeQuizFromTemplate = this.makeQuizFromTemplate.bind(this)
    this.getImage = this.getImage.bind(this)
    this.hide = this.hide.bind(this)
    this.getListQuizzes = this.getListQuizzes.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.ShowAllQuizzes = this.ShowAllQuizzes.bind(this)
    this.refreshQuizzes = this.refreshQuizzes.bind(this)
  }

  async componentDidMount() {
    localStorage.intervalId = setInterval(() => this.updateCount(), 300000)
    const { history } = this.props
    try {
      const auth = await AuthService.getUserInfo()
      this.setState({ userId: auth.user.string_id })
      if (!auth) {
        await AuthService.deauthenticateUser()
        history.push('/')


      }
      const quizzes = await QuizzService.getListQuizzes({page: 1, limit})
      let draftQuiz = false
      if (localStorage.draftJson) {
        draftQuiz = JSON.parse(localStorage.draftJson)
      }
      this.setState({ quizzes, draftQuiz, loader: false }, this.updateCount)
    } catch (error) {
      console.log(error)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { importQuiz } = this.props
    if (nextProps.isImport) {
      this.ShowAllQuizzes()
      importQuiz(false)
    }
  }

  async getListQuizzes(data) {
    let { selected: page } = data
    page += 1
    this.setState({ loader: true })
    const { quizQueryValue } = this.state
    if (quizQueryValue) {
      QuizzService.getListQuizzes({ page, limit, query: quizQueryValue })
        .then((response) => {
          this.setState({ loader: false, quizzes: response, page })
        })
    } else {
      QuizzService.getListQuizzes({ page, limit })
        .then((response) => {
          this.setState({ loader: false, quizzes: response, page })
        })
    }
  }

  async updateCount(totalQuizzes) {
    const { user } = await AuthService.getUserInfo()
    if (user) {
      const { quizzes, new_leads: totalLeads, leads_left } = user
      // if (!totalLeads || totalLeads === 0) totalLeads = ''
      // if (!quizzes) quizzes = 0
      let countPages
      if (totalQuizzes) {
        countPages = totalQuizzes
      } else {
        countPages = quizzes
      }

      countPages = Math.ceil(countPages / limit)
      this.setState({ leads_left: Math.abs(leads_left), countPage: countPages })
      if (this.state.leads_left * StepForWarning < quizzes && leads_left < 0 ) {
        this.setState({ isWarning: true })
      }
      if (quizzes && document.getElementById('count-quizzes')) document.getElementById('count-quizzes').innerHTML = quizzes
      if (totalLeads && document.getElementById('count-leads')) document.getElementById('count-leads').innerHTML = totalLeads
    } else {
      clearInterval(+localStorage.intervalId)
    }
  }

  toggleModalSaveAsTemplate(url) {
    const { modalSaveAsTemplate } = this.state
    if (modalSaveAsTemplate) {
      const saveAsTemplateTheme = 'other'
      const saveAsTemplateIsPublic = false
      this.setState({
        modalSaveAsTemplate: !modalSaveAsTemplate, uniqueHash: url, saveAsTemplateTheme, saveAsTemplateIsPublic,
      })
    } else {
      this.setState({ modalSaveAsTemplate: !modalSaveAsTemplate, uniqueHash: url })
    }
  }

  toggleModalGetCode(url) {
    const { modalGetCode } = this.state
    this.setState({ modalGetCode: !modalGetCode, uniqueHash: url })
  }

  toggleModalSetQuizEndpoint(url) {
    const { modalSetQuizEndpoint } = this.state
    const saveEndpointURL = ''
    if (!modalSetQuizEndpoint) {
      this.setState({ message: '' })
    }
    this.setState({ modalSetQuizEndpoint: !modalSetQuizEndpoint, uniqueHash: url, saveEndpointURL })
  }

  handleChangeSaveAsTemplateTheme(event) {
    const { target: { value, name } } = event
    this.setState({ [name]: value })
  }

  handleChange(event) {
    const { target: { value, name } } = event
    this.setState({ message: '' })
    this.setState({ saveEndpointURL: value })
  }

  async getImage(hash, revision_id) {
    const image = await QuizzService.getImages(hash, revision_id);
    if(image && image.screenpath) {
     return {image: image.screenpath}
    }
  }

  hide() {
    this.setState({image: ''})
  }

  async saveAsTemplate() {
    const { saveAsTemplateTheme, uniqueHash, saveAsTemplateIsPublic } = this.state
    const { history } = this.props
    if (uniqueHash) {
      try {
        const quiz = await QuizzService.getQuizz(uniqueHash)
        const locale = Intl.getLocale()
        const result = await QuizzTemplatesService.createQuizzTemplate({
          saveAsTemplateTheme, json_data: quiz.json_data, locale, saveAsTemplateIsPublic,
        })
        if (result) history.push('/templates')
      } catch (error) {
        console.log(error)
      }
    }
  }

  async saveQuizEndpoint() {
    const { saveEndpointURL, uniqueHash, page } = this.state
    let result
    try {
      result = await QuizzService.setQuizEndpoint(uniqueHash, saveEndpointURL)
      const quizzes = await QuizzService.getListQuizzes({ page, limit })

      this.setState({ quizzes })
    } catch (error) {
      console.log(error)
    }
    if (result.status) {
      this.toggleModalSetQuizEndpoint(uniqueHash)
    } else if (result.detail === 'Invalid endpoint. Use form of: domain.com/some-optional-path') {
      this.setState({ message: 'Неверный формат адреса квиза' })
    } else if (result.detail === 'Quiz with such endpoint is already set') {
      this.setState({ message: 'Такой адрес уже назначен в системе' })
    }
  }

  exportQuiz(id) {
    QuizzService.getQuizz(id).then((response) => {
      const a = document.createElement('a')
      const newObj = {
        jsonData: JSON.parse(response.json_data),
        notifications: {},
      }
      const file = new Blob([JSON.stringify(newObj)], { type: 'application/json' })
      a.href = URL.createObjectURL(file)
      a.download = `quiz-${id}.json`
      a.click()
    })
  }

  async deleteQuiz(id) {
    const { quizzes } = this.state
    try {
      const willDelete = await swal({
        title: Intl.getMessage('indexPage.alertAreUSure'),
        text: Intl.getMessage('indexPage.alertQuizWillBeDeleted'),
        icon: 'warning',
        buttons: {
          cancel: {
            text: Intl.getMessage('indexPage.alertNoDeleted'),
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: Intl.getMessage('indexPage.alertYSure'),
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      })
      if (willDelete) {
        const result = QuizzService.deleteQuizz(quizzes[id].unique_hash)
        if (result) quizzes.splice(id, 1)
        this.setState({ quizzes }, this.updateCount)
        swal({
          title: Intl.getMessage('indexPage.alertQuizDeleted'),
          icon: 'error',
        })
      } else {
        swal({
          title: Intl.getMessage('indexPage.alertYQuizInSafe'),
          icon: 'info',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  unPublishQuiz(id) {
    const { quizzes } = this.state
    try {
      const result = QuizzService.unPublishQuiz(quizzes[id].unique_hash)
      if (result) quizzes[id].state = 'draft'
      this.setState({ quizzes })
    } catch (error) {
      console.log(error)
    }
  }

  publishQuiz(id) {
    const { quizzes } = this.state
    try {
      const result = QuizzService.publishQuiz(quizzes[id].unique_hash)
      if (result) quizzes[id].state = 'published'
      this.setState({ quizzes })
    } catch (error) {
      console.log(error)
    }
  }

  deleteQuizEndpoint(id) {
    const { quizzes } = this.state
    try {
      const result = QuizzService.deleteQuizEndpoint(quizzes[id].unique_hash)
      if (result) quizzes[id].endpoint = ''
      this.setState({ quizzes })
    } catch (error) {
      console.log(error)
    }
  }

  sendToSpecialist(id) {
    const { quizzes } = this.state
    try {
      swal({
        title: Intl.getMessage('indexPage.SendToSpecConfirmationTitle'),
        text: Intl.getMessage('indexPage.CautionBeforeSend'),
        icon: 'info',
        buttons: true,
      }).then((resolve) => {
        if (resolve) {
          const result = QuizzService.sendToSpecialist(quizzes[id].unique_hash)
          swal(Intl.getMessage('indexPage.SendToSpecConfirmation'), {
            icon: "success",
          });
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  openModalChatMode(hash) {
    const { modalTelegramConsultant } = this.state

    this.setState({ modalTelegramConsultant: !modalTelegramConsultant, hashSelectedQuiz: hash })
  }

  refreshQuizzes() {
    this.setState({ modalTelegramConsultant: false })
    QuizzService.getListQuizzes({page: 1, limit}).then(quizzes => {
      let draftQuiz = false
      if (localStorage.draftJson) {
        draftQuiz = JSON.parse(localStorage.draftJson)
      }
      this.setState({ quizzes, draftQuiz, loader: false }, this.updateCount)
    })

  }

  sendToRequestDomain(hash) {
    try {
      swal({
        title: Intl.getMessage('indexPage.SendToRequestDomainTitle'),
        text: Intl.getMessage('indexPage.SendToRequestDomainText'),
        content: {
          element: 'input',
          attributes: {
            placeholder: 'Введите домен',
            type: 'text',
          },
        },
        buttons: {
          cancel: {
            text: 'Отмена',
            value: 'close modal',
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: 'Отправить',
            value: true,
            visible: true,
            closeModal: true,
          },
        },
      }).then((resolve) => {
        if (resolve && resolve !== 'close modal') {
          QuizzService.sendToRequestDomain({ hash, domain: resolve })
            .then((response) => {
              swal(Intl.getMessage('indexPage.SendToRequestDomainSuccess'), {
                icon: 'success',
              })
            })
        } else if (resolve === 'close modal') {
          swal.close()
        } else {
          swal('Введите домен', {
            icon: 'error',
          })
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  async copyQuiz(id) {
    const { quizzes } = this.state
    try {
      const quiz = await QuizzService.getQuizz(quizzes[id].unique_hash)
      const json = JSON.parse(quiz.json_data)
      const notifications = JSON.parse(quiz.notifications)
      json.quiz_title = `${json.quiz_title}(${Intl.getMessage('indexPage.copy')})`
      json.quiz_sys_name = `${json.quiz_sys_name}_copy`
      const result = await QuizzService.createQuizz({ json, notifications })
      if (result) {
        const newQuizzes = await QuizzService.getListQuizzes({page: 1, limit})
        let draftQuiz = false
        if (localStorage.draftJson) {
          draftQuiz = JSON.parse(localStorage.draftJson)
        }
        this.setState({ quizzes: newQuizzes, draftQuiz }, this.updateCount)
      }
    } catch (error) {
      console.log(error)
    }
  }

  handleInputSearch(value) {
    this.setState({ quizQueryValue: value })
  }

  handleKeyPress(event) {
    const { quizQueryValue } = this.state
    if (event.key === 'Enter') {
      this.getQuizzesQuery(quizQueryValue)
    }
  }

  ShowAllQuizzes() {
    QuizzService.getListQuizzes({ page: 1, limit })
      .then((quizzes) => {
        this.setState({ quizzes, quizQueryValue: '' }, this.updateCount)
      })
  }

  getQuizzesQuery(query) {
    QuizzService.getListQuizzes({ page: 1, limit: 200, query })
      .then((response) => {
        if (response) {
          this.setState({ quizzes: response.slice(0, limit) }, () => this.updateCount(response.length))
        } else {
          this.setState({ quizzes: [] }, () => this.updateCount(0))
        }
      })
  }

  handleCheck(type) {
    this.setState({ [type]: !this.state[type] })
  }

  async makeQuizFromTemplate(id) {
    const { history } = this.props
    try {
      const quiz = await QuizzTemplatesService.makeQuizzFromTemplate(id)
      const { unique_hash } = quiz
      history.push(`/edit-quiz/${unique_hash}`)
    } catch (error) {
      console.log(error)
    }
  }

  importQuizJSON = (event) => {
    const { importQuiz } = this.props
    const reader = new FileReader()
    reader.readAsText(event.target.files[0], 'application/json')

    reader.onload = () => {
      if (reader.result.indexOf('quiz_title') !== -1 && reader.result.indexOf('quiz_sys_name') !== -1) {
        QuizzService.createQuizz({ json: JSON.parse(reader.result).jsonData, notifications: JSON.parse(reader.result).notifications }).then((response) => {
          importQuiz(true)
        })
      } else {
        alert('Неверный формат файла')
      }
    }
  }

  render() {
    const {
      quizzes, isOpenAddQuiz, quizQueryValue, draftQuiz, modalSaveAsTemplate, saveAsTemplateTheme, saveAsTemplateIsPublic, uniqueHash, loader, modalGetCode, modalSetQuizEndpoint, saveEndpointURL, message, countPage,
    } = this.state
    const { history } = this.props;

    return (
      <div style={{position: 'relative'}} className="quiz-container">
        {!loader && quizzes && (
          <div className="pagination-container" style={{ justifyContent: `${countPage > 1 && quizzes.length !== 0 ? 'space-between' : 'end'}`}}>
            {quizzes.length !== 0 && countPage > 1 && (
              <ReactPaginate
                previousLabel={Intl.getMessage('leadsPage.prev')}
                nextLabel={Intl.getMessage('leadsPage.next')}
                breakLabel={<a className="page-link" href="">...</a>}
                breakClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                pageCount={+countPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.getListQuizzes}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"pagination-active"}
                forcePage={this.state.page - 1}
              />
            )}
            <div className="search-quiz">
              <input className="form-control" value={quizQueryValue} onChange={event => this.setState({ quizQueryValue: event.target.value })} placeholder="Поиск..." onKeyPress={this.handleKeyPress} />
              <div className="search-quiz-icon" onClick={() => this.getQuizzesQuery(quizQueryValue)}>
                <i class="fas fa-search" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        )}
        {!loader && quizzes && quizzes.length === 0 && (
          <div>
            <h2>По запросу ничего не найдено</h2>
            <a className="show-all-quizzes" onClick={() => this.ShowAllQuizzes()}>Показать все квизы</a>
          </div>
        )}
        {draftQuiz && (
          <QuizContainer
            draft={draftQuiz}
            state="draft"
            title={draftQuiz.quiz_title}
            url="draftquiz"
            creationDate={localStorage.draftCreationDate}
          />
        )}
        {this.state.isWarning && (
          <div className="quizzWarning">
            <div className="quizzWarning-title">
              <h2>Предупреждение</h2>
              <i className="fas fa-times" onClick={() => this.setState({ isWarning: false })} />
            </div>
            <p>Вы создали более {StepForWarning * this.state.leads_left} квизов, а количество купленных пакетов: {this.state.leads_left}, что не предусмотрено условиями безлимитного пакета. Часть квизов будет работать с ошибками и без вывода результатов. Пожалуйста, подключите еще безлимитный пакет для поддержки следующих {StepForWarning} квизов. В случае большого объема квизов условия сотрудничества могут обсуждаться.</p>
          </div>
        )}
        {loader && (
          <Loader />
        )}
        {quizzes && !loader && (
          <div className="preview_container">
            <div>
              {loader && (
              <Loader />
              )}
              {quizzes.map((card, index) => (
                <div key={index} className="containerTooltip">
                    <QuizContainer
                      index={index}
                      key={index}
                      state={card.state}
                      title={card.title}
                      url={card.unique_hash}
                      endpoint={card.endpoint}
                      endpoint_settings={card.endpoint_settings}
                      visits={card.visits}
                      leads={card.leads}
                      chatSettings={card.chatbot_settings}
                      newLeads={card.new_leads}
                      creationDate={card.creation_date}
                      history={history}
                      changeChatMode={this.openModalChatMode}
                      saveAsTemplate={this.toggleModalSaveAsTemplate}
                      getCode={this.toggleModalGetCode}
                      setQuizEndpoint={this.toggleModalSetQuizEndpoint}
                      deleteQuizEndpoint={this.deleteQuizEndpoint}
                      deleteQuiz={this.deleteQuiz}
                      exportQuiz={this.exportQuiz}
                      unPublishQuiz={this.unPublishQuiz}
                      publishQuiz={this.publishQuiz}
                      copyQuiz={this.copyQuiz}
                      sendToSpecialist={this.sendToSpecialist}
                      sendToRequestDomain={this.sendToRequestDomain}
                      getImage={async () => await this.getImage(card.unique_hash, card.revision_id)}
                    />
                </div>
              ))}
            </div>
          </div>
        )}
        {(!quizzes && !draftQuiz) && (
          <div className="empty-quizes">
            <div>
              <h1>Вы не создали ни одного квиза</h1>
              <Dropdown isOpen={isOpenAddQuiz} toggle={()=>this.setState({isOpenAddQuiz: !isOpenAddQuiz})} className="btn-group float-md-right top-buttons" role="group" aria-label="Button group with nested dropdown">
                <DropdownToggle
                  className="btn btn-create round dropdown-toggle dropdown-menu-right box-shadow-2 px-2 top-buttons"
                  id="btnGroupDrop1"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup={true}
                  aria-expanded="false"
                >
                  <i className="ft-plus icon-left"></i>
                  <FormattedMessage id="contentHeader.createQuizz" defaultMessage="СОЗДАТЬ КВИЗ" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                  <Link to="/empty" style={{ color: '#2a2e30' }}>
                    <DropdownItem className="dropdown-item">
                      <i className="ft-square"></i>
                      <FormattedMessage id="contentHeader.fromScratch" defaultMessage="С нуля" />
                    </DropdownItem>
                  </Link>
                  <Link to="/templates" style={{ color: '#2a2e30' }}>
                    <DropdownItem className="dropdown-item">
                      <i className="ft-image"></i>
                      <FormattedMessage id="contentHeader.basedTemplate" defaultMessage="На основе шаблона" />
                    </DropdownItem>
                  </Link>
                  <div className="dropdown-item">
                    <i class="fa fa-upload" aria-hidden="true"></i>
                    <input type="file" name="file" id="file" className="input-file" accept="application/JSON" onChange={event => this.importQuizJSON(event)} />
                    <label htmlFor="file">
                      <FormattedMessage id="contentHeader.import" defaultMessage="Импортировать" />
                    </label>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        )}
        {quizzes && quizzes.length !== 0 && !loader && countPage > 1 && (
          <ReactPaginate
            previousLabel={Intl.getMessage('leadsPage.prev')}
            nextLabel={Intl.getMessage('leadsPage.next')}
            breakLabel={<a className="page-link" href="">...</a>}
            breakClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            pageCount={+countPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.getListQuizzes}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"pagination-active"}
            forcePage={this.state.page - 1}
          />
        )}

        <Modal isOpen={modalSaveAsTemplate} toggle={this.toggleModalSaveAsTemplate}>
          <ModalHeader toggle={this.toggleModalSaveAsTemplate}>
            <FormattedMessage id="indexPage.selectTemplateTheme" defaultMessage="Выберите тему шаблона" />
          </ModalHeader>
          <ModalBody>
            <fieldset className="form-group position-relative">
              <label>
                <FormattedMessage id="indexPage.templateTheme" defaultMessage="Тема шаблона" />
              </label>
              <select className="form-control" name="saveAsTemplateTheme" id="DefaultSelect" value={saveAsTemplateTheme} onChange={this.handleChangeSaveAsTemplateTheme}>
                {config.TEMPLATE_THEMES.map(name => (<option key={name} value={name}> {Intl.getMessage(`templateThemes.${name}`)}</option>))}
              </select>
            </fieldset>
            <fieldset className="skin skin-square" onClick={() => this.handleCheck('saveAsTemplateIsPublic')} style={{ cursor: 'pointer' }} title="Опубликовать в общий каталог">
              <br />
              <div className={`icheckbox_square-red ${saveAsTemplateIsPublic ? 'checked' : ''}`} style={{ position: 'relative' }}>
                <input type="checkbox" id="input-12" checked="" style={{ position: 'absolute', opacity: '0' }} />
              </div>
              <FormattedMessage id="indexPage.publishToGeneralCatalog" defaultMessage="Опубликовать в общий каталог" />
            </fieldset>
          </ModalBody>
          <ModalFooter>
            <input type="reset" onClick={this.toggleModalSaveAsTemplate} className="btn btn-secondary" data-dismiss="modal" value={Intl.getMessage('step3.cancel')} />
            <input type="submit" onClick={() => this.saveAsTemplate()} className="btn btn-primary" value={Intl.getMessage('step3.save')} />
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalSetQuizEndpoint} toggle={this.toggleModaSetQuizEndpoint}>
          <ModalHeader toggle={this.toggleModaSetQuizEndpoint}>
            <FormattedMessage id="indexPage.setQuizEndpoint" defaultMessage="Назначить свой домен" />
          </ModalHeader>
          <ModalBody>
            <h4><i className="ft-external-link" /><a href="https://enquiz.io/docs/creation/installation/domain/" target="_blank">{Intl.getMessage('indexPage.endpointDocsLink')}</a></h4>
            <FormattedMessage id="indexPage.endpointQuickGuide" /><br />
            <FormattedMessage id="indexPage.endpointQuickGuide2" /><br /><br />
            <fieldset className="form-group position-relative">
              <label>
                <FormattedMessage id="indexPage.setQuizEndpointTip" defaultMessage="Ваш домен без http:// и https://" />
              </label>
              <input placeholder="domain.ru/some-optional-path" className="form-control" onChange={this.handleChange} name="saveEndpointURL" value={saveEndpointURL} />
              <div style={{ color: 'red', paddingBottom: '10px' }}>
                { message }
              </div>
            </fieldset>

          </ModalBody>
          <ModalFooter>
            <input type="reset" onClick={this.toggleModalSetQuizEndpoint} className="btn btn-secondary" data-dismiss="modal" value={Intl.getMessage('step3.cancel')} />
            <input type="submit" onClick={() => this.saveQuizEndpoint()} className="btn btn-primary" value={Intl.getMessage('step3.save')} />
          </ModalFooter>
        </Modal>

        <SweetAlert
          title="Настройка режима чата"
          onConfirm={() => this.refreshQuizzes()}
          onCancel={() => this.refreshQuizzes()}
          show={this.state.modalTelegramConsultant}
          style={{ width: "480px" }}
        >
          <ChatModeModalContent uniqueHash={this.state.hashSelectedQuiz} userId={this.state.userId} />
        </SweetAlert>

        <Modal isOpen={modalGetCode} toggle={this.toggleModalGetCode}>
          <ModalHeader toggle={this.toggleModalGetCode}>
            {Intl.getMessage('card.getCode')}
          </ModalHeader>
          <ModalBody>

            <h4><i className="ft-external-link" /><a href="https://enquiz.io/docs/creation/installation/on_site/" target="_blank">{Intl.getMessage('card.codeInstallationLink')}</a></h4>
            {Intl.getMessage('card.codeForHead')}
            <br />
            <textarea rows="2" cols="50" defaultValue={'<script type="text/javascript" src="https://show.enquiz.io/js/quizloader.js"></script>'} />
            <br />
            <br />
            {Intl.getMessage('card.callCode')}
            <br />
            <textarea
              rows="9"
              cols="50"
              defaultValue={`<script>
              (function () {
                  new enquiz_ioLoader({
                      quiz_hash:"${uniqueHash}",
                      type: enquiz_ioLoader.EMBEDDED,
                      hash: "quiz_${uniqueHash}"
                  })
              })()
</script>`}
            />
            <hr /><strong>{Intl.getMessage('card.or')}</strong><hr />
            {Intl.getMessage('card.callCodeDialog')}
            <br />
            <textarea
              rows="10"
              cols="50"
              defaultValue={`<script>
              (function () {
                  new enquiz_ioLoader({
                      quiz_hash:"${uniqueHash}",
                      type: enquiz_ioLoader.FLOAT,
                      button: "YourButtonId",
                      hash: "quiz_${uniqueHash}"
                  })
              })()
</script>`}
            />
            <br />
            {Intl.getMessage('card.callCodeDialogDescription')}
            <br />

              - {Intl.getMessage('card.callCodeDialogDescription2')}<br />
              - {Intl.getMessage('card.callCodeDialogDescription3')} <input type="text" value={`#quiz_${uniqueHash}`} readOnly />


          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isImport: state.quizReducer.isImport,
})

const mapDispatchToProps = {
  importQuiz: importQuizAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)
