import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc'
import { CustomInput, FormGroup, Label} from 'reactstrap';
import { Intl } from '../../utils'
import { Switch } from '../index'
import { Tooltip } from '../../components';
import './style.scss';

const placeholders = ['name', 'phone', 'email', 'whatsapp', 'telegram', 'viber', 'skype']

const titles = {
  name: Intl.getMessage('step4.name'),
  phone: Intl.getMessage('step4.phone'),
  email: 'Email',
  whatsapp: 'WhatsApp',
  telegram: 'Telegram',
  viber: 'Viber',
  skype: 'Skype',
}

const messengersTitles = {
  whatsapp: "WhatsApp",
  viber: 'Viber',
  vkontakte: "Vkontakte",
  messenger: "Messenger",
  instagram: "Instagram",
  telegram: 'Telegram',
}
const messengersPlaceholders = {
  whatsapp: "+7-800-555-35-35",
  viber: '+7-800-555-35-35',
  vkontakte: "+7-800-555-35-35",
  messenger: "Messenger",
  instagram: "Instagram",
  telegram: 'yourTelegram',
}

const SortableItem = SortableElement(({value}) =>
  <div style={{ marginBottom: '-0.5rem' }}>{value}</div>
)

const DragHandle = SortableHandle(() => (<i className="fas fa-bars move-contacts" title={Intl.getMessage('step3.move')} style={{ cursor: 'move', paddingRight: '0.5rem' }}></i>))

const SortableList = SortableContainer(({items}) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  )
})

class Contacts extends React.Component {
  render() {
    const { index, objChannels, channel, handleCheckAskContacts, handleCheckRequired } = this.props
    return (
      <fieldset
        key={index}
        className="skin skin-square"
        style={{cursor: 'pointer', marginBottom: '20px', color: 'black', display: 'flex'}}
        id="drag-side-form"
      >
        <DragHandle />
        <div onClick={handleCheckAskContacts}>
          <div
            className={`icheckbox_square-green ${objChannels[channel] ? 'checked' : ''}`}
            style={{ position: 'relative' }}
          >
            <input type="checkbox" id="input-12" checked="" style={{position: 'absolute', opacity: '0'}}/>
          </div>
          <span className="check-text">{`${titles[channel]}`}</span>
        </div>
        <span onClick={handleCheckRequired}>
          {objChannels[channel] ? 
            objChannels[channel] === 'required' ? 
              <span className="badge badge-default badge-danger">обязательное</span>
            : <span className="badge badge-default badge-info">необязательное</span> 
          : null
          }
        </span>
      </fieldset>
    )
  }
}

class Messenger extends React.Component {
  render () {
    const { index, objMessengers, messenger, handleCheckAskMessengers, recordContactForMessenger, ask_messengers } = this.props
    return (
      <fieldset
        key={index}
        className="skin skin-square"
        style={{cursor: 'pointer', marginBottom: '20px', color: 'black', height: '24px', display: 'flex'}}
        id="drag-side-form"
      >
        <DragHandle />
        <div onClick={handleCheckAskMessengers} style={{display: 'flex'}}>
          <div
            className={`icheckbox_square-green ${objMessengers[messenger] ? 'checked' : ''}`}
            style={{ position: 'relative', marginRight: '15px' }}
          >
            <input type="checkbox" id="input-12" checked="" style={{position: 'absolute', opacity: '0'}}/>
          </div>
          <span className="check-text" style={{display: 'inline-block', width: '100px'}}>{`${messengersTitles[messenger]}`}</span>
        </div>
        {objMessengers[messenger] ? ( <input value={ask_messengers[index][2] || ''} onChange={recordContactForMessenger} type="text" placeholder={messengersPlaceholders[messenger]} style={{marginLeft: "10px"}}/>) : (null)}
      </fieldset>
    );
  }
}


class Step4 extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
      isRequestContactsButton: false,
      json: this.props.data,
      objChannels: {},
      objMessengers: {},
      isWarning: !this.props.data.request_contacts || this.props.data.allow_skip_contacts,
      isWarningEmpty: false,
      isSendMessengers: true
    }
    
    this.changeRequestContacts = this.changeRequestContacts.bind(this)
    this.handleCheckAskContacts = this.handleCheckAskContacts.bind(this)
    this.handleCheckRequired = this.handleCheckRequired.bind(this)
    this.changeSkipContacts = this.changeSkipContacts.bind(this)
    this.changeAtLeastOneContact = this.changeAtLeastOneContact.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.changeSendMessengers = this.changeSendMessengers.bind(this)
    this.toggleTextAfterRequestContacts = this.toggleTextAfterRequestContacts.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
    this.onSortEndMessengers = this.onSortEndMessengers.bind(this)
    this.recordContactForMessenger = this.recordContactForMessenger.bind(this)
    this.handleCheckAskMessengers = this.handleCheckAskMessengers.bind(this)
  }

  componentDidMount() {
    const { json: {ask_contacts, ask_messengers}} = this.state
    const objChannels = {}
    ask_contacts.map((channel) => {
      objChannels[channel[0]] = channel[1]
    })
    const objMessengers = {}
    ask_messengers.map((msg) => {
      objMessengers[msg[0]] = msg[1]
    })
    this.setState({objChannels, objMessengers})
  }
  
  changeRequestContacts() {
    const { json } = this.state
    json.request_contacts = !json.request_contacts
    this.setState({ json, isWarning: !json.request_contacts || json.allow_skip_contacts }, this.props.handler(this.state.json))
  }
  
  changeAtLeastOneContact() {
    const { json } = this.state;
    json.at_least_one_contact = !json.at_least_one_contact
    this.setState({ json }, this.props.handler(this.state.json))
  }

  changeSkipContacts() {
    const { json } = this.state
    json.allow_skip_contacts = !json.allow_skip_contacts
    this.setState({ json, isWarning: json.allow_skip_contacts  }, this.props.handler(this.state.json))
  }

  changeSendMessengers() {
    const { json, json: { ask_messengers } } = this.state
    if (!this.state.isSendMessengers) {
      this.setState({ isWarningEmpty: false})
    }
    if (ask_messengers.every(item => item[1] === false) && !json.enable_messangers) {
      this.setState({ isWarningEmpty: true})
    }
    json.enable_messangers  = !json.enable_messangers
    this.setState({ json, isSendMessengers: !json.enable_messangers }, this.props.handler(this.state.json))
  }

  handleCheckAskContacts(channel) {
    const { objChannels, json } = this.state
    objChannels[channel] = !objChannels[channel]
    json.ask_contacts.map((contact, index) => {
      if (contact[0] === channel) json.ask_contacts[index][1] = !json.ask_contacts[index][1]
    })
    this.setState({ objChannels, json }, this.props.handler(this.state.json))
  }

  recordContactForMessenger(value, messenger) {
    const { json, json: {ask_messengers}} = this.state
    let current = ask_messengers.find(item => item[0] === messenger[0])
    const findIndex = ask_messengers.indexOf(current)
    current[2] = value
    ask_messengers[findIndex] = current
    json.ask_messengers = ask_messengers
    this.setState({ json }, this.props.handler(this.state.json))
  }

  handleCheckAskMessengers(messenger) {
    const { objMessengers, json, json: {enable_messangers} } = this.state

    objMessengers[messenger] = !objMessengers[messenger]
    json.ask_messengers.map((mg, index) => {
      if (mg[0] === messenger) json.ask_messengers[index][1] = !json.ask_messengers[index][1]
    })
    this.setState({ objMessengers, json }, this.props.handler(this.state.json))
    if (Object.values(objMessengers).every(item => item === false) && enable_messangers) {
      this.setState({ isWarningEmpty: true})
    } else {
      this.setState({ isWarningEmpty: false})
    }

  }
  
  handleCheckRequired(channel) {
    const { objChannels, json } = this.state
    if (objChannels[channel]) {
      if (objChannels[channel] === 'required') {
        objChannels[channel] = true
      } else {
        objChannels[channel] = 'required'
      }
    }
    
    json.ask_contacts.map((contact, index) => {
      if (contact[0] === channel) json.ask_contacts[index][1] = objChannels[channel]
    })
    this.setState({ objChannels, json }, this.props.handler(this.state.json))
  }
  
  onSortEnd(ids) {
    const { oldIndex, newIndex } = ids
    const { json } = this.state
    json.ask_contacts = arrayMove(json.ask_contacts, oldIndex, newIndex)
    const objChannels = {}
    json.ask_contacts.map((channel, index) => {
      objChannels[channel[0]] = channel[1]
    })
    this.setState({ json, objChannels }, this.props.handler(this.state.json))
  }

  onSortEndMessengers(ids) {
    const { oldIndex, newIndex } = ids
    const { json } = this.state
    json.ask_messengers = arrayMove(json.ask_messengers, oldIndex, newIndex)
    const objMessengers = {}
    json.ask_messengers.map((msg, index) => {
      objMessengers[msg[0]] = msg[1]
    })
    this.setState({ json, objMessengers }, this.props.handler(this.state.json))
  }
  
  handleChange(event) {
    const { target: { value, name } } = event
    const { json } = this.state
    json[name] = value
    this.setState({ json }, this.props.handler(this.state.json))
  }
  
  toggleTextAfterRequestContacts() {
    const { isRequestContactsButton } = this.state
    this.setState({ isRequestContactsButton: !isRequestContactsButton  })
  }
  
  render() {
    const {
      json: {
        request_contacts, ask_contacts, at_least_one_contact, allow_skip_contacts, enable_messangers , request_contacts_title, request_contacts_details, ask_messengers,
        privacy_policy_text, request_contacts_button, quiz_result_text, phone_prefix
      },
      objChannels, isRequestContactsButton, objMessengers
    } = this.state

    return (
      <fieldset className="stage-4-content">
        <div className="stage-4-header-bar">
          {this.state.isWarning && (
            <div className="quizzWarning">
              <div className="quizzWarning-title">
                <h2>Предупреждение</h2>
                <i className="fas fa-times" onClick={() => this.setState({ isWarning: false })} />
              </div>
              <p>Квизы, которые не собирают контакты, требуют подключения <b>Безлимитного</b> пакета или любого из линейки PRO пакетов.</p>
            </div>
          )}
          <div onClick={this.changeRequestContacts} className="collect-contact-switch">
            <Switch toggle={request_contacts} />
            <label className="ml-1 custom-text-title">
              <FormattedMessage id="step4.enableRequestContacts" defaultMessage="Включить сбор контактов" />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 left-side">
            <div className="card">
              <div className="card-content collapse show">
                <div className="card-body">
                  {request_contacts && (
                  <div>
                    <p className="custom-text-title type-2">Метод сбора контактов</p>
                    <div className="radio-control">
                      <FormGroup check inline>
                        <Label>
                          <CustomInput
                            type="radio"
                            className="inline custom-text-title"
                            id="textRadio"
                            name="typeRadio"
                            label="Форма сбора контактов"
                            value={'text'}
                            checked={!enable_messangers}
                            onChange={this.changeSendMessengers}
                          />
                          <span>По окончанию квиза отображается форма для ввода контактов</span>
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label>
                          <CustomInput
                            type="radio"
                            className="inline custom-text-title"
                            id="linkRadio"
                            name="typeRadio"
                            label="Кнопки мессенджеров"
                            value={'link'}
                            checked={enable_messangers}
                            onChange={this.changeSendMessengers}
                          />
                          <span>В конце квиза отображаются кнопки мессенджеров</span>
                        </Label>
                      </FormGroup>
                    </div>
                    {this.state.isWarningEmpty && (
                    <div>
                      <div className="quizzWarning-title">
                        <h2>Предупреждение</h2>
                      </div>
                      <p>Необходимо выбрать как минимум один мессенджер</p>
                    </div>
                    )}
                    {enable_messangers && (
                      <div className="row skin skin-square checkbox-control">
                        <div className="card-body">
                        <div className="skin skin-square">
                            <div className="col-md-8 col-sm-12">
                              <SortableList
                                useDragHandle={true}
                                onSortEnd={this.onSortEndMessengers}
                                items={ask_messengers.map((msg, index) => (
                                    <Messenger
                                      index={index}
                                      messenger={msg[0]}
                                      ask_messengers={ask_messengers}
                                      objMessengers={objMessengers}
                                      handleCheckAskMessengers={() => this.handleCheckAskMessengers(msg[0])}
                                      recordContactForMessenger={(event) => this.recordContactForMessenger(event.target.value, msg)}
                                    />
                                  )
                                )}
                              />
                            </div>
                        </div>
                        </div>
                      </div>
                    )}
                    {!this.state.json.enable_messangers && (
                      <div className="checkbox-control">
                        <div className="card-body">
                          <p className="custom-text-title">Поля формы</p>
                          <div className="row skin skin-square">
                            <div className="col-md-8 col-sm-12">
                              <SortableList
                                useDragHandle={true}
                                onSortEnd={this.onSortEnd}
                                items={ask_contacts.map((channel, index) => (
                                    <Contacts
                                      index={index}
                                      channel={channel[0]}
                                      objChannels={objChannels}
                                      handleCheckAskContacts={() => this.handleCheckAskContacts(channel[0])}
                                      handleCheckRequired={() => this.handleCheckRequired(channel[0])}
                                    />
                                  )
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="switch-side" onClick={this.changeSkipContacts}>
                          <Switch toggle={allow_skip_contacts} />
                          <label className="ml-1 custom-text-title">
                            <FormattedMessage id="step4.allowSkipContacts" defaultMessage="Возможно пропустить ввод контактов" />
                          </label>
                        </div>
                        <div className="switch-side" onClick={this.changeAtLeastOneContact}>
                          <Switch toggle={at_least_one_contact} />
                          <label className="ml-1 custom-text-title">
                            <FormattedMessage id="step4.atLeastOneContact" defaultMessage="Ввод хотя бы одного контакта" />
                          </label>
                        </div>
                      </div>
                      )}
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {request_contacts && <div className="col-md-6 right-side">
            <p className="custom-text-title type-2">Тексты</p>
            <div className="form-group">
              <label htmlFor="request_contacts_title">
                <FormattedMessage id="step4.title" defaultMessage="Заголовок" />
              </label>
              <textarea
                id="request_contacts_title"
                rows="5"
                className="form-control"
                name="request_contacts_title"
                data-toggle="tooltip"
                data-trigger="hover"
                data-placement="top"
                value={request_contacts_title}
                onChange={this.handleChange}
              >
                {request_contacts_title}
              </textarea>
            </div>
            <div className="form-group">
              <label htmlFor="request_contacts_details">
                <FormattedMessage id="step4.details" defaultMessage="Описание" />
              </label>
              <textarea
                id="request_contacts_details"
                rows="5"
                className="form-control"
                name="request_contacts_details"
                data-toggle="tooltip"
                data-trigger="hover"
                data-placement="top"
                value={request_contacts_details}
                onChange={this.handleChange}
              >
                {request_contacts_details}
              </textarea>
            </div>
            <div className="form-group">
              <label htmlFor="phone_prefix">
                <FormattedMessage id="step4.prefixText" defaultMessage="Телефонный код страны по умолчанию" />
              </label>
              <input type="text" name="phone_prefix" className="form-control" id="phone_prefix" value={phone_prefix} onChange={this.handleChange} placeholder="+7" />
            </div>
            <div className="form-group">
              <label htmlFor="privacy_policy_text">
                <FormattedMessage id="step4.privacyPolicyText" defaultMessage="Текст о конфеденциальности данных" />
              </label>
              <textarea
                id="privacy_policy_text"
                rows="5"
                className="form-control"
                name="privacy_policy_text"
                placeholder="comments"
                data-toggle="tooltip"
                data-trigger="hover"
                data-placement="top"
                value={privacy_policy_text}
                onChange={this.handleChange}
              >
                {privacy_policy_text}
              </textarea>
            </div>
            <div className="form-group">
              <label htmlFor="request_contacts_button">
                <FormattedMessage id="step4.requestContactsButton" defaultMessage="Текст кнопки запроса результатов" />
              </label>
              <input type="text" name="request_contacts_button" className="form-control" id="request_contacts_button" value={request_contacts_button} onChange={this.handleChange}/>
            </div>
            <div className="form-group">
              <label htmlFor="quiz_result_text">
                <FormattedMessage id="step4.quizResultText" defaultMessage="Текст после отправки контактов/завершения квиза" />
              </label>
              <Tooltip
                  header={Intl.getMessage('step4.tooltipResultTextTitle')}
                  body={Intl.getMessage('step4.tooltipResultText')}
                  target="id62"
                />
              <textarea
                id="quiz_result_text"
                rows="5"
                className="form-control"
                name="quiz_result_text"
                placeholder="comments"
                data-toggle="tooltip"
                data-trigger="hover"
                data-placement="top"
                value={quiz_result_text}
                onChange={this.handleChange}
              >
                {quiz_result_text}
              </textarea>
            </div>
          </div>}
        </div>
      </fieldset>
    )
  }
}

export default Step4
