import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider, addLocaleData } from 'react-intl'
import { Provider } from 'react-redux'
import { compose, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import localeEn from 'react-intl/locale-data/en'
import localeRu from 'react-intl/locale-data/ru'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import messagesRu from './locale/ru.json'
import messagesEn from './locale/en.json'
import reducer from './reducers'

addLocaleData([...localeEn, ...localeRu])
const messages = {
  ru: messagesRu,
  en: messagesEn,
}

const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

// const language = navigator.language.split(/[-_]/)[0]
// const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)))
const store = createStore(
  reducer,
  composeSetup(applyMiddleware(thunk)),
)

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale="ru" messages={messages.ru}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById('root'),
)
registerServiceWorker()
