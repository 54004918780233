export const EMAIL_PROFILE_DELETE_REQUEST = 'EMAIL_PROFILE_DELETE_REQUEST'
export const EMAIL_PROFILE_DELETE_SUCCESS = 'EMAIL_PROFILE_DELETE_SUCCESS'
export const EMAIL_PROFILE_DELETE_FAILURE = 'EMAIL_PROFILE_DELETE_FAILURE'

export const EMAIL_PROFILE_CREATE_REQUEST = 'EMAIL_PROFILE_CREATE_REQUEST'
export const EMAIL_PROFILE_CREATE_SUCCESS = 'EMAIL_PROFILE_CREATE_SUCCESS'
export const EMAIL_PROFILE_CREATE_FAILURE = 'EMAIL_PROFILE_CREATE_FAILURE'

export const EMAIL_PROFILE_GETLIST_REQUEST = 'EMAIL_PROFILE_GETLIST_REQUEST'
export const EMAIL_PROFILE_GETLIST_SUCCESS = 'EMAIL_PROFILE_GETLIST_SUCCESS'
export const EMAIL_PROFILE_GETLIST_FAILURE = 'EMAIL_PROFILE_GETLIST_FAILURE'
