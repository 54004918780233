const prodconfig = require('../config/prodconfig')

const initialState = {
  csvLink: {
    quizFilter: '',
    leadFilter: '',
    defaultLink: prodconfig.LEAD_EXPORT,
  },
}

const csvLinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_CSV_LINK':
      return { ...state, csvLink: action.payload }
    default:
      return state
  }
}

export default csvLinkReducer
