import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Intl } from '../../../utils'
import './Card.css'

const config = require('../../../config/config')

const imageSize = '200,fit'

const Card = ({
  cardTitle, cardDesc, cardLink, kind, id, deleteResult, editResult, preview, countBind, questionIds, image, newResultType, clearResultData, isShowEnd, selectedId
}) => {
  return (
    <div
    key={id}
    className={`${selectedId === id ? 'selected' : ''} card border-primary bg-blue-grey bg-lighten-4 border-primary border-accent-1`}
    onClick={(e) => preview(id, e)}
    style={{ cursor: 'pointer' }}
  >
    <div className="card-header bg-transparent">
      <div href="#">
        <h4 className="card-title">
          {/* <span
            className="badge badge-default badge-info"
            title={Intl.getMessage('step2.countLinkVars')}
          >
            {countBind}
          </span> */}
          <span className="fix-result-title"><i class="fas fa-check"></i> Фиксированный результат</span>
          {(kind === 'text' || kind === 'extended') && (<span> {cardTitle}</span>)}
          {kind === 'link' && (<FormattedMessage id="step2.link" defaultMessage="Пересылка на URL" />)}
        </h4>
      </div>
      {/* <div className="heading-elements-toggle">
        <i className="la la-ellipsis-v font-medium-3" />
      </div> */}
      <div className="heading-elements">
        <ul className="list-inline mb-0">
          {/* <li onClick={() => { clearResultData(false); editResult(id) }} title={Intl.getMessage('step2.edit')}>
            <a><i className="ft-feather" /></a>
          </li> */}
          <li onClick={() => deleteResult(id, questionIds)} title={Intl.getMessage('step2.delete')}>
            <a><i class="fas fa-times"></i></a>
          </li>
        </ul>
      </div>
    </div>
    <div className="card-content collapse show" style={{ marginTop: '-30px' }}>
      {(kind === 'text' || kind === 'extended') && (
      <div className="card-body">
        {`${cardDesc.substring(0, 50)}`}
        <p className="card-text" />
        {image && (
        <div className="card-img">
          <img
            className="media-object round-image"
            src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${image.replace("s3-eu-west-2.amazonaws.com", "storage.yandexcloud.net")}`}
            alt=""
          />
        </div>
          )}
      </div>
      )}
      {kind === 'link' && (
      <div className="card-body">
        <a href={cardLink}>{cardLink}</a><i className="ft-external-link" />
        <p className="card-text" />
      </div>
      )}
      {isShowEnd && (<div className="isShowEnd">
        Будет показан в конце квиза
      </div>)}
    </div>
  </div>
  )
}

export default Card
