import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../config/config';
import { Tooltip } from '../../components';
import { Intl } from '../../utils';
import { Switch } from '../index';


class AnswerAction extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            isOpenSelectBody: false,
            isLoad: false,
        }
    }

    handleCheckAnswer = (key, value) => {
        this.props.handleChangeEditAnswerSwitch(key, value)
    }

    handleChangeDiscount = (event, type) => {
        const { target: { value, name } } = event
        const {editableAnswer} = this.props;
        const key = type || name;
        editableAnswer[key] = value;

        this.props.handleChangeAnswer(editableAnswer);
    }

    editImage = async  (event) => {
        const editableAnswer = this.props.editableAnswer || {};
        this.setState({ isLoad: true })
        const data = new FormData()
        const file = event.target.files[0]
        if (file) {
          data.append('file', file)
          editableAnswer.answer_image = data
          try {
            let response = await fetch(config.IMAGE_UPLOAD_URL, {
              method: 'POST',
              body: editableAnswer.answer_image,
            })
            response = await response.json()
            editableAnswer.answer_image = response.Url
          } catch (error) {
            console.log(error)
          }

          this.setState({isLoad: false})
          this.props.handleChangeImageAnswer(editableAnswer);
        }
      }
    
    deleteImage = () => {
      const {editableAnswer, handleChangeImageAnswer} = this.props;
      delete editableAnswer.answer_image
      handleChangeImageAnswer(editableAnswer);
    }

    render(){
        const {
            submitAnswer,
            selectedQuest,
            imageSize,
            handleChangeEditAnswer,
            editableAnswer,
            cancelEditAnswer,
            activeType,
        } = this.props;

        const {isLoad} = this.state;

        return(
            <div>
                <form onSubmit={submitAnswer}>
                    <div className="modal-body">
                        {(selectedQuest && selectedQuest.question_kind === 'option' || selectedQuest && selectedQuest.question_kind === 'motivator' || selectedQuest && selectedQuest.question_kind === 'option_image') && (
                        <fieldset className="form-group floating-label-form-group">
                            <label htmlFor="atitle">
                            <FormattedMessage id="step3.nameVar" defaultMessage="Название варианта" />
                            </label>
                            <input required name="answer_title" type="text" className="form-control" id="atitle" value={editableAnswer ? editableAnswer.answer_title : ''} onChange={handleChangeEditAnswer} />
                        </fieldset>
                        )}
                        <fieldset className="form-group floating-label-form-group">
                        <label htmlFor="qhelper">
                            <FormattedMessage id="step3.textDesc" defaultMessage="Текст подсказки" />
                        </label>
                        <textarea
                            className="form-control"
                            id="qhelper"
                            rows="3"
                            name="answer_description"
                            value={editableAnswer ? editableAnswer.answer_description : ''}
                            onChange={handleChangeEditAnswer}
                        >
                        </textarea>
                        </fieldset>
                        {(selectedQuest && selectedQuest.question_kind === 'option_image' || selectedQuest && selectedQuest.question_kind === 'motivator') && (
                            <div className="image-media">
                                <div className="image-media-title">
                                    <span className="custom-text-title">Изображение</span>
                                </div>
                                <div className="image-media-body">
                                    {isLoad && (
                                        <img src={require('./load.gif')}/>
                                    )}
                                    {editableAnswer && editableAnswer.answer_image && (
                                        <div className="media">
                                            <span className="media-left">
                                                <img
                                                    className="media-object round-image"
                                                    src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${editableAnswer && editableAnswer.answer_image}`}
                                                    alt="" 
                                                />
                                                <a className="btn-icon" onClick={this.deleteImage}>
                                                    <i class="fas fa-times"></i>
                                                </a>
                                            </span>
                                        </div>
                                    )}
                                    <label htmlFor="getImage" className="btn btn-create img-upload">
                                        <FormattedMessage id="step3.clickToSetImage" defaultMessage="Нажмите, чтобы выбрать изображение" />
                                    </label>
                                    <input id="getImage" type="file" onChange={this.editImage} value={(editableAnswer && !editableAnswer.answer_image) ? '' : ''} style={{ display: 'none'}} />
                                </div>
                            </div>
                        )}
                        {(selectedQuest && selectedQuest.question_kind === 'option' || selectedQuest && selectedQuest.question_kind === 'motivator' || selectedQuest && selectedQuest.question_kind === 'option_image') && (
                        <div>
                        <fieldset className="form-group floating-label-form-group">
                            <label htmlFor="answer_factor">
                            <FormattedMessage id="step3.answerFactor" defaultMessage="Числовой фактор ответа" />
                            <Tooltip
                                header={Intl.getMessage('step3.tooltipAnswerFactorTitle')}
                                body={Intl.getMessage('step3.tooltipAnswerFactor')}
                                target="id14"
                                />
                            </label>
                            <input name="answer_factor" type="number" step="any" className="form-control" id="answer_factor" value={editableAnswer && editableAnswer.answer_factor || ''} placeholder="1.0" onChange={handleChangeEditAnswer} />
                        </fieldset>
                            {editableAnswer && editableAnswer.answer_factor && (
                            <fieldset className="form-group floating-label-form-group">
                                <label htmlFor="answer_factor_default">
                                <FormattedMessage id="step3.answerFactorDefault" defaultMessage="Значение невыбранного варианта" />
                                <Tooltip
                                header={Intl.getMessage('step3.tooltipAnswerFactorDefaultTitle')}
                                body={Intl.getMessage('step3.tooltipAnswerFactorDefault')}
                                target="id15"
                                />
                                </label>
                                <input name="answer_factor_default" type="number" step="any" className="form-control" id="answer_factor_default" value={editableAnswer && editableAnswer.answer_factor_default || ''} placeholder="1.0" onChange={handleChangeEditAnswer} />
                            </fieldset>
                            )}
                            <div>
                            <fieldset className="form-group position-relative">
                            <select className="form-control" name="discount_action" id="DefaultSelect" onChange={(e) => this.handleChangeDiscount(e)}>
                                <option value="none" selected={editableAnswer && editableAnswer.discount_action === 'none' ? true : false}>{'Не изменять скидку'}</option>
                                <option value="increase" selected={editableAnswer && editableAnswer.discount_action === 'increase' ? true : false}>{'Прибавить скидку'}</option>
                                <option value="set_fixed" selected={editableAnswer && editableAnswer.discount_action === 'set_fixed' ? true : false}>{'Установить фикс. скидку'}</option>
                            </select>
                            </fieldset>
                        </div>
        
                        {editableAnswer && editableAnswer.discount_action === 'increase' && (
                        <div>
                            <label style={{color: "#545454", fontSize: '15px', fontWeight: '500', textTransform: 'uppercase' }}>Прибавить {this.props.discountUnit}</label>
                            <input name="increase" type="number" value={editableAnswer && editableAnswer.discount_increase_by} step="any" className="form-control" id="answer_factor_default" placeholder="1.0" onChange={(e) => this.handleChangeDiscount(e, 'discount_increase_by')} />
                        </div>
                        )}
        
                        {editableAnswer && editableAnswer.discount_action === 'set_fixed' && (
                        <div>
                            <label>Установить скидку равной {this.props.discountUnit}</label>
                            <input name="set_fixed" type="number" value={editableAnswer && editableAnswer.discount_set} step="any" className="form-control" id="answer_factor_default" placeholder="10" onChange={(e) => this.handleChangeDiscount(e, 'discount_set')} />
                        </div>
                        )}
        
                        </div>
                        )}
                        {(selectedQuest && selectedQuest.question_kind === 'option' || selectedQuest && selectedQuest.question_kind === 'option_image' || selectedQuest && selectedQuest.question_kind === 'motivator') && (
                        <>
                            <fieldset className="skin skin-square" onClick={()=>this.handleCheckAnswer('custom', editableAnswer && !editableAnswer.custom)} style={{ cursor: 'pointer', paddingTop: '10px' }}>
                                <Switch toggle={editableAnswer && editableAnswer.custom}/>
                                {'Свой вариант'}
                            </fieldset>
                            <fieldset className="skin skin-square" onClick={()=>this.handleCheckAnswer('is_correct', editableAnswer && !editableAnswer.is_correct)} style={{ cursor: 'pointer', paddingTop: '10px' }}>
                                <Switch toggle={editableAnswer && editableAnswer.is_correct}/>
                                {'Правильный ответ'} 
                            </fieldset>
                        </>
                        )}
                    
                    </div>
                    {activeType === 'addAnswer' && <div className="modal-footer">
                        <input type="reset" onClick={cancelEditAnswer} className="btn btn-create reset" data-dismiss="modal" value={Intl.getMessage('step3.cancel')} />
                        <input type="submit" className="btn btn-create submit" value='Добавить' />
                    </div>}
                </form>
            </div>
        )
    }
}

export default AnswerAction;