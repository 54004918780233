import React from 'react'
import { connect } from "react-redux"
import { CustomInput, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Tooltip } from '../../components'
import { Intl } from '../../utils'
import { Switch } from '../index'
import { UploadService } from '../../services'
import Result from './Result'
import Cards from './Cards'
import {SketchPicker} from "react-color"
import * as emailProfileActions from '../../actions/emailProfile';
import './style.scss';

const config = require('../../config/config')

const imageSize = '200,fit'

class Step2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      load: false,
      inputClick: true,
      json: this.props.data,
      modal: false,
      newResultType: 'text',
      resultTitle: '',
      resultDescription: '',
      resultImage: '',
      resultUrl: '',
      resultDelay: 0,
      resultId: null,
      previewTitle: '',
      previewDesc: '',
      previewImage: '',
      isVisible: true,
      displayCondition: 'range',
      displayConditionMax: 0,
      displayConditionMin: 0,
      displayConditionValue: 0,
      displayConditionError: null,
      displayPickerBackgroundColor: false,
      displayPickerTextColor: false,

      text_color: '#ffffff',
      background_color: '#84acff',
      background_image: null,

      isLoadImage: false,
      isLoadFile: false,

      resultFile: null,
      resultFileName: null,

      extendedResult: false,
      sendResultEmail: false,

      resultProfile: null,
      emailSubject: null,

      overrideText: false,
      mailTitle: null,
      mailDescription: null,

      emailProfileUsername: null
    }

    this.initialModalData = {
      newResultType: 'text',
      resultTitle: '',
      resultDescription: '',
      resultImage: '',
      resultUrl: '',
      resultDelay: 0,
      resultId: null,
      previewTitle: '',
      previewDesc: '',
      previewImage: '',
      isVisible: true,
      displayCondition: 'range',
      displayConditionMax: 0,
      displayConditionMin: 0,
      displayConditionValue: 0,
      displayConditionError: null,
      displayPickerBackgroundColor: false,
      displayPickerTextColor: false,
    }

    this.deleteResult = this.deleteResult.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleResultsTypeChange = this.handleResultsTypeChange.bind(this)
    this.handleNewResultTypeChange = this.handleNewResultTypeChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.newResult = this.newResult.bind(this)
    this.editResult = this.editResult.bind(this)
    this.clearState = this.clearState.bind(this)
    this.changeShowResult = this.changeShowResult.bind(this)
    this.changeShowResultBeforeContact = this.changeShowResultBeforeContact.bind(this)
    this.changeContactsButtonText = this.changeContactsButtonText.bind(this)
    this.preview = this.preview.bind(this)
    this.addFormula = this.addFormula.bind(this)
    this.handleChangeFormula = this.handleChangeFormula.bind(this)
    this.getImage = this.getImage.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.checkVisibility = this.checkVisibility.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.openPickerBackgroundColor = this.openPickerBackgroundColor.bind(this)
    this.changePickerBackgroundColor = this.changePickerBackgroundColor.bind(this)
    this.chooseBackgroundColor = this.chooseBackgroundColor.bind(this)
    this.openPickerTextColor = this.openPickerTextColor.bind(this)
    this.chooseTextColor = this.chooseTextColor.bind(this)
    this.changePickerTextColor = this.changePickerTextColor.bind(this)
    this.getBackgroundImage = this.getBackgroundImage.bind(this)
    this.deleteResultImage = this.deleteResultImage.bind(this)
    this.handleChangeResultProfile = this.handleChangeResultProfile.bind(this)
    this.handleChangeEmailSubject = this.handleChangeEmailSubject.bind(this)

    this.getResultFile = this.getResultFile.bind(this)
    this.deleteResultFile = this.deleteResultFile.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)

    const { getListEmailProfile } = this.props
    getListEmailProfile()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const panel = document.getElementById('controlPanel')
    this.setState({ isVisible: this.checkVisibility(panel) })
  }

  checkVisibility(el) {
    if (el) {
      const rect = el.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    }
  }

  toggle() {
    const { modal } = this.state;
    this.setState({
      modal: true,
    })
    // if (modal) this.clearState()
  }

  clearState() {
    this.setState({
      resultId: null,
      resultTitle: '',
      resultDescription: '',
      resultUrl: '',
      resultDelay: 0,
      resultImage: '',
    })
  }

  preview(id, event) {
    const { json, modal } = this.state
    const result = json.results[id];
    const target = event && event.target;
    if(target){
        const crash = !!target.closest('.fa-times');
        !crash && this.editResult(id);
    }

    // if (result) {
    //   if (result.result_kind === 'text' || result.result_kind === 'extended') {
    //     this.setState({
    //       previewTitle: result.result_title,
    //       previewDesc: result.result_description,
    //       previewImage: result.result_image,
    //       modal: !modal
    //     })
    //   }
    //   else {
    //     this.setState({previewTitle: result.result_url, previewDesc: '', modal: false})
    //   }
    // }
  }

  editResult(id) {
    const { json } = this.state
    this.setState({
      modal: true,
      resultId: id,
      newResultType: json.results[id].result_kind,
      resultTitle: json.results[id].result_title,
      resultDescription: json.results[id].result_description,
      resultImage: json.results[id].result_image,
      resultUrl: json.results[id].result_url,
      resultDelay: json.results[id].result_delay,
      overrideText: json.results[id].override_text,
      emailProfileUsername: json.results[id].mail_profile_id,
      emailSubject: json.results[id].mail_subject,
      resultFile: json.results[id].mail_attachment,
      resultFileName: json.results[id].result_file_name,
      sendResultEmail: json.results[id].send_result_email,
      displayCondition: json.results[id].display_condition,
      displayConditionMin: json.results[id].display_condition_min,
      displayConditionMax: json.results[id].display_condition_max,
      displayConditionValue: json.results[id].display_condition_value,
      mailTitle: json.results[id].mail_title,
      mailDescription: json.results[id].mail_description,
    }, this.props.refreshState(this.state.json))

    if (json.results[id].result_kind !== 'link') {
      this.setState({
        modal: true,
        resultId: id,
        newResultType: json.results[id].result_kind,
        resultTitle: json.results[id].result_title,
        resultDescription: json.results[id].result_description,
        resultImage: json.results[id].result_image,
        resultUrl: json.results[id].result_url,
        resultDelay: json.results[id].result_delay,

        text_color: json.results[id].text_color,
        background_color: json.results[id].background_color,
        background_image: json.results[id].background_image,
        emailSubject: json.results[id].mail_subject,
        resultFile: json.results[id].mail_attachment,
        resultFileName: json.results[id].result_file_name,
        sendResultEmail: json.results[id].send_result_email,
        emailProfileUsername: json.results[id].mail_profile_id,
        overrideText: json.results[id].override_text,
        mailTitle: json.results[id].mail_title,
        mailDescription: json.results[id].mail_description,
      }, this.props.refreshState(this.state.json))
    }
  }

  handleResultsTypeChange(event) {
    console.log('handleResultsTypeChange')
    const { json } = this.state
    const value = event && event.target.value;
    json.results_type = value;

    this.setState({ json, modal: false, resultId: null, inputClick: value === 'mixed' ? true :  false }, this.props.refreshState(this.state.json))
  }

  handleNewResultTypeChange(event) {
    console.log('handleNewResultTypeChange')
    this.setState({
      newResultType: event.target.value,
    }, this.props.refreshState(this.state.json))
  }

  handleChange(event) {
    const { target: { value, name } } = event
    this.setState({
      [name]: value,
    }, this.props.refreshState(this.state.json))
  }

  handleChangeFormula(event) {
    console.log('handleChangeFormula')
    const { target: { value, name } } = event
    const { json } = this.state
    json[name] = value
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  deleteResult(id, questionIds) {
    const { json } = this.state
    json.questions.map((question, questionId) => {
      question.answers.map((answer, answerId) => {
        if (answer.result_binding === id) delete json.questions[questionId].answers[answerId].result_binding
      })
    })
    json.results.splice(id, 1)
    this.setState({ json, modal: false }, this.props.refreshState(this.state.json))
  }

  changeShowResult() {
    console.log('changeShowResult')
    const { json } = this.state
    json.show_results = !json.show_results
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  changeShowResultBeforeContact() {
    console.log('changeShowResultBeforeContact')
    const { json } = this.state
    json.results_before_contacts = !json.results_before_contacts
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  changeContactsButtonText(e) {
    const { json } = this.state
    json.contacts_button_text = e.target.value
    this.setState({ contactButtonText: e.target.value, json }, this.props.refreshState(this.state.json))
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  addFormula(formula) {
    const { inputClick } = this.state
    let position = 0
    if (inputClick) {
      position = document.getElementById('results_formula').selectionStart
    }
    const { json } = this.state
    const start = json.results_formula.substring(0, position)
    const end = json.results_formula.substring(position)
    json.results_formula = `${start}${formula}${end}`
    this.setState({ json }, this.props.refreshState(this.state.json))
  }

  async getImage(event) {
    this.setState({ load: true })
    const data = new FormData()
    const file = event.target.files[0]
    let resultImage
    if (file) {
      data.append('file', file)
      try {
        resultImage = await UploadService.uploadImage(data)
        console.log(resultImage)
      } catch (error) {
        console.log(error)
      }
    }

    this.setState({ resultImage, load: false }, this.props.refreshState(this.state.json))
  }

  deleteImage() {
    const resultImage = ''
    this.setState({ resultImage }, this.props.refreshState(this.state.json))
  }

  newResult(e) {
    e.preventDefault()
    const {
      newResultType, resultTitle, resultDescription, resultUrl, resultId, resultImage,
      displayCondition, displayConditionValue, displayConditionMax, displayConditionMin,
      background_color, text_color, background_image, emailSubject, resultFile, resultFileName, sendResultEmail,
      emailProfileUsername, displayConditionError, overrideText, mailDescription, mailTitle, resultDelay
    } = this.state
    const { json, json: { results_type } } = this.state
    let newResult
    if (newResultType !== 'link') {
      newResult = {
        result_kind: newResultType,
        result_title: resultTitle,
        result_description: resultDescription,
        result_image: resultImage,
        background_image: background_image,
        background_color: background_color,
        text_color: text_color,
        mail_subject: emailSubject,
        mail_attachment: resultFile,
        result_file_name: resultFileName,
        send_result_email: sendResultEmail,
        mail_profile_id: emailProfileUsername,
        override_text: overrideText,
        mail_title: mailTitle,
        mail_description: mailDescription,
        display_condition: displayCondition,
        display_condition_min: displayConditionMin,
        display_condition_max: displayConditionMax,
        display_condition_value: displayConditionValue
      }
    } else {
      newResult = {
        result_url: resultUrl,
        result_delay: resultDelay,
        result_kind: newResultType,
        result_title: resultTitle,
        result_description: resultDescription,
        result_image: resultImage,
        background_image: background_image,
        background_color: background_color,
        text_color: text_color,
        mail_subject: emailSubject,
        mail_attachment: resultFile,
        result_file_name: resultFileName,
        send_result_email: sendResultEmail,
        mail_profile_id: emailProfileUsername,
        override_text: overrideText,
        mail_title: mailTitle,
        mail_description: mailDescription,
        display_condition: displayCondition,
        display_condition_min: displayConditionMin,
        display_condition_max: displayConditionMax,
        display_condition_value: displayConditionValue
      }
    }

    if (!sendResultEmail) {
      newResult = { 
        ...newResult, 
        override_text: false,
        mail_title: '',
        mail_description: '',
        mail_profile_id: '',
        mail_subject: '',
        mail_attachment: '',
      }
    }

    if (!overrideText) {
      newResult = { 
        ...newResult, 
        mail_title: '',
        mail_description: '',
      }
    }

    if (results_type === 'mixed') {
      if (displayCondition === 'range') {
        newResult.conditions = [{
          type: displayCondition,
          max_value: displayConditionMax,
          min_value: displayConditionMin
        }]
      } else {
        newResult.conditions = [{
          type: displayCondition,
          value: displayConditionValue
        }]
      }
    }

    if(displayCondition === 'range') {
      if (parseInt(displayConditionMin) > parseInt(displayConditionMax)) {
        this.setState({
          displayConditionError: Intl.getMessage('step2.displayConditionError')
        })
        return false
      }

      if (displayConditionError) {
        this.setState({
          displayConditionError: null
        })
      }
    }

    if (resultId || resultId === 0) {
      json.results[resultId] = newResult
      this.preview(resultId)
    } else json.results.push(newResult)
    this.setState({ json, modal: false }, this.props.refreshState(this.state.json))
    this.clearState()
  }

  handleChangeDisplayCondition = (event) => {
    this.setState({
      displayCondition: event.target.value,
      displayConditionError: null
    })
  }

  handleChangeDisplayConditionValue = (event) => {
    this.setState({
      displayConditionValue: parseInt(event.target.value)
    })
  }

  handleChangeDisplayConditionMin = (event) => {
    this.setState({
      displayConditionMin: parseInt(event.target.value)
    })
  }

  handleChangeDisplayConditionMax = (event) => {
    this.setState({
      displayConditionMax: parseInt(event.target.value)
    })
  }

  openPickerBackgroundColor() {
    const { displayPickerBackgroundColor } = this.state
    this.setState({ displayPickerBackgroundColor: !displayPickerBackgroundColor })
  }

  changePickerBackgroundColor(color) {
    this.setState({
      background_color: color.hex
    })
  }

  chooseBackgroundColor() {
    this.setState({ displayPickerBackgroundColor: false })
  }

  openPickerTextColor() {
    const { displayPickerTextColor } = this.state
    this.setState({ displayPickerTextColor: !displayPickerTextColor })
  }

  chooseTextColor() {
    this.setState({ displayPickerTextColor: false })
  }

  changePickerTextColor(color) {
    this.setState({
      text_color: color.hex
    })
  }

  async getBackgroundImage(event) {
    console.log('getBackgroundImage')
    let newImage = null
    this.setState({ isLoadImage: true })
    const data = new FormData()
    const file = event.target.files[0]
    if (file) {
      data.append('file', file)
      newImage = data
      try {
        let response = await fetch(config.IMAGE_UPLOAD_URL, {
          method: 'POST',
          body: newImage,
        })
        response = await response.json()
        this.setState({
          background_image: response.Url,
          isLoadImage: false }
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  async getResultFile(event) {
    let newFile = null
    this.setState({ isLoadFile: true })
    const data = new FormData()
    const file = event.target.files[0]
    if (file) {
      data.append('file', file)
      newFile = data
      try {
        let response = await fetch(config.IMAGE_UPLOAD_URL, {
          method: 'POST',
          body: newFile,
        })
        response = await response.json()
        this.setState({
          resultFile: response.Url,
          resultFileName: file.name,
          isLoadFile: false }
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  deleteResultImage() {
    this.setState({
      background_image: null,
      isLoadImage: false
    })
  }

  deleteResultFile() {
    this.setState({
      resultFile: null,
      isLoadFile: false,
      resultFileName: null
    })
  }

  handleChangeResultProfile(event) {
    this.setState({
      emailProfileUsername: event.target.value,
      resultProfile: event.target.value
    })
  }

  handleChangeEmailSubject(event) {
    this.setState({
      emailSubject: event.target.value
    })
  }

  clearResultData = (resetDisplayDondition = true) => {
    if(resetDisplayDondition) {
      this.setState({
        text_color: '#ffffff',
        background_color: '#84acff',
        background_image: null,
        isLoadImage: false,
        isLoadFile: false,
        resultFile: null,
        resultFileName: null,
        extendedResult: false,
        sendResultEmail: false,
        resultProfile: null,
        emailSubject: null,
        overrideText: false,
        mailTitle: null,
        mailDescription: null,
        emailProfileUsername: null,
        displayConditionMax: 0,
        displayConditionMin: 0,
        displayConditionValue: 0,
        displayCondition: 'range',
        displayConditionError: null,
      })
    } else {
      this.setState({
        text_color: '#ffffff',
        background_color: '#84acff',
        background_image: null,
        isLoadImage: false,
        isLoadFile: false,
        resultFile: null,
        resultFileName: null,
        extendedResult: false,
        sendResultEmail: false,
        resultProfile: null,
        emailSubject: null,
        overrideText: false,
        mailTitle: null,
        mailDescription: null,
        emailProfileUsername: null,
        displayCondition: 'range',
        displayConditionError: null
      })
    }


  }

  render() {
    const {
      newResultType, resultTitle, resultDescription, resultUrl, previewTitle, previewDesc, previewImage, resultId, resultImage,
      load, isVisible, displayPickerBackgroundColor, background_color, displayPickerTextColor, text_color, isLoadImage, background_image,
      json: {
        results_formula, show_results, results_before_contacts, results_type, results, questions, contacts_button_text
      },
      displayCondition, displayConditionMax, displayConditionMin, displayConditionValue,
      extendedResult, sendResultEmail, resultProfile, emailSubject, overrideText, resultFile, isLoadFile, resultFileName,
      emailProfileUsername, displayConditionError, mailDescription, mailTitle, modal, inputClick, resultDelay
    } = this.state;

    let resultActive = inputClick;
    
    if(modal){
      resultActive = false;
    }
    if(results_type === 'computed'){
      resultActive = true;
    }

    let currentEmailProfile = emailProfileUsername

    const { emailProfileList } = this.props

    let profileListSelect = []
    let showProfileList = false
    if(emailProfileList) {
      showProfileList = emailProfileList.length > 0
    }
    if(showProfileList) {
      emailProfileList.map(profile => {
        profileListSelect.push({
          id: profile.id,
          value: profile.username,
        })
      })

      if(!currentEmailProfile) {
        let wasDeletedProfile = true
        profileListSelect.map(select => {
          if (select.value === currentEmailProfile) {
            wasDeletedProfile = false
          }
        })

        if (wasDeletedProfile) {
          currentEmailProfile = null
        }
      }
    }

    const finnalyResult = (
    <Result
      toggle={this.toggle}
      resultId={resultId}
      isVisible={isVisible}
      results={results}
      questions={questions}
      results_formula={results_formula}
      handleChangeFormula={(event) => { this.handleChangeFormula(event) }}
      setInputClick={() => { this.setState({ inputClick: true, modal: false, resultId: null }) }}
      deleteResult={this.deleteResult}
      editResult={this.editResult}
      preview={this.preview}
      type={results_type}
      clearResultData={this.clearResultData}
      setResultType={() => {
        this.setState({
          newResultType: 'text',
          modal: true,
          extendedResult: false,
          ...this.initialModalData
        }, this.props.refreshState(this.state.json))
      }}
    />
    )

    const finnalyCard = (
      <Cards
        show_results={show_results}
        previewTitle={previewTitle}
        imageSizeUrl={config.IMAGE_RESIZE_URL}
        imageSize={imageSize}
        previewImage={previewImage}
        previewDesc={previewDesc}
        isVisible={isVisible}
        toggle={this.toggle}
        questions={questions}
        addFormula={this.addFormula}
        type={results_type}
        clearResultData={this.clearResultData}
        setResultType={() => {
          this.setState({
            newResultType: 'text',
            extendedResult: false,
            modal: true,
          }, this.props.refreshState(this.state.json))
        }}
    />)

    const editCard = (
        <div className="edit-card">
          <form action="" onSubmit={this.newResult}>
            <div className="modal-body">
              <FormGroup>
                <Label>
                  <FormattedMessage id="step2.typeNewResult" defaultMessage="Тип результата" />
                </Label>
                <FormGroup tag="fieldset">
                  <FormGroup check inline>
                    <Label onClick={() => {
                      this.setState({
                        newResultType: 'text',
                        extendedResult: false,
                      }, this.props.refreshState(this.state.json))
                    }}>
                      <CustomInput
                        type="radio"
                        className="inline"
                        id="textRadio"
                        name="typeRadio"
                        label={Intl.getMessage('step2.text')}
                        value={'text'}
                        checked={newResultType === 'text'}
                      />
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label onClick={() => {
                      this.setState({
                        newResultType: 'link',
                        extendedResult: false,
                      }, this.props.refreshState(this.state.json))
                    }}>
                      <CustomInput
                        type="radio"
                        className="inline"
                        id="linkRadio"
                        name="typeRadio"
                        label={Intl.getMessage('step2.link')}
                        value={'link'}
                        checked={newResultType === 'link'}
                      />
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Label onClick={() => {
                      this.setState({
                        newResultType: 'extended',
                        extendedResult: true,
                      }, this.props.refreshState(this.state.json))
                    }}>
                      <CustomInput
                        type="radio"
                        className="inline"
                        id="extendedRadio"
                        name="typeRadio"
                        label={Intl.getMessage('step2.newResultExtendedType')}
                        value={'extended'}
                        checked={newResultType === 'extended'}
                      />
                    </Label>
                  </FormGroup>

                </FormGroup>
              </FormGroup>
              {(newResultType === 'text' || newResultType === 'extended') && (
                <div>
                  <fieldset className="form-group floating-label-form-group">
                    <label htmlFor="email">
                      <FormattedMessage id="step2.textResult" defaultMessage="Текст" />
                    </label>
                    <input name="resultTitle" required type="text" className="form-control" id="email" placeholder={Intl.getMessage('step2.placeholderTitle')} onChange={this.handleChange} value={resultTitle} />
                  </fieldset>
                  <br />
                  <fieldset className="form-group floating-label-form-group">
                    <label htmlFor="title1">
                      <FormattedMessage id="step2.titleResult" defaultMessage="Заголовок" />
                    </label>
                    <textarea name="resultDescription" required className="form-control" id="title1" rows="5" placeholder={Intl.getMessage('step2.placeholderDesc')} onChange={this.handleChange} value={resultDescription} />
                  </fieldset>
                  <fieldset className="form-group floating-label-form-group upload-img">
                    <p>Изображение</p>
                    <input id="getImage" type="file" onChange={this.getImage} value={(!resultImage) ? '' : ''} style={{ display: 'none' }} />
                    {load && <img src={require('./load.gif')} />}
                    <div className="img-content">
                      {resultImage && (
                        <div className="media">
                          <span className="media-left">
                            <img
                              className="media-object round-image"
                              src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${resultImage}`}
                              alt=""
                            />
                          </span>
                          <span className="media-right">
                            <a className="btn-icon" onClick={() => this.deleteImage()}>
                              <i class="fas fa-times"></i>
                            </a>
                          </span>
                        </div>
                      )}
                      <label htmlFor="getImage" className="btn btn-create">
                        <FormattedMessage id="step2.resultImage" defaultMessage="Нажмите, чтобы выбрать изображение" />
                      </label>
                    </div>
                  </fieldset>
                </div>
              )}
              {newResultType === 'link' && (
                <div>
                  <fieldset className="form-group floating-label-form-group">
                    <label htmlFor="email">URL </label>
                    <input name="resultUrl" type="url" required className="form-control" id="email" placeholder={Intl.getMessage('step2.placeholderUrl')} onChange={this.handleChange} value={resultUrl || ''} />
                  </fieldset>
                  <fieldset className="form-group floating-label-form-group">
                    <label htmlFor="email">Задержка перехода, сек</label>
                    <input name="resultDelay" type="number" className="form-control" id="delay" onChange={this.handleChange} value={resultDelay || ''} />
                  </fieldset>
                </div>
              )}
              {
                newResultType === 'extended' && (
                  <React.Fragment>
                    <fieldset className="form-group floating-label-form-group">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              {'Цвет фона'}
                            </label>
                            <br />
                            <div className="swatch">
                              {!displayPickerBackgroundColor && (
                                <div className="color" style={{ background: background_color}} onClick={this.openPickerBackgroundColor} />
                              )}
                              {displayPickerBackgroundColor && (
                                <div onClick={this.chooseBackgroundColor}>
                                  <FormattedMessage id="step5.choose" defaultMessage="Выбрать" />
                                </div>
                              )}

                            </div>
                            {displayPickerBackgroundColor && (
                              <div className="popoverColorPicker">
                                <SketchPicker color={background_color} onChange={this.changePickerBackgroundColor} disableAlpha={true} />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              {'Цвет текста'}
                            </label>
                            <br />
                            <div className="swatch">
                              {!displayPickerTextColor && (
                                <div className="color" style={{ background: text_color}} onClick={this.openPickerTextColor} />
                              )}
                              {displayPickerTextColor && (
                                <div onClick={this.chooseTextColor}>
                                  <FormattedMessage id="step5.choose" defaultMessage="Выбрать" />
                                </div>
                              )}
                            </div>
                            {displayPickerTextColor && (
                              <div className="popoverColorPicker">
                                <SketchPicker color={text_color} onChange={this.changePickerTextColor} disableAlpha={true} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <fieldset className="form-group floating-label-form-group upload-img">
                              <input id="getBackgroundImage" type="file" onChange={this.getBackgroundImage} value={(!background_image) ? '' : ''} style={{ display: 'none' }} />
                              <div className="img-content">
                                {isLoadImage && (
                                  <img src={require('./load.gif')} />
                                )}
                                {background_image && (
                                  <div className="media">
                                    <span className="media-left">
                                      <img
                                        className="media-object round-image"
                                        src={`${config.IMAGE_RESIZE_URL}/${imageSize}/${background_image}`}
                                        alt=""
                                      />
                                    </span>
                                    <span className="media-right">
                                      <a className="btn-icon" onClick={() => this.deleteResultImage()}>
                                        <i className="fas fa-times" />
                                      </a>
                                    </span>
                                  </div>
                                )}
                                <label htmlFor="getBackgroundImage" className="btn btn-create">
                                  <FormattedMessage id="step2.backgroundImage" defaultMessage="Нажмите, чтобы выбрать изображение" />
                                </label>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </React.Fragment>
                )
              }
              <fieldset className="form-group floating-label-form-group switch-label">
                <fieldset className="skin skin-square" onClick={() => this.setState({sendResultEmail: !sendResultEmail})} style={{ cursor: 'pointer' }}>
                  <Switch toggle={sendResultEmail}/>
                  <FormattedMessage id="step2.sendResultEmail" defaultMessage="Отправлять письмо с результатом на email клиента" />
                  <Tooltip
                    header={Intl.getMessage('step2.tooltipSendResultEmailTitle')}
                    body={Intl.getMessage('step2.tooltipSendResultEmail')}
                    target="id1343"
                />
                </fieldset>
              </fieldset>
              {
                (sendResultEmail && showProfileList) && (
                  <React.Fragment>
                    <fieldset className="form-group floating-label-form-group">
                      <label>
                        <FormattedMessage id="mainMenu.profile" defaultMessage="Профиль" />
                      </label>

                      <select required className="form-control" value={emailProfileUsername} onChange={(event) => {
                        this.handleChangeResultProfile(event)
                      }}>
                        <option></option>
                        {
                          profileListSelect.map(profile => (
                            <option key={profile.id} value={profile.id}>{profile.value}</option>
                          ))
                        }
                      </select>
                    </fieldset>

                    {
                      emailProfileUsername && (
                        <React.Fragment>
                          <fieldset className="form-group floating-label-form-group">
                            <label>
                              <FormattedMessage id="step2.emailSubject" defaultMessage="Тема письма" />
                            </label>
                            <input required type="text" className="form-control" placeholder={Intl.getMessage('step2.emailSubject')} onChange={this.handleChangeEmailSubject} value={emailSubject} />
                          </fieldset>

                          <fieldset className="form-group floating-label-form-group switch-label">
                            <fieldset className="skin skin-square" onClick={() => this.setState({overrideText: !overrideText})} style={{ cursor: 'pointer' }}>
                              <Switch toggle={overrideText}/>
                              <FormattedMessage id="step2.overrideText" defaultMessage="Переопределить текст результата" />
                            </fieldset>
                          </fieldset>

                          {
                            overrideText && (
                              <React.Fragment>
                                <fieldset className="form-group floating-label-form-group">
                                  <label htmlFor="email">
                                    <FormattedMessage id="step2.titleResult" defaultMessage="Заголовок" />
                                  </label>
                                  <input name="resultTitle"
                                         required
                                         type="text"
                                         className="form-control"
                                         id="email"
                                         placeholder={Intl.getMessage('step2.placeholderTitle')}
                                         onChange={(e) => this.setState({ mailTitle: e.target.value })}
                                         value={mailTitle}
                                  />
                                </fieldset>
                                <br />
                                <fieldset className="form-group floating-label-form-group">
                                  <label htmlFor="title1">
                                    <FormattedMessage id="step2.textResult" defaultMessage="Текст" />
                                  </label>
                                  <textarea name="resultDescription"
                                            required
                                            className="form-control"
                                            id="title1"
                                            rows="5"
                                            placeholder={Intl.getMessage('step2.placeholderDesc')}
                                            onChange={(e) => this.setState({ mailDescription: e.target.value })}
                                            value={mailDescription} />
                                </fieldset>
                              </React.Fragment>
                            )
                          }

                          {overrideText && (
                            <fieldset className="form-group floating-label-form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <fieldset className="form-group floating-label-form-group upload-file">
                                      <input id="getResultFile" type="file" onChange={this.getResultFile} value={(!resultFile) ? '' : ''} style={{ display: 'none' }} />
                                      <label htmlFor="getResultFile" className="btn btn-create">
                                        <FormattedMessage id="step2.resultFile" defaultMessage="Нажмите, чтобы выбрать файл" />
                                      </label>
                                      <br />
                                      <br />
                                      {isLoadFile && (
                                        <img src={require('./load.gif')} />
                                      )}
                                      {resultFileName && (
                                        <div className="img-content">
                                          <div className="media">
                                            <span className="media-left">
                                              {resultFileName}
                                            </span>
                                            <span className="media-right">
                                              <a className="btn-icon btn red" onClick={() => this.deleteResultFile()}>
                                                <i className="fas fa-times"/>
                                              </a>
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </fieldset>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          )}
                        </React.Fragment>
                      )
                    }

                  </React.Fragment>
                )
              }
              {
                (sendResultEmail && !showProfileList) && (
                  <div>
                          <span>
                            Необходимо сначала создать профиль для отправки в почты в
                            <Link to="/profile"> настройках</Link>
                          </span>
                  </div>
                )
              }
              {
                results_type === 'mixed' && (
                  <fieldset className="form-group position-relative">
                    <label>
                      <FormattedMessage id="step2.displayCondition" defaultMessage="Условия показа результата" />
                      <Tooltip
                        header={Intl.getMessage('step2.tooltipConditionTitle')}
                        body={Intl.getMessage('step2.tooltipCondition')}
                        target="id132"
                      />
                    </label>
                    <br />
                    <label style={{color: 'red'}}>
                      {displayConditionError}
                    </label>
                    <select className="form-control" value={displayCondition} onChange={this.handleChangeDisplayCondition}>
                      <option value="range">В диапазоне</option>
                      <option value="gt">больше</option>
                      <option value="lt">меньше</option>
                    </select>
                    {
                      displayCondition === 'range' && (
                        <div className="row range-label">
                          <div className="col-md-6">
                            <label>
                              {Intl.getMessage('step2.placeholderFrom')}
                            </label>
                            <input required type="number" className="form-control" onChange={this.handleChangeDisplayConditionMin} value={displayConditionMin} />
                          </div>
                          <div className="col-md-6">
                            <label>
                              {Intl.getMessage('step2.placeholderTo')}
                            </label>
                            <input required type="number" className="form-control" onChange={this.handleChangeDisplayConditionMax} value={displayConditionMax} />
                          </div>
                        </div>
                      )
                    }
                    {
                      (displayCondition === 'lt' || displayCondition === 'gt') && (
                        <div className="row range-label">
                          <div className="col-md-12">
                            <label>
                              <FormattedMessage id={Intl.getMessage('step2.placeholderDisplayConditionValue')} defaultMessage={Intl.getMessage('step2.placeholderDisplayConditionValue')} />
                            </label>
                            <input required type="number" className="form-control" placeholder={Intl.getMessage('step2.placeholderDisplayConditionValue')} onChange={this.handleChangeDisplayConditionValue} value={displayConditionValue} />
                          </div>
                        </div>
                      )
                    }
                  </fieldset>
                )
              }
            </div>
            <div className="modal-footer">
              <input type="reset" className="btn btn-create" onClick={()=>this.setState({modal: false})} value={Intl.getMessage('step2.close')} />
              {resultId === null && (<input type="submit" className="btn btn-create green" value={Intl.getMessage('step2.add')} />)}
              {resultId !== null && (<input type="submit" className="btn btn-create green" value={Intl.getMessage('step2.change')} />)}
            </div>
          </form>
        </div>
    )

    // RENDER
    return (
      <fieldset className="step-2-content">
        <div className="step-2-header-options">
          <div>
            <div className="form-group ">
              <input
                type="checkbox"
                id="switcheryColor"
                className="switchery"
                data-color="success"
                style={{ display: 'none' }}
              />
              <div onClick={this.changeShowResult}>
                <Switch toggle={show_results} />
                <label className="ml-1"><FormattedMessage id="step2.showResult" defaultMessage="Показывать результаты?" /></label>
              </div>

              <div onClick={this.changeShowResultBeforeContact}>
                <Switch toggle={results_before_contacts} />
                <label className="ml-1"><FormattedMessage id="step2.showResultBeforeContact" defaultMessage="Показывать до формы контактов" /></label>
              </div>
              {results_before_contacts &&
                (<fieldset className="form-group floating-label-form-group label-text-before-contacts">
                    <label htmlFor="buttonText">
                      <FormattedMessage id="step2.contactsButtonText" defaultMessage="Текст кнопки перехода к контактам" />
                    </label>
                    <input
                      name="buttonText"
                      required
                      type="text"
                      className="form-control"
                      id="buttonText"
                      placeholder={Intl.getMessage('step2.placeholderButton')}
                      onChange={(e) => this.changeContactsButtonText(e)}
                      value={contacts_button_text || ''}
                    />
                  </fieldset>)}
            </div>
            {show_results && (
              <span className="header-options-results">
              <FormGroup>
                <Label><FormattedMessage id="step2.typeResult" defaultMessage="Вид результатов" /></Label>
                {/* <Tooltip
                  header={Intl.getMessage('step2.tooltipResultTypeTitle')}
                  body={Intl.getMessage('step2.tooltipResultType')}
                  target="id1"
                /> */}
                <FormGroup tag="fieldset">
                  <FormGroup check inline>
                    <Label>
                      <CustomInput
                        type="radio"
                        className="inline"
                        id="exampleCustomRadio"
                        name="customRadio"
                        label={Intl.getMessage('step2.fixed')}
                        value="fixed"
                        checked={results_type === 'fixed'}
                        onChange={this.handleResultsTypeChange}
                      />
                      <div className="result-info">
                          <p>По окончанию отображается какое-либо сообщение или происходит переход на URL.</p>
                      </div>
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label>
                      <CustomInput
                        type="radio"
                        className="inline"
                        id="exampleCustomRadio2"
                        name="customRadio"
                        label={Intl.getMessage('step2.computed')}
                        value="computed"
                        checked={results_type === 'computed'}
                        onChange={this.handleResultsTypeChange}
                      />
                      <div className="result-info">
                          <p>Результат рассчитывается по формуле на основании введенных клиентом данных</p>
                      </div>
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label>
                      <CustomInput
                        type="radio"
                        className="inline"
                        id="exampleCustomRadio3"
                        name="customRadio"
                        label={Intl.getMessage('step2.mixed')}
                        value="mixed"
                        checked={results_type === 'mixed'}
                        onChange={this.handleResultsTypeChange}
                      />
                      <div className="result-info">
                          <p>Комбинированный вариант, когда клиенту показывается расчет и далее происходит конкретное действие</p>
                      </div>
                    </Label>
                  </FormGroup>
                </FormGroup>
              </FormGroup>
            </span>
            )}
          </div>
        </div>
        <div className={`row ${(!results.length && results_type === 'fixed') ? 'drop' : ''}`}>
          <div className={`${results_type === 'fixed' ? 'fixed-container' : (results_type === 'computed' || resultId===null && resultActive) ? 'computed' : ''} col-md-6`}>
            {finnalyResult}
          </div>
          {results_type === 'fixed' ? 
            modal && 
            (<div className={`col-md-6 card right-side`}>
              {modal ? editCard : finnalyCard}
            </div>)
          : 
            (modal || resultActive) ? 
              (<div className={`col-md-6 card right-side`}>
              {modal ? editCard : finnalyCard}
            </div>) 
          : null
          }
        </div>
      </fieldset>
    )
  }
}

const mapStateToProps = state => ({
  emailProfileList: state.emailProfile.emailProfileList,
})

const mapDispatchToProps = {
  getListEmailProfile: emailProfileActions.getList,
}


export default connect(mapStateToProps, mapDispatchToProps)(Step2)
