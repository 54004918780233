/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import {
  Collapse, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import swal from 'sweetalert'
import { Intl } from '../../utils'
import './styles.css'

class Card extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isCollapsed: false,
      isOpenUpdateState: false,
      contacts: {},
      json_data: {},
      selectedLead: false,
    }

    this.toggleUpdateState = this.toggleUpdateState.bind(this)
    this.updateLeadState = this.updateLeadState.bind(this)
    this.showWarning = this.showWarning.bind(this)
    this.changeStatusLead = this.changeStatusLead.bind(this)
  }

  componentDidMount() {
    const { lead } = this.props
    const { contacts, json_data } = lead
    if (contacts) this.setState({ contacts: JSON.parse(contacts) })
    if (json_data) this.setState({ json_data: JSON.parse(json_data) })
  }

  componentWillReceiveProps(nextProps) {
    const { selectedLeads } = nextProps
    const { contacts, json_data, id } = nextProps.lead

    if (selectedLeads.length === 0) {
      this.setState({ selectedLead: false })
    } else {
      this.setState({ selectedLead: selectedLeads.includes(id) })
    }

    if (contacts) this.setState({ contacts: JSON.parse(contacts) })
    if (json_data) this.setState({ json_data: JSON.parse(json_data) })
  }

  toggleCollapse() {
    const { isCollapsed } = this.state
    this.setState({ isCollapsed: !isCollapsed })
  }

  toggleUpdateState() {
    const { isOpenUpdateState } = this.state
    this.setState({ isOpenUpdateState: !isOpenUpdateState })
  }

  updateLeadState(id, state) {
    const { updateLeadState } = this.props
    updateLeadState(id, state)
  }

  changeStatusLead() {
    const { selectedLead } = this.state
    const {
      selectLead, selectedLeads, updateDataForRequest, lead: { id }, page, leadFilter, quizFilter,
    } = this.props

    this.setState({ selectedLead: !selectedLead })
    selectLead({ selectedLeads, id })
    updateDataForRequest(page, leadFilter, quizFilter)
  }


  async showWarning() {
    const { openViewModal } = this.props

    try {
      const willCheckBalance = await swal({
        title: Intl.getMessage('indexPage.alertAreUSure'),
        text: Intl.getMessage('leadsPage.alertLead'),
        icon: 'warning',
        buttons: {
          cancel: {
            text: Intl.getMessage('indexPage.alertNoDeleted'),
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: Intl.getMessage('indexPage.alertYSure'),
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      })

      if (willCheckBalance) {
        openViewModal()
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const {
      isCollapsed, isOpenUpdateState, contacts, json_data, selectedLead,
    } = this.state

    const { lead, openViewModal } = this.props

    const {
      id, last_step, total_steps, state, response_date, is_paid, is_completed, is_online,
    } = lead;

    
    const stepPercent = last_step === 0 ? 0 : last_step*100/total_steps;
    const percent = stepPercent>0 && stepPercent<= 35 ? 25 : stepPercent>35 && stepPercent<= 65 ? 50 : stepPercent>65 && stepPercent<100 ? 75 : stepPercent;
    const confirmed = !is_completed && !is_online && percent===100 ? 'confirmed' : '';
    
    const jsonDataParse = lead && lead.json_data && JSON.parse(lead.json_data);
    const quizTitle = jsonDataParse && jsonDataParse.quiz_title;

    return (
      <div className="card">
        <Collapse isOpen={!isCollapsed} className="card-content">
          <div className="table-responsive">
            <table className="table table-column">
              <tbody>
                <tr>
                  <td width="5%">
                    <div className={`checkbox-container ${selectedLead ? 'checked' : ''}`} >
                      <label className={`container ${!is_paid && !is_online ? 'disabled' : ''}`} >
                        <input type="checkbox"  onClick={this.changeStatusLead} disabled={!is_paid && !is_online} checked={selectedLead} />
                        <span className={`${!is_paid && !is_online ? 'checkmark-disabled' : ''} checkmark`}></span>
                      </label>
                    </div>
                  </td>
                  <td width="20%" className="lead-response-date"><FormattedDate value={response_date * 1000} day="2-digit" month="long" year="numeric"/>
                    <FormattedTime value={response_date * 1000} />
                  </td>
                  <td width="15%" className="progress-percent">
                    <span>{percent!==100 ? 'Квиз не завершен' : (confirmed)  ? 'Не все шаги проейдены' : 'Квиз завершен'}</span>
                    <div class={`pie-wrapper pie-wrapper--solid progress-${percent} ${confirmed}`}>
                    </div>
                    Шаг {last_step} из {total_steps}&nbsp;
                  </td>
                  
                  {!is_paid && !is_online && (
                    <td width="20%" className="status">
                      <Dropdown className="btn-group mr-1 mb-1" isOpen={isOpenUpdateState} toggle={this.toggleUpdateState}>
                        <DropdownToggle className="btn btn-outline-light mr-1 mb-1">Скрытый</DropdownToggle>
                      </Dropdown>
                    </td>
                  )}  

                  {((is_paid && state) || (!is_online && is_paid)) && (
                    <td width="20%" className="status-lead-container status">
                      <Dropdown className="btn-group mr-1 mb-1" isOpen={isOpenUpdateState} toggle={this.toggleUpdateState}>
                        {state === 'done' && (<DropdownToggle className="dropdown-toggle" style={{color: '#00C87F'}}>{Intl.getMessage('leadsPage.done')}</DropdownToggle>)}
                        {state === 'archived' && (<DropdownToggle className="dropdown-toggle" style={{color: '#787878'}}>{Intl.getMessage('leadsPage.archived')}</DropdownToggle>)}
                        {state === 'new' && (<DropdownToggle className="dropdown-toggle" style={{color: '#00ADFF'}}>{Intl.getMessage('leadsPage.new')}</DropdownToggle>)}
                        {state === 'unfinished' && (<DropdownToggle className="dropdown-toggle" style={{color: 'black'}}>{Intl.getMessage('leadsPage.unfinished')}</DropdownToggle>)}
                        {state === 'seen' && (<DropdownToggle className="dropdown-toggle" style={{color: '#E0186C'}}>{Intl.getMessage('leadsPage.seen')}</DropdownToggle>)}
                        <DropdownMenu className="dropdown-menu arrow">
                          <DropdownItem onClick={() => this.updateLeadState(id, 'new')} className="dropdown-item" type="button">{Intl.getMessage('leadsPage.new')}</DropdownItem>
                          <DropdownItem onClick={() => this.updateLeadState(id, 'done')} className="dropdown-item" type="button">{Intl.getMessage('leadsPage.done')}</DropdownItem>
                          <DropdownItem onClick={() => this.updateLeadState(id, 'seen')} className="dropdown-item" type="button">{Intl.getMessage('leadsPage.seen')}</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </td>
                  )}  

                  {!is_paid && is_online && (
                    <td width="20%">
                      <Dropdown className="btn-group" isOpen={isOpenUpdateState} toggle={this.toggleUpdateState}>
                        <DropdownToggle className="btn btn-outline-light btn-min-width">В процессе</DropdownToggle>
                      </Dropdown>
                    </td>
                  )}

                  {is_online && !is_completed && (
                    <td width="30%" className="contact">
                      <span style={{color: '#ABABAB', fontSize: '15px'}}>{Intl.getMessage('leadsPage.contactsNotLeft')}</span>
                        <br/>
                       {is_online && <span style={{marginTop: '10px'}} className="badge border-success success badge-border">{Intl.getMessage('leadsPage.online')}</span>}
                    </td>
                  )}

                  {!is_completed && !is_online && json_data && (
                    <td width="30%">
                      {quizTitle ? <span className="quiz-title">{quizTitle}</span> : <span style={{color: '#ABABAB', fontSize: '15px'}}>{Intl.getMessage('leadsPage.quizNotFinished')}</span>}
                        <br/>
                       {is_online && <span style={{marginTop: '10px'}} className="badge border-success success badge-border">{Intl.getMessage('leadsPage.online')}</span>}
                    </td>
                  )}

                  {!is_paid && is_completed && (
                    <td width="30%">
                      <span style={{color: '#ABABAB', fontSize: '15px'}}>{Intl.getMessage('leadsPage.dataHidden')}</span>
                        <br/>
                       {is_online && <span style={{marginTop: '10px'}} className="badge border-success success badge-border">{Intl.getMessage('leadsPage.online')}</span>}
                    </td>
                  )}  

                  {is_paid && is_completed && contacts && json_data && (
                    <td width="30%">
                      <div className="contacts">
                        {quizTitle && (<span className="quiz-title">{quizTitle}</span>)}
                        {contacts.name && (<span><strong>Name: </strong>{contacts.name}</span>)}
                        {contacts.email && (<span><strong>E-mail: </strong>{contacts.email}</span>)}
                        {contacts.phone && (<span><strong>Phone: </strong>{contacts.phone}</span>)}
                        {contacts.skype && (<span><strong>Skype: </strong>{contacts.skype}</span>)}
                        {contacts.whatsapp && (<span><strong>Whatsapp: </strong>{contacts.whatsapp}</span>)}
                        {contacts.telegram && (<span><strong>Telegram: </strong>{contacts.telegram}</span>)}
                        {contacts.viber && (<span><strong>Viber: </strong>{contacts.viber}</span>)}
                      </div>
                      <br/>
                      {is_online && <span style={{marginTop: '10px'}} className="badge border-success success badge-border">{Intl.getMessage('leadsPage.online')}</span>}
                    </td>
                  )}      

                  <td width="10%">
                    <div className="form-group text-center lead-action">
                      {is_paid && is_online && (<a className="btn btn-float btn-cyan action-lead" data-toggle="modal" data-target="#leadview" onClick={openViewModal}><i className="fas fa-search" /></a>)}
                      {!is_paid && !is_online && (<a className="btn btn-float btn-success action-lead" data-toggle="modal" data-target="#leadview" onClick={this.showWarning} title={Intl.getMessage('leadsPage.showTitle')}><i class="fas fa-eye"></i></a>)}
                      {is_paid && !is_online && (<a className="btn btn-float btn-cyan action-lead" data-toggle="modal" data-target="#leadview" onClick={openViewModal}><i class="fas fa-eye"></i></a>)}
                      {state !== 'archived' && (<a className="btn btn-float btn-pink action-lead" onClick={() => this.updateLeadState(id, 'archived')}><i class="fas fa-trash"></i></a>)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse>
      </div>
    )
  }
}

export default Card
