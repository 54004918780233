import React from 'react'
import { Prompt } from 'react-router-dom'
import { Intl } from '../../utils'
import { Step1Container } from '../../containers'
import { StepsTabList, Step2, Step3, Step4, Step5, Step6, Step7 } from '../../components'
import { AuthService, QuizzService } from '../../services';
import { connect } from 'react-redux'

import './style.scss'

const dcopy = require('deep-copy')
const deepEql = require('deep-eql')


class QuizFromScratchPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      alrtShow: false,
      validSteps: {},
      userData: null,
      unique_hash: null,
      state: null,
      nextStep: null,
      submit: false,
      step: 1,
      maxStep: 1,
      json: {
        ask_messengers: [
          ['whatsapp', false],
          ['telegram', false],
          ['viber', false],
          ['instagram', false],
          ['messenger', false],
          ['vkontakte', false],
        ],
        customStyles: '',
        quiz_sys_name: '',
        quiz_title: '',
        quiz_description: '',
        button_text: 'Пройти тест',
        show_results: true,
        results_type: 'fixed',
        results: [],
        results_formula: '',
        questions: [],
        request_contacts: true,
        request_contacts_title: 'Мы вычисляем результаты',
        request_contacts_details: 'Введите Ваши контакты, чтобы увидеть результаты теста',
        at_least_one_contact: false,
        allow_skip_contacts: false,
        enable_messangers : false,
        ask_contacts: [
          ['name', true],
          ['phone', true],
          ['email', 'required'],
          ['whatsapp', false],
          ['telegram', false],
          ['viber', false],
          ['skype', false],
        ],
        privacy_policy_text: 'Введенные данные обрабатываются в соответствии с политикой строгой конфиденциальности',
        request_contacts_button: 'Посмотреть результат',
        phone_prefix: '+7',
        metrica_id: '',
        quiz_result_text: 'Спасибо за Ваши ответы. Расчет стоимости: {RESULT} рублей. В знак благодарности за потраченное Вами время и сэкономленное время нашего менеджера мы с удовольствием дарим скидку {DISCOUNT}%. Итоговая сумма составит {DISCOUNT_RESULT} рублей.',
        // step 5
        show_expert: false,
        expert_name: 'Иван Конюхов',
        expert_position: 'Ведущий инженер',
        expert_image: '',
        background_image: '',
        side_image: '',
        major_color: '#00C87F',
        elements_color: '#00ADFF',
        text_color: '#FFFFFF',
        language: 'ru',
        // step 6
        enable_quiz: true,
        discount_offer: true,
        discount_volume: 5,
        discount_increment: true,
        discount_increase_by: 0.5,
        initial_state: 'cover',
        placement_type: 'built-in',
      },
      json_copy: {
        ask_messengers: [
          ['whatsapp', false],
          ['telegram', false],
          ['viber', false],
          ['instagram', false],
          ['messenger', false],
          ['vkontakte', false],
        ],
        customStyles: '',
        quiz_sys_name: '',
        quiz_title: '',
        quiz_description: '',
        button_text: 'Пройти тест',
        show_results: true,
        results_type: 'fixed',
        results: [],
        results_formula: '',
        questions: [],
        request_contacts: true,
        request_contacts_title: 'Мы вычисляем результаты',
        request_contacts_details: 'Введите Ваши контакты, чтобы увидеть результаты теста',
        at_least_one_contact: false,
        allow_skip_contacts: false,
        enable_messangers : false,
        ask_contacts: [
          ['name', true],
          ['phone', true],
          ['email', 'required'],
          ['whatsapp', false],
          ['telegram', false],
          ['viber', false],
          ['skype', false],
        ],
        privacy_policy_text: 'Введенные данные обрабатываются в соответствии с политикой строгой конфиденциальности',
        request_contacts_button: 'Посмотреть результат',
        phone_prefix: '+7',
        metrica_id: '',
        quiz_result_text: 'Спасибо за Ваши ответы. Расчет стоимости: {RESULT} рублей. В знак благодарности за потраченное Вами время и сэкономленное время нашего менеджера мы с удовольствием дарим скидку {DISCOUNT}%. Итоговая сумма составит {DISCOUNT_RESULT} рублей.',
        // step 5
        show_expert: false,
        expert_name: 'Иван Конюхов',
        expert_position: 'Ведущий инженер',
        expert_image: '',
        background_image: '',
        side_image: '',
        major_color: '#9AB0F8',
        elements_color: '#00ADFF',
        text_color: '#eef8ff',
        language: 'ru',
        // step 6
        enable_quiz: true,
        discount_offer: true,
        discount_volume: 5,
        discount_increment: true,
        discount_increase_by: 0.5,
        discount_units: '%',
        initial_state: 'cover',
        placement_type: 'built-in',
      },
      notifications: {
        send_owner_email: true,
        webhook_url: '',
        additional_emails: [null, null, null]
      },
      notification_copy: {
        send_owner_email: true,
        webhook_url: '',
        additional_emails: [null, null, null]
      },
      isSave: true,
    }

    this.step1Handler = this.step1Handler.bind(this)
    this.step3Handler = this.step3Handler.bind(this)
    this.step4Handler = this.step4Handler.bind(this)
    this.changeStep = this.changeStep.bind(this)
    this.setStep = this.setStep.bind(this)
    this.createQuizz = this.createQuizz.bind(this)
    this.refreshState = this.refreshState.bind(this)
    this.refreshQuestions = this.refreshQuestions.bind(this)
    this.refreshNotifications = this.refreshNotifications.bind(this)
    this.saveQuiz = this.saveQuiz.bind(this)
  }

  async componentWillMount() {
    const { unique_hash } = this.props.match.params
    let { json } = this.state
    if (unique_hash) {
      try {
        const quiz = await QuizzService.getQuizz(unique_hash)


        json = Object.assign(json, JSON.parse(quiz.json_data))
		if (json.json) {
		  let newJson = Object.assign({}, this.state.json)
		  if (newJson.json.json) {
			  delete newJson.json.json
		  }
		  this.setState({
			json: { ...newJson}, notifications: JSON.parse(quiz.notifications), unique_hash, json_copy: JSON.parse(quiz.json_data), notification_copy: JSON.parse(quiz.notifications),
		  })
		}
		if (!json.json) {
		  this.setState({
			json: { ...this.state.json, json}, notifications: JSON.parse(quiz.notifications), unique_hash, json_copy: JSON.parse(quiz.json_data), notification_copy: JSON.parse(quiz.notifications),
		  })
		}
      } catch (error) {
        console.log(error)
      }
    }
  }


  async componentDidMount() {
    const { history } = this.props
    const { unique_hash, draft } = this.props.match.params
    const {validSteps} = this.state;

    try {
      const auth = await AuthService.getUserInfo()

      if (!auth) {
        await AuthService.deauthenticateUser()
        history.push('/')
      }

      this.setState({ userData: auth })
    } catch (error) {
      console.log(error)
    }
    if (unique_hash) {
      try {
        const quiz = await QuizzService.getQuizz(unique_hash)
        let { json } = this.state
        json = Object.assign(json, JSON.parse(quiz.json_data))
		const { state } = quiz
		
    if(json && json.questions.length){
      this.setState({validSteps: {...validSteps,step1: true, step2: true}})
    }
		if (json.json) {
		  let newJson = Object.assign({}, this.state.json)
		  if (newJson.json.json) {
      delete newJson.json.json
		  }
		  this.setState({ json: {...newJson}, state })
		}
		if (!json.json) {
		  this.setState({ json: {...this.state.json, json}, state })
		}
      } catch (error) {
        console.log(error)
      }
    }
    if (draft) {
      const parseJson = JSON.parse(localStorage.draftJson);

      this.setState({validSteps: {...validSteps, step1: true, step2: !!parseJson.questions.length}})
      this.setState({ json: {...parseJson, elements_color: '#00ADFF'}, notifications: JSON.parse(localStorage.draftNotifications) })
    }
  }

  async createQuizz() {
    try {
      const { json, notifications, state } = this.state
      const { unique_hash } = this.props.match.params
      const { history } = this.props
      json.questions.map((question, index) => {
        delete json.questions[index].isCollapsed
      })
      let result = false
      if (unique_hash === undefined) {
        delete localStorage.draftJson
        delete localStorage.draftNotifications
        delete localStorage.draftCreationDate
        result = QuizzService.createQuizz({ json, notifications })
      } else {
        result = QuizzService.updateQuizz({
          json, notifications, unique_hash, state,
        })
      }
      if (result) {
        history.push('/')
      }
    } catch (error) {
      console.log(error)
    }
  }

  saveQuiz() {
    console.log('saveQuiz')
    try {
      const { json, notifications, state } = this.state
      const { unique_hash } = this.props.match.params
      json.questions.map((question, index) => {
        delete json.questions[index].isCollapsed
      })

      json.questions.map(question => {
        question.answers.map((answer, index) => {
          if(answer.discount_action === 'set_fixed') {
            delete question.answers[index].discount_increase_by
          }

          if(answer.discount_action === 'increase') {
            delete question.answers[index].discount_set
          }

          if(answer.discount_action === 'none') {
            delete question.answers[index].discount_set
            delete question.answers[index].discount_increase_by
          }
        })
      })
      const result = QuizzService.updateQuizz({
        json, notifications, unique_hash, state,
      })
      const json_copy = dcopy(json)
      const notification_copy = dcopy(notifications)
      this.setState({ json_copy, notification_copy })
    } catch (error) {
      console.log(error)
    }
  }

  setStep(action) {
    const {step, validSteps} = this.state;

    if(step === 1 && action < 1) return;

    if((validSteps['step1'] && !validSteps['step2']) && (action===2 || action===1)){
      this.setState({step: action})
    }

    if(!validSteps['step1'] || !validSteps['step2']){
      (step === 2 && action >2) && this.setShowAlert();
      return;
    }

    this.setState({step: action})
  }

  changeStep(action) {
    const {
      submit, json, step, nextStep, unique_hash, notifications, notification_copy, json_copy
    } = this.state

    if(unique_hash && !submit && (!deepEql(json_copy, json) || !deepEql(notifications, notification_copy))) {
      this.saveQuiz()
    }

    if (!unique_hash) {
      localStorage.draftJson = JSON.stringify(json)
      localStorage.draftNotifications = JSON.stringify(notifications)
      localStorage.draftCreationDate = Date.parse(new Date())
    }
    action.preventDefault()
    if (json.questions.length === 0 && nextStep > 3) {
      this.setState({ nextStep: null })
    } else {
      const { nextStep } = this.state
      if (!isNaN(nextStep) && nextStep !== null) this.setState({ step: nextStep, nextStep: null })
      const { step } = this.state
      if (nextStep === 'next' && step <= 5) {
        this.setState({ step: step + 1, nextStep: null })
      }
      if (nextStep === 'prev' && step > 1) this.setState({ step: step - 1, nextStep: null })
      if (step === 7 && submit && nextStep === null) this.createQuizz()
    }
  }

  step1Handler(data) {
    const json = { ...this.state.json };
    const validSteps = this.state.validSteps;
    json.quiz_sys_name = data.quizSysName
    json.quiz_title = data.quizTitle
    json.quiz_description = data.quizDescription
    json.button_text = data.buttonText
    json.typewriter_for_title = data.quizTypewriterForTitle
    json.show_contact_info = data.showContactInfo
    json.company_name = data.companyName
    json.description_activity = data.descriptionActivity
    json.company_phone = data.companyPhone
    json.opening_hours = data.openingHours
    json.place_at_bottom = data.placeAtBottom

    if(!data.quizSysName || !data.quizTitle){
      this.setState({validSteps: {...validSteps, step1: false}})
    }
    else{
      this.setState({validSteps: {...validSteps, step1: true}})
    }
    this.setState({ json })
  }

  step3Handler(data) {
    const json = { ...this.state.json }
    json.questions = data
    this.setState({ json })
  }

  step2ChangeHandler = (json) => {
    this.setState({json})
  }

  step4Handler(json) {
    this.setState({ json: {...json} })
  }

  refreshState(json) {
    this.setState({ json })
  }

  refreshNotifications(notifications) {
    this.setState({ notifications })
  }

  refreshQuestions(questions) {
    const { json, validSteps } = this.state
    json.questions = questions

    if(!questions.length){
      this.setState({validSteps: {...validSteps, step2: false}});
    }
    else{
      this.setState({validSteps: {...validSteps, step2: true}})
    }
    this.setState({ json : {...json} })
  }

  openPreview(event) {
    this.changeStep(event)
    window.open(`http://show.enquiz.io/q/${this.state.unique_hash}`)
  }

  setShowAlert = () => {
    this.setState({alrtShow: true}, ()=>setTimeout(()=>this.setState({alrtShow: false}), 3000))
  }

  render() {
    const {
      step, json, maxStep, notifications, json_copy, notification_copy, isSave, submit, alrtShow
	} = this.state
    const { unique_hash } = this.props.match.params;
    
    json.questions = json.questions.filter(item => item!==null);
    const {questions} = json;


    return (
      <div className="quiz-form-content">
        {alrtShow && 
          <div className="alert-window">
            <p>Добавьте хотя бы 1 вопрос для перехода на следующий шаг</p>
          </div>
        }
        {unique_hash && !submit && (
          <Prompt
            key="block-nav"
            when={!deepEql(json_copy, json) || !deepEql(notifications, notification_copy)}
            message={Intl.getMessage('steps.exitMsg')}
          />
        )}

        <form id="stepForm" onSubmit={this.changeStep}>
          <div className="card-header" style={{ padding: '0'}}>
            <div className="heading-elements" />
          </div>
          <div className="card-content collapse show" style={{ backgroundColor: 'white' }}>
            <div className="card-body">
              <div action="#" className="icons-tab-steps wizard wizard-circle" id="form_steps">
                <div className="steps clearfix">
                  <StepsTabList step={step} maxStep={maxStep} changeStep={this.changeStep} setStep={this.setStep} />
                </div>
                <div className="content clearfix">
                  {step === 1 && <Step1Container data={json} handler={this.step1Handler} />}
                  {step === 2 && <Step3 data={json} questions={questions} refreshQuestions={this.refreshQuestions} handler={this.step3Handler} />}
                  {step === 3 && <Step2 data={json} refreshState={this.refreshState} />}
                  {step === 4 && <Step4 data={json} handler={this.step4Handler}/>}
                  {step === 5 && <Step5 data={json} refreshState={this.refreshState} />}
                  {step === 6 && <Step6 data={json} userData={this.state.userData} notifications={notifications} unique_hash={unique_hash} refreshState={this.refreshState} refreshNotifications={this.refreshNotifications} />}
                  {step === 7 && <Step7 data={json} uniqueHash={unique_hash} />}
                </div>
                <div className="actions clearfix quiz-stage-footer">
                  <ul role="menu" aria-label="Pagination">
                    <li aria-hidden="false" aria-disabled="false">
                    <div className="disabled" aria-disabled="true" onClick={() => this.setStep(step - 1)}>
                        <a href="#previous" role="menuitem">
                          <input type="submit" className={`inputStep btn-create ${step === 1 ? 'disabled' : ''}`} href="#next" role="menuitem" name="prev" value={Intl.getMessage('steps.prev')}/>
                        </a>
                      </div>
                      {step !== 7 && (<input type="submit" className={`inputStep btn-create`} href="#next" role="menuitem" name="next" value={Intl.getMessage('steps.next')} onClick={() => this.setStep(step + 1)} />)}
                      {step === 2 && <li className="create-quiz-button">
                        <button className="btn-create blue" onClick={this.props.createQuiz}>
                          <i className="fas fa-plus"></i>
                          Вопрос
                        </button>
                      </li>}
                    </li>
                    <li className="quiz-see">
                      {unique_hash && !submit && (!deepEql(json_copy, json) || !deepEql(notifications, notification_copy)) && (
                        <div aria-disabled="true" className="quiz-save-button">
                          <a role="menuitem" style={{ backgroundColor: '#FF9149' }}>
                            <input type="submit" className="inputStep btn-create" role="menuitem" value={Intl.getMessage('steps.save')} />
                          </a>
                        </div>
                      )}
                      {unique_hash && !submit && !(!deepEql(json_copy, json) || !deepEql(notifications, notification_copy)) && (
                        <div aria-disabled="true">
                          <div style={{ fontSize: '18px', color: 'black'}}>{Intl.getMessage('steps.saved')}</div>
                        </div>
                      )}
                      {this.state.unique_hash && 
                        <button className="previewOpen" type="button" onClick={(event) => this.openPreview(event)}>
                          <i className="fas fa-eye" />
                          <span>Предпросмотр</span>
                        </button>
                      }
                      {step === 7 && (<input type="submit" className="inputStep btn-create btn-pink" href="#next" role="menuitem" name="next" value={Intl.getMessage('steps.save')} onClick={() => this.setState({ submit: true, nextStep: null, isSave: false })} />)}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createQuiz: () => dispatch({ type:'CREATE_QUIZ'})
  }
};

export default connect(null, mapDispatchToProps)(QuizFromScratchPage);
