import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss'

const FooterQuiz = () => {
    return (
        <footer className="quiz-fooer">
            <Link to="/partner">Партнерам </Link>
            <Link to="/support">Поддержка </Link>
        </footer>
    )
}

export default FooterQuiz;
