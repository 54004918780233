import React from 'react'
import FixedResult from '../FixedResult'
import ComputedResult from '../ComputedResult'

const Result = ({
  questions, results_formula, handleChangeFormula, setInputClick,
  results, toggle, isVisible, deleteResult, editResult, preview, type, setResultType, clearResultData, resultId
}) => {
  const fixed = (<FixedResult
    toggle={toggle}
    isVisible={isVisible}
    results={results}
    questions={questions}
    deleteResult={deleteResult}
    editResult={editResult}
    preview={preview}
    setResultType={setResultType}
    clearResultData={clearResultData}
    resultType={type}
    selectedId={resultId}
  />)

  const computed = (<ComputedResult
    questions={questions}
    results_formula={results_formula}
    handleChangeFormula={handleChangeFormula}
    setInputClick={setInputClick}
  />)

  if (type === 'fixed') {
    return fixed
  } else if (type === 'computed') {
    return computed
  } else if (type === 'mixed') {
    return (
      <React.Fragment>
        {computed}
        {fixed}
      </React.Fragment>
    )
  }
  return null
}

export default Result
