const config = require('../config/config')

class UploadService {
  static async uploadImage(data) {
    try {
      let response = await fetch(config.IMAGE_UPLOAD_URL, {
        method: 'POST',
        body: data,
      })

      response = await response
      if (response.status !== 200) return false
      response = await response.json()

      return response.Url
    } catch (error) {
      throw error
    }
  }
}

export default UploadService
