import React, {createRef} from 'react'
import { connect } from 'react-redux';
import './PartnerPage.scss';

class SupportPage extends React.Component {
  constructor(props) {
    super(props)
    this.quizContaner = React.createRef();
    this.linkRef = createRef();
  }

  copyToClipboard = (e) => {
    this.linkRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  };

  render() {
    return (
      <div className="partner-page">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-12">
                        <div className="float-left pl-2">
                          <p className="card-count txt-dark">{this.props.userInfo.partner_total || 0} ₽</p> 
                        </div>
                        <div className="float-left mt-2 ml-1">
                          <p className="card-title">Доход за все время</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-12">
                        <div className="float-left pl-2">
                          <p className="card-count txt-green">{this.props.userInfo.partner_balance || 0} ₽</p>
                        </div>
                        <div className="float-left mt-2 ml-1">
                          <p className="card-title">Доступный баланс</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-12">
                        <div className="float-left pl-2">
                          <p className="card-count txt-black">{this.props.userInfo.total_regs || 0}</p>
                        </div>
                        <div className="float-left mt-2 ml-1">
                          <p className="card-title">Привлеченных клиентов</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-12">
                        <div className="float-left pl-2">
                          <p className="card-count txt-pink">{this.props.userInfo.comission}%</p>
                        </div>
                        <div className="float-left mt-2 ml-1">
                          <p className="card-title">Ваша комиссия</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="partner-content">
          <div className="row partner-info">
            <div>
              <div className="card-info">
                <div className="card-header">
                  <h4 className="card-title">Партнерская ссылка</h4>
                </div>
                <div className="card-block">
                  <div className="card-body">
                    <fieldset className="form-group partner-link">
                      <input ref={this.linkRef} type="text" className="form-control" readOnly="readonly" value={"https://enquiz.io/?a=" + this.props.userInfo.string_id} />
                    </fieldset>
                    <i class="fas fa-copy" onClick={this.copyToClipboard}></i>
                  </div>
                </div>
              </div>
            </div>
            <div>
                <div className="card-info">
                <div className="card-header">
                  <h4 className="card-title">Вывод средств</h4>
                </div>
                <div className="card-block">
                  <div className="card-body">
                    <fieldset className="form-group partner-payment">
                      <input type="text" className="form-control" id="readonlyInput" readonly="readonly" value="0" />
                    </fieldset>
                    <button className="btn-create">Вывести</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row partner-about">
            <div>
              <div>
                <div className="card-content">
                  <div className="card-body">
                    <div className="card-title">
                      <h4>Пожизненный пассивный доход </h4>
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="card-text">Приводите клиента 1 раз, а получаете доход все время пока он платит. Ваша комиссия растет вместе с вашими клиентами. 
                    Ознакомьтесь с вашей текущей статистикой на этой странице ниже. Получайте регулярные выплаты различными способами.</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="card-content">
                  <div className="card-body">
                    <div className="card-title">
                      <h4>Расширение спектра услуг</h4>
                      <i class="far fa-check"></i>
                    </div>
                    <p className="card-text">Если вы оказываете услуги по разработке сайтов, лендингов или занимаетесь рекламой, то добавление квизов в список услуг 
                      выделит вас на фоне конкрурентов. Тренд пока нарастающий, не упускайте возможностей.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="card-content">
                  <div className="card-body">
                    <div className="card-title">
                      <h4>Надежная и продвинутая платформа</h4>
                      <i class="fas fa-check"></i>
                    </div>
                    <p className="card-text"> Наш конструктор постоянно совершенствуется и радует клиентов новыми функциями и улучшениями. 
                    Предоставьте нам всю заботу по поддержанию технической и клиентской составляющей.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
})

export default connect(mapStateToProps)(SupportPage)
