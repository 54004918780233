export const packagesData = [
    
    {
        text: 'Создание квизов для сбора контактов',
        active: true
    },
    {
        text: 'Использование готовых шаблонов',
        active: true
    },
    {
        text: 'Расчет результата по формуле',
        active: true
    },
    {
        text: 'Неограниченное количество заявок',
        active: false
    },
    {
        text: 'Возможность убрать логотип Enquiz',
        active: false
    },
    {
        text: 'Квизы без сбора контактов',
        active: false
    },
    {
        text: 'Показ результата до формы контактов',
        active: false
    },
    {
        text: 'Перенаправление клиента в мессенджеры',
        active: false
    },
    {
        text: 'Приоритетная тех. поддержка',
        active: false
    },
]