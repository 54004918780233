import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { MainMenu } from '../../components'
import { AuthService } from '../../services'
import * as userInfoActions from '../../actions/userInfoActions'

class MainMenuContainer extends React.Component {
  // constructor(props) {
  //   super(props)
  //
    // this.state = {
    //   quizzes: 0,
    //   totalLeads: 0,
    //   leadLeft: '',
    //   is_confirmed: true,
    // }
  // }
  
  // async componentDidMount() {
  //   const { user } = await AuthService.getUserInfo()
  //   if (user) {
  //     let { quizzes, new_leads: totalLeads, leads_left: leadsLeft, is_confirmed } = user
  //     if (!quizzes) quizzes = 0
  //     if (!totalLeads || totalLeads === 0) totalLeads = ''
  //     // if (!leadsLeft) leadsLeft = 0
  //     if (leadsLeft === -1) leadsLeft = 'Unlimited'
  //     this.setState({ quizzes, totalLeads, leadsLeft, is_confirmed })
  //   }
  // }
  getTitleUnlimited(count) {
    if (count < 0) {
      return `Unlim. x${Math.abs(count)}`
    } else {
      return count.toString()
    }
  }
  
  render() {
    const { quizzes, new_leads, leads_left, is_confirmed } = this.props.userInfo;
    
    return (
      <MainMenu
        quizzes={quizzes || 0}
        totalLeads={new_leads || ''}
        leadsLeft={(leads_left) ? this.getTitleUnlimited(leads_left) : '0'}
        is_confirmed={is_confirmed}
      />
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
})

// export default connect(mapStateToProps)(withRouter(MainMenuContainer))
export default withRouter(connect(mapStateToProps)(MainMenuContainer))
