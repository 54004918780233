import { emailProfileConstants } from '../constants'

const initialState = {
  emailProfileList: [],
  emailProfileLoading: false,
  emailProfileError: null,
}

const emailProfile = (state = initialState, action) => {
  switch (action.type) {
    case emailProfileConstants.EMAIL_PROFILE_GETLIST_REQUEST:
    case emailProfileConstants.EMAIL_PROFILE_CREATE_REQUEST:
    case emailProfileConstants.EMAIL_PROFILE_DELETE_REQUEST:
      return {
        ...state,
        emailProfileLoading: true,
      }
    case emailProfileConstants.EMAIL_PROFILE_GETLIST_SUCCESS:
    case emailProfileConstants.EMAIL_PROFILE_CREATE_SUCCESS:
    case emailProfileConstants.EMAIL_PROFILE_DELETE_SUCCESS:
      return {
        ...state,
        emailProfileLoading: false,
        emailProfileList: action.emailProfileList,
      }
    case emailProfileConstants.EMAIL_PROFILE_GETLIST_FAILURE:
    case emailProfileConstants.EMAIL_PROFILE_CREATE_FAILURE:
    case emailProfileConstants.EMAIL_PROFILE_DELETE_FAILURE:
      return {
        ...state,
        emailProfileLoading: true,
        emailProfileError: action.error,
      }

    default:
      return state
  }
}

export default emailProfile
